import React, { ReactNode } from 'react'
// import Footer from '@/components/Footer'
import Header from '@/components/Header'
import MailIcon from '@/assets/mail-icon.svg?react'
import ArrowForwardIcon from '@/assets/arrow_forward_ios_new.svg?react'
import PhoneIcon from '@/assets/phone-icon.svg?react'
import { Box, Container, Grid, Typography, styled, IconButton, Link, Button } from '@mui/material'
import ContactUsFab from '@/components/ContactUsFab'

interface Props {
  children?: ReactNode
  sticky?: boolean
  // any props that come into the component
}
const OuterContainer = styled(Box)`
  display: flex;
  overflow: hidden;
  height: inherit;
  flex-direction: column;
  min-height: 100vh;
`

const InnerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: inherit;
  background-color: #fbfbfb;
`

export const Body: React.FC<Props> = ({ children }) => {
  return (
    <>
      <OuterContainer>
        <Header />
        <main>
          <InnerContainer>{children}</InnerContainer>
          <Footer />
        </main>
        <ContactUsFab />
      </OuterContainer>
    </>
  )
}

export const Footer = () => {
  const fontParrent = { xs: 12, sm: 16 }
  const fontChild = { xs: 12, sm: 16 }
  const navigate = useNavigate()
  const { changeProfileSelected } = useProfileStore()
  const { setDataSelectedUnlock } = useComparisonStore()
  return (
    <>
      <Box component="footer" sx={{ bgcolor: '#262626', color: 'white', pt: 3 }}>
        <Container maxWidth="xl">
          <Grid container spacing={2} direction={{ xs: 'column-reverse', sm: 'column', md: 'row' }}>
            <Grid item xs={12} md={6}>
              <Typography
                gutterBottom
                marginBottom={2}
                sx={{ textAlign: { xs: 'center', md: 'left' }, fontSize: fontParrent }}
              >
                NoLimit Indonesia
              </Typography>
              <Typography sx={{ textAlign: { xs: 'center', md: 'left' }, fontSize: fontChild }}>
                Jl. Setra Indah Utara II No 1A, Sukajadi, Kota Bandung, Jawa Barat 40163
              </Typography>
              <Box paddingY={2}>
                <Box display={'flex'} gap={2} sx={{ justifyContent: { xs: 'center', md: 'left' } }}>
                  <MailIcon />
                  <Typography
                    component="a"
                    href="mailto:contact@nolimit.id"
                    sx={{ color: 'white', textDecoration: 'none', fontSize: fontChild }}
                  >
                    contact@nolimit.id
                  </Typography>
                </Box>
                <Box display={'flex'} gap={2} paddingY={2} sx={{ justifyContent: { xs: 'center', md: 'left' } }}>
                  <PhoneIcon />
                  <Typography
                    component="a"
                    href="tel:+622282602415"
                    sx={{ color: 'white', textDecoration: 'none', fontSize: fontChild }}
                  >
                    +62 22-82602415
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography sx={{ fontSize: fontParrent, fontWeight: 700 }} gutterBottom>
                      IndSight
                    </Typography>
                    <IconButton href="https://nolimit.id/indsight/">
                      <Box display={'flex'} alignItems={'center'} gap={1}>
                        <ArrowForwardIcon />
                        <Typography
                          sx={{
                            fontSize: fontChild,
                            color: 'white',
                            '&:hover': {
                              textDecoration: 'underline ',
                            },
                          }}
                          textAlign="left"
                        >
                          FAQ IndSight
                        </Typography>
                      </Box>
                    </IconButton>
                    <IconButton href="https://nolimit.id/nolimit-indsight/">
                      <Box display={'flex'} alignItems={'center'} gap={1}>
                        <ArrowForwardIcon />
                        <Typography
                          sx={{
                            fontSize: fontChild,
                            color: 'white',
                            '&:hover': {
                              textDecoration: 'underline ',
                            },
                          }}
                          textAlign="left"
                        >
                          IndSight Report
                        </Typography>
                      </Box>
                    </IconButton>
                    {/* <IconButton href="https://indsight.id/blog">
                      <Box display={'flex'} alignItems={'center'} gap={1}>
                        <ArrowForwardIcon />
                        <Typography
                          sx={{
                            fontSize: fontChild,
                            color: 'white',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                          textAlign="left"
                        >
                          Blog
                        </Typography>
                      </Box>
                    </IconButton> */}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography sx={{ fontSize: fontParrent, fontWeight: 700 }} gutterBottom>
                      Features
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setDataSelectedUnlock(null)
                        navigate('/comparison')
                      }}
                    >
                      <Box display={'flex'} alignItems={'center'} gap={1}>
                        <ArrowForwardIcon />
                        <Typography
                          sx={{
                            fontSize: fontChild,
                            color: 'white',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                          textAlign="left"
                        >
                          Comparison
                        </Typography>
                      </Box>
                    </IconButton>
                    <Link component={Button} onClick={() => window.open('https://now.indsight.id', '_blank')}>
                      <Box display={'flex'} alignItems={'center'} gap={1}>
                        <ArrowForwardIcon />
                        <Typography
                          sx={{
                            fontSize: fontChild,
                            color: 'white',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                          textAlign="left"
                          textTransform="none"
                        >
                          IndSight Now
                        </Typography>
                      </Box>
                    </Link>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography sx={{ fontSize: fontParrent, fontWeight: 700 }} gutterBottom>
                      Company
                    </Typography>
                    <IconButton href="https://nolimit.id/tentang-kami/">
                      <Box display={'flex'} alignItems={'center'} gap={1}>
                        <ArrowForwardIcon />
                        <Typography
                          sx={{
                            fontSize: fontChild,
                            color: 'white',
                            '&:hover': {
                              textDecoration: 'underline ',
                            },
                          }}
                          textAlign="left"
                        >
                          About Us
                        </Typography>
                      </Box>
                    </IconButton>
                    <IconButton href="https://nolimit.id/kontak-indsight">
                      <Box display={'flex'} alignItems={'center'} gap={1}>
                        <ArrowForwardIcon />
                        <Typography
                          sx={{
                            fontSize: fontChild,
                            color: 'white',
                            '&:hover': {
                              textDecoration: 'underline ',
                            },
                          }}
                          textAlign="left"
                        >
                          Contact Us
                        </Typography>
                      </Box>
                    </IconButton>
                    <IconButton href="https://nolimit.id/siaran-pers">
                      <Box display={'flex'} alignItems={'center'} gap={1}>
                        <ArrowForwardIcon />
                        <Typography
                          sx={{
                            fontSize: fontChild,
                            color: 'white',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                          textAlign="left"
                        >
                          Pers Release
                        </Typography>
                      </Box>
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography sx={{ fontSize: fontParrent, fontWeight: 700 }} gutterBottom>
                      Settings Profile
                    </Typography>
                    {/* <Link underline="always" variant='body1' sx={{ textDecoration: 'underline' }}> */}
                    <IconButton
                      onClick={() => {
                        navigate('/profile')
                        changeProfileSelected(1)
                      }}
                    >
                      <Box display={'flex'} alignItems={'center'} gap={1}>
                        <ArrowForwardIcon />
                        <Typography
                          sx={{
                            fontSize: fontChild,
                            color: 'white',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                          textAlign="left"
                        >
                          Account Detail Info
                        </Typography>
                      </Box>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        navigate('/profile')
                        changeProfileSelected(2)
                      }}
                    >
                      <Box display={'flex'} alignItems={'center'} gap={1}>
                        <ArrowForwardIcon />
                        <Typography
                          sx={{
                            fontSize: fontChild,
                            color: 'white',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                          textAlign="left"
                        >
                          Coin Balance & History
                        </Typography>
                      </Box>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        navigate('/profile')
                        changeProfileSelected(3)
                      }}
                    >
                      <Box display={'flex'} alignItems={'center'} gap={1}>
                        <ArrowForwardIcon />
                        <Typography
                          sx={{
                            fontSize: fontChild,
                            color: 'white',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                          textAlign="left"
                        >
                          List My Entity
                        </Typography>
                      </Box>
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Box textAlign="center" mt={5} pb={2} bgcolor={'rgba(38, 38, 38, 1)'} justifyContent={'flex-end'}>
          <Typography sx={{ fontSize: { xs: 10, sm: 14 } }}>
            Copyright © 2024 NoLimit Indonesia. All Rights Reversed
          </Typography>
        </Box>
      </Box>
    </>
  )
}
