import { create } from 'zustand'
import moment from 'moment'
import { devtools } from 'zustand/middleware'
export interface SelectedEntity {
  entityId?: string
  id?: string
  name?: string
  displayPicture?: string
  currentTalk: number
  pastTalk: number
  growthTalk: number
  pastOrder: number
  currentOrder: number
  growthOrder: number
}
interface DataItem {
  entityId: string
  startDate: string
  endDate: string
  price: number
  name: string
  displayPicture: string
}
interface ComparisonStore {
  comparisonSelectedList: Array<string>
  selectedItems: string[]
  selectedEntity: SelectedEntity
  searchQuery: string
  startDate: string
  endDate: string
  startDateDropDown: string
  endDateDropDown: string
  selectedPlatform: string
  unlockStartDate: string
  unlockEndDate: string
  tempSelectedSubCategory: string[]
  setTempSelectedSubCategory: (newItems: string[]) => void
  selectedSubCategory: string[]
  setSelectedSubCategory: (newItems: string[]) => void
  setSelectedItems: (newItems: string[]) => void
  setSelectedEntity: (newItems: SelectedEntity) => void
  selectedItemsName: string[]
  dataSelectedUnlock: DataItem[]
  addDataUnlock: (newItem: DataItem) => void
  setDataSelectedUnlock: (newItem: DataItem) => void
  setSelectedItemsName: (newItems: string[]) => void
  setSearchQuery: (query: string) => void
  setSelectedPlatform: (selectedPlatform: string) => void
  setComparisonSelectedList: (list: Array<string>) => void
  setEndDate: (date: string) => void
  setStartDate: (date: string) => void
  setEndDateDropDown: (date: string) => void
  setStartDateDropDown: (date: string) => void
  setUnlockEndDate: (date: string) => void
  setUnlockStartDate: (date: string) => void
  updateDateRange: (entityId: string, startDate: string, endDate: string) => void
  updatePricePerEntity: (entityId: string, price: number) => void
  deleteDataUnlock: (entityId: string) => void
}

const now = moment()

export const useComparisonStore = create(
  devtools<ComparisonStore>(
    (set) => ({
      comparisonSelectedList: [],
      selectedItems: [],
      selectedItemsName: [],
      searchQuery: '',
      startDate: now.clone().subtract(30, 'days').format('YYYY-MM-DD'),
      endDate: `${now.clone().subtract(1, 'days').format('YYYY-MM-DD')}`,
      startDateDropDown: '',
      endDateDropDown: '',
      selectedPlatform: 'all',
      selectedEntity: {
        entityId: '',
        name: '',
        displayPicture: '',
        currentTalk: 0,
        pastTalk: 0,
        growthTalk: 0,
        pastOrder: 0,
        currentOrder: 0,
        growthOrder: 0,
      },
      unlockStartDate: '',
      unlockEndDate: '',
      dataSelectedUnlock: [],
      setDataSelectedUnlock: () => set({ dataSelectedUnlock: [] }),
      addDataUnlock: (newItem) => set((state) => ({ dataSelectedUnlock: [...state.dataSelectedUnlock, newItem] })),
      deleteDataUnlock: (entityId) =>
        set((state) => ({
          dataSelectedUnlock: state.dataSelectedUnlock.filter((item) => item.entityId !== entityId),
        })),
      updateDateRange: (entityId, startDate, endDate) =>
        set((state) => ({
          dataSelectedUnlock: state.dataSelectedUnlock.map((item) => {
            if (item.entityId === entityId) return { ...item, startDate, endDate }
            return item
          }),
        })),
      updatePricePerEntity: (entityId, price) =>
        set((state) => ({
          dataSelectedUnlock: state.dataSelectedUnlock.map((item) =>
            item.entityId === entityId ? { ...item, price } : item,
          ),
        })),
      setSelectedEntity: (list) => {
        set({ selectedEntity: list })
      },
      setSelectedPlatform: (selectedPlatform) => set(() => ({ selectedPlatform })),
      setComparisonSelectedList: (list) => {
        set({ comparisonSelectedList: list })
      },
      setSelectedItems: (newItems) => set({ selectedItems: newItems }),
      setSelectedItemsName: (newItems) => set({ selectedItemsName: newItems }),
      setSearchQuery: (query) => set({ searchQuery: query }),
      setEndDate: (date) => set({ endDate: date }),
      setStartDate: (date) => set({ startDate: date }),
      setStartDateDropDown: (date) => set({ startDateDropDown: date }),
      setEndDateDropDown: (date) => set({ endDateDropDown: date }),
      setUnlockEndDate: (date) => set({ unlockEndDate: date }),
      setUnlockStartDate: (date) => set({ unlockStartDate: date }),
      tempSelectedSubCategory: [],
      setTempSelectedSubCategory: (newItems) => set({ tempSelectedSubCategory: newItems }),
      selectedSubCategory: [],
      setSelectedSubCategory: (newItems) => set({ selectedSubCategory: newItems }),
    }),
    { name: 'ComparisonStore', trace: 'true' },
  ), // Adding a name for the store in Redux DevTools
)
