/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CoinIcon from '@/assets/coin-icon.svg?react'
import { useUserWallet } from '@/hooks/User/useUser'
import { formatCurrency, getToken } from '@/utils/storage'

export const CoinInfo = () => {
  const navigate = useNavigate()
  const token = getToken()
  const { data: userWallet } = useUserWallet()
  const { setWalletUser } = useProfileStore()
  const userWalletData = userWallet?.payload

  useEffect(() => {
    if (userWalletData) {
      setWalletUser(userWalletData?.walletId)
    }
  }, [userWalletData])

  return (
    <Box
      onClick={() => (token ? navigate('/topup') : navigate('/signin'))}
      sx={{
        border: '1px solid white',
        borderRadius: '10px',
        backgroundColor: 'white',
        marginLeft: '10px',
        marginRight: { xs: 1, sm: 0 },
        padding: '6.5px 6.5px',
        display: 'flex',
        alignItems: 'center',
        '&:hover': { cursor: 'pointer' },
        gap: 0,
        boxShadow: 1,
      }}
      minWidth="100px"
      width="auto"
      height={{ xs: 25, sm: 'auto' }}
    >
      <CoinIcon />
      <Typography sx={{ marginLeft: '10px', color: 'black', fontSize: { xs: 9, sm: 14 }, fontWeight: 600 }}>
        {formatCurrency(userWalletData?.balance) || 0} coins
      </Typography>
    </Box>
  )
}
