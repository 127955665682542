/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-catch */
import { API } from './api'

export const getTotalPostmade = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/total_post`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getTotalFollower = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/total_follower`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getTotalPostTalk = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/total_talk`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getSentimentScore = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/sentiment_score`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getEngagementRate = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/engagement_rate`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getTopPost = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/top_post`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getPostType = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/post_type`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getSentimentAnalysis = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/sentiment_analysis`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getPostTypeByEngagement = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/top_talker_by_engagement`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getConversationByMedia = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/conversation_by_media`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getDayToDayPerformance = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/day_to_day_performance`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getSocialMediaPerformance = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/social_media_performance`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getPeakTime = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/peak_time`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getWordCloud = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/word_cloud`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getDayToDayTalk = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/day_to_day_talk`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}

// ONLINE MEDIA ANALYTIC
export const getNewsPortalArticle = async (params: any) => {
  try {
    const response = await API(`api/v1/online_media/total_article`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getSentimentAnalysisOnline = async (params: any) => {
  try {
    const response = await API(`api/v1/online_media/sentiment_analysis`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getArticleByMedia = async (params: any) => {
  try {
    const response = await API(`api/v1/online_media/article_by_media`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getTalkBySentiment = async (params: any) => {
  try {
    const response = await API(`api/v1/online_media/article_by_media`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getOnlineMediaSentiment = async (params: any) => {
  try {
    const response = await API(`api/v1/online_media/article_by_media`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}

export const getOnlineMediaTotalArticle = async (params: any) => {
  try {
    const response = await API(`api/v1/online_media/total_article`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getOnlineMediaTotalMedia = async (params: any) => {
  try {
    const response = await API(`api/v1/online_media/total_media`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getOnlineMediaSentimentScore = async (params: any) => {
  try {
    const response = await API(`api/v1/online_media/sentiment_score`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getOnlineMediaChronoLogicalMedia = async (params: any) => {
  try {
    const response = await API(`api/v1/online_media/chronological_media`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getOnlineMediaChronoLogicalArticle = async (params: any) => {
  try {
    const response = await API(`api/v1/online_media/chronological_article`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getTotalMedia = async (params: any) => {
  try {
    const response = await API(`api/v1/online_media/total_media`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getTotalArticle = async (params: any) => {
  try {
    const response = await API(`api/v1/online_media/total_article`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
// export const getAuthMe = async () => {
//   try {
//     const response = await API(`api/v1/auth/me`, 'GET', null, null)
//     return response
//   } catch (error) {
//     return error
//   }
// }
// export const getDayToDayTalk = async (params: any) => {
//   try {
//     const response = await API(`api/v1/social_media/day_to_day_talk`, 'GET', params, null)
//     return response
//   } catch (error) {
//     return error
//   }
// }
// export const getDayToDayTalk = async (params: any) => {
//   try {
//     const response = await API(`api/v1/social_media/day_to_day_talk`, 'GET', params, null)
//     return response
//   } catch (error) {
//     return error
//   }
// }
// export const getDayToDayTalk = async (params: any) => {
//   try {
//     const response = await API(`api/v1/social_media/day_to_day_talk`, 'GET', params, null)
//     return response
//   } catch (error) {
//     return error
//   }
// }
