import moment from 'moment'
import { create } from 'zustand'
interface EntityStore {
  categoryIds: string
  startDate: string
  endDate: string
  metric: string
  page: number
  size: number
  search: string
  status: string
  sortBy: string
  sortDirection: string
  setCategoryIds: (categoryIds: string) => void
  setStartDate: (startDate: string) => void
  setEndDate: (endDate: string) => void
  setMetric: (metric: string) => void
  setPage: (page: number) => void
  setSize: (size: number) => void
  setSearch: (search: string) => void
  setStatus: (status: string) => void
  setSortBy: (sortBy: string) => void
  setSortDirection: (sortDirection: string) => void
}
export const useEntityStore = create<EntityStore>((set) => ({
  categoryIds: '',
  startDate: moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD'),
  endDate: moment().endOf('month').format('YYYY-MM-DD'),
  metric: '',
  page: 1,
  size: 10,
  search: '',
  status: 'ACTIVE',
  sortBy: 'createdAt',
  sortDirection: 'DESC',
  setPage: (page: number) => () => ({ page }),
  setSize: (size: number) => () => ({ size }),
  setSearch: (search: string) => () => ({ search }),
  setStatus: (status: string) => () => ({ status }),
  setSortBy: (sortBy: string) => () => ({ sortBy }),
  setSortDirection: (sortDirection: string) => () => ({ sortDirection }),
  setCategoryIds: (categoryIds: string) => set(() => ({ categoryIds })),
  setStartDate: (startDate: string) => set(() => ({ startDate })),
  setEndDate: (endDate: string) => set(() => ({ endDate })),
  setMetric: (metric: string) => set(() => ({ metric })),
}))
