import { Box, IconButton, Typography } from '@mui/material'
import NolimitIcon from '@/assets/Logo-NL.svg?react'
import { useNavigate } from 'react-router-dom'
const SuccessPage = () => {
  const navigate = useNavigate()
  return (
    <div>
      <Box
        bgcolor="rgba(251, 251, 251, 1)"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Box sx={{ position: 'absolute', top: 20 }}>
          <NolimitIcon />
        </Box>
        <Box sx={{ width: '40rem', textAlign: 'center' }}>
          <Box
            sx={{
              bgcolor: 'rgba(56, 42, 2, 1)',
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderTopRightRadius: 20,
              borderTopLeftRadius: 20,
            }}
          >
            <Box sx={{ width: '20rem' }}>
              <Typography sx={{ color: 'white', fontSize: 30, fontWeight: 700 }}>
                Thank you for your payment!
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: 'white',
              height: '15rem',
              display: 'flex',
              justifyContent: 'center',
              borderBottomRightRadius: 20,
              borderBottomLeftRadius: 20,
            }}
          >
            <Box
              sx={{
                width: '30rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontSize: 20, py: 3 }}>
                Your top up coin has been successfully added, please return to IndSight and enjoy the IndSight features.
              </Typography>
              <IconButton
                onClick={() => navigate('/')}
                sx={{ bgcolor: 'rgba(56, 42, 2, 1)', width: '50%', padding: 2, borderRadius: 3 }}
              >
                <Typography sx={{ color: 'white', fontSize: 18, fontWeight: 'bold' }}>Back to IndSight</Typography>
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default SuccessPage
