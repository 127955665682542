import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import moment from 'moment'

export default function ModalChangeAlertFrequent({
  open,
  dataEdit,
  setDataEdit,
  handleClose,
  handleConfirm,
  isConfirmLoading,
}) {
  const getUTCHours = (h: number) => {
    const hour = moment().set({ hour: h, minute: 0 }).toISOString().split('T')[1].split(':')[0]
    if (hour.startsWith('0')) {
      return Number(hour.substring(1))
    } else {
      return Number(hour)
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          borderRadius: '10px',
          paddingY: 3,
          paddingX: { xs: 3, sm: 6 },
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          top: '50%',
          left: '50%',
          width: { xs: 370, sm: 550 },
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight="500" fontSize="18px">
            Edit Frequent
          </Typography>
          <CloseIcon onClick={handleClose} cursor="pointer" />
        </Stack>
        <Divider sx={{ borderWidth: '1px', marginY: '8px' }} />
        <FormControl fullWidth sx={{ marginY: '12px' }}>
          <FormLabel>Threshold Article*</FormLabel>
          <TextField
            value={dataEdit.threshold}
            onChange={(e) => setDataEdit((prev) => ({ ...prev, threshold: Number(e.target.value) }))}
            size="small"
          />
        </FormControl>
        <FormControl fullWidth sx={{ marginY: '12px' }}>
          <FormLabel>Time(s)*</FormLabel>
          <RadioGroup
            row
            value={dataEdit.alertTime}
            onChange={(e) => setDataEdit((prev) => ({ ...prev, alertTime: Number(e.target.value) }))}
          >
            <FormControlLabel value={25} control={<Radio />} label="Dynamic Time" />
            <FormControlLabel value={getUTCHours(9)} control={<Radio />} label="09:00 AM" />
            <FormControlLabel value={getUTCHours(12)} control={<Radio />} label="12:00 PM" />
          </RadioGroup>
        </FormControl>
        <Divider sx={{ borderWidth: '1px' }} />
        <Stack direction="row" justifyContent="end" marginTop="12px">
          <LoadingButton
            loading={isConfirmLoading}
            onClick={handleConfirm}
            sx={{ borderRadius: '12px' }}
            disableElevation
            disableRipple
            disableTouchRipple
            size="large"
            variant="contained"
          >
            save
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  )
}
