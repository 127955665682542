/* eslint-disable @typescript-eslint/no-explicit-any */

import { Body } from '@/layout'
import {
  Box,
  Container,
  Grid,
  InputAdornment,
  IconButton,
  Paper,
  Tab,
  Typography,
  CircularProgress,
  Button,
  useTheme,
  useMediaQuery,
  Popover,
  Stack,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from '@mui/material'
import React from 'react'
import DatePicker from 'react-datepicker'
import BackIcon from '@/assets/back-icon.svg?react'
import BackIcon1 from '@/assets/back-low-px.svg?react'
import PeopleIcon from '@/assets/people-icon.svg?react'
import CalendarIcon from '@/assets/calendar-icon.svg?react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { StreamModule } from '@/modules/Stream'
import { AudienceModule } from '@/modules/Audience'
import { AnalyticModule } from '@/modules/Analytic'
import LockIcon from '@/assets/lock-icon.svg?react'
import { getTopPost, getTotalFollower } from '@/network/api/analytic'
import { useEntityById } from '@/hooks/Entity/useEntity'
import { formatCurrency, getToken } from '@/utils/storage'
import moment from 'moment'
import { useDateProduct, useProductPrice } from '@/hooks/Entity/useHelper'
import { calculateTotalPrice } from '@/utils/helper'

import 'react-datepicker/dist/react-datepicker.css'
import '../style/customDatePickerStyles.css'
import { KeyboardArrowRight } from '@mui/icons-material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const timeOptions = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Last 7 Days', value: 'sevenDays' },
  { label: 'Last 30 Days', value: 'thirtyDays' },
  { label: 'This Month', value: 'thisMonth' },
  { label: 'Last Month', value: 'lastMonth' },
]

interface DataTop {
  isLock: string
  data?: any
}
interface ProductPrice {
  payload: {
    length: number
    price: number
    currency: string
  }
}

interface UseProductPriceReturn {
  data: ProductPrice | null
}

interface AnalyticModuleProps {
  handleUnlockEntity?: () => void
}

const EntityPage: React.FC<AnalyticModuleProps> = () => {
  const theme = useTheme()
  const isScreenSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const navigate = useNavigate()
  const token = getToken()
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const id = query.get('entityId') as string
  const { data: payloadEntity } = useEntityById(id)
  const { data: payloadDate } = useDateProduct() as UseProductPriceReturn

  const [startDateLocal, setStartDateLocal] = useState(null)
  const [endDateLocal, setEndDateLocal] = useState(null)

  const [value, setValue] = React.useState<string>('1')
  const [isLoading, setLoading] = useState(false)
  const { endDate, setEndDate, selectedEntity, startDate, selectedPlatform, setStartDate, addDataUnlock } =
    useComparisonStore()

  const [dataFollower, setDataFollower] = useState<any>('')
  const { data: productPrice } = useProductPrice()
  const [dataTopPost, setTopPost] = useState<DataTop>({} as DataTop)
  const dataEntity = payloadEntity?.payload

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const popOverId = open ? 'date-range-popOver' : undefined

  const minDate = useMemo(() => {
    return new Date(payloadDate?.payload[0]?.startDate)
  }, [payloadDate])

  const maxDate = useMemo(() => {
    return new Date(payloadDate?.payload[payloadDate?.payload?.length - 1].endDate)
  }, [payloadDate])

  const handleCloseDateRange = () => {
    setAnchorEl(null)
  }

  const handleChangeDateRange = (dates) => {
    const [start, end] = dates
    setStartDateLocal(start)
    setEndDateLocal(end)
  }

  useEffect(() => {
    setStartDateLocal(new Date(startDate))
    setEndDateLocal(new Date(endDate))
  }, [startDate, endDate])

  useEffect(() => {
    fetchTopPost()
  }, [startDate, endDate, selectedPlatform, selectedEntity])

  const fetchTopPost = async () => {
    const params = {
      startDate,
      endDate,
      entityId: id || selectedEntity?.entityId,
      platforms: selectedPlatform,
    }

    try {
      setLoading(true)
      const [response, responseTopPost] = await Promise.all([getTotalFollower(params), getTopPost(params)])
      if (response.statusCode === 200) {
        setDataFollower(response.payload)
      } else {
        console.error('Failed to fetch followers:', response)
      }

      if (responseTopPost.statusCode === 200) {
        setTopPost(responseTopPost.payload)
      } else {
        console.error('Failed to fetch post made:', responseTopPost)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue.toString())
  }

  const handleUnlockEntity = () => {
    // const startDateNotSelected = moment(startDate).subtract(1, 'months').format('YYYY-MM-01')
    // const endDateNotSelected = moment(startDate).endOf('months').format('YYYY-MM-DD')
    const newItem = {
      entityId: dataEntity?.entityId,
      startDate: startDate,
      endDate: endDate,
      displayPicture: dataEntity?.displayPicture,
      name: dataEntity?.name,
      price: calculateTotalPrice(startDate, endDate, productPrice?.payload?.price),
    }
    if (token && dataTopPost.isLock) {
      console.log('unlock entity', newItem)

      addDataUnlock(newItem)
      navigate(`/unlock?entityId=${id}&startDate=${startDate}&endDate=${endDate}`)
    } else if (!token) {
      navigate('/topup')
    }
  }
  const fontSize = { xs: '14px', sm: '16px' }
  const fontButton = { xs: '8px', sm: '14px' }

  const handleClickTimeFrame = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const CustomInputWithButton = () => (
    <Button
      onClick={() => {
        setStartDate(moment(startDateLocal).format('YYYY-MM-DD'))
        setEndDate(moment(endDateLocal).format('YYYY-MM-DD'))
        setAnchorEl(null)
      }}
    >
      Confirm
    </Button>
  )

  const confirmDateRange = (startDate: Date, endDate: Date) => {
    setStartDate(moment(startDate).format('YYYY-MM-DD'))
    setEndDate(moment(endDate).format('YYYY-MM-DD'))
    handleCloseDateRange()
  }

  const handleClickTimeOption = (option: string) => {
    const today = new Date()
    switch (option) {
      case 'today':
        confirmDateRange(today, today)
        break
      case 'yesterday': {
        const yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        confirmDateRange(yesterday, yesterday)
        break
      }
      case 'sevenDays': {
        const oneWeekAgo = new Date(today)
        oneWeekAgo.setDate(today.getDate() - 7)
        confirmDateRange(oneWeekAgo, today)
        break
      }
      case 'thirtyDays': {
        const thirtyDaysAgo = new Date(today)
        thirtyDaysAgo.setDate(today.getDate() - 30)
        confirmDateRange(thirtyDaysAgo, today)
        break
      }
      case 'thisMonth': {
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
        confirmDateRange(firstDayOfMonth, today)
        break
      }
      case 'lastMonth': {
        const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1)
        const lastDayOfLastMonth = new Date(firstDayOfCurrentMonth.getTime() - 1 * 24 * 60 * 60 * 1000)
        confirmDateRange(firstDayOfLastMonth, lastDayOfLastMonth)
        break
      }
      default:
        throw new Error('Time option is not valid.')
    }
  }

  return (
    <Body sticky={false}>
      <Box sx={{ backgroundColor: '#262626' }}>
        <Container maxWidth="xl">
          <Grid
            xs={12}
            container
            justifyContent="space-between"
            alignItems="flex-start"
            flexDirection={{ xs: 'column-reverse', sm: 'row' }}
            sx={{ fontSize: '12px', py: { xs: 0.5, sm: 2 } }}
          >
            {isLoading ? (
              <Box>
                <CircularProgress />
              </Box>
            ) : (
              <Grid item display="flex" gap={3}>
                <IconButton sx={{ display: { xs: 'none', sm: 'block' } }} onClick={() => navigate(-1)}>
                  <BackIcon />
                </IconButton>
                <Box
                  sx={{
                    border: '1px solid #E3E8EF',
                    display: 'flex',
                    borderRadius: 1,
                    backgroundColor: 'white',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={selectedEntity?.displayPicture || dataEntity?.displayPicture || ''}
                    style={{
                      maxWidth: 90,
                      maxHeight: 90,
                      width: 90,
                      height: 90,
                      borderRadius: 4,
                      objectFit: 'contain',
                    }}
                  />
                </Box>
                <div>
                  <Typography sx={{ color: 'white', fontSize: fontSize }}>
                    Detail entity -{' '}
                    <Typography sx={{ color: '#dfca72', fontWeight: 600, fontSize: fontSize }} component="span">
                      {selectedEntity?.name || dataEntity?.name || ''}
                    </Typography>{' '}
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: fontSize }}>{dataEntity?.category || ''}</Typography>
                  <Box display="flex" sx={{ gap: { xs: 0, sm: 1 } }}>
                    <Paper
                      sx={{
                        backgroundColor: '#DFCA72',
                        borderRadius: 2,
                        color: 'black',
                        height: { xs: 30, sm: 40 },
                        px: 2,
                        py: 0,
                        mt: 1,
                        fontSize: 14,
                        fontWeight: 400,
                        alignItems: 'center',
                        display: 'flex',
                        gap: 1,
                      }}
                    >
                      <PeopleIcon />
                      <Typography
                        sx={{ fontSize: fontButton }}
                      >{`Followers: ${formatCurrency(dataFollower?.recentValue)}`}</Typography>
                    </Paper>
                    {/* </IconButton> */}
                    <IconButton
                      onClick={() => {
                        navigate(`/comparison`)
                      }}
                    >
                      <Paper
                        sx={{
                          backgroundColor: 'White',
                          borderRadius: 2,
                          color: 'black',
                          px: 2,
                          py: 1,
                          // fontSize: 14,
                          fontWeight: 400,
                          alignItems: 'center',
                          display: 'flex',
                          gap: 1,
                          width: { xs: 100, sm: 140 },
                          height: { xs: 30, sm: 40 },
                        }}
                      >
                        <Typography sx={{ fontSize: fontButton }}>Comparison</Typography>
                        <KeyboardArrowRight sx={{ color: '#BB9555' }} />
                      </Paper>
                    </IconButton>
                  </Box>
                </div>
              </Grid>
            )}
            <Grid item width={{ xs: '100%', sm: 'auto' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'space-between', sm: 'center' },
                  alignItems: 'center',
                  width: '100%',
                  paddingBottom: { xs: 1, sm: 0 },
                }}
              >
                <Box>
                  <IconButton
                    sx={{
                      display: { xs: 'block', sm: 'none' },
                      padding: 0,
                    }}
                    onClick={() => navigate(-1)}
                  >
                    <BackIcon1 style={{ marginTop: '6px', marginRight: '15px' }} />
                  </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {dataTopPost?.isLock ? (
                    <IconButton
                      onClick={() => {
                        handleUnlockEntity()
                        // set selected timeframe one month before now
                      }}
                    >
                      <Paper
                        sx={{
                          backgroundColor: '#DFCA72',
                          borderRadius: 2,
                          color: 'black',
                          px: 2,
                          py: 1,
                          fontSize: 14,
                          fontWeight: 400,
                          alignItems: 'center',
                          display: 'flex',
                          gap: 1,
                          height: { xs: 25, sm: 40 },
                        }}
                      >
                        <LockIcon />
                        <Typography sx={{ fontSize: fontButton }}>Unlock Entity</Typography>
                      </Paper>
                    </IconButton>
                  ) : null}

                  <div>
                    <Button
                      aria-describedby={popOverId}
                      type="button"
                      onClick={handleClickTimeFrame}
                      variant="text"
                      disableRipple
                      sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        height: { xs: 25, sm: 40 },
                        fontSize: fontButton,
                        borderRadius: 2,
                        textTransform: 'unset',
                        ':hover': { bgcolor: 'white' },
                      }}
                    >
                      <InputAdornment position="start">
                        <CalendarIcon />
                      </InputAdornment>
                      <div style={{ overflow: 'auto', width: isScreenSmUp ? 'auto' : '80px' }}>
                        <Typography fontSize={{ xs: 10, sm: 14 }} textOverflow="ellipsis" noWrap>
                          {moment(startDate).format('DD MMM YYYY')} - {moment(endDate).format('DD MMM YYYY')}
                        </Typography>
                      </div>
                      <InputAdornment position="end">
                        <ArrowDropDownIcon />
                      </InputAdornment>
                    </Button>
                    <Popover
                      id={popOverId}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      onClose={handleCloseDateRange}
                    >
                      <Stack direction={isScreenSmUp ? 'row' : 'column'}>
                        {isScreenSmUp ? (
                          <List>
                            {timeOptions.map((option) => (
                              <ListItem disablePadding key={option.value}>
                                <ListItemButton dense onClick={() => handleClickTimeOption(option.value)}>
                                  <ListItemText primaryTypographyProps={{ fontSize: '12px' }}>
                                    {option.label}
                                  </ListItemText>
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          <Stack direction="row" gap={1} flexWrap="wrap" padding={1}>
                            {timeOptions.map((option, index) => (
                              <>
                                <Button
                                  sx={{ textTransform: 'none' }}
                                  onClick={() => handleClickTimeOption(option.value)}
                                >
                                  {option.label}
                                </Button>
                                {index < timeOptions.length - 1 && (
                                  <Divider orientation="vertical" variant="middle" flexItem />
                                )}
                              </>
                            ))}
                          </Stack>
                        )}
                        <Box overflow="scroll">
                          <DatePicker
                            selected={startDateLocal}
                            onChange={handleChangeDateRange}
                            startDate={startDateLocal}
                            endDate={endDateLocal}
                            minDate={minDate}
                            maxDate={maxDate}
                            selectsRange
                            inline
                          >
                            <CustomInputWithButton />
                          </DatePicker>
                        </Box>
                      </Stack>
                    </Popover>
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="xl">
        <TabContext value={value || '1'}>
          <Box sx={{ width: '100%', px: 2, boxShadow: 3, mt: 2, borderRadius: 2 }}>
            <TabList variant="fullWidth" onChange={handleChange} centered>
              <Tab
                value="1"
                label="Analytic"
                sx={{
                  fontSize: { xs: 12, sm: '14px' },
                }}
              />
              <Tab value="2" label="Audience" sx={{ fontSize: { xs: 12, sm: '14px' } }} />
              <Tab value="3" label="Stream" sx={{ fontSize: { xs: 12, sm: '14px' } }} />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ p: 0 }}>
            <AnalyticModule handleUnlockEntity={handleUnlockEntity} />
          </TabPanel>
          <TabPanel value="2" sx={{ p: 0 }}>
            <AudienceModule handleUnlockEntity={handleUnlockEntity} />
          </TabPanel>
          <TabPanel value="3" sx={{ p: 0 }}>
            <StreamModule />
          </TabPanel>
        </TabContext>
      </Container>
    </Body>
  )
}

export default EntityPage
