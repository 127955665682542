/* eslint-disable @typescript-eslint/no-explicit-any */
import { Body } from '@/layout'
import {
  Box,
  Typography,
  Container,
  Paper,
  IconButton,
  CircularProgress,
  TableHead,
  Avatar,
  FormControl,
  Button,
  useTheme,
  useMediaQuery,
  Stack,
  Dialog,
  DialogContent,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import moment, { Moment } from 'moment'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import SuccessIcon from '@/assets/success-green-icon.svg?react'
import ErrorIcon from '@/assets/error-icon.svg?react'
import BackIcon1 from '@/assets/back-low-px.svg?react'
import BackIcon from '@/assets/back-icon.svg'
import CoinIcon from '@/assets/coin-icon.svg?react'
import DeleteIcon from '@/assets/delete-red-icon.svg?react'
import DeleteModalIcon from '@/assets/delete-modal-icon.svg?react'
import { useUserProfile } from '@/hooks/User/useUser'
import { ProfileAvatar } from '@/components/Avatar'
import { useQuery as useQueries } from 'react-query'
import { getProductPrice } from '@/network/api/product'
import { CalendarIcon } from '@mui/x-date-pickers'
import MonthYearPicker from '@/components/DatePicker/MonthYearPicker'
import { useDateProduct } from '@/hooks/Entity/useHelper'
import { useOrderEntity } from '@/hooks/Entity/useEntity'
import ModalShare from '@/components/Modal'
import { getWallet } from '@/network/api/api'
import { formatCurrency } from '@/utils/storage'
import { Close, ExpandMore } from '@mui/icons-material'

const StyledTable = styled(Table)({
  border: 'none',
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #ddd',
    borderRight: 'none',
    borderLeft: 'none',
    padding: '8px 16px',
  },
  '& .MuiTableHead-root': {
    '& .MuiTableCell-root': {
      borderBottom: '1px solid #ddd',
      borderTop: '1px solid #ddd',
      borderRight: 'none',
      borderLeft: 'none',
      padding: '8px 16px',
    },
  },
})

const UnlockFeature = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'))

  const [modalSuccess, setModalSuccess] = useState(false)
  const [modalError, setModalError] = useState<boolean>(false)
  const [modalDeleteEntity, setModalDeleteEntity] = useState<boolean>(false)
  const navigate = useNavigate()
  const { data: dataUser } = useUserProfile()
  const { updateDateRange, dataSelectedUnlock, updatePricePerEntity, setDataSelectedUnlock, deleteDataUnlock } =
    useComparisonStore()
  const { data } = useDateProduct()
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [currentEntityId, setCurrentEntityId] = useState(null)
  const [dataProductPrice, setDataProductPrice] = useState<any>(null)
  const [selectOpen, setSelectOpen] = useState(false)
  const { data: dataWallet } = useQueries(['DataWallet'], () => getWallet(), {
    staleTime: 1000 * 60 * 1,
    retry: 2,
  })
  useEffect(() => {
    fetchProductPrice()
  }, [])

  const handleChangeMonth = (entityId, startDate, endDate) => {
    const dataPrice = calculatePriceOverPeriod(startDate, endDate)
    updateDateRange(entityId, startDate, endDate)
    updatePricePerEntity(entityId, dataPrice)
  }

  const fetchProductPrice = async () => {
    try {
      setLoading(true)
      const response = await getProductPrice()
      if (response.statusCode === 200) {
        setDataProductPrice(response.payload)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const triggerBackTobefore = () => {
    navigate(-1)
    setDataSelectedUnlock(null)
  }

  function calculatePriceOverPeriod(startDate, endDate) {
    const start = moment(startDate).startOf('month')
    const end = moment(endDate).endOf('month')

    const months = end.diff(start, 'months', true) // 'true' for a fractional result
    const totalMonths = Math.ceil(months)
    const totalPrice = dataProductPrice?.price * totalMonths
    return totalPrice
  }

  const monthCount = (end: Moment, start: Moment) => {
    const startMonth = start.startOf('month')
    const endMonth = end.endOf('month')
    const months = Math.ceil(endMonth.diff(startMonth, 'months', true))
    return months
  }
  const { mutate: orderEntity, isError, isSuccess, error, isLoading: loadingOrder } = useOrderEntity(dataSelectedUnlock)

  useEffect(() => {
    if (isSuccess) {
      setModalSuccess(true)
    }
    if (isError) {
      setErrorMsg(error.response.data.message)
      setModalError(true)
    }
  }, [isSuccess, isError])

  const formatNames = (data) => {
    return (
      data?.map((item) => {
        const date = new Date(item.startDate)
        const month = date.toLocaleString('en-US', { month: 'short' })
        const year = date.getFullYear()
        item.name = `${month} ${year}`
        return item
      }) ?? []
    )
  }
  const handleDeleteItem = (entityId: string) => {
    deleteDataUnlock(entityId)
  }

  const totalPrice = dataSelectedUnlock.reduce((acc, item) => acc + item.price, 0)

  if (isLoading) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={100}>
        <CircularProgress />
      </Box>
    )
  }
  return (
    <Body sticky={true}>
      <Container maxWidth="xl" sx={{ my: 4 }}>
        <Box>
          <Box display={'flex'} flexDirection={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'}>
            <Box display={'flex'} alignItems={'center'}>
              <IconButton
                style={{ display: matches ? 'block' : 'none' }}
                onClick={() => {
                  setDataSelectedUnlock(null)
                  navigate(-1)
                }}
              >
                <img src={BackIcon} />
              </IconButton>
              <Box pl={{ xs: 0, sm: 4 }}>
                <Typography display={'flex'} gap={1}>
                  <IconButton
                    sx={{
                      display: { xs: 'block', sm: 'none' },
                      padding: 0,
                    }}
                    onClick={() => {
                      setDataSelectedUnlock(null)
                      navigate(-1)
                    }}
                  >
                    <BackIcon1 style={{ marginRight: '15px' }} />
                  </IconButton>
                  Coin Balance
                </Typography>
                <Paper sx={{ padding: 2, width: { xs: '100%' } }}>
                  <Box display={'flex'} gap={2} alignItems={'center'}>
                    <IconButton
                      onClick={() => {
                        setDataSelectedUnlock(null)
                        navigate('/profile')
                      }}
                    >
                      <ProfileAvatar
                        name={''}
                        onClick={function (): void {
                          throw new Error('Function not implemented.')
                        }}
                      />
                    </IconButton>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: 16,
                        fontWeight: 600,
                        fontFamily: 'Inter',
                      }}
                    >
                      {dataUser?.payload?.fullname ? (
                        <Typography
                          sx={{ fontSize: 16, fontWeight: 500, fontFamily: 'Inter', textAlign: 'center' }}
                          component="span"
                        >
                          {dataUser?.payload?.fullname}
                        </Typography>
                      ) : null}
                      <Box display={'flex'} alignItems={'center'}>
                        <CoinIcon />
                        <Typography sx={{ marginLeft: '10px', color: 'black', fontSize: 12, fontWeight: 500 }}>
                          {formatCurrency(dataWallet?.payload?.balance)} coins
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  setDataSelectedUnlock(null)
                  navigate('/profile')
                }}
              >
                <Typography fontSize={{ xs: '12px', sm: 14 }} fontWeight={700} color={'black'}>
                  View Transaction History
                </Typography>
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ borderBottom: 1, py: { xs: 0, sm: 2 }, borderColor: 'rgba(140, 140, 140, .1)' }} />
          <Box mt={4}>
            <Paper sx={{ p: 4 }}>
              <Typography sx={{ fontSize: 16, fontWeight: 500, fontFamily: 'Inter' }}>Review</Typography>
              <Box display={'flex'} alignItems={'center'}>
                <CoinIcon />
                <Typography
                  sx={{ marginLeft: 0.4, color: 'black', fontSize: 12, fontWeight: 600, py: { xs: 0, sm: 2 } }}
                >
                  {dataProductPrice?.price} coins for 1 entity per month
                </Typography>
              </Box>
              <TableContainer style={{ maxHeight: '250px' }}>
                <StyledTable stickyHeader>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: 'rgba(223, 202, 114, 1)',
                        '& .MuiTableCell-head': {
                          paddingY: { xs: 0, md: 2 },
                          fontSize: { xs: 10, md: 16 },
                          lineHeight: 1.5,
                        },
                      }}
                    >
                      <TableCell>Entity</TableCell>
                      <TableCell>Start Month - End Month</TableCell>
                      <TableCell style={{ display: matchesMd ? '' : 'none' }} align="center">
                        Month
                      </TableCell>
                      <TableCell style={{ display: matchesMd ? '' : 'none' }} align="right">
                        Total
                      </TableCell>
                      {dataSelectedUnlock.length > 1 && <TableCell padding="checkbox"></TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataSelectedUnlock.map((item) => (
                      <TableRow key={item.entityId} role="checkbox">
                        <TableCell>
                          <Box display={'space-between'} alignItems={'center'}>
                            <Box display={'flex'} alignItems={'center'}>
                              <Avatar sx={{ borderRadius: 2 }} src={item.displayPicture} variant="square" />
                              <Typography
                                sx={{
                                  marginLeft: 1,
                                  fontSize: { xs: 12, md: 16 },
                                  fontWeight: 500,
                                  fontFamily: 'Inter',
                                }}
                              >
                                {item?.name}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ alignItems: 'center' }}>
                          <FormControl fullWidth size="small">
                            <Button
                              variant="outlined"
                              sx={{ justifyContent: 'space-between' }}
                              onClick={() => {
                                setCurrentEntityId(item.entityId)
                                setSelectOpen(true)
                              }}
                            >
                              <Stack direction="row" gap={1}>
                                <CalendarIcon />
                                <Typography sx={{ fontSize: { xs: 12, md: 16 }, fontWeight: 500, fontFamily: 'Inter' }}>
                                  {moment(item.startDate).format('MMMM')} - {moment(item.endDate).format('MMMM')}
                                </Typography>
                              </Stack>
                              <ExpandMore />
                            </Button>
                          </FormControl>
                          <Box
                            style={{ display: matchesMd ? 'none' : '' }}
                            display={'flex'}
                            justifyContent="space-between"
                            pt={2}
                          >
                            <Typography fontSize={{ xs: 12, md: 14 }}>
                              {monthCount(moment(item.endDate), moment(item.startDate)) + ' Month'}
                            </Typography>
                            <Typography fontSize={{ xs: 12, md: 14 }} display={'flex'} gap={1}>
                              <CoinIcon />
                              {calculatePriceOverPeriod(item.startDate, item.endDate)} Coin
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell style={{ display: matchesMd ? '' : 'none' }} align="center">
                          {monthCount(moment(item.endDate), moment(item.startDate))}
                        </TableCell>
                        <TableCell style={{ display: matchesMd ? '' : 'none' }} align="right">
                          <Box display={'flex'} justifyContent={'flex-end'} gap={4}>
                            <CoinIcon />
                            <Typography sx={{ marginLeft: 0.4, color: 'black', fontSize: 14, fontWeight: 500 }}>
                              {calculatePriceOverPeriod(item.startDate, item.endDate)} Coin
                            </Typography>
                          </Box>
                        </TableCell>
                        {dataSelectedUnlock.length > 1 && (
                          <TableCell padding="checkbox">
                            <IconButton
                              onClick={() => {
                                setCurrentEntityId(item.entityId)
                                setModalDeleteEntity(true)
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </TableContainer>
              {/* button process */}
              <Box display={'flex'} justifyContent={'flex-end'} flexDirection={'column'} alignItems={'flex-end'} px={2}>
                <Box display={'flex'} alignItems={'center'} gap={2} mt={3}>
                  <Typography fontWeight={500} fontSize={{ sx: 12, md: 18 }}>
                    Grand Total
                  </Typography>
                  <Box gap={3} display={'flex'} alignItems={'center'}>
                    <CoinIcon />
                    <Typography sx={{ marginLeft: 0.4, color: 'black', fontSize: { sx: 12, md: 18 }, fontWeight: 500 }}>
                      {totalPrice} Coin
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  justifyContent={'flex-end'}
                  flexDirection={'column'}
                  alignItems={'flex-end'}
                  mt={2}
                >
                  <Button
                    onClick={() => orderEntity()}
                    disabled={loadingOrder}
                    variant="contained"
                    sx={{ width: 200, borderRadius: 2 }}
                  >
                    {loadingOrder ? <CircularProgress size={24} /> : 'Unlock'}
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Container>
      {/* {console.log(startDate, '----=-=-=-=-', endDate)} */}
      <ModalShare
        open={modalSuccess}
        title={'Unlock Entity Successfull'}
        submitDescription={'Oke'}
        description={'Your premium features have been successfully unlocked, thank you!'}
        icon={<SuccessIcon />}
        handleClose={() => {
          setModalSuccess(!modalSuccess)
          setDataSelectedUnlock(null)
        }}
        onClickSubmit={triggerBackTobefore}
        oppositeButton={true}
      />
      <ModalShare
        open={modalError}
        title={'Unlock Entity Failed'}
        submitDescription={'Continue'}
        description={errorMsg}
        icon={<ErrorIcon />}
        onClickCancel={() => setModalError(!modalError)}
        handleClose={() => setModalError(!modalError)}
        onClickSubmit={() => setModalError(!modalError)}
        oppositeButton={true}
      />
      <ModalShare
        open={modalDeleteEntity}
        title={'Delete Entity Confirmation'}
        submitDescription={'Oke'}
        description={'Are you sure you want to delete this entity from your cart?'}
        icon={<DeleteModalIcon />}
        cancelDescription={'Cancel'}
        onClickCancel={() => setModalDeleteEntity(!modalDeleteEntity)}
        handleClose={() => setModalDeleteEntity(!modalDeleteEntity)}
        onClickSubmit={() => {
          handleDeleteItem(currentEntityId)
          setModalDeleteEntity(!modalDeleteEntity)
        }}
        oppositeButton={true}
      />
      <Dialog open={selectOpen} onClose={() => setSelectOpen(false)}>
        <DialogContent>
          <IconButton
            onClick={() => setSelectOpen(false)}
            sx={{
              position: 'absolute',
              right: 2,
              top: 2,
            }}
          >
            <Close />
          </IconButton>
          <MonthYearPicker
            apiData={formatNames(data?.payload)}
            onApply={({ startDate, endDate }) => {
              setSelectOpen(false)
              handleChangeMonth(currentEntityId, startDate, endDate)
              setCurrentEntityId(null)
            }}
          />
        </DialogContent>
      </Dialog>
    </Body>
  )
}

export default UnlockFeature
