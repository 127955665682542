/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Paper, Typography, Avatar, Skeleton, Stack, IconButton } from '@mui/material'
import InstagramIcon from '@/assets/IG-socmed-icon.svg?react'
// import PositifIcon from '@/assets/Positif-green-icon.svg?react'
// import NegatifIcon from '@/assets/Negatif-icon.svg?react'
// import NetralIcon from '@/assets/Netral-icon.svg?react'
import TimeIcon from '@/assets/time-icon.svg?react'
import LockBigIcon from '@/assets/lock-big-icon.svg'
import { getTopPost } from '@/network/api/analytic'
import moment from 'moment'
import CustomTooltip from '@/components/Tooltip'
interface DataTop {
  isLock: string
  data: any
}
export const TopPostAnalytic = ({ id, handleUnlockEntity }: { id: string; handleUnlockEntity: () => void }) => {
  const { startDate, endDate, selectedPlatform, selectedEntity } = useComparisonStore()
  const [isLoading, setLoading] = useState(false)
  const [dataTopPost, setTopPost] = useState<DataTop>({} as DataTop)

  useEffect(() => {
    fetchTopPost()
  }, [startDate, endDate, selectedPlatform, selectedEntity])

  const fetchTopPost = async () => {
    const params = {
      startDate,
      endDate,
      entityId: id || selectedEntity.entityId,
      platforms: selectedPlatform,
    }
    try {
      setLoading(true)
      const response = await getTopPost(params)
      if (response.statusCode === 200) {
        setTopPost(response?.payload)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (isLoading) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={100}>
        <Skeleton variant="rounded" width={1470} height={106} />
      </Box>
    )
  }

  // const benchmark = (item: string) => {
  //   switch (item) {
  //     case 'above':
  //       return <PositifIcon />
  //     case 'below':
  //       return <NegatifIcon />
  //     case 'neutral':
  //       return <NetralIcon />
  //     default:
  //       break
  //   }
  // }

  return (
    <Paper sx={{ p: 2, my: 2, borderRadius: 4, position: 'relative' }}>
      <Stack direction="row">
        <Typography fontWeight={600} fontSize={{ xs: 16, sm: 18 }}>
          Top Post
        </Typography>
        <CustomTooltip title="How is your content performance?" />
      </Stack>
      <Box sx={{ maxHeight: '500px', overflowY: 'scroll', minHeight: 300 }}>
        {dataTopPost.data?.result?.length &&
          dataTopPost.data?.result?.map((item, index: number) => (
            <Paper
              variant="outlined"
              key={item.originalId}
              sx={{
                p: 2,
                mt: 2,
                borderRadius: 3,
                ...(index >= 3 && index <= 10 && dataTopPost?.isLock
                  ? { filter: 'blur(8px)', transition: 'filter 0.3s ease-in-out' }
                  : {}),
              }}
            >
              <Box display={'flex'} alignItems={'flex-start'}>
                <Box display={'flex'} alignItems={'center'}>
                  <Avatar src={item.mediaLink} />
                  <Typography fontWeight={600} fontSize={{ xs: 12, sm: 14 }} variant="h6" sx={{ mx: 2 }}>
                    {item.fromName}
                  </Typography>
                </Box>
                <Paper
                  sx={{
                    mx: { xs: 0, sm: 4 },
                    px: 2,
                    py: 0.4,
                    borderRadius: 3,
                    bgcolor: 'rgba(223, 202, 114, 0.2)',
                  }}
                >
                  <Typography fontWeight={400} fontSize={{ xs: 9, sm: 10 }}>
                    Engagement :
                    <Typography component={'span'} fontWeight={600} fontSize={{ xs: 11, sm: 12 }}>
                      {' '}
                      {item.engagement}
                    </Typography>
                  </Typography>
                </Paper>
              </Box>
              <Box display={'flex'} mt={0.5} gap={1}>
                <InstagramIcon />
                {/* {benchmark(item.benchmark?.benchmark)} */}
              </Box>
              <Typography color={'rgba(89, 89, 89, 1)'} py={2} px={1} fontSize={{ xs: 12, sm: 14 }}>
                {item?.content}
              </Typography>
              <Box display={'flex'} alignItems={'center'} gap={1}>
                <TimeIcon />
                <Typography fontSize={10} fontWeight={400} color={'rgba(157, 157, 156, 1)'}>
                  {moment(item?.timestamp).format('DD MMM YYYY | hh:mm')}
                </Typography>
              </Box>
            </Paper>
          ))}
      </Box>
      {dataTopPost?.isLock && (
        <Box
          position={'absolute'}
          sx={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            '@media (max-width: 100px)': {
              top: '50%',
              left: '40%',
              transform: 'translate(-40%, -40%)',
            },
          }}
        >
          <IconButton onClick={handleUnlockEntity}>
            <img src={LockBigIcon} height={200} />{' '}
          </IconButton>
        </Box>
      )}
    </Paper>
  )
}
