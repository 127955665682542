/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { CssBaseline, Box, Grid, Button, Typography, IconButton } from '@mui/material'
import SuccessModalIcon from '@/assets/success-green-icon.svg?react'
import { InputPassword } from '@/components/Input/Input'
import { CarouselSign } from '@/components/Carousel'
import { resetSchema } from '@/schemas'
import BackIcon from '@/assets/back-icon.svg?react'
import { verifyForgotPassword } from '@/network/api/api'
import ModalShare from '@/components/Modal'
import LogoInsight from '@/assets/LogoIndsightAuth.svg?react'
import { getIdF, getTokenF } from '@/utils/storage'

const VerifyForgotPasswordPage = () => {
  const navigate = useNavigate()
  const [modalSuccess, setModalSuccess] = useState(false)
  const tokenF = getTokenF()
  const idF = getIdF()
  const { confirmPassword, password, changePassword, changeConfirmPassword } = useUserStore()
  const formik = useFormik({
    initialValues: {
      password: password,
      passwordConfirm: confirmPassword,
      id: idF,
      token: tokenF,
    },
    validationSchema: resetSchema, // Our Yup schema
    onSubmit: async (values, { setFieldError }) => {
      try {
        const response = await verifyForgotPassword(values)
        if (response.statusCode === 201) {
          await setModalSuccess(!modalSuccess)
        } else {
          setFieldError('email', response.message)
        }
      } catch (error: any) {
        console.log('error--->', error)
      }
    },
    enableReinitialize: true,
  })
  return (
    <div>
      <CssBaseline />
      <form onSubmit={formik.handleSubmit}>
        <Box height={'100vh'}>
          <Grid container sx={{ height: '100vh', backgroundColor: '#FBFBFB', padding: '20px' }}>
            <Grid
              rowSpacing={1}
              item
              xs={12}
              md={6}
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton onClick={() => navigate(-1)} sx={{ position: 'absolute', zIndex: 40, top: 10, left: 10 }}>
                <BackIcon />
              </IconButton>
              <IconButton
                onClick={() => navigate(-1)}
                sx={{ position: 'absolute', zIndex: 40, top: 10, right: 10, display: { md: 'none' } }}
              >
                <LogoInsight />
              </IconButton>
              <Box
                // width={400}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: { xs: '100%', md: '400px' },
                }}
              >
                <Typography
                  sx={{ marginBottom: '20px' }}
                  variant="h3"
                  fontWeight={700}
                  fontFamily={'Inter'}
                  fontSize={'28px'}
                >
                  Forgot Password
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={400}
                  fontFamily={'Inter'}
                  fontSize={'16px'}
                  sx={{ marginBottom: '20px' }}
                >
                  Enter the new password and confirm password
                </Typography>

                <InputPassword
                  label="Password"
                  placeholder="Enter Password"
                  value={password}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => changePassword(e.target.value)}
                  name={''}
                />
                <InputPassword
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
                  helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeConfirmPassword(e.target.value)}
                  name={''}
                />

                <Button
                  type="submit"
                  onClick={() => console.log('lalala')}
                  sx={{
                    backgroundColor: '#6D5103',
                    marginTop: '30px',
                    width: '400px',
                    height: '57px',
                    fontWeight: 600,
                    borderRadius: '14px',
                    '&:hover': { bgcolor: '#6D5103' },
                  }}
                >
                  <Typography sx={{ color: '#FFFFFF', fontWeight: 500 }}>Verify Password</Typography>
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ display: { xs: 'none', md: 'block' } }}>
              <CarouselSign />
            </Grid>
          </Grid>
        </Box>
        <ModalShare
          open={modalSuccess}
          oppositeButton={true}
          title={'Password change was Successful'}
          submitDescription={'Continue'}
          description={'Your password change has been successful, please click continue to login!'}
          // cancelDescription={'Cancel'}
          icon={<SuccessModalIcon />}
          // handleClose={() => setModalSuccess(!modalSuccess)}
          onClickCancel={() => setModalSuccess(!modalSuccess)}
          onClickSubmit={async () => {
            await setModalSuccess(!modalSuccess)
            await navigate('/signin')
          }}
        />
      </form>
    </div>
  )
}

export default VerifyForgotPasswordPage
