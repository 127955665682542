import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Stack } from '@mui/material'
import SubsAlertModal from '@/assets/subs-alert-modal.svg?react'
import { LoadingButton } from '@mui/lab'

export default function ModalChangeAlertStatus({ open, handleConfirm, handleClose, isActivate, isConfirmLoading }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          borderRadius: '10px',
          paddingY: 3,
          paddingX: { xs: 3, sm: 8 },
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          top: '50%',
          left: '50%',
          width: { xs: 380, sm: 550 },
          height: 380,
        }}
      >
        <Box>
          <SubsAlertModal />
        </Box>
        <Typography
          gutterBottom={true}
          sx={{
            fontFamily: 'Inter',
            fontSize: '24px',
            fontWeight: '600',
            lineHeight: '29px',
            letterSpacing: '0em',
            textAlign: 'center',
          }}
        >
          Subscription Alert
        </Typography>
        <Typography
          gutterBottom={true}
          sx={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '19px',
            letterSpacing: '0em',
            textAlign: 'center',
            marginBottom: 2,
          }}
        >
          {isActivate
            ? `Are you sure you want to enable WhatsApp notifications for this entity? You will receive updates and alerts
          related to this entity on your WhatsApp.`
            : `Are you sure you want to disable WhatsApp notifications for this entity? You will no longer receive updates and alerts related to this entity on your WhatsApp.`}
        </Typography>
        <Stack direction="row" gap={3}>
          <Button
            onClick={handleClose}
            sx={{ borderRadius: '12px' }}
            disableElevation
            disableRipple
            disableTouchRipple
            size="large"
            variant="outlined"
          >
            cancel
          </Button>
          <LoadingButton
            loading={isConfirmLoading}
            onClick={handleConfirm}
            sx={{ borderRadius: '12px' }}
            disableElevation
            disableRipple
            disableTouchRipple
            size="large"
            variant="contained"
          >
            {isActivate ? 'Yes, Enable' : 'Yes, Disable'}
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  )
}
