import { Box, Button, Container, Grid, Typography, useTheme, Link } from '@mui/material'
import indsightLogo from '@assets/LogoIndsightAuth.svg'
import { Input, InputPassword } from '@/components/Input/Input'
import { setToken } from '@/utils/storage'
import { signUpLaunchParams, signUpLaunch } from '@/network/api/api'
import { useFormik } from 'formik'
import { signUpSchema } from '@/schemas'
import { AxiosError } from 'axios'
import { ModalOtp } from '@/components/Modal/ModalOtp'
import { ModalOtpSuccess } from '@/components/Modal/ModalOtpSuccess'

const SignUpLaunch = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const {
    fullname,
    email,
    confirmPassword,
    password,
    changeEmail,
    changePassword,
    changeFullname,
    changeConfirmPassword,
    setModalOtp,
  } = useUserStore()

  const formik = useFormik({
    initialValues: {
      fullname: fullname,
      email: email,
      password: password,
      confirmPassword: confirmPassword,
    },
    validationSchema: signUpSchema, // Our Yup schema

    onSubmit: async (values, { setFieldError }) => {
      try {
        const signUpParams: signUpLaunchParams = {
          email: values.email,
          fullname: values.fullname,
          password: values.password,
        }
        const response = await signUpLaunch(signUpParams)
        const token = response.payload.token
        if (response.statusCode === 201) {
          setToken(token)
          setModalOtp(true)
        }
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          setFieldError('fullname', error.response?.data.message)
          setFieldError('email', error.response?.data.message)
          setFieldError('password', error.response?.data.message)
          setFieldError('confirmPassword', error.response?.data.message)
        }
      }
    },
    enableReinitialize: true,
  })

  return (
    <Box height="100vh" bgcolor="#FBFBFB">
      <Container maxWidth="xl">
        <Box textAlign="center" py={theme.spacing(2)}>
          <img src={indsightLogo} alt="indsight logo" />
        </Box>
        <Grid container maxWidth="400px" mx="auto">
          <Grid item xs={12} textAlign="center">
            <Box mb={4}>
              <Typography variant="h4" component="h1" fontWeight="bold">
                Create Account
              </Typography>
              <Typography variant="subtitle1">Enter the data below correctly!</Typography>
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <Input
                label="Full Name"
                placeholder="Full name"
                value={fullname}
                error={formik.touched.fullname && Boolean(formik.errors.fullname)}
                helperText={formik.touched.fullname && formik.errors.fullname}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeFullname(e.target.value)}
              />
              <Input
                label="Email"
                placeholder="Example : example@example.com"
                value={email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeEmail(e.target.value)}
              />
              <InputPassword
                label="Password"
                placeholder="Enter Password"
                value={password}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changePassword(e.target.value)}
                name={''}
              />
              <InputPassword
                label="Confirm Password"
                placeholder="Confirm Password"
                value={confirmPassword}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeConfirmPassword(e.target.value)}
                name={''}
              />
              <Box mt={2}>
                <Button size="large" fullWidth type="submit" variant="contained" sx={{ borderRadius: '12px' }}>
                  Create Account
                </Button>
              </Box>
            </form>
            <Typography mt={4}>
              Already have an account?{' '}
              <Link component="button" color={theme.palette.primary.main} onClick={() => navigate('/signin')}>
                Login
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <ModalOtp />
      <ModalOtpSuccess onClick={() => navigate('/signin')} />
    </Box>
  )
}

export default SignUpLaunch
