import { createBrowserRouter } from 'react-router-dom'
// import Signup from '@/pages/SignUp'
import Signin from '@/pages/SignIn'
import Dashboard from '@/pages/Dashboard'
import Home from '@/pages/Home'
import ProfilePage from './Profile'
import TopUpPage from './TopUp'
import ForgotPasswordPage from './ForgotPassword'
import CategoryPage from './Category'
import EntityPage from './Entity'
import ComparisonPage from './Comparison'
import UnlockFeature from './Unlock'
import VerifyForgotPasswordPage from './VerifyPassword'
import SuccessPage from './SuccessPage'
import ErrorPage from './ErrorPage'
import SignUpLaunch from './SignUpLaunch'
import SignUp from './SignUp'

// eslint-disable-next-line react-refresh/only-export-components
// const SignUpLaunch = lazy(() => import('./SignUpLaunch'))

export const routers = createBrowserRouter([
  {
    path: '/',
    element: <Dashboard />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
    ],
  },

  { path: '/profile', element: <ProfilePage /> },
  { path: '/signin', element: <Signin /> },
  {
    path: 'entity',
    element: <EntityPage />,
  },
  {
    path: 'unlock',
    element: <UnlockFeature />,
  },
  {
    path: 'comparison',
    element: <ComparisonPage />,
  },
  { path: '/topup', element: <TopUpPage /> },
  { path: 'success', element: <SuccessPage /> },
  { path: 'error', element: <ErrorPage /> },
  { path: '/signup', element: <SignUp /> },
  { path: '/signup-launch', element: <SignUpLaunch /> },
  { path: '/forgot', element: <ForgotPasswordPage /> },
  { path: '/reset-password', element: <VerifyForgotPasswordPage /> },
  { path: '/category', element: <CategoryPage /> },
])
