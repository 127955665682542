import { Box, Button, Modal, Typography } from '@mui/material'
import SuccessGreen from '@/assets/success-green-icon.svg?react'

export const ModalOtpSuccess = ({ onClick }: { onClick: () => void }) => {
  const { modalOtpSuccess, setModalOtpSuccess } = useUserStore()

  const handleOutsideClickSuccess = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      setModalOtpSuccess(false)
    }
  }

  return (
    <Modal
      open={modalOtpSuccess}
      onClose={() => {
        setModalOtpSuccess(false)
        onClick()
      }}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box
        onClick={handleOutsideClickSuccess}
        sx={{
          borderRadius: '10px',
          paddingY: 5,
          paddingX: 2,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          top: '50%',
          left: '50%',
          width: { xs: 360, sm: 550 },
          height: 400,
        }}
      >
        <Box>
          <SuccessGreen />
        </Box>
        <Typography
          // variant="div"
          gutterBottom={true}
          sx={{
            fontFamily: 'Inter',
            fontSize: '24px',
            fontWeight: '600',
            lineHeight: '29px',
            letterSpacing: '0em',
            textAlign: 'center',
          }}
        >
          OTP Verification was Successful
        </Typography>
        <Typography
          gutterBottom={true}
          sx={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '19px',
            letterSpacing: '0em',
            textAlign: 'center',
            marginBottom: 2,
          }}
        >
          Your verification has been successful, please click continue!
        </Typography>
        <Button
          disableElevation
          disableRipple
          disableTouchRipple
          onClick={() => {
            setModalOtpSuccess(false)
            onClick()
          }}
          size="large"
          variant="contained"
          sx={{
            padding: '20px 60px',
            '&:hover': {
              backgroundColor: '#6D5103',
              boxShadow: 'none',
            },
            borderRadius: '10px',
            marginBottom: '20px',
            backgroundColor: '#6D5103',
            color: 'white',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '16px',
            letterSpacing: '0em',
            textAlign: 'center',
          }}
        >
          Continue
        </Button>
      </Box>
    </Modal>
  )
}
