import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { AuthContextProvider } from './context/authContext'
import { CssBaseline } from '@mui/material'
import './index.css'
import '../node_modules/swiper/swiper.scss'
import '../node_modules/swiper/modules/navigation.scss'
import '../node_modules/swiper/modules/pagination.scss'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <CssBaseline />
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </React.StrictMode>,
)
