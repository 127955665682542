/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
  Typography,
  Tooltip,
} from '@mui/material'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { useQuery as useReactQuery } from 'react-query'
import {
  getSocialMediaBrandPositioning,
  getSocialMediaPerformanceTalk,
  getSocialMediaPerformanceAccount,
  getSocialMediaSentimentAnalysis,
} from '@/network/api/comparison'
import { formatCurrency, formatCurrencyShortForm, roundToDecimals } from '@/utils/storage'
import { Key, ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from 'react'
import CustomTooltip from '@/components/Tooltip'

interface ISocialMediaPerformanceByAccountTableHeaders {
  id: string
  label: string
}

const performanceByAccountTableHeaders: ISocialMediaPerformanceByAccountTableHeaders[] = [
  { id: 'no', label: 'No' },
  { id: 'name', label: 'Name' },
  { id: 'postMade', label: 'Postmade' },
  { id: 'engagement', label: 'Engagement' },
  { id: 'engagementRate', label: 'Engagement Rate' },
  { id: 'engagementPerAccount', label: 'Engagement/ Post' },
  { id: 'like', label: 'Like' },
  { id: 'share', label: 'Share' },
  { id: 'comment', label: 'Comment' },
  { id: 'view', label: 'View' },
  { id: 'reach', label: 'Reach' },
  { id: 'impression', label: 'Impression' },
]

const SocialMediaComparison = ({ setIsError }) => {
  const { startDate, endDate, selectedPlatform, selectedItemsName } = useComparisonStore()

  const params = {
    startDate,
    endDate,
    entityIds: selectedItemsName.join(','),
    platforms: selectedPlatform,
  }
  const { data: dataOnlineMediaBrandPositioning, isLoading: isLoadingSocialMediaBrandPositioning } = useReactQuery(
    ['SocialMediaBrandPositioning', params],
    () => getSocialMediaBrandPositioning(params),
    {
      staleTime: 1000 * 60 * 1,
      retry: 2,
    },
  )
  useEffect(() => {
    if (dataOnlineMediaBrandPositioning?.response?.data?.statusCode === 400) {
      if (
        dataOnlineMediaBrandPositioning?.response?.data?.statusCode === 400 &&
        Number(dataOnlineMediaBrandPositioning?.response?.data?.innerException?.errorCode) == 4101
      ) {
        console.log(dataOnlineMediaBrandPositioning?.response?.data?.innerException?.errorCode, '0eoror')
        setIsError(true)
      }
    }
  }, [dataOnlineMediaBrandPositioning])

  const {
    data: dataSocialMediaTalk,
    isLoading: isLoadSocialMediaTalk,
    isError: isErrorSocialMediaTalk,
  } = useReactQuery(['SocialMediaTalk', params, selectedItemsName], () => getSocialMediaPerformanceTalk(params), {
    staleTime: 1000 * 60 * 1,
    retry: 2,
  })
  const {
    data: dataSocialMediaPerformanceAccount,
    isLoading: isLoadSocialMediaPerformanceAccount,
    isError: isErrorSocialMediaPerformanceAccount,
  } = useReactQuery(
    ['SocialMediaPerformanceAccount', params, selectedItemsName],
    () => getSocialMediaPerformanceAccount(params),
    {
      staleTime: 1000 * 60 * 1,
      retry: 2,
    },
  )
  const { data: dataSentimentAnalysis, isLoading: isLoadingSentimentAnalysis } = useReactQuery(
    ['SocialMediaSentimentAnalysis', params],
    () => getSocialMediaSentimentAnalysis(params),
    {
      staleTime: 1000 * 60 * 1,
      retry: 2,
    },
  )
  const separateNumberFormat = (num: number) => {
    if (Number.isInteger(num)) {
      return parseFloat(num.toFixed(2))?.toLocaleString('id-Id')
    } else {
      return num?.toLocaleString('id-Id')
    }
  }

  const dataSeries = (dataOnlineMediaBrandPositioning?.payload?.data || []).map((res) => {
    return {
      name: res.entityName,
      data: [[res?.entityData?.positivity, res?.entityData?.popularity]],
    }
  })
  let topTalkNum = dataOnlineMediaBrandPositioning?.payload?.data?.map((values) => values.entityData?.popularity) ?? []
  if (topTalkNum.length > 0) {
    topTalkNum = Math.max(...topTalkNum)
  } else {
    topTalkNum = 0
  }
  if (isNaN(topTalkNum)) {
    topTalkNum = 1
  }

  const lcm = 40

  if (topTalkNum % lcm !== 0) {
    topTalkNum = topTalkNum + (lcm - (topTalkNum % lcm))
  }

  const yAnnotation = topTalkNum / 2
  const yAnnotationHeight = topTalkNum / 1000
  const options: ApexOptions = {
    chart: {
      animations: {
        enabled: true,
      },
      height: 500,
      toolbar: {
        show: false,
      },
    },
    colors: dataOnlineMediaBrandPositioning?.payload?.data?.entityColors,
    annotations: {
      yaxis: [
        {
          y: yAnnotation - yAnnotationHeight,
          y2: yAnnotation + yAnnotationHeight,
          borderColor: '#000000',
          fillColor: '#000000',
          strokeDashArray: 0,
          opacity: 1,
        },
      ],
      xaxis: [
        {
          x: -0.1,
          x2: 0.1,
          borderColor: '#000000',
          fillColor: '#000000',
          strokeDashArray: 0,
          opacity: 1,
        },
      ],
    },
    xaxis: {
      tickAmount: 10,
      min: -102,
      max: 102,
      labels: {
        show: false,
      },
    },
    yaxis: {
      tickAmount: 8,
      show: true,
      min: 0,
      max: topTalkNum,
      labels: {
        show: false,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: 10,
    },
    legend: {
      show: true,
      position: 'right',
      width: 400,
      formatter: function (seriesName: any, { seriesIndex, w }: any) {
        return `${seriesName} (${w.config.series[seriesIndex]?.data[0][0]?.toFixed(2)}%, ${separateNumberFormat(
          w.config.series[seriesIndex]?.data[0][1],
        )})`
      },
      offsetX: -80,
    },
  }
  function transformData(inputData) {
    const transformedData = [
      { name: 'positive', data: [] },
      { name: 'neutral', data: [] },
      { name: 'negative', data: [] },
    ]
    const labels = []

    ;(inputData?.payload?.data || [])?.forEach((entity) => {
      labels.push(entity.entityName)
      entity.entityData.forEach((dataPoint) => {
        const index = transformedData.findIndex((item) => item.name === dataPoint.name)
        if (index !== -1) {
          transformedData[index].data.push(parseFloat(dataPoint.value.toFixed(2)))
        }
      })
    })

    return {
      data: transformedData,
      label: labels,
    }
  }
  const { label, data } = transformData(dataSentimentAnalysis)
  const dataBarChart = {
    series: data,
    options: {
      chart: {
        type: 'bar',
        height: 430,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: '10px',
          colors: ['#fff'],
        },
        colors: ['#26C281', '#22A7F0', '#DC3023'],
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff'],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: label,
      },
      fill: {
        colors: ['#26C281', '#22A7F0', '#DC3023'],
      },
      colors: ['#26C281', '#22A7F0', '#DC3023'],
    } as ApexOptions,
  }
  const renderTableCell = (key: string, value: number | string) => {
    const unit = key === 'engagementRate' ? '%' : ''

    return (
      <TableCell key={key} align="center">
        <Tooltip title={Number(value).toLocaleString('id-ID')} arrow>
          <Typography>{formatCurrencyShortForm(roundToDecimals(Number(value), 2)) + unit}</Typography>
        </Tooltip>
      </TableCell>
    )
  }

  return (
    <>
      <Paper sx={{ height: '400px', paddingBottom: 10, borderRadius: 2, pl: 2 }}>
        <Typography fontWeight={500} fontSize={{ xs: 16, sm: 18 }} pt={2}>
          Brand Positioning
        </Typography>
        {isLoadingSocialMediaBrandPositioning ? (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={400}>
            <Skeleton variant="rounded" width={'95%'} height={300} />
          </Box>
        ) : (
          <Stack
            alignItems="center"
            height="100%"
            width={dataSeries.length > 1 ? { xs: 650, md: '100%' } : { xs: '100%' }}
            // style={{ backgroundColor: 'red' }}
          >
            <Box
              fontSize="12px"
              marginBottom="-28px"
              marginLeft={dataSeries.length > 1 ? '-310px' : 'none'}
              sx={{ width: '100%', textAlign: 'center' }}
            >
              {formatCurrency(topTalkNum)} Talk
            </Box>
            <Stack
              display="flex"
              direction="row"
              alignItems="center"
              height="100%"
              width="100%"
              // style={{ backgroundColor: 'transparent' }}
            >
              <Box fontSize="12px" width="5%" marginRight={dataSeries.length > 1 ? '-10px' : ''}>
                Negative -100%
              </Box>
              <Box
                fontSize="12px"
                width="100%"
                height="100%"
                // style={{ backgroundColor: 'transparent' }}
                // style={{ backgroundColor: 'red' }}
                //   marginRight="-180px"
              >
                <Chart options={options as ApexOptions} series={dataSeries} height="100%" type="scatter" />
              </Box>
              <Box fontSize="12px" width="5%" marginLeft={dataSeries.length > 1 ? '-400px' : ''}>
                Positive +100%
              </Box>
            </Stack>
            <Box
              fontSize="12px"
              marginLeft={dataSeries.length > 1 ? { xs: '-330px', md: '-300px' } : ''}
              sx={{ width: '100%', textAlign: 'center' }}
            >
              0 Talk
            </Box>
          </Stack>
        )}
      </Paper>
      <Paper sx={{ p: 2, mb: 2, mt: 2, display: 'flex', flexDirection: 'column' }}>
        <Typography mb={2} fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
          Social Media Performance based on Owned Account
        </Typography>
        {isLoadSocialMediaPerformanceAccount ? (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={400}>
            <Skeleton variant="rounded" width={'95%'} height={300} />
          </Box>
        ) : (
          <TableContainer component={Paper} style={{ maxHeight: '560px', overflow: 'auto', borderRadius: 8 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
              <TableHead sx={{ backgroundColor: 'rgba(223, 202, 114, 1)' }}>
                <TableRow
                  sx={{
                    backgroundColor: 'rgba(223, 202, 114, 1)',
                    // '& .MuiTableCell-head': {
                    //   padding: '6px 16px',
                    //   backgroundColor: 'rgba(223, 202, 114, 1)',
                    // },
                    '& .MuiTableCell-head': {
                      // padding: '6px 16px',
                      paddingY: { xs: 1, sm: 2 },
                      backgroundColor: 'rgba(223, 202, 114, 1)',
                      fontSize: { xs: 10, sm: 16 },
                      lineHeight: 1.5,
                      // backgroundColor: 'blue',
                    },
                  }}
                >
                  <TableCell>{performanceByAccountTableHeaders[0].label}</TableCell>
                  {performanceByAccountTableHeaders.slice(1).map((header) => (
                    <TableCell key={header.id} align="left">
                      {header.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoadSocialMediaPerformanceAccount &&
                  !isErrorSocialMediaPerformanceAccount &&
                  (dataSocialMediaPerformanceAccount?.payload?.data || []).map(
                    (
                      row: {
                        No: Key
                        entityName: string
                        entityData: {
                          postMade: string | number
                          engagement: string | number
                          engagementRate: string | number
                          engagementPerAccount: string | number
                          like: string | number
                          share: string | number
                          comment: string | number
                          view: string | number
                          // talk: string | number
                          reach: string | number
                          impression: string | number
                        }
                      },
                      index: number,
                    ) => (
                      <TableRow
                        key={row.No}
                        sx={{
                          // '&:last-child td, &:last-child th': { border: 0 }
                          '&:last-child td, &:last-child th': { border: 0 },
                          '& .MuiTableCell-body': {
                            fontSize: { xs: 10, sm: 16 },
                            paddingY: { xs: 1, sm: 2 },
                          },
                        }}
                      >
                        <TableCell scope="row">{index + 1}</TableCell>
                        <TableCell align="left">{row.entityName}</TableCell>
                        {performanceByAccountTableHeaders
                          .slice(2)
                          .map((header) => renderTableCell(header.id, row.entityData[header.id]))}
                      </TableRow>
                    ),
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataTable.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <Paper sx={{ p: 2, mb: 2, mt: 2, display: 'flex', flexDirection: 'column' }}>
        <Typography mb={2} fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
          Social Media Performance based on Mention
        </Typography>
        {isLoadSocialMediaTalk ? (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={400}>
            <Skeleton variant="rounded" width={'95%'} height={300} />
          </Box>
        ) : (
          <TableContainer component={Paper} style={{ maxHeight: '560px', overflow: 'auto', borderRadius: 8 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
              <TableHead sx={{ backgroundColor: 'rgba(223, 202, 114, 1)' }}>
                <TableRow
                  sx={{
                    backgroundColor: 'rgba(223, 202, 114, 1)',
                    // '& .MuiTableCell-head': {
                    //   padding: '6px 16px',
                    //   backgroundColor: 'rgba(223, 202, 114, 1)',
                    // },
                    '& .MuiTableCell-head': {
                      // padding: '6px 16px',
                      paddingY: { xs: 1, sm: 2 },
                      backgroundColor: 'rgba(223, 202, 114, 1)',
                      fontSize: { xs: 10, sm: 16 },
                      lineHeight: 1.5,
                      // backgroundColor: 'blue',
                    },
                  }}
                >
                  <TableCell>No</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="center">Talk</TableCell>
                  <TableCell align="center">Talker</TableCell>
                  <TableCell align="center">Engagement</TableCell>
                  <TableCell align="center">View</TableCell>
                  <TableCell align="center">Reach</TableCell>
                  <TableCell align="center">Impression</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoadSocialMediaTalk &&
                  !isErrorSocialMediaTalk &&
                  (dataSocialMediaTalk?.payload?.data || []).map(
                    (
                      row: {
                        entity: Key
                        entityName:
                          | string
                          | number
                          | boolean
                          | ReactElement<any, string | JSXElementConstructor<any>>
                          | Iterable<ReactNode>
                          | ReactPortal
                        entityData: {
                          talk:
                            | string
                            | number
                            | boolean
                            | ReactElement<any, string | JSXElementConstructor<any>>
                            | Iterable<ReactNode>
                            | ReactPortal
                          talker:
                            | string
                            | number
                            | boolean
                            | ReactElement<any, string | JSXElementConstructor<any>>
                            | Iterable<ReactNode>
                            | ReactPortal
                          view:
                            | string
                            | number
                            | boolean
                            | ReactElement<any, string | JSXElementConstructor<any>>
                            | Iterable<ReactNode>
                            | ReactPortal
                          engagement:
                            | string
                            | number
                            | boolean
                            | ReactElement<any, string | JSXElementConstructor<any>>
                            | Iterable<ReactNode>
                            | ReactPortal
                          reach:
                            | string
                            | number
                            | boolean
                            | ReactElement<any, string | JSXElementConstructor<any>>
                            | Iterable<ReactNode>
                            | ReactPortal
                          impression:
                            | string
                            | number
                            | boolean
                            | ReactElement<any, string | JSXElementConstructor<any>>
                            | Iterable<ReactNode>
                            | ReactPortal
                        }
                      },
                      index: number,
                    ) => (
                      <TableRow
                        key={row.entity}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          '& .MuiTableCell-body': {
                            fontSize: { xs: 10, sm: 16 },
                            paddingY: { xs: 1, sm: 2 },
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="left">{row.entityName}</TableCell>
                        {Object.keys(row.entityData).map((field) => {
                          if (field === 'comment') return
                          return (
                            <TableCell key={field} align="center">
                              <Tooltip title={row.entityData[field].toLocaleString('id-ID')} arrow>
                                <Typography>{formatCurrencyShortForm(Number(row.entityData[field]))}</Typography>
                              </Tooltip>
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    ),
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <Paper sx={{ p: 2, mb: 2, mt: 2, display: 'flex', flexDirection: 'column' }}>
        <Stack direction="row">
          <Typography fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
            Sentiment Analysis
          </Typography>
          <CustomTooltip title="How is your sentiment analysis?" />
        </Stack>
        {isLoadingSentimentAnalysis ? (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={400}>
            <Skeleton variant="rounded" width={'95%'} height={300} />
          </Box>
        ) : (
          <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
            <Box
              sx={{
                width: { xs: 500, sm: '100%' },
                height: 350,
              }}
            >
              <Chart
                options={dataBarChart.options}
                series={dataBarChart.series}
                type="bar"
                height={'100%'}
                width={'100%'}
              />
            </Box>
          </div>
        )}
      </Paper>
    </>
  )
}

export default SocialMediaComparison
