/* eslint-disable @typescript-eslint/no-explicit-any */
import { API } from './api'

export const getAudienceTotalEngageUser = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/total_engage_user`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getAudienceResponseRate = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/response_rate`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getAudienceDayToDayEngageUser = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/day_to_day_engage_user`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getAudienceEngageUserByGender = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/engaged_user_by_gender`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getAudienceEngageUserByLocation = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/engaged_user_by_location`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getAudienceEngageUserByInterest = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/engaged_user_by_interest`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getAudienceTopTalker = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/top_talker`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getAudienceTopInfluencer = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/top_influencer`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getAudienceTopTalkerByEngagement = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/top_talker_by_engagement`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
