import { request } from '@/network/axios'
import { useQuery } from 'react-query'

enum APIS {
  GENERAL = '/api/v1',
}
interface ApiResponseDateProduct {
  statusCode: number
  result: boolean
  message: string | null
  method: string
  path: string
  payload: DateProduct[]
}
interface ApiResponseProductPRice {
  statusCode: number
  result: boolean
  message: string | null
  method: string
  path: string
  payload: ProductPrice
}

interface DateProduct {
  productId: string
  name: string
  startDate: string
  endDate: string
}
interface ProductPrice {
  price: number
  currency: string
}
export const useDateProduct = () => {
  return useQuery<ApiResponseDateProduct>(['date_product'], async () => await request.get(`${APIS.GENERAL}/product`), {
    refetchOnWindowFocus: false,
  })
}
export const useProductPrice = () => {
  return useQuery<ApiResponseProductPRice>(
    ['product_price'],
    async () => await request.get(`${APIS.GENERAL}/product/price`),
    {
      refetchOnWindowFocus: false,
    },
  )
}
