import * as React from 'react'
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  TextField,
  useTheme,
  useMediaQuery,
  InputAdornment,
} from '@mui/material'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Input, InputPassword } from '../Input/Input'
import { Lock } from '@mui/icons-material'
import ProfileIcon from '@/assets/profile-black-icon.svg?react'
import SuccessModalIcon from '@/assets/success-green-icon.svg?react'
import ErrorIcon from '@/assets/error-icon.svg?react'
import { useUserProfile } from '@/hooks/User/useUser'
import { ModalUpload } from '../Modal/ModalUpload'
import { useProfileStore } from '@/store/profile'
import { changePassword, putProfile } from '@/network/api/api'
import ModalShare from '../Modal'
import { ModalOtpWhatsapp } from '../Modal/ModalOtpWhatsapp'
import { useQuery } from 'react-query'
import { sendOtpWhatsappQuery } from '@/network/api/user'
import LoadingButton from '@mui/lab/LoadingButton'
import { ModalOtpSuccess } from '../Modal/ModalOtpSuccess'
const DOMAIN_URL = import.meta.env.VITE_API_SERVER_URL
export const DetailAccount = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const navigate = useNavigate()

  const [value, setValue] = React.useState('1')
  const { openModalUpload, setOpenModalUpload } = useProfileStore()
  const [modalSuccess, setModalSuccess] = useState(false)
  const [modalFailed, setModalFailed] = useState(false)
  // const [gender, setGender] = React.useState('')
  const [userProfile, setUserProfile] = useState({
    fullname: '',
    gender: '',
    phoneNumber: '',
  })
  const [userPassword, setUserPassword] = useState({
    password: '',
    passwordConfirm: '',
  })

  const sendOtpWhatsapp = useQuery(
    ['sendOtpWa', userProfile.phoneNumber],
    () => sendOtpWhatsappQuery({ whatsapp: userProfile.phoneNumber }),
    {
      enabled: false,
      retry: false,
    },
  )
  const [modalOtp, setModalOtp] = useState(false)
  const handleVerification = () => {
    sendOtpWhatsapp.refetch().then((res) => {
      if (res.status === 'success') {
        setModalOtp(true)
      }
    })
  }

  const { data: dataUser, isLoading: isDataUserLoading } = useUserProfile()

  const user = dataUser?.payload
  useEffect(() => {
    if (user) {
      setUserProfile({
        fullname: user.fullname,
        gender: user.gender,
        phoneNumber: user.phoneNumber,
      })
    }
  }, [user])

  if (isDataUserLoading) {
    return <CircularProgress />
  }
  const handleSave = async () => {
    try {
      const response = await putProfile({
        fullname: userProfile.fullname,
        gender: userProfile.gender,
      })
      if (response.statusCode === 200) {
        setModalSuccess(true)
      } else {
        setModalFailed(true)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleSavePassword = async () => {
    try {
      const response = await changePassword({
        password: userPassword.password,
        confirmPassword: userPassword.passwordConfirm,
      })
      if (response.statusCode === 200) {
        setModalSuccess(true)
      } else {
        setModalFailed(true)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const handleNameChange = (name) => {
    setUserProfile((prev) => ({ ...prev, fullname: name }))
  }

  const handleGenderChange = (gender) => {
    const data = gender.target.value
    setUserProfile((prev) => ({ ...prev, gender: data }))
  }

  const handlePhoneNumberChange = (phoneNumber) => {
    setUserProfile((prev) => ({ ...prev, phoneNumber: phoneNumber }))
  }

  return (
    <Container maxWidth="lg" sx={{}}>
      <Box sx={{ p: { xs: 0, sm: 4 } }}>
        <Typography sx={{ fontFamily: 'Inter', fontSize: { xs: 16, sm: 28 }, pt: { xs: 2, sm: 0 }, fontWeight: 600 }}>
          Account Detail Info
        </Typography>
        <Typography
          sx={{ fontFamily: 'Inter', fontSize: 14, fontWeight: 400, py: 2, display: { xs: 'none', sm: 'block' } }}
        >
          Set your detailed information, add your photo and here you can change your password.
        </Typography>
        <Divider />
        <Card sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, mt: 2 }}>
          <Box sx={{ margin: 3, textAlign: 'center' }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Avatar
                sx={{ width: { xs: 80, sm: 150 }, height: { xs: 80, sm: 150 } }}
                src={`${DOMAIN_URL}${user?.profileImage?.substring(1)}`}
              />
            </div>
            <Typography
              sx={{ pt: { xs: 0.5, sm: 1 }, fontFamily: 'Inter', fontWeight: 600, fontSize: { xs: 14, sm: 20 } }}
            >
              {user?.fullname}
            </Typography>
            <Typography
              sx={{ py: { xs: 0.5, sm: 1 }, fontFamily: 'Inter', fontWeight: 400, fontSize: { xs: 10, sm: 14 } }}
            >
              {user?.email}
            </Typography>
            <Typography
              sx={{ py: { xs: 0.5, sm: 1 }, fontFamily: 'Inter', fontWeight: 400, fontSize: { xs: 10, sm: 14 } }}
            >
              {user?.phoneNumber || '-'}
            </Typography>

            <Typography
              onClick={() => setOpenModalUpload(!openModalUpload)}
              sx={{
                py: { xs: 0.5, sm: 1 },
                fontFamily: 'Inter',
                fontWeight: 600,
                fontSize: { xs: 12, sm: 16 },
                color: '#6D5103',
                ':hover': { cursor: 'pointer' },
              }}
            >
              Edit Profile Photo
            </Typography>
          </Box>

          <Box
            sx={{
              borderStyle: 'solid',
              borderColor: 'rgba(140, 140, 140, 0.3)',
              borderWidth: '0 0 0 1px',
              width: '100%',
            }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{
                    width: { xs: '100%', lg: 750 },
                    '& .MuiTabs-flexContainer ': {
                      justifyContent: { xs: 'center', lg: 'flex-start' },
                    },
                  }}
                  // centered
                >
                  <Tab icon={<ProfileIcon />} iconPosition="start" label="Edit Profile" value="1" />
                  <Tab icon={<Lock />} iconPosition="start" label="Change Password" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Box gap={{ xs: 0.5, sm: 2 }} sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
                  <FormControl
                    sx={{
                      width: '100%',
                      marginBottom: { xs: 2, sm: 5 },
                    }}
                  >
                    <TextField
                      type={'text'}
                      placeholder={'Example : John Doe'}
                      value={userProfile?.fullname}
                      label={'Fullname'}
                      autoFocus={false}
                      size={matches ? 'medium' : 'small'}
                      onChange={(e) => handleNameChange(e.target.value)}
                      sx={{
                        height: '40px',
                        bgColor: 'red',
                        '& .MuiInputLabel-asterisk': {
                          color: 'red',
                        },
                        '& .MuiInputBase-root': {
                          borderRadius: '14px',
                          width: 1,
                        },
                        '& .MuiFocused': {
                          border: '1px solid',
                          borderColor: 'red',
                        },
                      }}
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    sx={{
                      borderRadius: 14,
                      marginBottom: { xs: 3, sm: 0 },
                      '& .css-woymur-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
                        fontSize: { xs: '12px', sm: '16px' },
                      },
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={userProfile.gender}
                      label="Gender"
                      size={matches ? 'medium' : 'small'}
                      onChange={handleGenderChange}
                      sx={{
                        borderRadius: '13px',
                        // height: { xs: '35px', sm: '55px' },
                        // '& .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
                        //   fontSize: { xs: '12px', sm: '20px' },
                        // },
                      }}
                    >
                      <MenuItem value={'MALE'}>MALE</MenuItem>
                      <MenuItem value={'FEMALE'}>FEMALE</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Input
                  label="Email"
                  placeholder="Example : example@example.com"
                  value={user?.email}
                  helperText={''}
                  disabled
                />
                <FormControl
                  sx={{
                    width: '100%',
                    marginBottom: { xs: 2, sm: 5 },
                  }}
                >
                  <TextField
                    type={'text'}
                    placeholder={'Example : +628xxxxxxxxx'}
                    value={userProfile?.phoneNumber}
                    label={'Whatsapp Number'}
                    autoFocus={false}
                    size={matches ? 'medium' : 'small'}
                    onChange={(e) => handlePhoneNumberChange(e.target.value)}
                    sx={{
                      '& .MuiInputBase-input': {
                        fontSize: { xs: '14px', sm: '16px' },
                      },
                      '& .MuiInputLabel-root': {
                        fontSize: { xs: '14px', sm: '16px' },
                      },
                      height: '46px',
                      borderRadius: '14px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '14px',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <LoadingButton
                            disabled={user?.phoneNumber === userProfile.phoneNumber || !userProfile.phoneNumber}
                            variant="contained"
                            loading={sendOtpWhatsapp.isRefetching || sendOtpWhatsapp.isLoading}
                            sx={{
                              fontSize: { xs: '12px', sm: '16px' },
                              borderRadius: { xs: '6px', sm: '10px' },
                              height: { xs: '28px', sm: '46px' },
                            }}
                            onClick={handleVerification}
                          >
                            Verification
                          </LoadingButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <Box display={'flex'} gap={2}>
                  <Button sx={{ borderRadius: 2 }} variant="outlined">
                    Cancel
                  </Button>
                  <Button sx={{ borderRadius: 2, px: 3 }} variant="contained" onClick={handleSave}>
                    Save
                  </Button>
                </Box>
              </TabPanel>
              <TabPanel value="2">
                {' '}
                <InputPassword
                  label="Change Password"
                  placeholder="Example : example@example.com"
                  value={''}
                  helperText={''}
                  name={''}
                  onChange={(e) => setUserPassword({ ...userPassword, password: e.target.value })}
                />
                <InputPassword
                  label="Repeat Password"
                  placeholder="Example : example@example.com"
                  value={''}
                  helperText={''}
                  name={''}
                  onChange={(e) => setUserPassword({ ...userPassword, passwordConfirm: e.target.value })}
                />
                <Box display={'flex'} gap={2}>
                  <Button sx={{ borderRadius: 2 }} variant="outlined">
                    Cancel
                  </Button>
                  <Button sx={{ borderRadius: 2, px: 3 }} variant="contained" onClick={handleSavePassword}>
                    Save
                  </Button>
                </Box>
              </TabPanel>
              <TabPanel value="3">Item Three</TabPanel>
            </TabContext>
          </Box>
        </Card>
      </Box>
      <ModalUpload />
      <ModalShare
        open={modalSuccess}
        oppositeButton={true}
        title={'Edit profile was Successful'}
        submitDescription={'Oke'}
        description={'Your edit profile has been successful!!'}
        // cancelDescription={'Cancel'}
        icon={<SuccessModalIcon />}
        handleClose={() => setModalSuccess(!modalSuccess)}
        onClickCancel={() => setModalSuccess(!modalSuccess)}
        onClickSubmit={() => setModalSuccess(!modalSuccess)}
      />
      <ModalShare
        open={modalFailed}
        oppositeButton={true}
        title={'Edit profile was Failed'}
        submitDescription={'Oke'}
        description={'Your edit profile has been failed!!'}
        // cancelDescription={'Cancel'}
        icon={<ErrorIcon />}
        handleClose={() => setModalFailed(!modalFailed)}
        onClickCancel={() => setModalFailed(!modalFailed)}
        onClickSubmit={() => setModalFailed(!modalFailed)}
      />
      <ModalOtpWhatsapp
        modalOtp={modalOtp}
        setModalOtp={setModalOtp}
        phoneNumber={userProfile.phoneNumber}
        resendOtpWhatsapp={sendOtpWhatsapp}
      />
      <ModalOtpSuccess
        onClick={() => {
          location.assign('/profile')
        }}
      />
    </Container>
  )
}
