/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  InputAdornment,
  Tab,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  Stack,
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useQuery as useReactQuery } from 'react-query'
import SearchIcon from '@mui/icons-material/Search'
import InfoIcon from '@/assets/info-icon.svg?react'
import { getAllEntity, getEntityRecomendation } from '@/network/api/comparison'
import { calculateTotalPrice } from '@/utils/helper'
import { useProductPrice } from '@/hooks/Entity/useHelper'
import { Close } from '@mui/icons-material'
import lockIcon from '@assets/lock-icon.svg'

export const ModalComparison = ({
  entities,
  open,
  onClose,
  token,
}: {
  entities: any
  open: boolean
  onClose: () => void
  token?: string
}) => {
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState('')
  const {
    selectedItems,
    setSelectedItems,
    selectedItemsName,
    addDataUnlock,
    deleteDataUnlock,
    setSelectedItemsName,
    startDate,
    endDate,
  } = useComparisonStore()
  const [valueTab, setValueTab] = React.useState('2')
  const { data: productPrice } = useProductPrice()
  const filteredData = entities.filter((entity) => {
    return entity.entityName.toLowerCase().includes(searchTerm.toLowerCase())
  })
  const activeEntityIds = filteredData.map((entity) => entity.entityId).join(',')

  const [paramsEntityRecomendation] = useState({
    page: '1',
    limit: '10',
    search: '',
    entityIds: activeEntityIds,
  })
  const [paramsAllEntity, setParamsAllEntity] = useState({
    page: '1',
    limit: '10',
    search: '',
    entityIds: activeEntityIds,
  })

  const onSetParams = (id, value) => {
    setParamsAllEntity((oldParams) => ({
      ...oldParams,
      [id]: value,
    }))
  }

  const { data: dataEntityRecomendation } = useReactQuery(
    ['EntityRecomendation', paramsEntityRecomendation],
    () => getEntityRecomendation(paramsEntityRecomendation),
    {
      staleTime: 1000 * 60 * 1,
    },
  )
  const { data: dataAllEntity } = useReactQuery(['AllEntity', paramsAllEntity], () => getAllEntity(paramsAllEntity), {
    staleTime: 1000 * 60 * 1,
  })
  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: string) => {
    setValueTab(newValue)
  }

  const handleSelectAllMyEntity = (entities: any[], checked: boolean) => {
    const names = entities.map((item) => item.name)
    const ids = entities.map((item) => item?.entityId)

    if (checked) {
      setSelectedItems(names)
      setSelectedItemsName(ids)
    } else {
      setSelectedItems([])
      setSelectedItemsName([])
    }
  }

  const handleSelectItem = (itemName: string) => {
    if (selectedItems.includes(itemName)) {
      setSelectedItems(selectedItems.filter((item) => item !== itemName))
    } else {
      setSelectedItems([...selectedItems, itemName])
    }
  }

  const handleSelectedUnlock = (item: any) => {
    if (selectedItems.includes(item.name)) {
      deleteDataUnlock(item.uuid)
      setSelectedItems(selectedItems.filter((name) => name !== item.name))
      setSelectedItemsName(selectedItemsName.filter((id) => id !== item.name))
    } else {
      // const firstDateOfMonth = moment().startOf('month').format('YYYY-MM-DD')
      // const lastDateOfMonth = moment().endOf('month').format('YYYY-MM-DD')
      const dataYangMasuk = {
        entityId: item.id || item.uuid,
        startDate: startDate,
        endDate: endDate,
        price: calculateTotalPrice(startDate, endDate, productPrice?.payload?.price),
        name: item.name,
        displayPicture: item.displayPicture,
      }
      addDataUnlock(dataYangMasuk)
    }
  }

  const handleSelectItemId = (itemName: string) => {
    if (selectedItemsName.includes(itemName)) {
      setSelectedItemsName(selectedItemsName?.filter((item) => item !== itemName))
    } else {
      setSelectedItemsName([...selectedItemsName, itemName])
    }
  }

  if (!token) {
    return (
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2">
            No Access
          </Typography>
          <Typography sx={{ mt: 2 }}>
            You do not have the necessary credentials to access this feature. Please log in or contact support.
          </Typography>
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={onClose}>Close</Button>
          </Box>
        </Box>
      </Modal>
    )
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          borderRadius: '10px',
          padding: { xs: 2, md: 5 },
          my: 5,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          top: '50%',
          left: '50%',
          width: { xs: '95%', md: 900 },
          minHeight: 'minContent',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <TabContext value={valueTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChangeTab}
              sx={{ width: { xs: '100%', md: 750 } }}
              aria-label="lab API tabs example"
            >
              <Tab sx={{ fontSize: { xs: 11, md: 16 } }} label="My Entity" value="1" />
              <Tab sx={{ fontSize: { xs: 11, md: 16 }, maxWidth: '300px' }} label="Buy Entity" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ width: '100%', p: { xs: 0, md: '24px' } }}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  mb: 1,
                  // flexDirection: { xs: 'column', md: 'row' },
                }}
              >
                <FormControlLabel
                  sx={{
                    '& MuiFormControlLabel-root .MuiFormControlLabel-label': {
                      fontSize: { xs: '5px', md: '16px' },
                    },
                  }}
                  control={
                    <Checkbox
                      checked={selectedItems.length === filteredData.length}
                      onChange={(event) => handleSelectAllMyEntity(filteredData, event.target.checked)}
                    />
                  }
                  label="Select All Entity"
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 4,
                    p: 1,
                  }}
                >
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 4,
                        width: '100%',
                        height: { xs: 35, md: 40 },
                        backgroundColor: 'white',
                        fontSize: { xs: 12, md: 16 },
                      },
                    }}
                    // onChange={(e) => onSetParams('search', e.target.value)}
                    variant="outlined"
                    placeholder="Search "
                    value={searchTerm}
                    onChange={handleSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ height: { xs: 15, md: 25 }, width: { xs: 15, md: 25 } }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <Box display={'flex'} flexDirection={'column'} sx={{ flex: 1, overflow: 'auto' }}>
                <Box gap={2} sx={{ display: 'flex', height: '130px', width: '100%' }}>
                  <Grid
                    container
                    spacing={1}
                    justifyContent={'flex-start'}
                    sx={{ overflow: 'auto', width: '100%', height: '100%' }}
                  >
                    {filteredData &&
                      (filteredData || []).map((item, index) => (
                        <Grid item xs={6} sm={3} md={3} key={index}>
                          <Box
                            onClick={() => {
                              handleSelectItem(item.name)
                              handleSelectItemId(item.entityId)
                            }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '100%',
                              gap: { xs: 1, md: 4 },
                              backgroundColor: selectedItemsName.includes(item.entityId)
                                ? 'rgba(223, 202, 114, 1)'
                                : 'rgba(223, 202, 114, 0.2)',
                              border: '1px solid rgba(140, 140, 140, 0.3)',
                              borderRadius: 6,
                              padding: '8px',
                            }}
                          >
                            <Typography
                              fontSize={{ xs: 10, sm: 12, md: 14 }}
                              sx={{ textAlign: 'center', color: 'black' }}
                            >
                              {item.entityName}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button onClick={onClose} variant="contained" sx={{ px: 6, borderRadius: 3 }}>
                Process
              </Button>
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Stack direction="column" gap={2}>
              <Box>
                <Typography fontSize={{ xs: 12, md: 18 }} fontWeight={500} mb={1}>
                  Entity Recommendation
                  <Typography component={'span'}>
                    <Tooltip placement="top-end" title={'Recommended entities based on preferences.'}>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Typography>
                {/* {isLoadEntityRecomendation && <Typography>isLoading...</Typography>} */}
                {dataEntityRecomendation && (
                  <Box>
                    <Box gap={2} sx={{ display: 'flex', height: '130px' }}>
                      <Grid container spacing={1} justifyContent={'flex-start'} sx={{ overflow: 'auto' }}>
                        {dataEntityRecomendation &&
                          (dataEntityRecomendation?.payload || []).map((item, index) => (
                            <Grid item xs={6} sm={4} key={index}>
                              <Box
                                onClick={() => {
                                  handleSelectItem(item.name)
                                  handleSelectedUnlock(item)
                                }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  width: '100%',
                                  gap: { xs: 0.5, md: 4 },
                                  backgroundColor: selectedItems.includes(item.name)
                                    ? 'rgba(223, 202, 114, 1)'
                                    : 'rgba(223, 202, 114, 0.2)',
                                  border: '1px solid rgba(140, 140, 140, 0.3)',
                                  borderRadius: 6,
                                  padding: '8px',
                                }}
                              >
                                <Box
                                  sx={{
                                    width: { xs: '25px', md: '40px' },
                                    height: { xs: '25px', md: '40px' },
                                    borderRadius: '50%',
                                    backgroundColor: 'white',
                                    overflow: 'hidden',
                                  }}
                                >
                                  <img
                                    src={item.displayPicture}
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                  />
                                </Box>
                                <Typography sx={{ color: 'black', fontSize: { xs: 10, sm: 14 } }}>
                                  {item.name}
                                </Typography>
                                <Box
                                  sx={{
                                    width: { xs: '16px', md: '16px' },
                                    height: { xs: '16px', md: '16px' },
                                    pr: 1,
                                    overflow: 'hidden',
                                  }}
                                >
                                  <img src={lockIcon} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </Box>
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap={2} pt={1}>
                <Stack gap={2} direction="row">
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography fontSize={{ xs: 12, md: 18 }} fontWeight={500}>
                      All Entity
                      <Typography component={'span'}>
                        <Tooltip placement="top-end" title={'Explore all available entities.'}>
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </Typography>
                  </Box>
                  <TextField
                    onChange={(e) => onSetParams('search', e.target.value)}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 4,
                        width: '100%',
                        height: { xs: 35, md: 40 },
                        backgroundColor: 'white',
                        fontSize: { xs: 12, md: 16 },
                      },
                    }}
                    variant="outlined"
                    placeholder="e.g. “BNI” or  “BCA”"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ height: { xs: 15, md: 25 }, width: { xs: 15, md: 25 } }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                <Box
                  gap={2}
                  sx={{
                    display: 'flex',
                    height: '100%',
                    maxHeight: '130px',
                  }}
                >
                  <Grid container spacing={1} justifyContent={'flex-start'} sx={{ overflow: 'auto' }}>
                    {dataAllEntity &&
                      (dataAllEntity?.payload || []).map((item, index) => (
                        <Grid item xs={6} sm={4} key={index}>
                          <Box
                            onClick={() => {
                              handleSelectItem(item.name)
                              handleSelectedUnlock(item)
                            }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%',
                              gap: { xs: 0.5, sm: 2 },
                              backgroundColor: selectedItems.includes(item.name)
                                ? 'rgba(223, 202, 114, 1)'
                                : 'rgba(223, 202, 114, 0.2)',
                              border: '1px solid rgba(140, 140, 140, 0.3)',
                              borderRadius: 6,
                              padding: '8px',
                            }}
                          >
                            <Box
                              sx={{
                                width: { xs: '25px', md: '40px' },
                                height: { xs: '25px', md: '40px' },
                                borderRadius: '50%',
                                backgroundColor: 'white',
                                overflow: 'hidden',
                              }}
                            >
                              <img
                                src={item.displayPicture}
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                              />
                            </Box>
                            <Typography
                              sx={{
                                fontSize: { xs: 10, sm: 14 },
                                color: 'black',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {item.name}
                            </Typography>
                            <Box
                              sx={{
                                width: { xs: '16px', md: '16px' },
                                height: { xs: '16px', md: '16px' },
                                overflow: 'hidden',
                                pr: 1,
                              }}
                            >
                              <img src={lockIcon} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </Box>
            </Stack>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button
                sx={{ px: 6, borderRadius: 3 }}
                variant="contained"
                onClick={() => navigate(`/unlock?entityId=${selectedItemsName.join(',')}`)}
              >
                UNLOCK
              </Button>
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </Modal>
  )
}
