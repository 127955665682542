/* eslint-disable @typescript-eslint/no-explicit-any */
import { API } from './api'

export const sendOtpWhatsappQuery = async (body: { whatsapp: string }) => {
  return await API(`api/v1/user/otp-wa/get`, 'POST', null, body)
}

export const verifyOtpWhatsappQuery = async (body: { otp: string }) => {
  return await API(`api/v1/user/otp-wa/verify`, 'POST', null, body)
}
