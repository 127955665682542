import * as Yup from 'yup'

export const signInSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  password: Yup.string().required('Password is required'),
})
export const forgotSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
})
export const resetSchema = Yup.object().shape({
  // email: Yup.string().required('Email is required'),
  password: Yup.string().required('Password is required'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Repeat password'),
  id: Yup.string().required('id is required'),
  token: Yup.string().required('token is required'),
})

export const signUpSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  fullname: Yup.string().required('Fullname is required'),
  password: Yup.string().required('Password is required'),
  // .min(8, 'Password must be 8 characters long')
  // .matches(/[0-9]/, 'Password requires a number')
  // .matches(/[a-z]/, 'Password requires a lowercase letter')
  // .matches(/[A-Z]/, 'Password requires an uppercase letter')
  // .matches(/[^\w]/, 'Password requires a symbol')
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Repeat password'),
  referralCode: Yup.string().notRequired(),
})
