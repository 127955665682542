import { Box, Stack, Tooltip } from '@mui/material'
import whatsappLogo from '@assets/WhatsApp.svg'
import contactUsFabIcon from '@assets/perm_phone_msg.svg'
import feedbackIcon from '@assets/maps_ugc.svg'
import CloseIcon from '@mui/icons-material/Close'

const waLink =
  'https://api.whatsapp.com/send?phone=628112207900&text=Halo%20NoLimit!%20saya%20ingin%20menanyakan%20tentang%20produk%20yang%20ada%20di%20NoLimit.'
const feedbackLink = 'https://nolimit-indsight.feedbear.com/boards/feedback'

const ContactUsFab = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {/* main*/}
      <Box
        borderRadius="100%"
        bgcolor="#BB9555"
        sx={{ boxShadow: 3, cursor: 'pointer', ':hover': { backgroundColor: '#947644' } }}
        p="10px"
        position="fixed"
        bottom={10}
        right={10}
        zIndex={10}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          height={{ xs: '30px', sm: '40px' }}
          width={{ xs: '30px', sm: '40px' }}
          sx={{ overflow: 'hidden' }}
        >
          {isOpen ? (
            <CloseIcon sx={{ color: '#ffffff', width: '30px', height: '30px' }} />
          ) : (
            <img src={contactUsFabIcon} width="70%" style={{ aspectRatio: '1/1' }} />
          )}
        </Stack>
      </Box>

      {/* whatsapp*/}
      {isOpen && (
        <Box
          borderRadius="100%"
          bgcolor="#26872e"
          sx={{ boxShadow: 3, cursor: 'pointer', ':hover': { backgroundColor: '#075e54' } }}
          p="10px"
          position="fixed"
          bottom={80}
          right={10}
          zIndex={10}
          onClick={() => window.open(waLink, '_blank', 'noopener noreferrer')}
          className="test"
        >
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            height={{ xs: '30px', sm: '40px' }}
            width={{ xs: '30px', sm: '40px' }}
            sx={{ overflow: 'hidden' }}
          >
            <Tooltip placement="left" title={'Whatsapp'}>
              <img src={whatsappLogo} width="100%" style={{ aspectRatio: '1/1' }} />
            </Tooltip>
          </Stack>
        </Box>
      )}

      {/* feedback*/}
      {isOpen && (
        <Box
          borderRadius="100%"
          bgcolor="#0B85AE"
          sx={{ boxShadow: 3, cursor: 'pointer', ':hover': { backgroundColor: '#086787' } }}
          p="10px"
          position="fixed"
          bottom={150}
          right={10}
          zIndex={10}
          onClick={() => window.open(feedbackLink, '_blank', 'noopener noreferrer')}
          className="test"
        >
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            height={{ xs: '30px', sm: '40px' }}
            width={{ xs: '30px', sm: '40px' }}
            sx={{ overflow: 'hidden' }}
          >
            <Tooltip placement="left" title={'Feedback'}>
              <img src={feedbackIcon} width="90%" style={{ aspectRatio: '1/1' }} />
            </Tooltip>
          </Stack>
        </Box>
      )}
    </>
  )
}

export default ContactUsFab
