export const getToken = () => {
  return JSON.parse(localStorage.getItem('token') as string)
}
export const getIdF = () => {
  return JSON.parse(localStorage.getItem('idF') as string)
}
export const getTokenF = () => {
  return JSON.parse(localStorage.getItem('tokenF') as string)
}
export const setToken = (token: string) => {
  localStorage.setItem(`token`, JSON.stringify(token))
}
export const setTokenF = (token: string) => {
  localStorage.setItem(`tokenF`, JSON.stringify(token))
}
export const setIdF = (token: string) => {
  localStorage.setItem(`idF`, JSON.stringify(token))
}
export const clearToken = () => {
  localStorage.removeItem(`token`)
}
export const roundToDecimals = (value: number, precision: number) => {
  const pow = Math.pow(10, precision)
  return Math.floor(value * pow) / pow
}

export function formatCurrency(value: number | string, precision = 0) {
  if (!value) {
    return 0
  }

  if (typeof value === 'number') {
    if (precision === 0) {
      value = roundToDecimals(value, 0)
    }
  } else {
    value = parseFloat(value)
    if (precision === 0) {
      value = roundToDecimals(value, 0)
    }
  }
  return value.toLocaleString('de-DE')

  // return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export function formatCurrencyShortForm(value: number | string) {
  if (value === null || value === undefined) {
    return ''
  }
  value = Number(value)
  if (value >= 1000000000000) {
    return roundToDecimals(value / 1000000000000, 1) + 'T'
  } else if (value >= 1000000000) {
    return roundToDecimals(value / 1000000000, 1) + 'B'
  } else if (value >= 1000000) {
    return roundToDecimals(value / 1000000, 1) + 'M'
  } else if (value >= 1000) {
    return roundToDecimals(value / 1000, 1) + 'K'
  }

  return formatCurrency(value, 1)
}

export function formatRupiahWithSuffix(amount: number) {
  //Add suffix to rupiah amount: misalnya Rp 10,000,000 -> Rp 10jt, Rp 750,000 -> Rp 750Rb .....

  let mainAmount = 0
  let suffix = ''

  if (amount >= 1000000000) {
    mainAmount = amount / 1000000000
    suffix = 'Mya'
  }
  if (amount >= 1000000) {
    mainAmount = amount / 1000000
    suffix = 'Jt'
  } else if (amount >= 1000) {
    mainAmount = amount / 1000
    suffix = 'Rb'
  } else {
    mainAmount = amount
  }

  const amountString = mainAmount.toString().replace('.', ',')

  return {
    amount: amountString,
    suffix: suffix,
  }
}

export function getRandomColor(count) {
  const colors = []

  for (let i = 0; i < count; i++) {
    // Generate random values for red, green, blue, and alpha
    const r = Math.floor(Math.random() * 256) // Red: 0-255
    const g = Math.floor(Math.random() * 256) // Green: 0-255
    const b = Math.floor(Math.random() * 256) // Blue: 0-255
    // const a = 1 // Alpha set to 1 for full opacity

    // Construct the rgba color string
    const color = `rgb(${r}, ${g}, ${b})`
    colors.push(color)
  }

  return colors
}
