/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, IconButton, Paper, Skeleton, Stack, Typography } from '@mui/material'
import Chart from 'react-apexcharts'

import LockBigIcons from '@/assets/lock-big-icon.svg?react'
import { getSentimentAnalysis } from '@/network/api/analytic'
import { transformDataToDonutChartSentiment } from '@/utils/helper'
import CustomTooltip from '@/components/Tooltip'
export const SentimentAnalysis = ({ id, handleUnlockEntity }: { id: string; handleUnlockEntity: () => void }) => {
  const { startDate, endDate, selectedPlatform, selectedEntity } = useComparisonStore()
  const [isLoading, setLoading] = useState(false)
  const [dataSentimentAnalysis, setSentimentAnalysis] = useState<any>([])

  useEffect(() => {
    fetchPostType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, selectedPlatform])
  const fetchPostType = async () => {
    const params = {
      startDate,
      endDate,
      entityId: id || selectedEntity.entityId,
      platforms: selectedPlatform,
    }
    try {
      setLoading(true)
      const response = await getSentimentAnalysis(params)
      if (response.statusCode === 200) {
        setSentimentAnalysis(response?.payload)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  const { series, options } = transformDataToDonutChartSentiment(dataSentimentAnalysis)

  if (isLoading) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={100}>
        <Skeleton variant="rounded" width={370} height={100} />
      </Box>
    )
  }
  return (
    <>
      <Box position={'relative'} sx={{ minHeight: 300, width: '100%' }}>
        <Paper
          sx={{
            p: 2,
            flexGrow: 1,
            minWidth: 200,
            flexBasis: '100%',
            height: { xs: 350, sm: 400 },
            borderRadius: 4,
          }}
        >
          <Stack direction="row">
            <Typography fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
              Sentiment Analysis
            </Typography>
            <CustomTooltip title="How is your sentiment analysis?" />
          </Stack>
          <Box position={'relative'} width="100%" display={'flex'} justifyContent={'center'}>
            <Box
              className="donut"
              sx={{
                flex: 1,
                position: 'absolute',
                width: '100%',
                justifyContent: 'center',
                maxWidth: 400,
              }}
            >
              {!dataSentimentAnalysis.isLock ? (
                <Chart options={options} series={series as []} type="pie" width="100%" />
              ) : null}
            </Box>
          </Box>
        </Paper>
        {dataSentimentAnalysis.isLock && (
          <Box
            position={'absolute'}
            sx={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              '@media (max-width: 600px)': {
                top: '40%',
                left: '40%',
                transform: 'translate(-40%, -40%)',
              },
            }}
          >
            <IconButton onClick={handleUnlockEntity}>
              <LockBigIcons />
            </IconButton>
          </Box>
        )}
      </Box>
    </>
  )
}
