import { formatCurrencyShortForm, roundToDecimals } from '@/utils/storage'
import { ListItem, Typography, Stack, Tooltip, Box } from '@mui/material'
import GetGrowthIcon from '../GrowthIcon'
import { KeyboardArrowRight } from '@mui/icons-material'
import ArrowUp from '@/assets/arrow-up.svg'
import ArrowDown from '@/assets/arrow-down.svg'

/* eslint-disable @typescript-eslint/no-explicit-any */
interface IProps {
  item: any
  title: string
  icon: string
  index: number
  handleClick: () => void
}

const CategoryItem = ({ item, title, icon, index, handleClick }: IProps) => {
  const backgroundFetch = (index: number) => {
    switch (index) {
      case 0:
        return '#AF9500'
      case 1:
        return 'rgba(140, 140, 140, 1)'
      case 2:
        return 'rgba(106, 56, 5, 1)'
      default:
        return 'transparent'
    }
  }
  return (
    <ListItem disablePadding={true} onClick={handleClick} sx={{ cursor: 'pointer' }}>
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          backgroundColor: '#ffffff',
          padding: '16px',
          gap: 1,
          width: '100%',
        }}
      >
        <Box>
          <div
            style={{
              marginBottom: 6,
              background: `${backgroundFetch(index)}`,
              height: 22,
              width: 22,
              borderRadius: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: `${index > 2 ? '#000000' : 'white'}`,
            }}
          >
            <Typography fontWeight={500} fontSize={14}>
              {index + 1}
            </Typography>
          </div>
          <Box display={'flex'} alignItems="center" sx={{ minWidth: 40 }}>
            <GetGrowthIcon growth={item?.growthOrder} />
            <Typography fontWeight={500} fontSize={13}>
              {item?.growthOrder}
            </Typography>
          </Box>
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{
            flex: 1,
            my: 0,
            mx: 0,
            gap: 1,
          }}
        >
          <Box
            sx={{
              border: '1px solid #E3E8EF',
              display: 'flex',
              borderRadius: 1,
              justifyContent: 'center',
            }}
          >
            <img
              src={icon}
              style={{
                maxWidth: 72,
                maxHeight: 72,
                width: 64,
                height: 64,
                borderRadius: 6,
                objectFit: 'contain',
              }}
            />
          </Box>
          {/* <div style={{ flex: 1, maxWidth: '200px' }}> */}
          <Stack width="100%">
            <Box display={'flex'} justifyContent={'space-between'}>
              <Tooltip placement="top-end" title={title}>
                <Typography
                  component={'span'}
                  fontWeight={600}
                  // mb={1.5}
                  fontSize={12}
                  sx={{
                    maxWidth: 100,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {title}
                </Typography>
              </Tooltip>
              <Box>
                <KeyboardArrowRight sx={{ color: '#bb9555' }} />
              </Box>
            </Box>
            <Stack direction="column" textOverflow={'ellipsis'}>
              <Tooltip title={item?.currentTalk.toLocaleString('id-ID')}>
                <Typography fontWeight={500} fontSize={14}>
                  {formatCurrencyShortForm(item?.currentTalk)}
                </Typography>
              </Tooltip>
              <Box display={'flex'} gap={0.5}>
                <img src={`${item?.growthTalk >= 0 ? ArrowUp : ArrowDown}`} />
                <Tooltip title={item?.growthTalk.toLocaleString('id-ID')}>
                  <Typography fontSize={14} color={`${item?.growthTalk >= 0 ? 'green' : 'red'}`}>
                    {formatCurrencyShortForm(roundToDecimals(item?.growthTalk, 1))}%
                  </Typography>
                </Tooltip>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </ListItem>
  )
}

export default CategoryItem
