import { useState } from 'react'
import { Select, MenuItem, Grid, Typography, Box, Button } from '@mui/material'

const MonthYearPicker = ({ apiData, onApply }) => {
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  // const [selectOpen, setSelectOpen] = useState(false);
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value)
    setEndDate('')
  }

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value)
  }

  const formatDate = (date) => {
    return date.split('T')[0]
  }

  const handleSubmit = () => {
    if (startDate && endDate && new Date(startDate) < new Date(endDate)) {
      const data = {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
      }
      onApply(data)
    } else {
      alert('Please ensure the start date is before the end date.')
    }
  }

  const getFilteredEndDateOptions = () => {
    return apiData.filter((item) => new Date(item?.startDate) >= new Date(startDate))
  }

  return (
    <Box sx={{ mx: 2, maxWidth: 280 }}>
      <Grid container spacing={0.6} sx={{ maxWidth: 260 }}>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: 18, fontWeight: 600 }}>Select Start and End Dates</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Start Date</Typography>
          <Select
            // fullWidth
            sx={{ width: 260, gap: 2 }}
            value={startDate}
            onChange={handleStartDateChange}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Start Date
            </MenuItem>
            {apiData.map((item) => (
              <MenuItem key={item.productId} value={item.startDate}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Typography>End Date</Typography>
          <Select
            sx={{ width: 260 }}
            // fullWidth

            value={endDate}
            onChange={handleEndDateChange}
            displayEmpty
            disabled={!startDate} // Disable until a start date is selected
          >
            <MenuItem value="" disabled>
              End Date
            </MenuItem>
            {getFilteredEndDateOptions().map((item) => (
              <MenuItem key={item.productId} value={item.endDate}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" fullWidth onClick={handleSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MonthYearPicker
