import {
  Box,
  Card,
  CircularProgress,
  Container,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  TablePagination,
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableSortLabel,
  IconButton,
} from '@mui/material'
import NoDataIcon from '@/assets/no-data-icon.svg?react'
import { useQuery as useReactQuery } from 'react-query'

import SearchIcon from '@mui/icons-material/Search'
import moment from 'moment'
import _ from 'lodash'
import { getListEntity } from '@/network/api/api'
import { useProductPrice } from '@/hooks/Entity/useHelper'
import { calculateTotalPrice } from '@/utils/helper'

const initialParams = { page: 1, size: 10, search: '', status: 'ACTIVE', sortBy: 'createdAt', sortDirection: 'DESC' }
export const ListEntity = () => {
  const { addDataUnlock, setSelectedEntity, setStartDate, setEndDate } = useComparisonStore()
  // const {} = useEntityStore()
  const navigate = useNavigate()
  const [params, setParams] = useState(initialParams)
  const { data: productPrice } = useProductPrice()
  const onSetParams = (id, value) => {
    setParams((oldParams) => ({
      ...oldParams,
      [id]: value,
    }))
  }

  const { data: dataMyEntity, isLoading } = useReactQuery(['ListEntity', params], () => getListEntity(params), {
    staleTime: 1000 * 60 * 1,
  })
  const handleChangePage = (_event: unknown, newPage: number) => {
    if (newPage < 1) return
    onSetParams('page', newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSetParams('size', parseInt(event.target.value, 10))
    onSetParams('page', 1)
  }
  const TablePageActions = () => {
    return (
      <Box sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}>
        <Pagination
          count={Math.ceil(dataMyEntity?.payload?.count / params.size)}
          page={params.page}
          onChange={handleChangePage}
        />
      </Box>
    )
  }
  const handleChangeSearch = (event) => {
    const searchValue = event.target.value
    onSetParams('search', searchValue)
  }
  const handleChange = (event) => {
    const selectedStatus = event.target.value
    onSetParams('status', selectedStatus)
  }
  const handleRequestSort = (property) => {
    const isAsc = params.sortBy === property && params.sortDirection === 'ASC'
    onSetParams('sortBy', property)
    onSetParams('sortDirection', isAsc ? 'DESC' : 'ASC')
  }

  const handleNavigate = (item) => {
    const params = {
      entityName: item.entityName,
      entityId: item.entityId,
      startDate: item.productStartDate,
      endDate: item.productEndDate,
      platform: 'all',
      // metric: 'talkgrowth',
    }
    setSelectedEntity(item)
    setStartDate(item.productStartDate)
    setEndDate(item.productEndDate)
    navigate(`/entity?${new URLSearchParams(params).toString()}`)
  }

  const handleEntityAction = (row) => {
    const newItem = {
      entityId: row?.entityId,
      startDate: row?.productStartDate,
      endDate: row?.productEndDate,
      displayPicture: row?.displayPicture || '',
      name: row?.entityName,
      price: calculateTotalPrice(row?.productStartDate, row.productEndDate, productPrice.payload.price),
    }

    addDataUnlock(newItem)
    navigate(`/unlock?entityId=${row?.entityId}&startDate=${row.productStartDate}&endDate=${row.productEndDate}`)
  }
  const StatusBox = ({ status }) => {
    if (status === 'ACTIVE') {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            px: 2,
            py: { xs: 0.5, sm: 1 },
            borderRadius: 1,
            backgroundColor: '#00B87C',
            color: '#fff',
          }}
        >
          <Typography sx={{ fontFamily: 'Inter', fontSize: { xs: 10, sm: 14 }, fontWeight: 600 }}>{status}</Typography>
        </Box>
      )
    }
    if (status === 'EXPIRED') {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            px: 2,
            py: 1,
            borderRadius: 1,
            backgroundColor: 'red',
            color: '#fff',
          }}
        >
          <Typography sx={{ fontFamily: 'Inter', fontSize: 14, fontWeight: 600 }}>{status}</Typography>
        </Box>
      )
    }
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ p: { xs: 0, sm: 4 } }}>
        <Typography sx={{ fontFamily: 'Inter', fontSize: { xs: 16, sm: 28 }, pt: { xs: 2, sm: 0 }, fontWeight: 600 }}>
          List My Entity
        </Typography>
        <Typography sx={{ fontFamily: 'Inter', fontSize: 14, fontWeight: 400, py: 2 }}>
          List My Entity is a collection of all entities that you have ever purchased and there is information with
          active and expired status.
        </Typography>
        <Divider />
        <Card sx={{ padding: 2, mt: 2 }}>
          <Box display={'flex'} alignItems={'center'}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: 2,
                p: 1,
              }}
            >
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    width: '100%',
                    // height: 40,
                    height: { xs: 35, md: 40 },
                    backgroundColor: 'white',
                    fontSize: { xs: 12, md: 16 },
                  },
                }}
                onChange={handleChangeSearch}
                variant="outlined"
                value={params.search}
                placeholder="Search "
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ height: { xs: 15, md: 25 }, width: { xs: 15, md: 25 } }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box sx={{ minWidth: 120, height: 40 }}>
              <FormControl
                fullWidth
                sx={{
                  height: { xs: '40px', sm: '45px' },
                  pt: { xs: 0.3, sm: 0 },
                  '.MuiOutlinedInput-root': { height: { xs: '40px', sm: '45px' } },
                }}
              >
                <InputLabel id="status-select-label">Status</InputLabel>
                <Select
                  labelId="status-select-label"
                  id="status-select"
                  value={params.status}
                  label="Status"
                  onChange={handleChange}
                  sx={{
                    height: '40px',
                    '.MuiSelect-select': {
                      height: { xs: '40px', sm: '45px' },
                      py: 0,
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: { xs: 12, sm: 16 },
                    },
                    '.MuiOutlinedInput-notchedOutline': { height: { xs: '40px', sm: '45px' } },
                  }}
                >
                  <MenuItem value="ACTIVE">Active</MenuItem>
                  <MenuItem value="EXPIRED">Expired</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <TableContainer
            component={Paper}
            sx={{ width: { xs: '100%' } }}
            style={{ maxHeight: '400px', overflow: 'auto', borderRadius: 8 }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
              <TableHead sx={{ backgroundColor: 'rgba(223, 202, 114, 1)' }}>
                <TableRow
                  sx={{
                    backgroundColor: 'rgba(223, 202, 114, 1)',
                    // '& .MuiTableCell-head': {
                    //   backgroundColor: 'rgba(223, 202, 114, 1)',
                    // },
                    '& .MuiTableCell-head': {
                      // padding: '6px 16px',
                      paddingY: { xs: 1, sm: 2 },
                      backgroundColor: 'rgba(223, 202, 114, 1)',
                      fontSize: { xs: 10, sm: 16 },
                      lineHeight: 1.5,
                      // backgroundColor: 'blue',
                    },
                  }}
                >
                  <TableCell>No</TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={params.sortBy === 'entityName'}
                      direction={
                        params.sortBy === 'entityName'
                          ? params.sortDirection.toLowerCase() === 'desc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      }
                      onClick={() => handleRequestSort('entityName')}
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={params.sortBy === 'categoryName'}
                      direction={
                        params.sortBy === 'categoryName'
                          ? params.sortDirection.toLowerCase() === 'desc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      }
                      onClick={() => handleRequestSort('categoryName')}
                    >
                      Category
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={params.sortBy === 'createdAt'}
                      direction={
                        params.sortBy === 'createdAt'
                          ? params.sortDirection.toLowerCase() === 'desc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      }
                      onClick={() => handleRequestSort('createdAt')}
                    >
                      Payment Period
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={params.sortBy === 'periods'}
                      direction={
                        params.sortBy === 'periods'
                          ? params.sortDirection.toLowerCase() === 'desc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      }
                      onClick={() => handleRequestSort('periods')}
                    >
                      Timeframe Entity
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={params.sortBy === 'status'}
                      direction={
                        params.sortBy === 'status'
                          ? params.sortDirection.toLowerCase() === 'desc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      }
                      onClick={() => handleRequestSort('status')}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : !_.isEmpty(dataMyEntity?.payload?.rows) ? (
                  dataMyEntity.payload.rows.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        // '&:last-child td, &:last-child th': { border: 0 }
                        '&:last-child td, &:last-child th': { border: 0 },
                        '& .MuiTableCell-body': {
                          fontSize: { xs: 10, sm: 16 },
                          paddingY: { xs: 1, sm: 2 },
                        },
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell
                        align="left"
                        sx={{ ':hover': { cursor: 'pointer' }, textDecoration: 'underline' }}
                        onClick={() => handleNavigate(row)}
                      >
                        {row.entityName}
                      </TableCell>
                      <TableCell align="center">{row.categoryName}</TableCell>
                      <TableCell align="center">
                        {moment(row.createdAt).format('DD MMM YYYY')} - {moment(row.expireDate).format('DD MMM YYYY')}
                      </TableCell>
                      <TableCell align="center">{row.productName}</TableCell>
                      <TableCell align="left">
                        <StatusBox status={row.status} />
                      </TableCell>
                      <TableCell align="left">
                        {row.status === 'ACTIVE' ? (
                          ''
                        ) : (
                          <IconButton onClick={() => handleEntityAction(row)}>
                            <Box>
                              <Typography>Reactive</Typography>
                            </Box>
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                        <NoDataIcon />
                        <Typography>No data available</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={dataMyEntity?.payload?.count}
            rowsPerPage={params.size}
            page={params.page - 1}
            showFirstButton
            showLastButton
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePageActions}
          />
        </Card>
      </Box>
    </Container>
  )
}
