import { useUserProfile } from '@/hooks/User/useUser'
import { IconButton } from '@mui/material'
import Avatar from '@mui/material/Avatar'
const DOMAIN_URL = import.meta.env.VITE_API_SERVER_URL
export const ProfileAvatar = ({ onClick }: { name: string; imageUrl?: string; onClick: () => void }) => {
  const { data: dataUser } = useUserProfile()
  const user = dataUser?.payload ?? {}

  interface User {
    profileImage?: string
  }

  if ((user as User).profileImage) {
    return (
      <Avatar
        sx={{ borderRadius: 2 }}
        onClick={onClick}
        src={`${DOMAIN_URL}${(user as User)?.profileImage?.substring(1)}`}
        variant="square"
      />
    )
  }

  return (
    <div>
      <IconButton sx={{ height: { xs: 25, sm: 40 }, width: { xs: 25, sm: 40 } }} onClick={onClick}>
        <Avatar sx={{ bgcolor: '#DFCA72', borderRadius: 2 }} variant="square" />
      </IconButton>
    </div>
  )
}
