import React, { useState } from 'react'
import { Avatar, Box, Button, Paper, Tab, Typography, Link, Tooltip, useTheme, useMediaQuery } from '@mui/material'
import { useQuery as useReactQuery } from 'react-query'
import StatsIcon from '@/assets/stats-icon.svg?react'
import InstagramIcon from '@/assets/IG-socmed-icon.svg?react'
import FacebookIcon from '@/assets/FB-socmed-icon.svg?react'
import TwitterIcon from '@/assets/Twitter-socmed-icon.svg?react'
import YoutubeIcon from '@/assets/Youtube-socmed-icon.svg?react'
import TiktokIcon from '@/assets/Tiktok-socmed-icon.svg?react'
import PositifIcon from '@/assets/Positif-green-icon.svg?react'
import TimeIcon from '@/assets/time-icon.svg?react'
import NegatifIcon from '@/assets/Negatif-icon.svg?react'
import NetralIcon from '@/assets/Netral-icon.svg?react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import moment from 'moment'
import { getNewsPortalArticle, getTotalPostTalk } from '@/network/api/analytic'
import { formatCurrency, formatCurrencyShortForm } from '@/utils/storage'
import { useGetSocialMediaStream } from '@/hooks/Entity/useGetEntitySocialMediaStream'
import { useGetOnlineMediaStream } from '@/hooks/Entity/useGetEntityONMStream'

const size = 10

export const StreamModule: React.FC = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const [value, setValue] = useState('1')
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const id = query.get('entityId') as string
  const { startDate, endDate, selectedPlatform, selectedEntity } = useComparisonStore()

  const benchmark = (item: string) => {
    switch (item) {
      case 'positive':
        return <PositifIcon />
      case 'negative':
        return <NegatifIcon />
      case 'above':
        return <PositifIcon />
      case 'below':
        return <NegatifIcon />
      case 'neutral':
        return <NetralIcon />

      default:
        break
    }
  }

  const socmed = (item: string) => {
    switch (item) {
      case 'instagram':
        return <InstagramIcon />
      case 'facebook':
        return <FacebookIcon />
      case 'twitter':
        return <TwitterIcon />
      case 'youtube':
        return <YoutubeIcon />
      case 'tiktok':
        return <TiktokIcon />
      default:
        break
    }
  }
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  const params = {
    startDate,
    endDate,
    entityId: id || selectedEntity.entityId,
    platforms: selectedPlatform,
    page: 1,
    size: size,
  }

  const paramsOnlineMedia = {
    startDate,
    endDate,
    entityId: id || selectedEntity.entityId,
    platforms: selectedPlatform,
    page: 1,
    limit: size,
  }

  const { data: dataOnlineMedia } = useReactQuery(
    ['OnlineMediaTotalArticle', params],
    () => getNewsPortalArticle(params),
    {
      staleTime: 1000 * 60 * 1,
      retry: 2,
    },
  )
  const { data: dataSocialMedia } = useReactQuery(
    ['SocialMediaTotalPostTalk', params],
    () => getTotalPostTalk(params),
    {
      staleTime: 1000 * 60 * 1,
      retry: 2,
    },
  )

  const {
    data: dataSocmed,
    isError: isErrorSocialMedia,
    fetchNextPage: fetchNextPageSocialMedia,
    hasNextPage: hasNextPageSocialMedia,
    isFetching: isFetchingSocialMedia,
    isFetchingNextPage: isFetchingNextPageSocialMedia,
  } = useGetSocialMediaStream(params, { enabled: value === '1' })

  const {
    data: dataONM,
    isError: isErrorONM,
    fetchNextPage: fetchNextPageONM,
    hasNextPage: hasNextPageONM,
    isFetching: isFetchingONM,
    isFetchingNextPage: isFetchingNextPageONM,
  } = useGetOnlineMediaStream(paramsOnlineMedia, { enabled: value === '2' })

  const [seeMoreStates, setSeeMoreStates] = useState({})
  const textLimit = (text: string, index: number) => {
    if (seeMoreStates[index]) {
      return text
    }
    return text.slice(0, matches ? 300 : 100) + '...'
  }

  const toggleSeeMore = (index: number) => {
    setSeeMoreStates((prev) => ({
      ...prev,
      [index]: !prev[index],
    }))
  }

  return (
    <Box sx={{ py: 2 }}>
      <Box display={'flex'} sx={{ gap: 2, flex: 1, justifyContent: 'space-between' }}>
        <Paper sx={{ width: '100%', p: 2, bgcolor: 'rgba(223, 202, 114, 0.2)', borderRadius: 4 }}>
          <Box display={'flex'} alignItems={'center'}>
            <StatsIcon />
            <Typography fontSize={{ xs: 12, sm: 16 }} fontWeight={600} variant="h6" sx={{ color: '#382A02' }}>
              Social Media
            </Typography>
          </Box>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
            <Tooltip title={dataSocialMedia?.payload?.data?.recentValue.toLocaleString('id-ID')}>
              <Typography fontSize={28} fontWeight={700}>
                {formatCurrencyShortForm(dataSocialMedia?.payload?.data?.recentValue)}
              </Typography>
            </Tooltip>
          </Box>
        </Paper>
        <Paper sx={{ width: '100%', p: 2, bgcolor: 'rgba(223, 202, 114, 0.2)', borderRadius: 4 }}>
          <Box display={'flex'} alignItems={'center'}>
            <StatsIcon />
            <Typography fontSize={{ xs: 12, sm: 16 }} fontWeight={600} variant="h6" sx={{ color: '#382A02' }}>
              Online Media
            </Typography>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'flex-start'}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Tooltip title={dataOnlineMedia?.payload?.data?.recentValue}>
              <Typography fontSize={28} fontWeight={700}>
                {formatCurrencyShortForm(dataOnlineMedia?.payload?.data?.recentValue)}
              </Typography>
            </Tooltip>
          </Box>
        </Paper>
      </Box>
      <TabContext value={value || '1'}>
        <Box sx={{ width: '100%', boxShadow: 3, mt: 2, mb: 1, borderRadius: 2 }}>
          <TabList
            TabIndicatorProps={{ style: { display: 'none' } }}
            variant="fullWidth"
            onChange={handleChange}
            centered
          >
            <Tab
              value="1"
              label="Social Media"
              sx={{
                borderRadius: 2,
                color: value === '1' ? '#FFFFFF' : 'black',
                backgroundColor: value === '1' ? 'rgba(56, 42, 2, 1)' : 'white',
                '&.Mui-selected': {
                  color: 'white',
                },
              }}
            />
            <Tab
              value="2"
              label="Online Media"
              sx={{
                borderRadius: 2,
                color: value === '2' ? '#FFFFFF' : 'black',
                backgroundColor: value === '2' ? 'rgba(56, 42, 2, 1)' : 'white',
                '&.Mui-selected': {
                  color: 'white',
                },
              }}
            />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: 0 }}>
          {isFetchingSocialMedia && (
            <Box sx={{ textAlign: 'center', p: 5 }}>
              <Typography>Loading...</Typography>
            </Box>
          )}
          {isErrorSocialMedia && (
            <Box sx={{ textAlign: 'center', p: 5 }}>
              <Typography>Error...</Typography>
            </Box>
          )}

          {dataSocmed?.pages.map((item) =>
            item.payload.data.result.map((streamItem, index) => (
              <Paper
                variant="outlined"
                key={index}
                sx={{
                  p: 2,
                  mt: 2,
                  borderRadius: 3,
                  ...(index >= 3 && index <= 10 && dataSocmed?.pages[0].payload.isLock
                    ? { filter: 'blur(8px)', transition: 'filter 0.3s ease-in-out' }
                    : {}),
                }}
              >
                <Box display={'flex'} alignItems={'flex-start'}>
                  <Box display={'flex'} alignItems={'center'}>
                    <Avatar src={streamItem.displayPicture} />
                    <Typography fontWeight={600} fontSize={14} variant="h6" sx={{ mx: 2 }}>
                      {streamItem.fromName}
                    </Typography>
                  </Box>
                  <Paper
                    sx={{
                      mx: 4,
                      px: 2,
                      py: 0.4,
                      borderRadius: 3,
                      bgcolor: 'rgba(223, 202, 114, 0.2)',
                      display: { xs: 'none', sm: 'block' },
                    }}
                  >
                    <Typography fontWeight={400} fontSize={10}>
                      Engagement:
                      <Typography component={'span'} fontWeight={600} fontSize={12}>
                        {' '}
                        {formatCurrency(streamItem.engagementRate)}
                      </Typography>
                    </Typography>
                  </Paper>
                </Box>
                <Box display={'flex'} alignItems={'center'} mt={0.5} gap={1}>
                  {/* <InstagramIcon /> */}
                  {socmed(streamItem.socialMedia)}
                  {benchmark(streamItem.sentiment)}
                  <Paper
                    sx={{
                      p: 0.2,
                      px: 1,
                      borderRadius: 3,
                      background: 'rgba(223, 202, 114, 0.2)',
                      display: { xs: 'block', sm: 'none' },
                    }}
                  >
                    <Typography fontWeight={400} fontSize={{ xs: 10 }}>
                      Engagement :
                      <span style={{ color: 'black', fontWeight: '600', fontSize: 10 }}>
                        {' '}
                        {formatCurrency(streamItem.engagementRate)}
                      </span>
                    </Typography>
                  </Paper>
                </Box>
                <Typography color={'rgba(89, 89, 89, 1)'} py={2} px={1}>
                  {streamItem.content}
                </Typography>
                <Box my={1} px={1}>
                  <Link variant="body1" href={streamItem.link} target="_blank" rel="noopener">
                    Link to source
                  </Link>
                </Box>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                  <TimeIcon />
                  <Typography fontSize={10} fontWeight={400} color={'rgba(157, 157, 156, 1)'}>
                    {' '}
                    {moment(streamItem?.timestamp).format('DD MMM YYYY | hh:mm')}
                  </Typography>
                </Box>
                <Box></Box>
              </Paper>
            )),
          )}
          <Box sx={{ display: 'flex', my: 4, alignItems: 'center', justifyContent: 'center' }}>
            <Button
              variant="contained"
              onClick={() => fetchNextPageSocialMedia()}
              disabled={
                !hasNextPageSocialMedia || isFetchingNextPageSocialMedia || dataSocmed?.pages[0]?.payload?.isLock
              }
            >
              Load More
            </Button>
          </Box>
        </TabPanel>

        <TabPanel value="2" sx={{ p: 0 }}>
          {isFetchingONM && (
            <Box sx={{ textAlign: 'center', p: 5 }}>
              <Typography>Loading...</Typography>
            </Box>
          )}
          {isErrorONM && (
            <Box sx={{ textAlign: 'center', p: 5 }}>
              <Typography>Error...</Typography>
            </Box>
          )}
          {!isFetchingONM &&
            dataONM?.pages.map((page) =>
              page.payload.data.result.map((item, index) => (
                <Paper
                  variant="outlined"
                  key={item.originalId}
                  sx={{
                    p: 2,
                    mt: 2,
                    borderRadius: 3,
                    ...(index >= 3 && index <= 10 && dataONM?.pages[0]?.payload?.isLock && value === '2'
                      ? { filter: 'blur(8px)', transition: 'filter 0.3s ease-in-out' }
                      : {}),
                  }}
                >
                  <Box display={'flex'} mt={0.5} gap={1}>
                    {/* <InstagramIcon /> */}
                    <Box>
                      {/* <img src={item.displayPicture} /> */}
                      <Typography fontWeight={400} fontSize={{ xs: 12, sm: 13 }} color={'rgba(89, 89, 89, 1)'}>
                        {item.sourceName}
                      </Typography>
                    </Box>
                    {benchmark(item.sentiment)}
                  </Box>
                  <Box display={'flex'} alignItems={'flex-start'} mt={1} py={1}>
                    <Box display={'flex'} alignItems={'center'}>
                      <Avatar variant="square" src={item.displayPicture} sx={{ width: '50px', height: '50px' }} />
                      <Box px={2}>
                        <Typography fontWeight={700} fontSize={12} variant="h6">
                          {item.title}
                        </Typography>
                        <Typography fontWeight={400} fontSize={{ xs: 12, sm: 13 }} color={'rgba(89, 89, 89, 1)'}>
                          {textLimit(item.content, index)}{' '}
                          <span
                            style={{ cursor: 'pointer', color: 'black', textDecoration: 'underline' }}
                            onClick={() => toggleSeeMore(index)}
                          >
                            {seeMoreStates[index] ? 'See Less' : 'See More'}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box display={'flex'} alignItems={'center'} gap={1}>
                    <TimeIcon />
                    <Typography fontSize={10} fontWeight={400} color={'rgba(157, 157, 156, 1)'}>
                      {moment(item.datePublished).format('DD MMM YYYY | hh:mm A')}
                    </Typography>
                  </Box>
                  <Box></Box>
                </Paper>
              )),
            )}
          <Box sx={{ display: 'flex', my: 4, alignItems: 'center', justifyContent: 'center' }}>
            <Button
              onClick={() => fetchNextPageONM()}
              variant="contained"
              disabled={!hasNextPageONM || isFetchingNextPageONM || dataONM?.pages[0]?.payload?.isLock}
            >
              Load More
            </Button>
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  )
}
