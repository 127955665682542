// import { useUserProfile } from '@/hooks/User/useUser'
import { create } from 'zustand'

interface ProfileStore {
  topup: {
    amount: number
    paymentMethod: string
    paymentChannel: string
    customer: {
      givenNames: string
      email: string
    }
    walletId: string
    coin: number
  }
  profileSelected: number
  openModalUpload: boolean
  changeProfileSelected: (profileSelected: number) => void
  setOpenModalUpload: (openModalUpload: boolean) => void
  setTopupAmountAndCoin: (amount: number, coin: number) => void
  setProviderAndId: (paymentMethod: string, paymentChannel: string) => void
  setNameAndEmail: (givenNames: string, email: string) => void
  setWalletUser: (walletId: string) => void
}

export const useProfileStore = create<ProfileStore>((set) => ({
  profileSelected: 1,
  openModalUpload: false,
  changeProfileSelected: (profileSelected: number) => set(() => ({ profileSelected })),
  setOpenModalUpload: (openModalUpload: boolean) => set(() => ({ openModalUpload })),
  topup: {
    amount: 0,
    paymentMethod: 'VIRTUAL_ACCOUNT',
    paymentChannel: 'BCA',
    customer: {
      givenNames: '',
      email: '',
    },
    walletId: '',
    coin: 0,
  },

  setTopupAmountAndCoin: (amount: number, coin: number) =>
    set((state) => ({
      topup: {
        ...state.topup,
        amount,
        coin,
      },
    })),
  setProviderAndId: (paymentMethod: string, paymentChannel: string) =>
    set((state) => ({
      topup: {
        ...state.topup,
        paymentMethod,
        paymentChannel,
      },
    })),
  setWalletUser: (walletId: string) =>
    set((state) => ({
      ...state,
      topup: {
        ...state.topup,
        walletId,
      },
    })),
  setNameAndEmail: (givenNames: string, email: string) =>
    set((state) => ({
      ...state,
      topup: {
        ...state.topup,
        customer: {
          ...state.topup.customer,
          givenNames,
          email,
        },
      },
    })),
}))
