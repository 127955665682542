/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import { Body } from '@/layout'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {
  Container,
  IconButton,
  Box,
  Typography,
  Button,
  Paper,
  Tab,
  Popover,
  Stack,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  useTheme,
  Divider,
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { CalendarIcon } from '@mui/x-date-pickers'
import _ from 'lodash'
import GearSettingIcon from '@/assets/gear-setting-icon.svg'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import '../style/customDatePickerStyles.css'
// components
import { ModalComparison } from '@/components/Modal/ModalComparison'
import ModalShare from '@/components/Modal'
// modules
import SocialMediaComparison from '@/modules/SocialMediaComparison'
import OnlineMediaComparison from '@/modules/OnlineMediaComparison'
// mock
// api
import { getMyEntity } from '@/network/api/comparison'
import { useDateProduct } from '@/hooks/Entity/useHelper'
import { getToken } from '@/utils/storage'

import BackIcon from '@/assets/back-icon.svg?react'
import BackMobile from '@/assets/back-low-px.svg?react'
import WarningIcon from '@/assets/warning-icon-gold.svg?react'
import { ArrowDropDown } from '@mui/icons-material'

const timeOptions = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Last 7 Days', value: 'sevenDays' },
  { label: 'Last 30 Days', value: 'thirtyDays' },
  { label: 'This Month', value: 'thisMonth' },
  { label: 'Last Month', value: 'lastMonth' },
]

const ComparisonPage: React.FC = () => {
  const {
    endDate,
    setEndDate,
    startDate,
    setSelectedItems,
    selectedItems,
    selectedItemsName,
    setSelectedItemsName,
    setStartDate,
  } = useComparisonStore()
  const { data: payloadDate } = useDateProduct()
  const theme = useTheme()
  const isScreenSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const navigate = useNavigate()
  const [startDateLocal, setStartDateLocal] = useState(new Date())
  const [endDateLocal, setEndDateLocal] = useState(new Date())
  const [modalComparison, setModalComparison] = React.useState(false)

  const [valueTab, setValueTab] = React.useState('1')
  const [notLogin, setNotLogin] = useState(false)
  const [isError, setIsError] = useState(false)
  const token = getToken()
  useEffect(() => {
    if (!token) {
      setNotLogin(true) // Show login modal if no token is found
    }
  }, [token])
  const [params] = React.useState({
    page: 1,
    size: 1000,
    startDate: startDate,
    endDate: endDate,
    status: 'ACTIVE',
  })

  const [entities, setEntities] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  useEffect(() => {
    return () => {
      setSelectedItems([])
      setSelectedItemsName([])
    }
  }, [setSelectedItems, setSelectedItemsName])

  const onChangeTimeFrame = (dates) => {
    const [start, end] = dates
    setStartDateLocal(start)
    setEndDateLocal(end)
  }

  useEffect(() => {
    fetchEntity(params)
  }, [params])

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'datepicker-popover' : undefined

  const handleClickDatePicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseDatePicker = () => {
    setAnchorEl(null)
  }

  const confirmDateRange = (startDate: Date, endDate: Date) => {
    setStartDate(moment(startDate).format('YYYY-MM-DD'))
    setEndDate(moment(endDate).format('YYYY-MM-DD'))
    handleCloseDatePicker()
  }

  const CustomInputWithButton = () => (
    <Button
      onClick={() => {
        confirmDateRange(startDateLocal, endDateLocal)
      }}
    >
      Confirm
    </Button>
  )
  const fetchEntity = async (params: any) => {
    // setNotLogin(false)
    try {
      const response = await getMyEntity(params)
      setEntities(response?.payload?.rows)
    } catch (error) {
      console.log(error, '---->')
    } finally {
      setLoading(false)
    }

    // setNotLogin(true)
  }

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: string) => {
    setValueTab(newValue)
  }

  const handleSelectItem = (itemName: string) => {
    if (selectedItems.includes(itemName)) {
      setSelectedItems(selectedItems?.filter((item) => item !== itemName))
    } else {
      setSelectedItems([...selectedItems, itemName])
    }
  }
  const handleSelectItemId = (itemName: string) => {
    if (selectedItemsName.includes(itemName)) {
      setSelectedItemsName(selectedItemsName?.filter((item) => item !== itemName))
    } else {
      setSelectedItemsName([...selectedItemsName, itemName])
    }
  }

  const getMonthsBetweenDates = (start: moment.Moment, end: moment.Moment) => {
    const interim = start.startOf('month').clone()
    const timeValues: moment.Moment[] = []

    while (end > interim || interim.format('M') === end.format('M')) {
      timeValues.push(interim.clone())
      interim.add(1, 'month')
    }

    return timeValues
  }

  const handleClickTimeOption = (option: string) => {
    const today = new Date()
    switch (option) {
      case 'today':
        confirmDateRange(today, today)
        break
      case 'yesterday': {
        const yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        confirmDateRange(yesterday, yesterday)
        break
      }
      case 'sevenDays': {
        const oneWeekAgo = new Date(today)
        oneWeekAgo.setDate(today.getDate() - 7)
        confirmDateRange(oneWeekAgo, today)
        break
      }
      case 'thirtyDays': {
        const thirtyDaysAgo = new Date(today)
        thirtyDaysAgo.setDate(today.getDate() - 30)
        confirmDateRange(thirtyDaysAgo, today)
        break
      }
      case 'thisMonth': {
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
        confirmDateRange(firstDayOfMonth, today)
        break
      }
      case 'lastMonth': {
        const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1)
        const lastDayOfLastMonth = new Date(firstDayOfCurrentMonth.getTime() - 1 * 24 * 60 * 60 * 1000)
        confirmDateRange(firstDayOfLastMonth, lastDayOfLastMonth)
        break
      }
      default:
        throw new Error('Time option is not valid.')
    }
  }

  const datesFilter = useMemo(() => {
    return getMonthsBetweenDates(moment(startDate), moment(endDate)).map((date) => date.format('YYYY-MM-DD'))
  }, [startDate, endDate])

  const filteredEntities = useMemo(() => {
    return entities?.filter((entity) => datesFilter.includes(entity.productStartDate))
  }, [entities, datesFilter])

  const afterFilterEntites = useMemo(() => {
    const filterEntitiesByTimeframe = _.chain(filteredEntities)
      .groupBy('entityId')
      .map((value, key) => ({
        entityId: key,
        entities: value,
      }))
      .value()
      .filter((item) => item.entities.length === datesFilter.length)
      .flatMap((item) => item.entities)

    return _.uniqBy(filterEntitiesByTimeframe, 'entityId')
  }, [filteredEntities, datesFilter])

  useEffect(() => {
    const isNotSignedIn = !token
    const noAvailableEntities = afterFilterEntites.length <= 0
    if (loading || isNotSignedIn) return

    if (noAvailableEntities) setModalComparison(true)
    else {
      const entityIds = afterFilterEntites.map((entity) => entity.entityId)
      setSelectedItems(afterFilterEntites)
      setSelectedItemsName(entityIds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterFilterEntites, loading, token])

  useEffect(() => {
    setStartDateLocal(new Date(startDate))
    setEndDateLocal(new Date(endDate))
  }, [startDate, endDate])

  if (loading) {
    return null
  }
  return (
    <Body>
      <Box bgcolor="#262626">
        <Container maxWidth="xl">
          <Box
            justifyContent={'space-between'}
            sx={{ display: 'flex', gap: 2, backgroundColor: '#262626', py: '16px', alignItems: 'center' }}
          >
            <Box display={'flex'} sx={{ gap: 2, flex: 1, justifyContent: 'flex-start', alignItems: 'center' }}>
              <IconButton sx={{ display: { xs: 'none', sm: 'block' } }} onClick={() => navigate(-1)}>
                <BackIcon />
              </IconButton>
              <IconButton sx={{ display: { xs: 'block', sm: 'none' } }} onClick={() => navigate(-1)}>
                <BackMobile style={{ marginTop: '6px' }} />
              </IconButton>
              <Typography sx={{ fontSize: 16, fontWeight: 600, fontFamily: 'Inter', color: 'white' }}>
                Comparison
              </Typography>
            </Box>
            <Box>
              <Button
                aria-describedby={id}
                variant="outlined"
                startIcon={<CalendarIcon />}
                sx={{ textTransform: 'none', color: 'black', bgcolor: 'white', ':hover': { bgcolor: 'white' } }}
                endIcon={<ArrowDropDown />}
                fullWidth
                onClick={handleClickDatePicker}
              >
                <Box width={isScreenSmUp ? 'auto' : '80px'}>
                  <Typography fontSize={{ xs: 10, sm: 14 }} textOverflow="ellipsis" noWrap>
                    {`${moment(startDate).format('DD MMM YYYY')} - ${moment(endDate).format('DD MMM YYYY')}`}
                  </Typography>
                </Box>
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseDatePicker}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Stack direction={isScreenSmUp ? 'row' : 'column'}>
                  {isScreenSmUp ? (
                    <List>
                      {timeOptions.map((option) => (
                        <ListItem disablePadding key={option.value}>
                          <ListItemButton dense onClick={() => handleClickTimeOption(option.value)}>
                            <ListItemText primaryTypographyProps={{ fontSize: '12px' }}>{option.label}</ListItemText>
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Stack direction="row" gap={1} flexWrap="wrap" padding={1}>
                      {timeOptions.map((option, index) => (
                        <>
                          <Button sx={{ textTransform: 'none' }} onClick={() => handleClickTimeOption(option.value)}>
                            {option.label}
                          </Button>
                          {index < timeOptions.length - 1 && (
                            <Divider orientation="vertical" variant="middle" flexItem />
                          )}
                        </>
                      ))}
                    </Stack>
                  )}
                  <Box overflow="scroll">
                    <DatePicker
                      selected={startDateLocal}
                      onChange={onChangeTimeFrame}
                      minDate={new Date(payloadDate?.payload[0].startDate)}
                      maxDate={new Date(payloadDate?.payload[payloadDate?.payload.length - 1].endDate)}
                      startDate={startDateLocal}
                      endDate={endDateLocal}
                      selectsRange
                      inline
                    >
                      <CustomInputWithButton />
                    </DatePicker>
                  </Box>
                </Stack>
              </Popover>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container maxWidth="xl" sx={{ backgroundColor: '#FBFBFB' }}>
        <Box>
          <Typography sx={{ fontSize: 16, fontWeight: 600, fontFamily: 'Inter', color: 'black', pt: { xs: 1, sm: 4 } }}>
            My Entity
          </Typography>
          <Box display="flex" alignItems={'center'}>
            <Box>
              <IconButton
                sx={{
                  width: { xs: 70, sm: 90 },
                  height: { xs: 70, sm: 90 },
                  overflow: 'hidden',
                  pt: 2,
                }}
                onClick={() => setModalComparison(true)}
              >
                {/* <GearSettingIcon /> */}
                <img src={GearSettingIcon} style={{ height: '100%', width: '100%', objectFit: 'cover' }} alt="" />
              </IconButton>
            </Box>
            {entities && afterFilterEntites.length > 0 ? (
              <Paper
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 2,
                  py: 1,
                  px: 2,
                  borderRadius: 4,
                  overflowX: 'scroll',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {afterFilterEntites.map((item: any, index: any) => (
                  <Box
                    key={index?.toString()}
                    onClick={() => {
                      handleSelectItem(item.entityName)
                      handleSelectItemId(item.entityId)
                    }}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      width: 'auto',
                      gap: 2,
                      backgroundColor: selectedItemsName.includes(item.entityId)
                        ? 'rgba(223, 202, 114, 1)'
                        : 'rgba(223, 202, 114, 0.2)',
                      border: '1px solid rgba(140, 140, 140, 0.3)',
                      borderRadius: 6,
                      padding: { xs: '4px', sm: '8px' },
                      cursor: 'pointer',
                      textWrap: 'nowrap',
                    }}
                  >
                    <Typography sx={{ color: 'black', fontSize: { xs: 10, sm: 14 }, whiteSpace: 'nowrap' }}>
                      {item.entityName}
                    </Typography>
                  </Box>
                ))}
              </Paper>
            ) : (
              <Paper
                sx={{
                  py: 1,
                  px: 1,
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    py: 1,
                    px: 1,
                    border: '1px dashed #8C8C8C',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: 12, sm: 16 },
                      fontWeight: 600,
                      fontFamily: 'Inter',
                      color: 'black',
                      textAlign: 'center',
                    }}
                  >
                    Entities within that time frame will be displayed here. Use coins to buy entities.
                  </Typography>
                </Box>
              </Paper>
            )}
          </Box>

          <TabContext value={valueTab}>
            <Box sx={{ width: '100%', boxShadow: 3, mt: 2, mb: 1, borderRadius: 2 }}>
              <TabList
                TabIndicatorProps={{ style: { display: 'none' } }}
                variant="fullWidth"
                centered
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Social Media"
                  value="1"
                  sx={{
                    borderRadius: 2,
                    color: valueTab === '1' ? '#FFFFFF' : 'black',
                    backgroundColor: valueTab === '1' ? 'rgba(56, 42, 2, 1)' : 'white',
                    '&.Mui-selected': {
                      color: 'white',
                    },
                  }}
                />
                <Tab
                  label="Online Media"
                  value="2"
                  sx={{
                    borderRadius: 2,
                    color: valueTab === '2' ? 'white' : 'black',
                    backgroundColor: valueTab === '2' ? 'rgba(56, 42, 2, 1)' : 'white',
                    '&.Mui-selected': {
                      color: 'white',
                    },
                  }}
                />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ p: 0 }}>
              <SocialMediaComparison setIsError={setIsError} />
            </TabPanel>
            <TabPanel value="2" sx={{ p: 0 }}>
              <OnlineMediaComparison setIsError={setIsError} />
            </TabPanel>
          </TabContext>
          <ModalComparison
            entities={afterFilterEntites}
            open={modalComparison}
            onClose={() => setModalComparison(!modalComparison)}
            token={token}
          />
        </Box>
      </Container>
      {notLogin && (
        <ModalShare
          onClickSubmit={() => {
            setNotLogin(!notLogin)
            navigate('/signin')
          }}
          title={'Premium feature'}
          description={`You must be login and have an entity to start comparison.`}
          icon={<WarningIcon />}
          open={notLogin}
          handleClose={() => {
            setNotLogin(!notLogin)
            navigate('/signin')
          }}
          submitDescription={'Continue'}
        ></ModalShare>
      )}
      <ModalShare
        onClickSubmit={() => {
          setIsError(false)
        }}
        title={'Entity outside the timeframe'}
        description={`Your entity is outside the timeframe. 
        You can purchase an entity within that timeframe and check your entity again to make a comparison.`}
        icon={<WarningIcon />}
        open={isError}
        handleClose={() => {
          setIsError(false)
        }}
        submitDescription={'Continue'}
      />
    </Body>
  )
}

export default ComparisonPage
