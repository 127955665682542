import React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

const style = {
  position: 'absolute',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
}

export default function ModalShare({
  open,
  title,
  description,
  handleClose,
  icon,
  submitDescription,
  cancelDescription,
  onClickSubmit,
  onClickCancel,
  oppositeButton,
}: {
  open: boolean
  title: string
  submitDescription: string
  cancelDescription?: string
  description: string
  oppositeButton?: boolean
  icon: React.ReactNode
  handleClose?: () => void
  onClickSubmit: () => void
  onClickCancel?: () => void
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="share-modal-title"
      aria-describedby="share-modal-description"
    >
      <Box sx={style}>
        {icon}
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 600,
            lineHeight: '29px',
            letterSpacing: '0em',
            textAlign: 'center',
          }}
          id="share-modal-title"
          variant="h6"
          component="h2"
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '17px',
            letterSpacing: '0em',
            textAlign: 'center',
            color: '#8C8C8C',
            py: 2,
          }}
          id="share-modal-title"
          variant="body1"
          component="body"
        >
          {description}
        </Typography>
        {oppositeButton ? (
          <Box
            id="share-modal-description"
            sx={{ mt: 2, display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 2 }}
          >
            {cancelDescription && (
              <Button variant="outlined" sx={{ borderRadius: 2 }} onClick={onClickCancel}>
                {cancelDescription}
              </Button>
            )}
            <Button variant="contained" sx={{ px: cancelDescription ? 4 : 4, borderRadius: 2 }} onClick={onClickSubmit}>
              {submitDescription}
            </Button>
          </Box>
        ) : (
          <Box
            id="share-modal-description"
            sx={{ mt: 2, display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 2 }}
          >
            <Button variant="contained" sx={{ px: cancelDescription ? 4 : 4, borderRadius: 2 }} onClick={onClickSubmit}>
              {submitDescription}
            </Button>
            {cancelDescription && (
              <Button variant="outlined" sx={{ borderRadius: 2 }} onClick={onClickCancel}>
                {cancelDescription}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  )
}
