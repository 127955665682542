import { Box, IconButton, Paper, Skeleton, Tooltip, Typography } from '@mui/material'
import InfoIcon from '@/assets/info-icon.svg?react'
import StatsIcon from '@/assets/stats-score-icon.svg'
import LockBigIcon from '@/assets/lock-big-icon.svg'
import { getSentimentScore } from '@/network/api/analytic'
import { formatCurrencyShortForm, roundToDecimals } from '@/utils/storage'
interface Response {
  data: {
    value: number
    endDate: string
    engagementRate: number
    engagementRateTrend: string
  }
  isLock: boolean
}
export const SentimentScoreAnalytic = ({ id, handleUnlockEntity }: { id: string; handleUnlockEntity: () => void }) => {
  const { startDate, endDate, selectedPlatform, selectedEntity } = useComparisonStore()
  const [isLoading, setLoading] = useState(false)
  const [dataPostmade, setPostmade] = useState<Response>()

  useEffect(() => {
    fetchPostMade()
  }, [startDate, endDate, selectedPlatform, id])

  const fetchPostMade = async () => {
    const params = {
      startDate,
      endDate,
      entityId: id || selectedEntity.entityId,
      platforms: selectedPlatform,
    }
    try {
      setLoading(true)
      const response = await getSentimentScore(params)
      if (response.statusCode === 200) {
        setPostmade(response.payload)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (isLoading) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={100}>
        <Skeleton variant="rounded" width={370} height={100} />
      </Box>
    )
  }
  return (
    <Paper
      sx={{
        width: '100%',
        minHeight: 100,
        p: { xs: 0.5, sm: 2 },
        bgcolor: 'rgba(223, 202, 114, 0.2)',
        borderRadius: 4,
        position: 'relative',
      }}
    >
      <Box display={'flex'} alignItems={'center'}>
        <img src={StatsIcon} />
        <Typography
          fontWeight={600}
          variant="h6"
          sx={{ color: '#382A02', fontSize: { xs: 12, sm: 20 }, lineHeight: 1.3 }}
        >
          Sentiment Score
        </Typography>
        <Tooltip
          placement="top-end"
          title={'Numeric values that reflect the level of sentiment, such as positive, negative, and neutral.'}
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Box position={'relative'}>
        <Box
          position={'absolute'}
          sx={{
            flex: 1,
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
            filter: dataPostmade?.isLock ? 'blur(8px)' : 'none',
            opacity: dataPostmade?.isLock ? 0.5 : 1,
            zIndex: dataPostmade?.isLock ? 1 : 0,
          }}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'flex-end'}
        >
          <Tooltip title={dataPostmade?.data?.value.toLocaleString('id-ID')}>
            <Typography
              fontSize={{ xs: 20, sm: 28 }}
              fontWeight={600}
              sx={{ paddingLeft: { xs: 1, sm: 0 }, width: { xs: '100%', sm: 'auto' }, textAlign: 'left' }}
            >
              {formatCurrencyShortForm(roundToDecimals(dataPostmade?.data?.value, 3))}%
            </Typography>
          </Tooltip>
        </Box>
      </Box>
      {dataPostmade?.isLock && (
        <Box
          position={'absolute'}
          sx={{
            top: '70%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            '@media (max-width: 100px)': {
              top: '70%',
              left: '40%',
              transform: 'translate(-40%, -40%)',
            },
          }}
        >
          <IconButton onClick={handleUnlockEntity}>
            <img src={LockBigIcon} width={50} />{' '}
          </IconButton>
        </Box>
      )}
    </Paper>
  )
}
