/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-catch */
import { API } from './api'

export const getMyEntity = async (params: any) => {
  try {
    const response = await API(`api/v1/order/my_entity`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}

// social media
export const getSocialMediaBrandPositioning = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/brand_positioning`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}

export const getSocialMediaSentimentAnalysis = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/comparison_sentiment_analysis`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}

export const getSocialMediaPerformanceTalk = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/social_media_performance_based_on_talk`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}

export const getSocialMediaPerformanceAccount = async (params: any) => {
  try {
    const response = await API(`api/v1/social_media/social_media_performance_based_on_account`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}

// online media
export const getOnlineMediaBrandPositioning = async (params: any) => {
  try {
    const response = await API(`api/v1/online_media/brand_positioning`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}

export const getOnlineMediaSentimentAnalysis = async (params: any) => {
  try {
    const response = await API(`api/v1/online_media/comparison_sentiment_analysis`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}

export const getOnlineMediaPerformance = async (params: any) => {
  try {
    const response = await API(`api/v1/online_media/online_media_performance`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getAllEntity = async (params: any) => {
  try {
    const response = await API(`api/v1/general/get_all_entity_pagination`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getEntityRecomendation = async (params: any) => {
  try {
    const response = await API(`api/v1/general/get_entity_recommendation`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
