import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Indsight from '@/assets/indsight-by-nolimit.svg?react'
import { useNavigate } from 'react-router-dom'
import {
  Avatar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { CoinInfo } from '@/components/Info'
import { useUserProfile } from '@/hooks/User/useUser'
import { clearToken, getToken } from '@/utils/storage'
import { ProfileAvatar } from '../Avatar'
import IndsightMobile from '@/assets/indsight-mobile.svg?react'
import MenuIcon from '@/assets/menu-icon.svg?react'
import SignOutIcon from '@/assets/sign-out-icon-black.svg?react'
import DeleteIcon from '@/assets/delete-red-icon.svg?react'
import LogoutRedIcon from '@/assets/logout-red-icon.svg?react'
import DeleteModalIcon from '@/assets/delete-modal-icon.svg?react'
import BackBrownIcon from '@/assets/back-brown-icon.svg?react'
import { getSearchEntity, postInactiveMe } from '@/network/api/api'
import { useQuery, useQueryClient } from 'react-query'
import ModalShare from '../Modal'
import ProfileBlack from '@/assets/profile-black-icon.svg?react'
import ProfileWhite from '@/assets/profile-white-icon.svg?react'
import CoinBlack from '@/assets/coin-black-icon.svg?react'
import CoinWhite from '@/assets/coin-white-icon.svg?react'
import HistoryBlack from '@/assets/history-black-icon.svg?react'
import HistoryWhite from '@/assets/history-white-icon.svg?react'
import { KeyboardArrowRight, Search } from '@mui/icons-material'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'

const Lists = [
  {
    name: 'Account Detail Info',
    icon: <ProfileBlack />,
    activeIcon: <ProfileWhite />,
    id: 1,
  },
  {
    name: 'Coin Balance & History',
    icon: <CoinBlack />,
    activeIcon: <CoinWhite />,
    id: 2,
  },
  {
    name: 'List My Entity',
    icon: <HistoryBlack />,
    activeIcon: <HistoryWhite />,
    id: 3,
  },
  {
    name: 'Subscription Alert',
    icon: <NotificationsNoneIcon sx={{ marginLeft: '-4px' }} color="primary" fontSize="medium" />,
    activeIcon: <NotificationsNoneIcon sx={{ marginLeft: '-4px' }} color="secondary" fontSize="medium" />,
    id: 4,
  },
]

interface DrawerListProps {
  setOpen: (newOpen: boolean) => void
}

// import AdbIcon from '@mui/icons-material/Adb'
const DrawerList = ({ setOpen }: DrawerListProps) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [modalSignout, setModalSignout] = useState<boolean>(false)
  const [modalDelete, setModalDelete] = useState<boolean>(false)
  const { setActiveStep } = useUserStore()
  const { changeProfileSelected, profileSelected } = useProfileStore()
  const signOutTrigger = () => {
    Promise.all([clearToken(), navigate('/'), queryClient.resetQueries(), setActiveStep(0)])
  }
  const deleteTrigger = async () => {
    await postInactiveMe()
    Promise.all([clearToken(), navigate('/'), queryClient.resetQueries(), setActiveStep(0)])
  }
  return (
    <>
      <Box
        sx={{
          overflow: 'auto',
          pt: 4,
          height: 1,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '300px',
        }}
      >
        <List disablePadding={true}>
          <ListItem
            key={'Sign Out'}
            onClick={() => {
              navigate('/')
              setOpen(false)
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <BackBrownIcon />
              </ListItemIcon>
              <ListItemText
                sx={{
                  '& .MuiListItemText-primary': {
                    fontSize: 12,
                    fontWeight: 600,
                    lineHeight: '10px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                    color: '#382A02',
                  },
                }}
                primary={'Back To Leaderboard'}
              />
            </ListItemButton>
          </ListItem>
          {Lists.map((item) => (
            <ListItem
              key={item?.name}
              onClick={() => {
                changeProfileSelected(item.id)
                setOpen(false)
              }}
            >
              <ListItemButton
                selected={profileSelected === item.id}
                sx={{ '&.Mui-selected': { backgroundColor: '#382A02', borderRadius: 2, color: 'white' } }}
              >
                <ListItemIcon sx={{ color: 'white' }}>
                  {profileSelected === item.id ? item.activeIcon : item?.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    '& .MuiListItemText-primary': {
                      fontSize: 12,
                      fontWeight: 600,
                      lineHeight: '10px',
                      letterSpacing: '0em',
                      textAlign: 'left',
                    },
                  }}
                  primary={item?.name}
                />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem
            key={'Sign Out'}
            onClick={async () => {
              // setOpen(false)
              setModalSignout(true)
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <SignOutIcon />
              </ListItemIcon>
              <ListItemText
                sx={{
                  '& .MuiListItemText-primary': {
                    fontSize: 12,
                    fontWeight: 600,
                    lineHeight: '10px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                  },
                }}
                primary={'Sign Out'}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key={'Delete Account'}>
            <ListItemButton
              onClick={() => {
                setModalDelete(true)
                // setOpen(false)
              }}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText
                sx={{
                  '& .MuiListItemText-primary': {
                    fontSize: 12,
                    fontWeight: 600,
                    lineHeight: '10px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                    color: '#C80F0F',
                  },
                }}
                primary={'Delete Account'}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <ModalShare
        open={modalSignout}
        title={'Logout'}
        submitDescription={'Oke'}
        description={'Are you sure would like to logout?'}
        icon={<LogoutRedIcon />}
        cancelDescription={'Cancel'}
        onClickCancel={() => setModalSignout(!modalSignout)}
        handleClose={() => setModalSignout(!modalSignout)}
        onClickSubmit={signOutTrigger}
        oppositeButton={true}
      />
      <ModalShare
        open={modalDelete}
        oppositeButton={true}
        title={'Delete Account'}
        submitDescription={'Oke'}
        description={'Are you sure would like to delete account?'}
        cancelDescription={'Cancel'}
        icon={<DeleteModalIcon />}
        handleClose={() => setModalDelete(!modalDelete)}
        onClickCancel={() => setModalDelete(!modalDelete)}
        onClickSubmit={deleteTrigger}
      />
    </>
  )
}

const initialParamsTopUp = { page: 1, size: 10, search: '' }

const Header = () => {
  const theme = useTheme()
  const isScreenSm = useMediaQuery(theme.breakpoints.up('sm'))
  const isScreenMd = useMediaQuery(theme.breakpoints.up('md'))
  const path = window.location.pathname
  const navigate = useNavigate()
  const token = getToken()
  const queryClient = useQueryClient()
  const { startDate, endDate, setDataSelectedUnlock, setSelectedEntity } = useComparisonStore()
  const { data: dataUser } = useUserProfile()
  const { setActiveStep } = useUserStore()
  const [params, setParams] = useState(initialParamsTopUp)
  const [isOpenEntitySearch, setIsOpenEntitySeach] = useState(false)
  const [modalSignout, setModalSignout] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const isRenderSearchField = path !== '/' && path !== '/category'
  const [searchBarWidth, setsearchBarWidth] = useState<number>(0)

  const { data: dataSearchEntity } = useQuery(['SearchEntity', params], () => getSearchEntity(params), {
    staleTime: 1000 * 60 * 1,
  })

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openProfileMenu = Boolean(anchorEl)

  const [open, setOpen] = useState<boolean>(false)

  const handleCloseProfileMenu = () => {
    setAnchorEl(null)
  }

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }

  const signOutTrigger = () => {
    Promise.all([clearToken(), navigate('/'), queryClient.resetQueries(), setActiveStep(0)])
    setModalSignout(false)
  }

  const deleteTrigger = async () => {
    await postInactiveMe()
    Promise.all([clearToken(), navigate('/'), queryClient.resetQueries(), setActiveStep(0)])
    setModalDelete(false)
  }

  const textLimit = (text: string) => {
    if (isScreenSm) {
      return text
    } else {
      if (text.split(' ')[0].length > 5) {
        return text.split(' ')[0].slice(0, 5) + '..'
      }
      return text.split(' ')[0]
    }
  }

  const onSetParams = (id, value) => {
    setParams((oldParams) => ({
      ...oldParams,
      [id]: value,
    }))
  }

  const handleSearchChangeEntity = (event) => {
    const value = event.target.value
    onSetParams('search', value)
    if (value.length > 1) {
      setIsOpenEntitySeach(true)
    } else {
      setIsOpenEntitySeach(false)
    }
  }

  const handleNavigateSelection = (item) => {
    const params = {
      entityId: item.entityId || item.id,
      entityName: item.name,
      startDate: startDate,
      endDate: endDate,
    }
    navigate(`/entity?${new URLSearchParams(params).toString()}`)
    setSelectedEntity(item)
    setIsOpenEntitySeach(false)
  }

  const handleClickProfileAvatar = (e) => {
    if (isScreenSm && token) {
      setDataSelectedUnlock(null)
      navigate('/profile')
      return
    }
    setAnchorEl(e.currentTarget)
  }

  return (
    <AppBar position="static" sx={{ backgroundColor: '#262626' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
          <Box sx={{ display: path == '/profile' ? { xs: 'flex', md: 'none' } : 'none', pr: 9 }}>
            <IconButton onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Box>

          <Box sx={{ display: 'flex' }}>
            <IconButton
              onClick={() => {
                setDataSelectedUnlock(null)
                navigate('/')
              }}
              sx={{ display: { xs: 'block', sm: 'none' } }}
            >
              <IndsightMobile className="font-size:10px" />
            </IconButton>
            <IconButton
              onClick={() => {
                setDataSelectedUnlock(null)
                navigate('/')
              }}
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              <Indsight className="font-size:10px" />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {isRenderSearchField ? (
              <>
                <TextField
                  ref={(r) => {
                    r !== null && setsearchBarWidth(r.offsetWidth)
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 2,
                      // width: { xs: '100%', md: '250px' },
                      height: { xs: 30, sm: 40 },
                      backgroundColor: 'white',
                      fontSize: { xs: 11, sm: 14 },
                    },
                  }}
                  variant="outlined"
                  placeholder="Search Entity e.g. “BNI” or “BCA”"
                  onChange={handleSearchChangeEntity}
                  InputProps={{
                    startAdornment: (
                      // <InputAdornment position="start">
                      <Search />
                      // </InputAdornment>
                    ),
                  }}
                />
                {isOpenEntitySearch && (
                  <Paper
                    sx={{
                      width: '100%',
                      position: 'absolute',
                      maxWidth: isScreenSm ? searchBarWidth : null,
                      mt: 0.5,
                      maxHeight: 300,
                      overflow: 'auto',
                      top: 50,
                      zIndex: 1,
                      flexWrap: 'no-wrap',
                    }}
                  >
                    <List>
                      {dataSearchEntity?.payload?.data.length > 0 ? (
                        dataSearchEntity.payload.data.map((result, index) => (
                          <ListItem
                            key={index}
                            onClick={() => {
                              handleNavigateSelection(result)
                            }}
                            dense
                            disablePadding
                          >
                            <ListItemButton
                              dense
                              sx={{ gap: 1 }}
                              // sx={{ display: 'flex', alignItems: 'center', p: 1, justifyContent: 'space-between' }}
                            >
                              {/* <Box display={'flex'} alignItems="center" gap={2} flexGrow={1} textOverflow={'ellipsis'}> */}
                              <img src={result.displayPicture} alt={result.name} height={30} width={30} />
                              <Typography variant="body2" noWrap flexGrow={1}>
                                {result.name}
                              </Typography>
                              <KeyboardArrowRight sx={{ color: '#BB9555' }} />
                              {/* </Box> */}
                            </ListItemButton>
                          </ListItem>
                        ))
                      ) : (
                        <ListItem dense disablePadding sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                          <Typography variant="body2">Entity not found.</Typography>
                        </ListItem>
                      )}
                    </List>
                  </Paper>
                )}
              </>
            ) : null}
            {/* {isScreenSm ? (
              <>
                <CoinInfo />
              </>
            ) : null} */}
            {path != '/profile' && isScreenSm ? (
              <>
                <CoinInfo />
              </>
            ) : isScreenMd ? (
              <>
                <CoinInfo />
              </>
            ) : null}
            <Divider
              style={{ backgroundColor: '#DFCA72', borderWidth: 1, margin: 20, height: 40 }}
              orientation="vertical"
              variant="fullWidth"
              flexItem
              sx={{
                display: path == '/profile' ? { xs: 'none', md: 'block' } : { xs: 'none', sm: 'block' },
              }}
            />{' '}
            {token ? (
              <Box
                display={path == '/profile' ? { xs: 'none', md: 'flex' } : 'flex'}
                gap={{ xs: 0, sm: 2 }}
                alignItems={'center'}
              >
                <IconButton onClick={(e) => handleClickProfileAvatar(e)}>
                  <ProfileAvatar
                    name={''}
                    onClick={function (): void {
                      throw new Error('Function not implemented.')
                    }}
                  />
                </IconButton>
                {!isScreenSm && (
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openProfileMenu}
                    onClose={handleCloseProfileMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={handleCloseProfileMenu}>
                      <CoinInfo />
                    </MenuItem>
                    <Divider orientation="horizontal" variant="middle" />
                    <MenuItem onClick={() => navigate('/profile')}>Settings</MenuItem>
                    <MenuItem onClick={async () => setModalSignout(true)}>Sign Out</MenuItem>
                    <MenuItem onClick={async () => setModalDelete(true)} color={theme.palette.error.main}>
                      Delete Account
                    </MenuItem>
                  </Menu>
                )}
                {isScreenSm && (
                  <Typography sx={{ fontSize: { xs: 10, sm: 16 }, fontWeight: 600, fontFamily: 'Inter' }}>
                    Hi,{' '}
                    {dataUser?.payload?.fullname ? (
                      <Typography
                        sx={{ color: '#DFCA72', fontSize: { xs: 10, sm: 16 }, fontWeight: 600, fontFamily: 'Inter' }}
                        component="span"
                      >
                        {textLimit(dataUser?.payload?.fullname)}
                      </Typography>
                    ) : null}
                  </Typography>
                )}
              </Box>
            ) : (
              <>
                <IconButton onClick={(e) => handleClickProfileAvatar(e)}>
                  <Avatar
                    sx={{
                      bgcolor: '#DFCA72',
                      borderRadius: 2,
                      border: '2px solid white',
                      height: { xs: 30, sm: 40 },
                      width: { xs: 30, sm: 40 },
                    }}
                    variant="square"
                  />
                </IconButton>
                {!isScreenSm && (
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openProfileMenu}
                    onClose={handleCloseProfileMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem dense onClick={handleCloseProfileMenu}>
                      <CoinInfo />
                    </MenuItem>
                    <Divider orientation="horizontal" variant="middle" />
                    <MenuItem dense onClick={() => navigate('/signin')}>
                      Login
                    </MenuItem>
                    <MenuItem dense onClick={() => navigate('/signup')}>
                      Register
                    </MenuItem>
                  </Menu>
                )}
                {isScreenSm && (
                  <IconButton onClick={() => navigate('/signin')}>
                    <Typography
                      sx={{
                        fontSize: { xs: 10, sm: 14 },
                        fontWeight: 600,
                        lineHeight: '10px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        color: 'white',
                      }}
                    >
                      Login
                    </Typography>
                  </IconButton>
                )}
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <DrawerList setOpen={setOpen} />
      </Drawer>
      <ModalShare
        open={modalSignout}
        title={'Logout'}
        submitDescription={'Oke'}
        description={'Are you sure would like to logout?'}
        icon={<LogoutRedIcon />}
        cancelDescription={'Cancel'}
        onClickCancel={() => setModalSignout(false)}
        handleClose={() => setModalSignout(false)}
        onClickSubmit={signOutTrigger}
        oppositeButton={true}
      />
      <ModalShare
        open={modalDelete}
        oppositeButton={true}
        title={'Delete Account'}
        submitDescription={'Oke'}
        description={'Are you sure would like to delete account?'}
        cancelDescription={'Cancel'}
        icon={<DeleteModalIcon />}
        handleClose={() => setModalDelete(!modalDelete)}
        onClickCancel={() => setModalDelete(!modalDelete)}
        onClickSubmit={deleteTrigger}
      />
    </AppBar>
  )
}

export default Header
