import { useState } from 'react'
import { Body } from '@/layout'
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useProfileStore } from '@/store/profile'
import ProfileBlack from '@/assets/profile-black-icon.svg?react'
import ProfileWhite from '@/assets/profile-white-icon.svg?react'
import BackBrownIcon from '@/assets/back-brown-icon.svg?react'
import HistoryBlack from '@/assets/history-black-icon.svg?react'
import HistoryWhite from '@/assets/history-white-icon.svg?react'
import CoinBlack from '@/assets/coin-black-icon.svg?react'
import CoinWhite from '@/assets/coin-white-icon.svg?react'
import SignOutIcon from '@/assets/sign-out-icon-black.svg?react'
import DeleteIcon from '@/assets/delete-red-icon.svg?react'
import LogoutRedIcon from '@/assets/logout-red-icon.svg?react'
import DeleteModalIcon from '@/assets/delete-modal-icon.svg?react'

import { DetailAccount } from '@/components/Profile/DetailAccount'
import { CoinHistory } from '@/components/Profile/CoinHistory'

import { ListEntity } from '@/components/Profile/ListEntity'
import { clearToken } from '@/utils/storage'
import ModalShare from '@/components/Modal'
import { useQueryClient } from 'react-query'
import { postInactiveMe } from '@/network/api/api'
import { ListSubsAlert } from '@/components/Profile/ListSubsAlert'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'

const Lists = [
  {
    name: 'Account Detail Info',
    icon: <ProfileBlack />,
    activeIcon: <ProfileWhite />,
    id: 1,
  },
  {
    name: 'Coin Balance & History',
    icon: <CoinBlack />,
    activeIcon: <CoinWhite />,
    id: 2,
  },
  {
    name: 'List My Entity',
    icon: <HistoryBlack />,
    activeIcon: <HistoryWhite />,
    id: 3,
  },
  {
    name: 'Subscription Alert',
    icon: <NotificationsNoneIcon sx={{ marginLeft: '-4px' }} color="primary" fontSize="medium" />,
    activeIcon: <NotificationsNoneIcon sx={{ marginLeft: '-4px' }} color="secondary" fontSize="medium" />,
    id: 4,
  },
]

const ProfilePage = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [modalSignout, setModalSignout] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const { setActiveStep } = useUserStore()
  const { changeProfileSelected, profileSelected } = useProfileStore()
  const signOutTrigger = () => {
    Promise.all([clearToken(), navigate('/'), queryClient.resetQueries(), setActiveStep(0)])
  }
  const deleteTrigger = async () => {
    await postInactiveMe()
    Promise.all([clearToken(), navigate('/'), queryClient.resetQueries(), setActiveStep(0)])
  }
  return (
    <Body sticky={false}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            flexShrink: 0,
            overflow: 'auto',
            pt: 4,
            width: { xs: '0', md: '80px', xl: '300px' },
          }}
        >
          <List disablePadding={true}>
            <ListItem key={'Sign Out'} onClick={() => navigate('/')}>
              <ListItemButton>
                <ListItemIcon>
                  <BackBrownIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    '& .MuiListItemText-primary': {
                      fontSize: 16,
                      fontWeight: 600,
                      lineHeight: '21px',
                      letterSpacing: '0em',
                      textAlign: 'left',
                      color: '#382A02',
                    },
                  }}
                  primary={'Back To Leaderboard'}
                />
              </ListItemButton>
            </ListItem>
            {Lists.map((item) => (
              <ListItem key={item?.name} onClick={() => changeProfileSelected(item.id)}>
                <ListItemButton
                  selected={profileSelected === item.id}
                  sx={{ '&.Mui-selected': { backgroundColor: '#382A02', borderRadius: 2, color: 'white' } }}
                >
                  <ListItemIcon sx={{ color: 'white' }}>
                    {profileSelected === item.id ? item.activeIcon : item?.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      '& .MuiListItemText-primary': {
                        fontSize: 16,
                        fontWeight: 600,
                        lineHeight: '21px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                      },
                    }}
                    primary={item?.name}
                  />
                </ListItemButton>
              </ListItem>
            ))}
            <ListItem
              key={'Sign Out'}
              onClick={async () => {
                setModalSignout(true)
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <SignOutIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    '& .MuiListItemText-primary': {
                      fontSize: 16,
                      fontWeight: 600,
                      lineHeight: '21px',
                      letterSpacing: '0em',
                      textAlign: 'left',
                    },
                  }}
                  primary={'Sign Out'}
                />
              </ListItemButton>
            </ListItem>
            <ListItem key={'Delete Account'}>
              <ListItemButton onClick={() => setModalDelete(true)}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    '& .MuiListItemText-primary': {
                      fontSize: 16,
                      fontWeight: 600,
                      lineHeight: '21px',
                      letterSpacing: '0em',
                      textAlign: 'left',
                      color: '#C80F0F',
                    },
                  }}
                  primary={'Delete Account'}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
        <Box
          sx={{
            borderStyle: 'solid',
            borderColor: 'rgba(140, 140, 140, 0.3)',
            borderWidth: '0 0 0 2px',
            width: '100%',
            flex: 1,
          }}
        >
          <SelectedComponent profileSelected={profileSelected} />
        </Box>
      </Box>
      <ModalShare
        open={modalSignout}
        title={'Logout'}
        submitDescription={'Oke'}
        description={'Are you sure would like to logout?'}
        icon={<LogoutRedIcon />}
        cancelDescription={'Cancel'}
        onClickCancel={() => setModalSignout(!modalSignout)}
        handleClose={() => setModalSignout(!modalSignout)}
        onClickSubmit={signOutTrigger}
        oppositeButton={true}
      />
      <ModalShare
        open={modalDelete}
        oppositeButton={true}
        title={'Delete Account'}
        submitDescription={'Oke'}
        description={'Are you sure would like to delete account?'}
        cancelDescription={'Cancel'}
        icon={<DeleteModalIcon />}
        handleClose={() => setModalDelete(!modalDelete)}
        onClickCancel={() => setModalDelete(!modalDelete)}
        onClickSubmit={deleteTrigger}
      />
    </Body>
  )
}

const SelectedComponent = ({ profileSelected }) => {
  switch (profileSelected) {
    case 1:
      return <DetailAccount />
    case 2:
      return <CoinHistory />
    case 3:
      return <ListEntity />
    case 4:
      return <ListSubsAlert />
    default:
      break
  }
}

export default ProfilePage
