/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CoinIcon from '@/assets/coin-icon.svg?react'
import { useQuery as useReactQuery } from 'react-query'
import SuccessIcon from '@/assets/success-green-icon.svg?react'
import ErrorIcon from '@/assets/error-icon.svg?react'
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  // TextField,
  // useMediaQuery,
  // useTheme,
} from '@mui/material'
import { useTopupWallet, useUserProfile, useUserWallet } from '@/hooks/User/useUser'
import { formatCurrency } from '@/utils/storage'
import ModalShare from '../Modal'
import { getPaymentList } from '@/network/api/api'
import { replaceUnderscoresWithSpaces } from '@/utils/helper'
const DOMAIN_URL = import.meta.env.VITE_DOMAIN_URL
export const PaymentSummary = () => {
  // const theme = useTheme()
  // const matches = useMediaQuery(theme.breakpoints.up('sm'))

  const navigate = useNavigate()
  const { setActiveStep } = useUserStore()
  const { mutate: topupWallet, error, isSuccess } = useTopupWallet()
  const { data: dataProfile } = useUserProfile()
  const { data: userWalletData } = useUserWallet()
  const { topup } = useProfileStore()
  const [selectedPayment, setSelectedPayment] = useState({
    paymentId: '',
    provider: '',
  })

  const [openModal, setOpenModal] = useState(false)
  const [openModalError, setOpenModalError] = useState(false)
  const { data: dataPaymentChannels, isError: isErrorPaymentChannels } = useReactQuery(
    ['PaymentChannels'],
    () => getPaymentList(),
    {
      staleTime: 1000 * 60 * 1,
      retry: 2,
    },
  )
  useEffect(() => {
    if (isSuccess) {
      setActiveStep(0)
      navigate('/')
    }
  }, [isSuccess])
  const handleTopUp = () => {
    const body = {
      amount: topup.amount,
      paymentMethod: selectedPayment.paymentId,
      paymentChannel: selectedPayment.provider,
      customer: {
        givenNames: dataProfile?.payload?.fullname,
        email: dataProfile?.payload.email,
      },
      walletId: userWalletData?.payload?.walletId,
      coin: topup.coin,
      successRedirectURL: `${DOMAIN_URL}/success`,
      failureRedirectURL: `${DOMAIN_URL}/error`,
    }
    topupWallet(body)
  }
  const handleRadioChange = (paymentId, provider) => {
    setSelectedPayment({ paymentId, provider })
  }
  useEffect(() => {
    if (error) {
      setOpenModalError(true)
    }
  }, [error])
  const separateByChannelCategory = (paymentChannels) => {
    if (paymentChannels && !isErrorPaymentChannels) {
      const groupedByCategory = paymentChannels.reduce((acc, channelItem) => {
        if (!channelItem || !channelItem.method) {
          console.error('Invalid channel data:', channelItem) // Error handling
          return acc
        }
        const { method, name, channel } = channelItem
        if (!acc[method]) {
          acc[method] = []
        }
        acc[method].push({
          provider: name,
          provider_channel: channel,
        })
        return acc
      }, {})

      const result = Object.keys(groupedByCategory).map((category) => ({
        name: category,
        id: category,
        options: groupedByCategory[category],
      }))

      return result
    } else {
      console.error('Payment channels are not available or there is an error flag.')
    }
  }

  // Example usage
  const categorizedPayments = separateByChannelCategory(dataPaymentChannels?.payload)

  return (
    <Container>
      <Grid container spacing={{ xs: 2, sm: 4 }} display={'flex'} flexDirection={{ xs: 'column-reverse', sm: 'row' }}>
        <Grid item xs={12} md={7}>
          <Typography
            variant="h5"
            sx={{
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '21px',
              letterSpacing: '0em',
              textAlign: 'left',
            }}
          >
            {' '}
            Choose Payment Method
          </Typography>
          <FormControl fullWidth sx={{ pt: 2 }}>
            {categorizedPayments?.map((payment, index) => (
              <Accordion
                key={payment.id}
                sx={{
                  my: 1,
                  flexGrow: 1,
                  flex: 1,
                  width: '100%',
                  '& .MuiPaper-root': {
                    color: 'darkslategray',
                    backgroundColor: '#E4FAF4',
                    boxShadow: 'none',
                  },
                }}
              >
                <AccordionSummary
                  sx={{
                    bgcolor: '#382A02',
                    height: '20px',
                    border: '1px solid #382A02',
                  }}
                  expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                >
                  <Typography
                    sx={{
                      color: 'white',
                      fontSize: { xs: '12px', sm: '14px' },
                      fontWeight: 600,
                      lineHeight: '21px',
                      letterSpacing: '0em',
                      textAlign: 'left',
                    }}
                  >
                    {replaceUnderscoresWithSpaces(payment.name)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RadioGroup
                    aria-labelledby={`panel${index}a-header`}
                    name={`payment-radio-group-${payment.id}`}
                    value={selectedPayment.paymentId === payment.id ? selectedPayment.provider : ''}
                    onChange={(e) => {
                      handleRadioChange(payment.id, e.target.value)
                    }}
                  >
                    {payment.options.map((option) => (
                      <FormControlLabel
                        key={option.provider_channel}
                        value={`${option.provider_channel}`}
                        control={<Radio />}
                        label={option.provider}
                      />
                    ))}
                  </RadioGroup>
                </AccordionDetails>
              </Accordion>
            ))}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography
            variant="h5"
            sx={{
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '21px',
              letterSpacing: '0em',
              textAlign: 'left',
            }}
          >
            Order Summary
          </Typography>
          <Paper sx={{ mt: 2, p: 2, borderRadius: '10px' }}>
            <Container>
              <Box display={'flex'} alignItems={'center'} sx={{ mb: 2 }}>
                <CoinIcon />
                <Typography
                  sx={{
                    fontSize: { xs: 12, sm: 14 },
                    fontWeight: 600,
                    lineHeight: '21px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                  }}
                >
                  {topup.coin} Coin
                </Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} sx={{ mb: 2 }}>
                <Typography
                  sx={{
                    fontSize: { xs: 12, sm: 14 },
                    fontWeight: 600,
                    lineHeight: '21px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                  }}
                >
                  Subtotal
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: 12, sm: 14 },
                    fontWeight: 600,
                    lineHeight: '21px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                  }}
                >
                  Rp {formatCurrency(topup.amount)}
                </Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} sx={{ mb: 1 }}>
                <Typography
                  sx={{
                    fontSize: { xs: 12, sm: 14 },
                    fontWeight: 600,
                    lineHeight: '21px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                  }}
                >
                  Discount
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: 12, sm: 14 },
                    fontWeight: 600,
                    lineHeight: '21px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                  }}
                >
                  Rp {formatCurrency(0)}
                </Typography>
              </Box>
              <Divider />
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} sx={{ my: 2 }}>
                <Typography
                  sx={{
                    fontSize: { xs: 12, sm: 14 },
                    fontWeight: 600,
                    lineHeight: '21px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                  }}
                >
                  Grand Total
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: 12, sm: 14 },
                    fontWeight: 600,
                    lineHeight: '21px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                  }}
                >
                  Rp {formatCurrency(topup.amount)}
                </Typography>
              </Box>
              <Button
                onClick={() => handleTopUp()}
                fullWidth
                variant="contained"
                sx={{
                  fontSize: { xs: 12, sm: 14 },
                  fontWeight: 700,
                  lineHeight: '21px',
                  letterSpacing: '0em',
                  textAlign: 'left',
                  borderRadius: '10px',
                }}
              >
                Checkout
              </Button>
            </Container>
          </Paper>
        </Grid>
      </Grid>
      <ModalShare
        onClickSubmit={async () => {
          await setActiveStep(0)
          await navigate('/')
        }}
        title={'Top Up Coin Successfull'}
        description={'Your top up coin have been successfully, thank you!'}
        icon={<SuccessIcon />}
        open={openModal}
        handleClose={() => setOpenModal(!openModal)}
        submitDescription={'Continue'}
      />
      <ModalShare
        onClickSubmit={() => setOpenModalError(!openModalError)}
        title={'Top Up Coin Unsuccessfull'}
        description={`${error?.response?.data?.message}`}
        icon={<ErrorIcon />}
        open={openModalError}
        handleClose={() => setOpenModalError(!openModalError)}
        submitDescription={'Cancel'}
      />
    </Container>
  )
}
