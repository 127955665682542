import { ArrowUpward, ArrowDownward, UnfoldMore } from '@mui/icons-material'
import { SxProps } from '@mui/material'

interface GrowthIconProps {
  growth: number
  size?: string
  positiveColor?: string
  negativeColor?: string
  neutralColor?: string
}

const GetGrowthIcon = ({
  growth,
  size = '13px',
  positiveColor = '#61B258',
  negativeColor = '#C80F0F',
  neutralColor = 'gray',
}: GrowthIconProps) => {
  const commonStyles: SxProps = { fontSize: size, strokeWidth: 1.5 }

  if (growth > 0) {
    return <ArrowUpward sx={{ ...commonStyles, color: positiveColor, stroke: positiveColor }} />
  }

  if (growth < 0) {
    return <ArrowDownward sx={{ ...commonStyles, color: negativeColor, stroke: negativeColor }} />
  }

  return <UnfoldMore sx={{ ...commonStyles, color: neutralColor, stroke: neutralColor }} />
}

export default GetGrowthIcon
