/* eslint-disable @typescript-eslint/no-explicit-any */
import { request } from '@/network/axios'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'

interface UploadResponse {
  payload: any
  // Define the response structure you expect from the server after upload
}

const uploadFile = async (file: File): Promise<UploadResponse> => {
  const formData = new FormData()
  formData.append('file', file)
  const response: AxiosResponse<{ payload: any }> = await request.post('/api/v1/user/image-profile', formData, {})
  return {
    payload: response,
  }
}

export const useUploadFile = () => {
  return useMutation(uploadFile)
}
