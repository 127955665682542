/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton, Paper, Skeleton, Tooltip, Typography } from '@mui/material'
import InfoIcon from '@/assets/info-icon.svg?react'
import TrendDownIcon from '@/assets/trend-down-red-icon.svg?react'
import TrendUpIcon from '@/assets/trend-up-green-icon.svg?react'
import StatsIcon from '@/assets/stats-engagement-icon.svg'
import LockBigIcon from '@/assets/lock-big-icon.svg'
import { getEngagementRate } from '@/network/api/analytic'
import { formatCurrencyShortForm, roundToDecimals } from '@/utils/storage'

interface DataEngagement {
  isLock: string
  data?: any
}
export const EngagementRateAnalytic = ({
  id,
  handleUnlockEntity,
}: {
  id: string | undefined
  handleUnlockEntity: any
}) => {
  const { startDate, endDate, selectedPlatform, selectedEntity } = useComparisonStore()
  const [isLoading, setLoading] = useState(false)
  const [dataPostmade, setEngagementRate] = useState<DataEngagement>({} as DataEngagement)

  useEffect(() => {
    fetchEngagementRate()
  }, [startDate, endDate, selectedPlatform, id])

  const fetchEngagementRate = async () => {
    const params = {
      startDate,
      endDate,
      entityId: id || selectedEntity.entityId,
      platforms: selectedPlatform,
    }
    try {
      setLoading(true)
      const response = await getEngagementRate(params)
      if (response.statusCode === 200) {
        setEngagementRate(response.payload)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  if (isLoading) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={100}>
        <Skeleton variant="rounded" width={370} height={100} />
      </Box>
    )
  }
  return (
    <Paper
      sx={{
        width: '100%',
        minHeight: 100,
        p: { xs: 0.5, sm: 2 },
        bgcolor: 'rgba(223, 202, 114, 0.2)',
        borderRadius: 4,
        position: 'relative',
      }}
    >
      <Box display={'flex'} alignItems={'center'}>
        <img src={StatsIcon} />
        <Typography
          fontWeight={600}
          variant="h6"
          sx={{ color: '#382A02', fontSize: { xs: 12, sm: 20 }, lineHeight: 1.3 }}
        >
          Engagement Rate
        </Typography>
        <Tooltip
          placement="top-end"
          title={'The average of Engagement Rate (Total Engagement Rate / Post : Total Postmade)'}
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Box position={'relative'}>
        <Box
          position={'absolute'}
          sx={{
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.4)',
            opacity: dataPostmade?.isLock ? 1 : 0,
            filter: dataPostmade?.isLock ? 'blur(3px)' : 'none',
            zIndex: dataPostmade?.isLock ? 1 : 0,
          }}
        />
        <Box
          position={'absolute'}
          sx={{
            flex: 1,
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
            filter: dataPostmade?.isLock ? 'blur(8px)' : 'none',
            opacity: dataPostmade?.isLock ? 0.5 : 1,
            zIndex: dataPostmade?.isLock ? 1 : 0,
          }}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'flex-end'}
        >
          <Tooltip title={dataPostmade?.data?.recentValue.toLocaleString('id-ID')}>
            <Typography
              fontSize={{ xs: 20, sm: 28 }}
              fontWeight={600}
              sx={{ paddingLeft: { xs: 1, sm: 0 }, width: { xs: '100%', sm: 'auto' }, textAlign: 'left' }}
            >
              {formatCurrencyShortForm(roundToDecimals(dataPostmade?.data?.recentValue, 3))}%
            </Typography>
          </Tooltip>
          <Tooltip
            placement="bottom-end"
            title={formatCurrencyShortForm(roundToDecimals(dataPostmade?.data?.pastValue, 3))}
          >
            <Paper sx={{ display: 'flex', alignItems: 'center', px: 1, gap: 1, borderRadius: 2 }}>
              {dataPostmade?.data?.growth > 0 ? <TrendUpIcon /> : <TrendDownIcon />}
              <Typography
                sx={{ color: dataPostmade?.data?.growth > 0 ? 'green' : 'red', fontSize: { xs: 12, sm: 16 } }}
              >
                {dataPostmade?.data?.growth}%
              </Typography>
            </Paper>
          </Tooltip>
        </Box>
      </Box>
      {dataPostmade?.isLock && (
        <Box
          position={'absolute'}
          sx={{
            top: '70%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            '@media (max-width: 100px)': {
              top: '70%',
              left: '40%',
              transform: 'translate(-40%, -40%)',
            },
          }}
        >
          <IconButton onClick={handleUnlockEntity}>
            <img src={LockBigIcon} width={50} />{' '}
          </IconButton>
        </Box>
      )}
    </Paper>
  )
}
