/* eslint-disable */
import { Body } from '@/layout'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import SearchIcon from '@mui/icons-material/Search'
import { useQuery as useReactQuery } from 'react-query'
import {
  Typography,
  Box,
  MenuItem,
  FormControl,
  Container,
  Grid,
  ListItem,
  TextField,
  InputAdornment,
  List,
  Link,
  ListItemButton,
  Paper,
  Skeleton,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import CalendarIcon from '@/assets/calendar-icon.svg?react'
import GrowthIcon from '@/assets/growth-icon.svg?react'
import Select, { SelectChangeEvent } from '@mui/material/Select'
// mock data
import { useGeneralCategories, useGeneralEntity, useGeneralMetricType } from '@/hooks/Entity/useEntity'
import { getMonthDetails } from '@/utils/helper'
import { getSearchEntity } from '@/network/api/api'
import CategoryItem from '@/components/CategoryItem'
import { SelectedEntity } from '@/store/comparison'
import CustomTooltip from '@/components/Tooltip'
import { KeyboardArrowRight } from '@mui/icons-material'
import { ModalWhatsappReminder } from '@/components/Modal/ModalWhatsappReminder'
import { useUserProfile } from '@/hooks/User/useUser'

const initialParamsTopUp = { page: 1, size: 10, search: '' }

const talkGrowthTooltip = 'The growth in the number of discussions or conversations occurring'
const articleGrowthTooltip = 'The growth in the number of articles published or posted'

const DashboardPage = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const {
    categoryIds,
    //  startDate,
    //  endDate,
    //  setStartDate,
    //  setEndDate,
    metric,
    setCategoryIds,
    setMetric,
  } = useEntityStore()
  const { startDate, endDate, setStartDate, setEndDate, setStartDateDropDown, setEndDateDropDown } =
    useComparisonStore()
  const { data: payloadMetricType, isSuccess, isLoading: isLoadingMetric } = useGeneralMetricType()
  const {
    data: payloadCategories,
    isSuccess: isSuccessCategories,
    isLoading: isLoadingCategories,
  } = useGeneralCategories()
  const dataMetricType = payloadMetricType?.payload
  const dataCategoriesType = payloadCategories?.payload
  const { data: payloadAllEntity, isLoading: entityLoading } = useGeneralEntity(categoryIds, startDate, endDate, metric)
  const dataEntity = payloadAllEntity?.payload || []
  const [isOpen, setIsOpen] = useState(false)
  const [params, setParams] = useState(initialParamsTopUp)
  const { setSelectedEntity } = useComparisonStore()
  const navigate = useNavigate()
  const [selectedMonth, setselectedMonth] = useState<string>('')

  // set initial timeframe
  useEffect(() => {
    const momentStartDate = moment(startDate)
    const momentEndDate = moment(endDate)
    const momentNow = moment()
    const last3Months = getMonthDetails(3)

    const monthDifference = momentNow.diff(momentStartDate, 'months', true)
    const isStartDateMoreThan3Months = monthDifference > 3

    const currentMonthStartDate = last3Months[2].startDate
    const currentMonthEndDate = last3Months[2].endDate
    const currentMonthLabel = last3Months[2].display

    if (isStartDateMoreThan3Months) {
      setStartDate(currentMonthStartDate)
      setEndDate(currentMonthEndDate)
      setselectedMonth(currentMonthLabel)
      return
    }

    const isTimeFrameSameMonth = momentStartDate.isSame(momentEndDate, 'month')
    if (!isTimeFrameSameMonth) {
      setStartDate(currentMonthStartDate)
      setEndDate(currentMonthEndDate)
      setselectedMonth(currentMonthLabel)
      return
    }

    const startDateMonth = momentStartDate.month()
    if (startDateMonth === momentNow.month()) {
      setStartDate(currentMonthStartDate)
      setEndDate(currentMonthEndDate)
      setselectedMonth(currentMonthLabel)
    }

    const selectedTimeframe = last3Months.find((monthOption) => {
      const optionStartDateMonth = moment(monthOption.startDate).month()
      return startDateMonth === optionStartDateMonth
    })

    if (selectedTimeframe) {
      setStartDate(selectedTimeframe.startDate)
      setEndDate(selectedTimeframe.endDate)
      setselectedMonth(selectedTimeframe.display)
    }
  }, [])

  const { data: dataSearchCategory } = useReactQuery(['SearchEntity', params], () => getSearchEntity(params), {
    staleTime: 1000 * 60 * 1,
  })
  useEffect(() => {
    if (
      (payloadCategories && isSuccessCategories && !isLoadingCategories && payloadCategories?.payload !== undefined) ||
      metric ||
      endDate
    ) {
      const ids = payloadCategories?.payload?.map((cat) => cat?.categoryId)
      if (ids) {
        setCategoryIds(ids.join(','))
      }
    }
  }, [payloadCategories])

  useEffect(() => {
    if (!isLoadingMetric && isSuccess && dataMetricType && dataMetricType.length > 0) {
      setMetric(metric || dataMetricType[0].name)
    }
  }, [isSuccess, dataMetricType, metric])

  const handleChange = (event: SelectChangeEvent<string>) => {
    setMetric(event.target.value as string)
  }
  const onSetParams = (id, value) => {
    setParams((oldParams) => ({
      ...oldParams,
      [id]: value,
    }))
  }
  const handleSearchChangeEntity = (event) => {
    const value = event.target.value
    onSetParams('search', value)
    // setAnchorEl(event.currentTarget);
    if (value.length > 1) {
      setIsOpen(true)
    } else {
      // setResults([]);
      setIsOpen(false)
    }
  }

  const handleChangeMonth = (event: SelectChangeEvent<any>) => {
    setStartDate(event?.target?.value?.startDate as any)
    setEndDate(event?.target?.value?.endDate as any)
    setStartDateDropDown(event?.target?.value?.startDate as any)
    setEndDateDropDown(event?.target?.value?.endDate as any)
    setselectedMonth(event?.target?.value?.display as any)
  }
  const handleNavigateSelection = (item) => {
    const params = {
      entityName: item.name,
      entityId: item.entityId || item.id,
      startDate: startDate,
      endDate: endDate,
      metric: metric,
    }
    navigate(`/entity?${new URLSearchParams(params).toString()}`)
    setSelectedEntity(item)
  }
  const last3Months = getMonthDetails(3)

  // check if user logged in, show input whatsapp reminder
  const { data } = useUserProfile()
  const [modalWhatsappReminder, setModalWhatsappReminder] = useState(false)
  useEffect(() => {
    setModalWhatsappReminder(Boolean(data?.payload.phoneNumber === null))
  }, [data])

  return (
    <Body sticky={false}>
      <ModalWhatsappReminder isShow={modalWhatsappReminder} setIsShow={setModalWhatsappReminder} />
      <Box sx={{ backgroundColor: '#262626' }}>
        <Container maxWidth="xl" sx={{ py: 2 }}>
          <Typography color="rgba(187, 149, 85, 1)" fontWeight={600} textAlign={'center'} fontSize={{ xs: 16, md: 20 }}>
            All Categories
          </Typography>
          <Typography color="#ffffff" fontWeight={400} textAlign={'center'} fontSize={{ xs: 12, md: 16 }}>
            Showcasing entities in sub-category based on the top rank within each category
          </Typography>
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 2,
              p: 1,
              width: '100%',
            }}
          >
            <TextField
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  width: { xs: '100%', md: '400px' },
                  height: { xs: 35, sm: 40 },
                  backgroundColor: 'white',
                  fontSize: { xs: 11, sm: 14 },
                },
              }}
              variant="outlined"
              placeholder="Search Entity e.g. “BNI” or “BCA”"
              onChange={handleSearchChangeEntity}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            {isOpen && (
              <Paper
                sx={{
                  position: 'absolute',
                  width: '400px',
                  mt: 0.5,
                  maxHeight: 300,
                  overflow: 'auto',
                  top: 50,
                  zIndex: 1,
                  flexWrap: 'no-wrap',
                }}
              >
                <List disablePadding>
                  {dataSearchCategory?.payload?.data.length > 0 ? (
                    dataSearchCategory.payload.data.map((result, index) => (
                      <ListItem
                        key={index}
                        onClick={() => {
                          handleNavigateSelection(result)
                        }}
                      >
                        <ListItemButton
                          sx={{ display: 'flex', alignItems: 'center', p: 1, justifyContent: 'space-between' }}
                        >
                          <Box display={'flex'} gap={2}>
                            <img src={result.displayPicture} alt={result.name} height={30} width={30} />
                            <Typography>{result.name}</Typography>
                          </Box>
                          <KeyboardArrowRight sx={{ color: '#BB9555' }} />
                        </ListItemButton>
                      </ListItem>
                    ))
                  ) : (
                    <ListItem sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                      <Typography>Entity not found.</Typography>
                    </ListItem>
                  )}
                </List>
              </Paper>
            )}
          </Box>
        </Container>
      </Box>

      <Container maxWidth="lg">
        <Grid
          container
          columns={{ xs: 6, sm: 12 }}
          spacing={{ xs: 1, sm: 2 }}
          p={{ xs: 1, sm: 2 }}
          justifyContent={'center'}
        >
          <Grid item xs={3}>
            <FormControl fullWidth size="small">
              <Select
                displayEmpty
                sx={{ backgroundColor: 'white', borderRadius: 3, fontSize: { xs: 11, sm: 14 }, height: 40 }}
                value={metric}
                startAdornment={
                  <InputAdornment position="start">
                    <GrowthIcon />
                  </InputAdornment>
                }
                onChange={handleChange}
              >
                {dataMetricType?.map((item: any) => (
                  <MenuItem key={item.name} value={item.name} sx={{ fontSize: { xs: 11, sm: 14 } }}>
                    {item.displayName}
                    <span>
                      <CustomTooltip title={item.name === 'talkGrowth' ? talkGrowthTooltip : articleGrowthTooltip} />
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth size="small">
              <Select
                startAdornment={
                  <InputAdornment position="start">
                    <CalendarIcon />
                  </InputAdornment>
                }
                displayEmpty
                value={selectedMonth}
                renderValue={(selected: string) => {
                  const selectedItem = last3Months.find((m) => m.display === selected)
                  const selectedItemIndex = last3Months.findIndex((m) => m.display === selected)
                  return (
                    <MenuItem value={selectedItem} sx={{ padding: 0, fontSize: { xs: 11, sm: 14 } }}>
                      {selectedItem?.display}
                      <span style={{ display: matches ? 'block' : 'none' }}>
                        <CustomTooltip
                          title={
                            selectedItemIndex === 2
                              ? 'Data for the last 30 days from 1 day before today'
                              : 'Data for a full month'
                          }
                        />
                      </span>
                    </MenuItem>
                  )
                }}
                sx={{ backgroundColor: 'white', color: 'black', borderRadius: 3, height: { xs: 40 } }}
                onChange={handleChangeMonth}
              >
                {last3Months.map((item: any, index) => (
                  <MenuItem key={item?.display} value={item} sx={{ fontSize: { xs: 11, sm: 14 } }}>
                    {item?.display}
                    <span>
                      <CustomTooltip
                        title={
                          index === 2 ? 'Data for the last 30 days from 1 day before today' : 'Data for a full month'
                        }
                      />
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg" sx={{ overflow: 'auto' }}>
        <Container sx={{ width: '1200px' }}>
          <Grid container spacing={2}>
            {isLoadingCategories && (
              <>
                <Grid item xs={3}>
                  <Skeleton variant="rounded" width={270} height={100} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton variant="rounded" width={270} height={100} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton variant="rounded" width={270} height={100} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton variant="rounded" width={270} height={100} />
                </Grid>
              </>
            )}
            {dataCategoriesType &&
              dataCategoriesType?.map((category) => (
                <Grid item xs={3}>
                  <Box bgcolor={'#000000'} borderRadius={2} height={90} className="relative" mb={1}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1',
                        justifyContent: 'space-between',
                        height: '100%',
                        padding: '16px',
                      }}
                    >
                      <Typography sx={{ color: 'rgba(187, 149, 85, 1)' }} fontWeight={700} fontSize={20}>
                        {category.name}
                      </Typography>
                      <Link
                        width={'60%'}
                        display={'flex'}
                        justifyContent="left"
                        variant="body1"
                        component="button"
                        onClick={() => navigate(`/category?name=${category.name}&categoryId=${category?.categoryId}`)}
                      >
                        <Typography color={'#ffffff'} fontWeight={600} fontSize={12}>
                          See Subcategory {'>'}
                        </Typography>
                      </Link>
                    </Box>
                    <div className="category-image">
                      <img src={category.displayPicture} />
                    </div>
                  </Box>
                </Grid>
              ))}
          </Grid>
          <Grid container spacing={2} display={'flex'} justifyContent={'space-between'} sx={{ marginBottom: 2 }}>
            {entityLoading || isLoadingCategories || !dataEntity ? (
              <>
                <Grid item xs={3}>
                  <Skeleton variant="rounded" width={270} height={1000} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton variant="rounded" width={270} height={1000} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton variant="rounded" width={270} height={1000} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton variant="rounded" width={270} height={1000} />
                </Grid>
              </>
            ) : (
              dataEntity?.map((entityGroup: { entities: any[] }) => (
                <Grid item xs={3}>
                  <Box
                    sx={{
                      bgcolor: '#FFFFFF',
                      borderRadius: 2,
                      boxShadow: 3,
                      padding: '10px 14px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                    }}
                  >
                    {entityGroup.entities.slice(0, 10).map((item: SelectedEntity, index: number) => {
                      const handleClick = () => {
                        const params = {
                          entityName: item.name,
                          entityId: item.entityId || item.id, // bcs response from backend is not consistent
                          startDate: startDate,
                          endDate: endDate,
                          metric: metric,
                        }
                        navigate(`/entity?${new URLSearchParams(params).toString()}`)
                        setSelectedEntity(item)
                      }
                      return (
                        <>
                          <CategoryItem
                            key={item?.entityId?.toString()}
                            title={item?.name}
                            item={item}
                            icon={item?.displayPicture}
                            index={index}
                            handleClick={handleClick}
                          />
                          {index < 10 - 1 && <Divider component="hr" />}
                        </>
                      )
                    })}
                  </Box>
                </Grid>
              ))
            )}
          </Grid>
        </Container>
      </Container>
    </Body>
  )
}

export default DashboardPage
