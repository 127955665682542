import { useCallback } from 'react'
import { Typography, Modal, Box, Card, Container, Button, Avatar } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import UploadIcon from '@/assets/upload-icon.svg?react'
import { useProfileStore } from '@/store/profile'
import { useUploadFile } from '@/hooks/useUploader'

const DOMAIN_URL = import.meta.env.VITE_API_SERVER_URL

export const ModalUpload = () => {
  const { mutate: upload, data: dataUpload, isSuccess } = useUploadFile()
  const { openModalUpload, setOpenModalUpload } = useProfileStore()

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const fileToUpload = acceptedFiles[0]
      if (fileToUpload) {
        upload(fileToUpload)
      }
    },
    [upload],
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false, // Set to true if you want to allow multiple files
  })

  return (
    <Modal open={openModalUpload}>
      <Box
        sx={{
          borderRadius: '10px',
          padding: 2,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          border: 'none',
          top: '50%',
          left: '50%',
          width: 720,
        }}
      >
        <Container>
          <Typography
            sx={{
              color: 'black',
              mt: 1,
              fontFamily: 'Inter',
              fontSize: 16,
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            Edit Photo Profile
          </Typography>
          {isSuccess ? (
            <Card
              sx={{
                flex: 1,
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 5,
                borderWidth: 1,
                borderRadius: 2,
                borderColor: '#eeeeee',
                borderStyle: 'dashed',
                backgroundColor: '#fafafa',
                color: '#bdbdbd',
                outline: 'none',
                transition: 'border .24s ease-in-out',
              }}
            >
              <Avatar sx={{ width: 100, height: 100 }} src={`${DOMAIN_URL}/shared${dataUpload?.payload?.payload}`} />
            </Card>
          ) : (
            <Card
              {...getRootProps({ className: 'dropzone' })}
              sx={{
                flex: 1,
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 5,
                borderWidth: 1,
                borderRadius: 2,
                borderColor: '#eeeeee',
                borderStyle: 'dashed',
                backgroundColor: '#fafafa',
                color: '#bdbdbd',
                outline: 'none',
                transition: 'border .24s ease-in-out',
              }}
            >
              <input {...getInputProps()} />
              <UploadIcon />
              <Box display="flex" sx={{ mt: 2, gap: 1 }}>
                <Typography
                  sx={{
                    color: 'black',
                    textDecoration: 'underline',
                    fontFamily: 'Inter',
                    fontSize: 14,
                    fontWeight: 400,
                    letterSpacing: '0.15px',
                    textAlign: 'left',
                  }}
                >
                  Click to upload{' '}
                </Typography>
                <Typography
                  sx={{
                    color: 'black',
                    fontFamily: 'Inter',
                    fontSize: 14,
                    fontWeight: 400,
                    letterSpacing: '0.15px',
                    textAlign: 'left',
                  }}
                  component="span"
                >
                  {' '}
                  or drag and drop
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: 'black',
                  mt: 1,
                  //   textDecoration: 'underline',
                  fontFamily: 'Inter',
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: '20px',
                  textAlign: 'left',
                }}
              >
                SVG, PNG, JPG or GIF (max. 3MB)
              </Typography>
            </Card>
          )}
          <Box display="flex" justifyContent="flex-end" sx={{ gap: 2, my: 2 }}>
            <Button
              variant="outlined"
              onClick={() => setOpenModalUpload(!openModalUpload)}
              sx={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #6D5103',
                color: '#6D5103',
                ':hover': { backgroundColor: 'white', border: '1px solid #6D5103' },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenModalUpload(!openModalUpload)}
              sx={{ backgroundColor: '#6D5103', border: 'none', ':hover': { backgroundColor: '#6D5103' } }}
            >
              Save
            </Button>
          </Box>
        </Container>
      </Box>
    </Modal>
  )
}
