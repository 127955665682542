/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { request } from '@/network/axios'
import { useMutation, useQuery } from 'react-query'
interface ApiResponseCategories {
  statusCode: number
  result: boolean
  message: string | null
  method: string
  path: string
  payload: Category[]
}
interface ApiResponseEntityById {
  statusCode: number
  result: boolean
  message: string | null
  method: string
  path: string
  payload: Entity
}
interface ApiResponseMyEntity {
  statusCode: number
  result: boolean
  message: string | null
  method: string
  path: string
  payload: MyEntity
}
interface MyEntity {
  rows: Entity[]
  orderItemId: string
  categoryId: string
  categoryName: string
  subCategoryId: string
  subCategoryName: string
  entityId: string
  entityName: string
  productName: string
  productStartDate: string
  productEndDate: string
  orderId: string
  createdAt: string
  expireDate: string
  periods: string
  status: string
  count: number
}
interface Entity {
  entityId: string
  name: string
  displayPicture: string
  category: string
  entityName: string
  categoryName: string
  productStartDate: string
  periods: string
  status: string
  productEndDate: string
  createdAt: string
  productName: string
  expireDate: string
  count: number
}

interface Category {
  projectIndsightMappingId: string
  categoryId: string
  subcategoryId: string
  name?: string
  parentId: string | null
  displayPicture?: string
  entities: unknown[]
  subCategories: Category[]
}
interface ApiResponseMetric {
  statusCode: number
  result: boolean
  message: string | null
  method: string
  path: string
  payload: [
    {
      name: string
      displayName: string
    },
  ]
}
interface ApiResponseGeneralEntities {
  statusCode: number
  result: boolean
  message: string | null
  method: string
  path: string
  payload: Category[]
}
enum APIS {
  GENERAL = '/api/v1/general',
  ENTITY = '/api/v1/order',
}

export const useGeneralMetricType = () => {
  return useQuery<ApiResponseMetric>(
    'general_metric_type',
    async () => await request.get(`${APIS.GENERAL}/metric_type`),
    {
      refetchOnWindowFocus: false,
    },
  )
}
export const useGeneralPeriods = () => {
  return useQuery<ApiResponseMetric>('general_periods', async () => await request.get(`${APIS.GENERAL}/periods`), {
    refetchOnWindowFocus: false,
  })
}
export const useGeneralCategories = () => {
  return useQuery<ApiResponseCategories>(
    'general_categories',
    async () => await request.get(`${APIS.GENERAL}/categories`),
    {
      refetchOnWindowFocus: false,
    },
  )
}

export const useGeneralEntity = (categoryIds: string, startDate: string, endDate: string, metric: string) => {
  const queryString = new URLSearchParams({
    startDate,
    endDate,
    categoryIds,
    metric,
  }).toString()
  return useQuery<ApiResponseGeneralEntities>(
    ['general_entities', categoryIds, startDate, endDate, metric],
    async () => await request.get(`${APIS.GENERAL}/entity/by-categories?${queryString}`),
    {
      refetchOnWindowFocus: true,
      enabled: !!categoryIds && !!metric && !!startDate && !!endDate,
    },
  )
}

export const useCategoryEntity = (subCategoryIds: string, startDate: string, endDate: string, metric: string) => {
  const queryString = new URLSearchParams({
    startDate,
    endDate,
    subCategoryIds,
    metric,
  }).toString()
  return useQuery<ApiResponseGeneralEntities>(
    ['category_entities', subCategoryIds, startDate, endDate, metric],
    async () => await request.get(`${APIS.GENERAL}/entity/by-categories?${queryString}`),
    {
      refetchOnWindowFocus: true,
      enabled: !!subCategoryIds && !!metric && !!startDate && !!endDate,
    },
  )
}

export const useGeneralSubCategories = (categoryId: string) => {
  return useQuery<ApiResponseCategories>(
    ['general_subcategories', categoryId],
    async () => await request.get(`${APIS.GENERAL}/subcategories/${categoryId}`),
    {
      refetchOnWindowFocus: false,
    },
  )
}

export const useSearchEntity = (categoryId: string) => {
  return useQuery<ApiResponseCategories>(
    ['general_subcategories', categoryId],
    async () => await request.get(`${APIS.GENERAL}/category`),
    {
      refetchOnWindowFocus: false,
    },
  )
}
export const useSubCategoryEntity = (subCategoryIds: string, startDate: string, endDate: string, metric: string) => {
  const queryString = new URLSearchParams({
    startDate,
    endDate,
    subCategoryIds,
    metric,
  }).toString()
  return useQuery<ApiResponseGeneralEntities>(
    ['sub_category_entities', subCategoryIds, startDate, endDate, metric],
    async () => await request.get(`${APIS.GENERAL}/entity/by-subcategories?${queryString}`),
    {
      refetchOnWindowFocus: true,
      enabled: !!subCategoryIds && !!metric && !!startDate && !!endDate,
    },
  )
}
export const useEntityById = (entityId: string) => {
  return useQuery<ApiResponseEntityById>(
    ['entity_by_id', entityId],
    async () => await request.get(`${APIS.GENERAL}/entity_by_id/${entityId}`),
    {
      refetchOnWindowFocus: false,
    },
  )
}
export const useMyEntityList = (initialParams) => {
  const queryString = new URLSearchParams({
    page: initialParams.page,
    size: initialParams.size,
    search: initialParams.search,
    status: initialParams.status,
    sortBy: initialParams.sortBy,
    sortDirection: initialParams.sortDirection,
  }).toString()
  return useQuery<ApiResponseMyEntity>(
    ['my_entity', initialParams],
    async () => await request.get(`${APIS.ENTITY}/my_entity?${queryString}`),
    {
      refetchOnWindowFocus: false,
      enabled: !!initialParams.page && !!initialParams.size,
    },
  )
}

export const useOrderEntity = (body) => {
  const modifiedData = body.map(({ name, displayPicture, ...rest }) => rest)
  return useMutation(
    async () => {
      const response = await request.post<ApiResponseMyEntity>(`${APIS.ENTITY}`, modifiedData)
      return response
    },
    {
      onSuccess: (data: any) => {
        return data.payload
        // window.open(`${data.payload}`)
      },
      onError: (error: any) => {
        if (error.response) {
          return error.response?.message
        }
      },
    },
  )
}
