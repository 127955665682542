import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import Background from '@/assets/background-img.jpg'
import IndsightGold from '@/assets/indsight-gold.svg?react'

import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'

export const CarouselSign = () => {
  const navigate = useNavigate()
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${Background})`,
          width: '100%',
          height: '100%',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
          borderRadius: '20px',
        }}
      >
        <Button
          onClick={() => navigate('/')}
          // sx={{ position: 'absolute', zIndex: 100, top: 20, left: 10 }}
          style={{
            position: 'absolute',
            display: 'inline-block',
            zIndex: 100,
            top: 20,
            right: '10%',
          }}
        >
          <IndsightGold />
        </Button>

        <Swiper
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          style={{ backgroundColor: 'transparent' }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide style={{ backgroundColor: 'transparent', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ width: '80%' }}>
              <Typography fontWeight={700} variant="h4" textAlign={'left'} color={'#FFFFFF'} paddingBottom={'20px'}>
                Welcome to Indonesia Social Media Insight Platform
              </Typography>
              <Typography fontWeight={600} variant="body1" textAlign={'left'} color={'#FFFFFF'}>
                Portal that provides information on the latest developments on what is happening on social media
                covering various industries in Indonesia.
              </Typography>
            </Box>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundColor: 'transparent', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ width: '80%' }}>
              <Typography variant="h4" textAlign={'left'} fontWeight={700} color={'#DFCA72'} paddingBottom={'20px'}>
                Free{' '}
                <Typography variant="h4" textAlign={'left'} fontWeight={700} color={'#FFFFFF'} component={'span'}>
                  IndSight Features
                </Typography>
              </Typography>
              <Typography variant="body1" textAlign={'left'} fontWeight={600} color={'#FFFFFF'}>
                IndSight offers several features that can be accessed for free, such as a leaderboard trend panel
              </Typography>
            </Box>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundColor: 'transparent', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ width: '80%' }}>
              <Typography variant="h4" textAlign={'left'} fontWeight={700} color={'#DFCA72'} paddingBottom={'20px'}>
                Premium{' '}
                <Typography variant="h4" textAlign={'left'} fontWeight={700} color={'#FFFFFF'} component="span">
                  IndSight Features
                </Typography>
              </Typography>{' '}
              <Typography variant="body1" textAlign={'left'} fontWeight={600} color={'#FFFFFF'}>
                IndSight offers many premium features that can be accessed by exchanging coins for premium features
              </Typography>
            </Box>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  )
}
