import { API } from './api'
export const getProductPrice = async () => {
  try {
    const response = await API(`api/v1/product/price`, 'GET', null, null)
    return response
  } catch (error) {
    return error
  }
}
export const getProductCalendarPicker = async () => {
  try {
    const response = await API(`api/v1/product`, 'GET', null, null)
    return response
  } catch (error) {
    return error
  }
}
