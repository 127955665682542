/* eslint-disable react-hooks/exhaustive-deps */
import { CssBaseline, Box, Grid, Button, Typography, Stack } from '@mui/material'
import { useFormik } from 'formik'
import { InputPassword, Input } from '@/components/Input/Input'
import { CarouselSign } from '@/components/Carousel'
import { signUpSchema } from '@/schemas'
import { signUp } from '@/network/api/api'
import { setToken } from '@/utils/storage'
import { AxiosError } from 'axios'
import { ModalOtp } from '@/components/Modal/ModalOtp'
import { ModalOtpSuccess } from '@/components/Modal/ModalOtpSuccess'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

const SignUpPage = () => {
  const navigate = useNavigate()
  const [apiErrMsg, setApiErrMsg] = useState('')
  const [isReferralExpand, setIsReferralExpand] = useState(false)
  const { setModalOtp } = useUserStore()

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      password: '',
      confirmPassword: '',
      referralCode: '',
    },
    validationSchema: signUpSchema, // Our Yup schema

    onSubmit: async (values) => {
      try {
        const response = await signUp(values)
        const token = response.payload.token
        if (response.statusCode === 201) {
          setToken(token)
          setModalOtp(true)
        }
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          setApiErrMsg(error.response?.data.message)
        }
      }
    },
    // enableReinitialize: true,
  })

  useEffect(() => {
    if (formik.values.password !== formik.values.confirmPassword) {
      formik.setFieldError('confirmPassword', 'password not same check again')
    }
  }, [formik.values.password, formik.values.confirmPassword])

  return (
    <div>
      <CssBaseline />
      <form onSubmit={formik.handleSubmit}>
        <Box height={'100vh'}>
          <Grid container sx={{ height: '100vh', backgroundColor: '#FBFBFB', padding: '20px' }}>
            <Grid
              rowSpacing={1}
              item
              xs={12}
              md={6}
              sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
            >
              <Box
                sx={{
                  marginBottom: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="h3"
                  fontWeight={700}
                  sx={{ marginBottom: '20px', fontSize: '28px', fontFamily: 'Inter' }}
                >
                  Create Account
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={400}
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '19px',
                    letterSpacing: '0em',
                    textAlign: 'center',
                  }}
                >
                  Enter the data below correctly!
                </Typography>
              </Box>
              <Box sx={{ width: { xs: '100%', md: '400px' } }}>
                <Input
                  label="Full Name*"
                  placeholder="Full name"
                  value={formik.values.fullname}
                  error={formik.touched.fullname && Boolean(formik.errors.fullname)}
                  helperText={formik.touched.fullname && formik.errors.fullname}
                  onChange={(e) => formik.setFieldValue('fullname', e.target.value)}
                />
                <Input
                  label="Email*"
                  placeholder="Example : example@example.com"
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  onChange={(e) => formik.setFieldValue('email', e.target.value)}
                />
                <InputPassword
                  label="Password*"
                  placeholder="Enter Password"
                  value={formik.values.password}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  onChange={(e) => formik.setFieldValue('password', e.target.value)}
                  name="password"
                />
                <InputPassword
                  label="Confirm Password*"
                  placeholder="Confirm Password"
                  value={formik.values.confirmPassword}
                  error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                  helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                  onChange={(e) => formik.setFieldValue('confirmPassword', e.target.value)}
                  name="confirmPassword"
                />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setIsReferralExpand((prev) => !prev)}
                >
                  <Typography mb={1} textAlign="left">
                    Referral Code
                  </Typography>
                  {isReferralExpand ? <ExpandLess /> : <ExpandMore />}
                </Stack>
                {isReferralExpand ? (
                  <Input
                    label="Enter Code"
                    placeholder="example: NLMT"
                    value={formik.values.referralCode}
                    onChange={(e) => formik.setFieldValue('referralCode', e.target.value)}
                  />
                ) : null}
                {apiErrMsg ? (
                  <Typography textAlign="center" fontSize={'14px'} color={'#D32F2F'}>
                    {apiErrMsg}
                  </Typography>
                ) : null}
              </Box>
              <Button
                type="submit"
                sx={{
                  backgroundColor: '#6D5103',
                  marginTop: 4,
                  width: { xs: '100%', md: '400px' },
                  height: '57px',
                  fontWeight: 600,
                  borderRadius: '14px',
                  '&:hover': { bgcolor: '#6D5103' },
                }}
              >
                <Typography sx={{ color: '#FFFFFF', fontWeight: 600, fontFamily: 'Inter' }}>Create Account</Typography>
              </Button>
              <Typography sx={{ marginTop: 2, color: '#262626' }}>
                Already have an account?{' '}
                <Button onClick={() => navigate('/signin')} sx={{ textTransform: 'none' }}>
                  <Typography
                    component={'span'}
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '19px',
                      letterSpacing: '0em',
                      textAlign: 'center',
                      color: '#6D5103',
                    }}
                  >
                    Login
                  </Typography>
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: { xs: 'none', md: 'block' } }}>
              <CarouselSign />
            </Grid>
          </Grid>
        </Box>
      </form>
      <ModalOtp />
      <ModalOtpSuccess onClick={() => navigate('/signin')} />
    </div>
  )
}

export default SignUpPage
