import InfoIcon from '@/assets/info-icon.svg?react'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'

interface Props {
  title: string
  Icon?: React.ComponentType
}

const CustomTooltip = ({ title, Icon = InfoIcon }: Props) => {
  return (
    <Tooltip title={title}>
      <IconButton sx={{ paddingY: 0 }}>
        <Icon />
      </IconButton>
    </Tooltip>
  )
}

export default CustomTooltip
