/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CssBaseline, Box, Grid, Button, Typography, IconButton } from '@mui/material'
import { useFormik } from 'formik'
// import BackIcon from '@/assets/back-icon.svg?react'
import BackHomeIcon from '@/assets/back.svg?react'
import LogoInsight from '@/assets/LogoIndsightAuth.svg?react'
import { InputPassword, Input } from '@/components/Input/Input'
import { CarouselSign } from '@/components/Carousel'
import { useUserStore } from '@/store/user'
import { signInSchema } from '@/schemas'
import { signIn } from '@/network/api/api'
import { setToken } from '@/utils/storage'

const SignInPage = () => {
  const navigate = useNavigate()
  const { email, password, changeEmail, changePassword } = useUserStore()

  const formik = useFormik({
    initialValues: {
      email: email,
      password: password,
    },
    validationSchema: signInSchema,
    onSubmit: async (values, { setFieldError }) => {
      const body = {
        username: values.email,
        password: values.password,
      }
      try {
        const response = await signIn(body)
        setToken(response.payload.token)
        navigate('/')
      } catch (error: any) {
        setFieldError('email', error.response.statusText)
        setFieldError('password', error.response.statusText)
      }
    },
    enableReinitialize: true,
  })

  return (
    <div>
      <CssBaseline />
      <form onSubmit={formik.handleSubmit}>
        <Box height={'100vh'}>
          <Grid container sx={{ height: '100vh', backgroundColor: '#FBFBFB', padding: '20px' }}>
            <Grid
              rowSpacing={1}
              item
              xs={12}
              md={6}
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton onClick={() => navigate('/')} sx={{ position: 'absolute', zIndex: 40, top: 10, left: 10 }}>
                <BackHomeIcon />
              </IconButton>
              <IconButton
                onClick={() => navigate(-1)}
                sx={{ position: 'absolute', zIndex: 40, top: 10, right: 10, display: { md: 'none' } }}
              >
                <LogoInsight />
              </IconButton>
              <Box
                // width={400}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: { xs: '100%', md: '400px' },
                }}
              >
                <Box
                  sx={{
                    marginBottom: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="h3"
                    fontWeight={700}
                    sx={{ marginBottom: '20px', fontSize: '28px', fontFamily: 'Inter' }}
                  >
                    Login
                  </Typography>
                  <Typography
                    variant="body1"
                    fontWeight={400}
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '19px',
                      letterSpacing: '0em',
                      textAlign: 'center',
                    }}
                  >
                    Please Login to your account
                  </Typography>
                </Box>
                <Input
                  label="Email"
                  placeholder="Example : example@example.com"
                  value={email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeEmail(e.target.value)}
                />
                <InputPassword
                  label="Password"
                  placeholder="Enter Password"
                  value={password}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => changePassword(e.target.value)}
                  name={''}
                />

                <Box sx={{ textAlign: 'right', width: '100%', marginBottom: 1 }}>
                  <Button disableRipple disableFocusRipple disableElevation onClick={() => navigate('/forgot')}>
                    <Typography
                      variant="button"
                      sx={{ textTransform: 'none', color: '#6D5103', fontWeight: 500, fontFamily: 'Inter' }}
                    >
                      Forgot Password ?
                    </Typography>
                  </Button>
                </Box>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: '#6D5103',
                    width: { xs: '100%', md: '400px' },
                    height: '57px',
                    borderRadius: '14px',
                    '&:hover': { bgcolor: '#6D5103' },
                  }}
                >
                  <Typography sx={{ color: '#FFFFFF', fontWeight: 600, fontFamily: 'Inter' }}>Login</Typography>
                </Button>
                <Typography sx={{ marginTop: 2, color: '#262626', textAlign: 'center' }}>
                  Don’t have an account?{' '}
                  <Button onClick={() => navigate('/signup')} sx={{ textTransform: 'none' }}>
                    <Typography
                      component={'span'}
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '19px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: '#6D5103',
                      }}
                    >
                      {' '}
                      Create your account
                    </Typography>
                  </Button>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ display: { xs: 'none', md: 'block' } }}>
              <CarouselSign />
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  )
}

export default SignInPage
