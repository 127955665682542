import React, { useState } from 'react'
import { FormControl, TextField, InputAdornment, IconButton, useTheme, useMediaQuery } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import SearchIcon from '@/assets/search-icon.svg?react'

export const Input = ({
  label,
  placeholder,
  value,
  error,
  helperText,
  name,
  disabled,
  onChange,
}: {
  label: string
  placeholder: string
  value?: string
  name?: string
  error?: boolean
  disabled?: boolean
  helperText?: string | boolean | undefined
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <>
      <FormControl
        sx={{
          width: '100%',
          marginBottom: { xs: 3, sm: 5 },
        }}
      >
        <TextField
          type={'text'}
          placeholder={placeholder}
          defaultValue={value || ''}
          label={label}
          autoFocus={false}
          error={error}
          disabled={disabled}
          name={name || ''}
          onChange={onChange}
          helperText={helperText}
          size={matches ? 'medium' : 'small'}
          sx={{
            height: '40px',
            bgColor: 'red',
            '& .MuiInputLabel-asterisk': {
              color: 'red',
            },
            '& .MuiInputBase-root': {
              borderRadius: '14px',
              width: 1,
            },
            '& .MuiFocused': {
              border: '1px solid',
              borderColor: 'red',
            },
          }}
        />
      </FormControl>
    </>
  )
}
export const InputPassword = ({
  label,
  placeholder,
  value,
  error,
  onChange,
  name,
  helperText,
}: {
  label: string
  placeholder: string
  value: string
  name: string
  error?: boolean
  helperText?: string | boolean | undefined
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <>
      <FormControl
        sx={{
          width: '100%',
          marginBottom: { xs: 3, sm: 5 },
        }}
        variant="standard"
      >
        <TextField
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          defaultValue={value || ''}
          autoFocus={false}
          label={label}
          error={error}
          name={name || ''}
          onChange={onChange}
          helperText={helperText}
          size={matches ? 'medium' : 'small'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            height: '40px',
            bgColor: 'red',
            '& .MuiInputLabel-asterisk': {
              color: 'red',
            },
            '& .MuiInputBase-root': {
              borderRadius: '14px',
              width: 1,
            },
            '& .MuiFocused': {
              border: '1px solid',
              borderColor: 'red',
            },
          }}
        />
      </FormControl>
    </>
  )
}

export const InputSearch = ({
  value,
  onChange,
}: {
  label: string
  placeholder: string
  value: string
  error?: boolean
  helperText?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <>
      <FormControl
        sx={{
          width: '200px',
          // margin: 5,
          borderRadius: '10px',
          backgroundColor: 'white',
        }}
      >
        <TextField
          type={'text'}
          placeholder={'Search'}
          defaultValue={value || ''}
          // label={}
          autoFocus={false}
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {/* <IconButton aria-label="toggle password visibility" edge="end"> */}
                <SearchIcon />
                {/* </IconButton> */}
              </InputAdornment>
            ),
          }}
          sx={{
            bgColor: 'red',
            '& .MuiInputLabel-asterisk': {
              color: 'red',
            },
            '& .MuiInputBase-root': {
              // borderRadius: '10px',
              width: 1,
            },
            '& .MuiFocused': {
              border: 'none',
            },
            '& .MuiInputBase-input': {
              padding: '6.5px 6.5px',
              maxHeight: '29px',
            },
          }}
        />
      </FormControl>
    </>
  )
}
