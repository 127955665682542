/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, IconButton, Paper, Skeleton, Tooltip, Typography } from '@mui/material'
import InfoIcon from '@/assets/info-icon.svg?react'
import TrendDownIcon from '@/assets/trend-down-red-icon.svg?react'
import TrendUpIcon from '@/assets/trend-up-green-icon.svg?react'
import StatsIcon from '@/assets/stats-icon.svg'
import { getTotalArticle } from '@/network/api/analytic'
import { formatCurrencyShortForm, roundToDecimals } from '@/utils/storage'
interface DataTop {
  isLock: string
  data?: Array<Data> | any
}
interface Data {
  growth: number
  pastValue: number
  recentValue: number
}
export const TotalArticle = ({ id }: { id: string }) => {
  const { startDate, endDate, selectedPlatform, selectedEntity } = useComparisonStore()
  const [isLoading, setLoading] = useState(false)
  const [dataPostmade, setPostmade] = useState<DataTop>({} as DataTop)

  useEffect(() => {
    fetchPostMade()
  }, [startDate, endDate, selectedPlatform, id])

  const fetchPostMade = async () => {
    const params = {
      startDate,
      endDate,
      entityId: id || selectedEntity.entityId,
      platforms: selectedPlatform,
    }
    try {
      setLoading(true)
      const response = await getTotalArticle(params)
      if (response.statusCode === 200) {
        setPostmade(response.payload)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  if (isLoading) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={100}>
        <Skeleton variant="rounded" width={400} height={100} />
      </Box>
    )
  }
  return (
    <Paper sx={{ width: '100%', p: 2, minHeight: 100, bgcolor: 'rgba(223, 202, 114, 0.2)', borderRadius: 4 }}>
      <Box display={'flex'} alignItems={'center'}>
        <img src={StatsIcon} />
        <Typography fontWeight={600} variant="h6" sx={{ color: '#382A02', fontSize: { xs: 12, sm: 20 } }}>
          Total Article
        </Typography>
        <Tooltip
          placement="top-end"
          title={'Amount of posts that the social profiles published in specific selected timeframe'}
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Box position={'relative'}>
        <Box
          position={'absolute'}
          sx={{
            flex: 1,
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
            filter: dataPostmade.isLock ? 'blur(8px)' : 'none',
            opacity: dataPostmade.isLock ? 0.5 : 1,
            zIndex: dataPostmade.isLock ? 1 : 0,
          }}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'flex-end'}
        >
          <Typography
            fontSize={{ xs: 20, sm: 28 }}
            sx={{ paddingLeft: { xs: 1, sm: 0 }, width: { xs: '100%', sm: 'auto' }, textAlign: 'left' }}
            fontWeight={600}
          >
            {formatCurrencyShortForm(roundToDecimals(dataPostmade.data?.recentValue, 3))}
          </Typography>
          <Tooltip placement="bottom-end" title={formatCurrencyShortForm(dataPostmade?.data?.pastValue)}>
            <Paper sx={{ display: 'flex', alignItems: 'center', px: 1, gap: 1, borderRadius: 2 }}>
              {dataPostmade.data?.growth > 0 ? <TrendUpIcon /> : <TrendDownIcon />}
              <Typography sx={{ color: dataPostmade.data?.growth > 0 ? 'green' : 'red', fontSize: { xs: 12, sm: 16 } }}>
                {formatCurrencyShortForm(roundToDecimals(dataPostmade.data?.growth, 3))}%
              </Typography>
            </Paper>
          </Tooltip>
        </Box>
      </Box>
    </Paper>
  )
}
