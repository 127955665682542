/* eslint-disable @typescript-eslint/no-explicit-any */
import { Body } from '@/layout'
import {
  Typography,
  Box,
  FormControl,
  MenuItem,
  Container,
  Grid,
  InputAdornment,
  CssBaseline,
  TextField,
  ListItem,
  Checkbox,
  IconButton,
  ListSubheader,
  List,
  Paper,
  Button,
  useTheme,
  useMediaQuery,
  Skeleton,
  FormControlLabel,
  Divider,
} from '@mui/material'
import moment from 'moment'
import _ from 'lodash'
import { useQuery as useReactQuery } from 'react-query'
import CalendarIcon from '@/assets/calendar-icon.svg?react'
import GrowthIcon from '@/assets/growth-icon.svg?react'
import CategoryIcon from '@/assets/category-icon.svg?react'
import BackIcon1 from '@/assets/back-low-px.svg?react'
import SearchIcon from '@mui/icons-material/Search'
import BackIcon from '@/assets/back-icon.svg?react'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { getMonthDetails } from '@/utils/helper'
import { useGeneralMetricType, useGeneralSubCategories, useSubCategoryEntity } from '@/hooks/Entity/useEntity'
import { getSearchEntity } from '@/network/api/api'
import { KeyboardArrowRight } from '@mui/icons-material'
import CustomTooltip from '@/components/Tooltip'
import CategoryItem from '@/components/CategoryItem'

const initialParamsTopUp = { page: 1, size: 10, search: '' }

const talkGrowthTooltip = 'The growth in the number of discussions or conversations occurring'
const articleGrowthTooltip = 'The growth in the number of articles published or posted'

const CategoryPage = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  const navigate = useNavigate()
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const id = query.get('categoryId') as string
  const name = query.get('name') as string
  const [subCategoryIds, setSubCategoryIds] = useState<string>('')
  const [searchQuery, setSearchQuery] = useState('')
  const { metric, setMetric } = useEntityStore()
  const [params, setParams] = useState(initialParamsTopUp)
  const [isOpen, setIsOpen] = useState(false)
  const [selectOpen, setSelectOpen] = useState(false)
  const [selectedMonth, setselectedMonth] = useState<string>('')

  const {
    tempSelectedSubCategory,
    setTempSelectedSubCategory,
    selectedSubCategory,
    setSelectedSubCategory,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  } = useComparisonStore()
  const { data: payloadMetricType, isSuccess, isLoading: isLoadingMetric } = useGeneralMetricType()
  const { data: payloadEntitySubcategory, isLoading: isLoadingEntity } = useSubCategoryEntity(
    subCategoryIds,
    startDate,
    endDate,
    metric,
  )
  const {
    data: payloadSubCategories,
    isSuccess: isSuccessCategories,
    isLoading: isLoadingCategories,
  } = useGeneralSubCategories(id)
  const dataMetricType = payloadMetricType?.payload
  const dataSubCategories = payloadSubCategories?.payload
  const dataSubCategoriesEntity = payloadEntitySubcategory?.payload
  const { setSelectedEntity } = useComparisonStore()

  // set initial timeframe
  useEffect(() => {
    const momentStartDate = moment(startDate)
    const momentEndDate = moment(endDate)
    const momentNow = moment()
    const last3Months = getMonthDetails(3)

    const monthDifference = momentNow.diff(momentStartDate, 'months', true)
    const isStartDateMoreThan3Months = monthDifference > 3

    const currentMonthStartDate = last3Months[2].startDate
    const currentMonthEndDate = last3Months[2].endDate
    const currentMonthLabel = last3Months[2].display

    if (isStartDateMoreThan3Months) {
      setStartDate(currentMonthStartDate)
      setEndDate(currentMonthEndDate)
      setselectedMonth(currentMonthLabel)
      return
    }

    const isTimeFrameSameMonth = momentStartDate.isSame(momentEndDate, 'month')
    if (!isTimeFrameSameMonth) {
      setStartDate(currentMonthStartDate)
      setEndDate(currentMonthEndDate)
      setselectedMonth(currentMonthLabel)
      return
    }

    const startDateMonth = momentStartDate.month()
    if (startDateMonth === momentNow.month()) {
      setStartDate(currentMonthStartDate)
      setEndDate(currentMonthEndDate)
      setselectedMonth(currentMonthLabel)
    }

    const selectedTimeframe = last3Months.find((monthOption) => {
      const optionStartDateMonth = moment(monthOption.startDate).month()
      return startDateMonth === optionStartDateMonth
    })

    if (selectedTimeframe) {
      setStartDate(selectedTimeframe.startDate)
      setEndDate(selectedTimeframe.endDate)
      setselectedMonth(selectedTimeframe.display)
    }
  }, [])

  useEffect(() => {
    if (payloadSubCategories && isSuccessCategories && !isLoadingCategories && _.isEmpty(tempSelectedSubCategory)) {
      const initialSubCategories = payloadSubCategories.payload.slice(0, 4).map((cat) => cat.subcategoryId)
      setTempSelectedSubCategory(initialSubCategories)
      setSelectedSubCategory(initialSubCategories)
      setSubCategoryIds(initialSubCategories.join(','))
    }
  }, [payloadSubCategories, isSuccessCategories, isLoadingCategories, tempSelectedSubCategory])

  useEffect(() => {
    if (selectedSubCategory.length > 0) {
      setSubCategoryIds(selectedSubCategory.join(','))
    }
  }, [selectedSubCategory])

  useEffect(() => {
    if (!isLoadingMetric && isSuccess && dataMetricType && dataMetricType.length > 0) {
      setMetric(metric || dataMetricType[0].name)
    }
  }, [isSuccess, dataMetricType, metric])

  useEffect(() => {
    setTempSelectedSubCategory(selectedSubCategory)
  }, [selectedSubCategory])

  const onSetParams = (id, value) => {
    setParams((oldParams) => ({
      ...oldParams,
      [id]: value,
    }))
  }
  const { data: dataSearchCategory } = useReactQuery(['SearchEntity', params], () => getSearchEntity(params), {
    staleTime: 1000 * 60 * 1,
  })

  const handleSearchChangeEntity = (event) => {
    const value = event.target.value
    onSetParams('search', value)
    if (value.length > 1) {
      setIsOpen(true)
    } else {
      // setResults([]);
      setIsOpen(false)
    }
  }

  const handleChangeSubCategory = (event, subcategoryId) => {
    event.stopPropagation()
    const isChecked = event.target.checked
    const currentSelections = [...tempSelectedSubCategory]

    if (isChecked) {
      if (currentSelections.length < 4) {
        setTempSelectedSubCategory([...currentSelections, subcategoryId])
      }
    } else {
      if (currentSelections.length > 1) {
        setTempSelectedSubCategory(currentSelections.filter((id) => id !== subcategoryId))
      }
    }
  }
  const handleChange = (event: SelectChangeEvent<string>) => {
    setMetric(event.target.value as string)
  }
  const handleChangeMonth = (event: SelectChangeEvent<any>) => {
    setStartDate(event?.target?.value?.startDate as string)
    setEndDate(event?.target?.value?.endDate as string)
    setselectedMonth(event?.target?.value?.display as any)
  }

  const last3Months = getMonthDetails(3)

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value)
  }

  const filteredCategories = dataSubCategories?.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase()),
  )
  const handleNavigateSelection = (item) => {
    const params = {
      entityId: item.entityId || item.id,
      entityName: item.name,
      startDate: startDate,
      endDate: endDate,
      metric: metric,
    }
    navigate(`/entity?${new URLSearchParams(params).toString()}`)
    setSelectedEntity(item)
  }

  const handleDoneClick = () => {
    setSelectedSubCategory(tempSelectedSubCategory)
    setSelectOpen(false)
  }

  const handleClickBack = () => {
    setTempSelectedSubCategory([])
    setSelectedSubCategory([])
    navigate('/')
  }

  // if (isLoadingEntity) {
  //   return <Typography>Loading ...</Typography>
  // }
  return (
    <Body sticky={false}>
      <CssBaseline />
      <Box bgcolor="#262626">
        <Container maxWidth="xl" sx={{ py: 2 }}>
          <Box position={'relative'}>
            <Box sx={{ position: 'absolute', top: 10, left: 0, width: '100%', height: 30, zIndex: 1 }}>
              <IconButton onClick={handleClickBack}>
                {matches ? <BackIcon /> : <BackIcon1 style={{ marginTop: '-6px', marginLeft: '-10px' }} />}
              </IconButton>
            </Box>
            <Box>
              <Typography
                pt={{ xs: 4, sm: 0 }}
                color="rgba(187, 149, 85, 1)"
                fontWeight={600}
                textAlign={'center'}
                fontSize={{ xs: 16, md: 20 }}
              >
                Category in {name}
              </Typography>
              <Typography color="#ffffff" fontWeight={400} fontSize={{ xs: 12, md: 16 }} textAlign={'center'}>
                Displaying entities across all sub-categories within one category
              </Typography>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 2,
                  p: 1,
                  width: '100%',
                }}
              >
                <TextField
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 2,
                      width: { xs: '100%', md: '400px' },
                      height: { xs: 35, sm: 40 },
                      backgroundColor: 'white',
                      fontSize: { xs: 11, sm: 14 },
                    },
                  }}
                  variant="outlined"
                  // onBlur={handleBlur}
                  placeholder="Search Entity e.g. “BNI” or “BCA”"
                  onChange={handleSearchChangeEntity}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {isOpen && (
                  <Paper
                    sx={{
                      position: 'absolute',
                      width: '400px',
                      mt: 0.5,
                      maxHeight: 300,
                      overflow: 'auto',
                      top: 50,
                      zIndex: 100,
                    }}
                  >
                    <List>
                      {dataSearchCategory?.payload?.data.length > 0 ? (
                        dataSearchCategory.payload.data.map((result, index) => (
                          <ListItem
                            key={index}
                            button
                            sx={{ display: 'flex', alignItems: 'center', p: 1, justifyContent: 'space-between' }}
                            onClick={() => handleNavigateSelection(result)}
                          >
                            <Box display={'flex'} gap={2}>
                              <img src={result.displayPicture} alt={result.name} height={30} width={30} />
                              <Typography>{result.name}</Typography>
                            </Box>
                            <KeyboardArrowRight sx={{ color: '#BB9555' }} />
                          </ListItem>
                        ))
                      ) : (
                        <ListItem sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                          <Typography>Entity not found.</Typography>
                        </ListItem>
                      )}
                    </List>
                  </Paper>
                )}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container maxWidth="lg">
        <Grid container spacing={{ xs: 0.5, sm: 2 }} p={{ xs: 0.5, sm: 2 }} xs={12} justifyContent={'center'}>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth size="small">
              <Select
                open={selectOpen}
                onClose={() => setSelectOpen(false)}
                onOpen={() => setSelectOpen(true)}
                displayEmpty
                multiple
                sx={{ backgroundColor: 'white', borderRadius: 2 }}
                value={tempSelectedSubCategory}
                startAdornment={
                  <InputAdornment position="start">
                    <CategoryIcon />
                  </InputAdornment>
                }
                onChange={handleChangeSubCategory}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.1 }}>
                    <Typography
                      color="black"
                      fontWeight={400}
                      pt={{ xs: 0.5, sm: 0 }}
                      fontSize={{ xs: 11, sm: 14 }}
                      sx={{ fontFamily: 'Inter', alignItems: 'center' }}
                    >
                      {matches ? ` Sub category (${selected.length})` : `Sub Cat (${selected.length})`}
                    </Typography>
                  </Box>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 500,
                      borderRadius: 8,
                      overflow: 'auto',
                    },
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    sx={{ py: 1 }}
                    autoFocus
                    placeholder="Search..."
                    fullWidth
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleSearchChange}
                    onKeyDown={(e) => {
                      if (e.key !== 'Escape') {
                        e.stopPropagation()
                      }
                    }}
                  />
                </ListSubheader>
                {filteredCategories?.map((item) => (
                  <MenuItem
                    key={item.subcategoryId}
                    value={item.subcategoryId}
                    sx={{ height: 40, py: 2, px: 0, mx: 1, borderTop: '1px solid #E5E5E5' }}
                    disabled={
                      tempSelectedSubCategory.length >= 4 && !tempSelectedSubCategory.includes(item.subcategoryId)
                    }
                    onClick={(event) => handleChangeSubCategory(event, item.subcategoryId)}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tempSelectedSubCategory.includes(item.subcategoryId)}
                          onClick={(event) => event.stopPropagation()}
                          onChange={(event) => handleChangeSubCategory(event, item.subcategoryId)}
                        />
                      }
                      label={item.name}
                    />

                    {/* </FormControlLabel> */}
                  </MenuItem>
                ))}
                <Box
                  sx={{
                    position: 'sticky',
                    bottom: 0,
                    width: '100%',
                    bgcolor: 'background.paper',
                    borderTop: '1px solid #E5E5E5',
                  }}
                >
                  <Button fullWidth onClick={handleDoneClick}>
                    Done
                  </Button>
                </Box>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth size="small">
              <Select
                displayEmpty
                sx={{ backgroundColor: 'white', borderRadius: 2 }}
                value={metric}
                startAdornment={
                  <InputAdornment position="start">
                    <GrowthIcon />
                  </InputAdornment>
                }
                onChange={handleChange}
              >
                {dataMetricType?.map((item: any) => (
                  <MenuItem key={item.name} value={item.name}>
                    <Typography pt={{ xs: 0.5, sm: 0 }} fontSize={{ xs: 11, sm: 14 }}>
                      {item.displayName}
                      <span>
                        <CustomTooltip title={item.name === 'talkGrowth' ? talkGrowthTooltip : articleGrowthTooltip} />
                      </span>
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth size="small">
              <Select
                startAdornment={
                  <InputAdornment position="start">
                    <CalendarIcon />
                  </InputAdornment>
                }
                displayEmpty
                value={selectedMonth}
                renderValue={(selected) => {
                  const selectedItem = last3Months.find((m) => m.display === selected)
                  const selectedItemIndex = last3Months.findIndex((m) => m.display === selected)
                  return (
                    <MenuItem value={selectedItem} sx={{ padding: 0, fontSize: { xs: 11, sm: 14 } }}>
                      {selectedItem?.display}
                      <span style={{ display: matches ? 'block' : 'none' }}>
                        <CustomTooltip
                          title={
                            selectedItemIndex === 2
                              ? 'Data for the last 30 days from 1 day before today'
                              : 'Data for a full month'
                          }
                        />
                      </span>
                    </MenuItem>
                  )
                }}
                sx={{
                  backgroundColor: 'white',
                  color: 'black',
                  borderRadius: 2,
                  fontSize: { xs: 11, sm: 14 },
                  height: 40,
                }}
                onChange={handleChangeMonth}
              >
                {last3Months.map((item: any, index) => (
                  <MenuItem key={item?.display} value={item}>
                    {item?.display}
                    <span>
                      <CustomTooltip
                        title={
                          index === 2 ? 'Data for the last 30 days from 1 day before today' : 'Data for a full month'
                        }
                      />
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg" sx={{ overflow: 'auto' }}>
        <Container sx={{ width: '1200px' }}>
          <Grid container spacing={2} mb={2}>
            {isLoadingCategories && (
              <>
                <Grid item xs={3}>
                  <Skeleton variant="rounded" width={270} height={100} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton variant="rounded" width={270} height={100} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton variant="rounded" width={270} height={100} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton variant="rounded" width={270} height={100} />
                </Grid>
              </>
            )}
            {dataSubCategories &&
              selectedSubCategory.length > 0 &&
              (() => {
                const filteredCategories = dataSubCategories.filter((category) =>
                  selectedSubCategory.includes(category.subcategoryId),
                )
                const gridSize = Math.floor(12 / filteredCategories.length)
                return filteredCategories.map((category) => (
                  <Grid item xs={gridSize} key={category.subcategoryId}>
                    <Box
                      sx={{
                        borderRadius: 5,
                        border: '3px solid rgba(187, 149, 85, 1)',
                        px: 1,
                      }}
                      display={'flex'}
                      height={90}
                      alignItems={'center'}
                      bgcolor={'#000000'}
                      my={2}
                      gap={1}
                    >
                      <Box display={'flex'} alignItems={'center'}>
                        <img
                          src={category.displayPicture}
                          style={{
                            width: '50px',
                            height: '50px',
                            objectFit: 'contain',
                          }}
                        />
                        <Box
                          px={1}
                          sx={{
                            height: 60,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRight: '3px solid rgba(187, 149, 85, 1)',
                          }}
                        />
                      </Box>
                      <Typography
                        color={'white'}
                        textAlign={'left'}
                        fontWeight={700}
                        // fontSize={20}
                        fontSize={category.name.length > 15 && category.name.split(' ').length === 1 ? 18 : 20}
                      >
                        {category.name}
                      </Typography>
                    </Box>
                    <Box sx={{ px: 1, bgcolor: '#FFFFFF', borderRadius: 2, boxShadow: 1 }}>
                      {(isLoadingCategories || isLoadingEntity) && (
                        <>
                          <Grid item xs={3}>
                            <Skeleton variant="rounded" width={270} height={1000} />
                          </Grid>
                          <Grid item xs={3}>
                            <Skeleton variant="rounded" width={270} height={1000} />
                          </Grid>
                          <Grid item xs={3}>
                            <Skeleton variant="rounded" width={270} height={1000} />
                          </Grid>
                          <Grid item xs={3}>
                            <Skeleton variant="rounded" width={270} height={1000} />
                          </Grid>
                        </>
                      )}
                      {dataSubCategoriesEntity
                        ?.filter((entityGroup) => entityGroup.parentId === category.subcategoryId)
                        .flatMap((entityGroup) => entityGroup.entities.slice(0, 10))
                        .map((item: any, index) => {
                          const handleClick = () => {
                            const params = {
                              entityName: item.name,
                              entityId: item.entityId || item.id,
                              startDate: startDate,
                              endDate: endDate,
                              platform: 'all',
                            }
                            setSelectedEntity(item)
                            navigate(`/entity?${new URLSearchParams(params).toString()}`)
                          }
                          return (
                            <>
                              <CategoryItem
                                // border={index === dataSubCategoriesEntity.length - 1 && true}
                                key={item.entityId}
                                title={item.name}
                                item={item}
                                icon={item.displayPicture}
                                index={index}
                                handleClick={handleClick}
                                // startDate={startDate}
                                // endDate={endDate}
                                // metric={metric}
                              />
                              {index < 10 - 1 && <Divider component="hr" />}
                            </>
                          )
                        })}
                    </Box>
                  </Grid>
                ))
              })()}
          </Grid>
        </Container>
      </Container>
    </Body>
  )
}

export default CategoryPage
