/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { CssBaseline, Box, Grid, Button, Typography, IconButton } from '@mui/material'

import { Input } from '@/components/Input/Input'
import { CarouselSign } from '@/components/Carousel'
import { forgotSchema } from '@/schemas'
import BackIcon from '@/assets/back-icon.svg?react'
import { forgotPassword } from '@/network/api/api'
import { setIdF, setTokenF } from '@/utils/storage'
const DOMAIN_URL = import.meta.env.VITE_DOMAIN_URL
const ForgotPasswordPage = () => {
  const navigate = useNavigate()
  const { email, changeEmail } = useUserStore()
  // const [modalSuccess, setModalSuccess] = useState(false)
  const formik = useFormik({
    initialValues: {
      email: email,
      url: `${DOMAIN_URL}/reset-password`,
    },
    validationSchema: forgotSchema, // Our Yup schema
    onSubmit: async (values, { setFieldError }) => {
      try {
        const response = await forgotPassword(values)
        if (response.statusCode === 201) {
          setIdF(response.payload.id)
          setTokenF(response.payload.token)
        } else {
          setFieldError('email', response.message)
        }
      } catch (error: any) {
        console.log('error--->', error)
      }
    },
    enableReinitialize: true,
  })
  return (
    <div>
      <CssBaseline />
      <form onSubmit={formik.handleSubmit}>
        <Box height={'100vh'}>
          <Grid container sx={{ height: '100vh', backgroundColor: '#FBFBFB', padding: '20px' }}>
            <Grid
              rowSpacing={1}
              item
              xs={12}
              md={6}
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton onClick={() => navigate(-1)} sx={{ position: 'absolute', zIndex: 40, top: 10, left: 10 }}>
                <BackIcon />
              </IconButton>
              <Box
                // width={400}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: { xs: '100%', md: '400px' },
                }}
              >
                <Typography
                  sx={{ marginBottom: '20px' }}
                  variant="h3"
                  fontWeight={700}
                  fontFamily={'Inter'}
                  fontSize={'28px'}
                >
                  Forgot Password
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={400}
                  fontFamily={'Inter'}
                  fontSize={{ xs: '12px', md: '16px' }}
                  sx={{ marginBottom: '20px' }}
                >
                  Enter the email address of your NoLimit account. We'll send you the link to reset your password.
                </Typography>

                <Input
                  label="Email"
                  placeholder="Example : example@example.com"
                  value={email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeEmail(e.target.value)}
                />

                <Button
                  type="submit"
                  sx={{
                    backgroundColor: '#6D5103',
                    marginTop: '30px',
                    width: { xs: '100%', md: '400px' },
                    height: '57px',
                    fontWeight: 600,
                    borderRadius: '14px',
                    '&:hover': { bgcolor: '#6D5103' },
                  }}
                >
                  <Typography sx={{ color: '#FFFFFF' }}>Reset Password</Typography>
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ display: { xs: 'none', md: 'block' } }}>
              <CarouselSign />
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  )
}

export default ForgotPasswordPage
