import { RouterProvider } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { routers } from './pages'
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles'
function App() {
  const queryClient = new QueryClient()
  const font = "'Inter',sans-serif"
  let theme = createTheme({
    typography: { fontFamily: font },
    palette: {
      primary: {
        main: '#382A02',
        light: '#F0E9DF',
        dark: '#382A02',
        // secondary: '#DFCA782',
      },
      secondary: {
        main: '#DFCA782',
        light: '#F0E9DF',
        dark: '#DFCA782',
      },
    },
  })

  theme = responsiveFontSizes(theme)

  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={routers} />
        </QueryClientProvider>
      </ThemeProvider>
    </div>
  )
}

export default App
