import { Box, Modal, Typography } from '@mui/material'
import { MuiOtpInput } from 'mui-one-time-password-input'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useQuery } from 'react-query'
import { verifyOtpWhatsappQuery } from '@/network/api/user'
import LoadingButton from '@mui/lab/LoadingButton'

export const ModalOtpWhatsapp = ({ modalOtp, setModalOtp, phoneNumber, resendOtpWhatsapp }) => {
  const [disableResend, setDisableResend] = useState(false)
  const [resendTimer, setResendTimer] = useState(60) // 1 minute timer
  const [otp, setOtp] = useState('')
  const { setModalOtpSuccess } = useUserStore()

  const handleChange = (newValue: string) => {
    setOtp(newValue)
  }

  const verifyOtpWhatsapp = useQuery(['verifyOtpWhatsapp'], () => verifyOtpWhatsappQuery({ otp: otp }), {
    retry: false,
    enabled: false,
  })
  const handleValidate = () => {
    verifyOtpWhatsapp.refetch().then((res) => {
      if (res.status === 'success') {
        setModalOtp(false)
        setModalOtpSuccess(true)
      }
    })
  }

  const handleResendOtpWhatsapp = () => {
    resendOtpWhatsapp.refetch().then((res) => {
      if (res.status === 'success') {
        setDisableResend(true)
        const intervalId = setInterval(() => {
          setResendTimer((prev) => prev - 1)
        }, 1000)

        setTimeout(() => {
          clearInterval(intervalId)
          setDisableResend(false)
          setResendTimer(60)
        }, 60_000)
      }
    })
  }

  return (
    <Modal open={modalOtp} aria-labelledby="keep-mounted-modal-title" aria-describedby="keep-mounted-modal-description">
      <Box
        sx={{
          borderRadius: '10px',
          padding: 2,
          my: 5,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          top: '50%',
          left: '50%',
          width: { xs: 360, sm: 550 },
          height: 400,
        }}
      >
        <HighlightOffIcon
          onClick={() => setModalOtp(false)}
          color="action"
          fontSize="large"
          sx={{
            position: 'absolute',
            top: { xs: '6px', sm: '16px' },
            right: { xs: '6px', sm: '16px' },
          }}
        />
        <Typography
          // variant="div"
          gutterBottom={true}
          sx={{
            fontFamily: 'Inter',
            fontSize: '24px',
            fontWeight: '600',
            lineHeight: '29px',
            letterSpacing: '0em',
            textAlign: 'center',
          }}
        >
          Please enter OTP to verify your number
        </Typography>
        <Typography
          gutterBottom={true}
          sx={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '29px',
            letterSpacing: '0em',
            textAlign: 'center',
            mb: 2,
          }}
        >
          Your OTP has been sent to Whatsapp {phoneNumber}
        </Typography>
        {verifyOtpWhatsapp.isError && (
          <Typography
            gutterBottom={true}
            sx={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '29px',
              letterSpacing: '0em',
              textAlign: 'center',
              color: 'red',
            }}
          >
            Your OTP is wrong, please check again!
          </Typography>
        )}
        <MuiOtpInput
          // onComplete={handleComplete}
          TextFieldsProps={{ placeholder: '-' }}
          sx={{
            marginBottom: '20px',
            '& .MuiTextField-root': { borderRadius: '6px', width: 56 },
            gap: 3,
            borderRadius: '10px',
          }}
          value={otp}
          onChange={handleChange}
        />

        <LoadingButton
          // disableElevation
          // disableRipple
          // disableTouchRipple
          loading={verifyOtpWhatsapp.isLoading}
          onClick={() => handleValidate()}
          size="large"
          variant="contained"
          // fullWidth
          sx={{
            padding: '20px 60px',
            borderRadius: '10px',
            marginBottom: '20px',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '16px',
            letterSpacing: '0em',
            textAlign: 'center',
          }}
        >
          Validate
        </LoadingButton>
        <LoadingButton
          onClick={handleResendOtpWhatsapp}
          loading={resendOtpWhatsapp.isRefetching}
          disabled={disableResend}
        >
          {disableResend ? `Resend OTP in ${resendTimer} seconds` : 'Resend OTP'}{' '}
        </LoadingButton>
        {disableResend && (
          <Typography textAlign={'center'} fontSize={'14px'}>
            We have sent you a new OTP, please check your Whatsapp!
          </Typography>
        )}
      </Box>
    </Modal>
  )
}
