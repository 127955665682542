import { create } from 'zustand'

interface UserStore {
  email: string
  password: string
  confirmPassword: string
  fullname: string
  toggleModalError: boolean
  otp: string
  idForgot: string
  tokenForgot: string
  setOtp: (otp: string) => void
  modalOtp: boolean
  setModalOtp: (modalOtp: boolean) => void
  modalOtpSuccess: boolean
  setModalOtpSuccess: (modalOtpSuccess: boolean) => void
  errorOtp: boolean
  activeStep: number
  setActiveStep: (activeStep: number) => void
  setErrorOtp: (errorOtp: boolean) => void
  changeEmail: (email: string) => void
  changePassword: (password: string) => void
  changeFullname: (fullname: string) => void
  changeConfirmPassword: (confirmPassword: string) => void
  setToggleModalError: (toggleModalError: boolean) => void
  changeIdForgot: (idForgot: string) => void
  changeTokenForgot: (tokenForgot: string) => void
}
export const useUserStore = create<UserStore>((set) => ({
  email: '',
  password: '',
  fullname: '',
  confirmPassword: '',
  toggleModalError: false,
  otp: '',
  modalOtp: false,
  modalOtpSuccess: false,
  errorOtp: false,
  idForgot: '',
  tokenForgot: '',
  changeIdForgot: (idForgot: string) => set(() => ({ idForgot: idForgot })),
  changeTokenForgot: (tokenForgot: string) => set(() => ({ tokenForgot: tokenForgot })),
  changeEmail: (email: string) => set(() => ({ email: email })),
  changePassword: (password: string) => set(() => ({ password: password })),
  changeFullname: (fullname: string) => set(() => ({ fullname: fullname })),
  changeConfirmPassword: (confirmPassword: string) => set(() => ({ confirmPassword: confirmPassword })),
  setToggleModalError: (toggleModalError: boolean) => set(() => ({ toggleModalError: toggleModalError })),
  setOtp: (otp: string) => set(() => ({ otp: otp })),
  setModalOtp: (modalOtp: boolean) => set(() => ({ modalOtp: modalOtp })),
  setModalOtpSuccess: (modalOtpSuccess: boolean) => set(() => ({ modalOtpSuccess: modalOtpSuccess })),
  setErrorOtp: (errorOtp: boolean) => set(() => ({ errorOtp: errorOtp })),
  activeStep: 0,
  setActiveStep: (activeStep: number) => set(() => ({ activeStep: activeStep })),
}))
