import {
  Box,
  Card,
  CircularProgress,
  Container,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  TablePagination,
  InputAdornment,
  TextField,
  TableSortLabel,
  Stack,
  Button,
} from '@mui/material'
import NoDataIcon from '@/assets/no-data-icon.svg?react'
import { useMutation, useQuery as useReactQuery } from 'react-query'
import SearchIcon from '@mui/icons-material/Search'
import moment from 'moment'
import _ from 'lodash'
import { getListSubsAlert, postChangeSubsAlertFrequent, postChangeSubsAlertStatus } from '@/network/api/api'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { useUserProfile } from '@/hooks/User/useUser'
import Switch from '@mui/material/Switch'
import ModalChangeAlertStatus from '../Modal/ModalChangeAlertStatus'
import { ChangeEvent } from 'react'
import ModalChangeAlertFrequent from '../Modal/ModalChangeAlertFrequent'
import { calculateTotalPrice } from '@/utils/helper'
import { useProductPrice } from '@/hooks/Entity/useHelper'

const initialParams = { page: 1, size: 10, search: '', sortBy: 'status', sortDirection: 'DESC' }
export const ListSubsAlert = () => {
  const { addDataUnlock, setSelectedEntity, setStartDate, setEndDate } = useComparisonStore()
  const { data: userData } = useUserProfile()
  const { changeProfileSelected } = useProfileStore()
  const { data: productPrice } = useProductPrice()
  const navigate = useNavigate()
  const [params, setParams] = useState(initialParams)
  const onSetParams = (id, value) => {
    setParams((oldParams) => ({
      ...oldParams,
      [id]: value,
    }))
  }

  const {
    data: dataMyAlert,
    isLoading: listLoading,
    refetch: refetchList,
  } = useReactQuery(['ListSubsAlert', params], () => getListSubsAlert(params))
  const handleChangePage = (_event: unknown, newPage: number) => {
    if (newPage < 1) return
    onSetParams('page', newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSetParams('size', parseInt(event.target.value, 10))
    onSetParams('page', 1)
  }
  const TablePageActions = () => {
    return (
      <Box sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}>
        <Pagination
          count={Math.ceil(dataMyAlert?.payload?.count / params.size)}
          page={params.page}
          onChange={handleChangePage}
        />
      </Box>
    )
  }
  const handleChangeSearch = (event) => {
    const searchValue = event.target.value
    onSetParams('search', searchValue)
  }
  const handleRequestSort = (property) => {
    const isAsc = params.sortBy === property && params.sortDirection === 'ASC'
    onSetParams('sortBy', property)
    onSetParams('sortDirection', isAsc ? 'DESC' : 'ASC')
  }

  const handleNavigate = (item) => {
    const params = {
      entityName: item.entityName,
      entityId: item.entityId,
      startDate: item.productStartDate,
      endDate: item.productEndDate,
      platform: 'all',
      // metric: 'talkgrowth',
    }
    setSelectedEntity(item)
    setStartDate(item.productStartDate)
    setEndDate(item.productEndDate)
    navigate(`/entity?${new URLSearchParams(params).toString()}`)
  }

  const [modalStatus, setModalStatus] = useState(false)
  const [modalFrequent, setModalFrequent] = useState(false)
  const [isActivate, setIsActivate] = useState(false)
  const [rowSelected, setRowSelected] = useState<any>()
  const [dataEdit, setDataEdit] = useState({ entityId: null, threshold: null, alertTime: null })

  const handleChangeStatus = (e: ChangeEvent<HTMLInputElement>, row) => {
    if (e.target.checked) {
      setIsActivate(true)
    } else {
      setIsActivate(false)
    }
    setRowSelected(row)
    setModalStatus(true)
  }
  const handleChangeFrequent = (row) => {
    setDataEdit({
      entityId: row.entityId,
      threshold: row.threshold,
      alertTime: row.alertTime,
    })
    setModalFrequent(true)
  }

  const { mutate: mutateStatus, isLoading: changeStatusLoading } = useMutation(postChangeSubsAlertStatus, {
    onSuccess: () => {
      refetchList()
      setModalStatus(false)
    },
    onError: () => {
      alert('failed to change subs alert status')
    },
  })

  const { mutate: mutateFrequent, isLoading: changeFrequentLoading } = useMutation(postChangeSubsAlertFrequent, {
    onSuccess: () => {
      refetchList()
      setModalFrequent(false)
    },
    onError: () => {
      alert('failed to change subs alert status')
    },
  })

  const handleConfirmFrequent = () => {
    mutateFrequent({
      entityId: dataEdit.entityId,
      threshold: dataEdit.threshold,
      alertTime: dataEdit.alertTime,
    })
  }

  const handleConfirmStatus = () => {
    if (rowSelected) {
      mutateStatus({
        entityId: rowSelected.entityId,
        subStatus: isActivate,
      })
    }
  }

  const handleExtend = (row) => {
    const newItem = {
      entityId: row?.entityId,
      startDate: moment().set({ date: 10 }).format('YYYY-MM-DD'),
      endDate: moment().set({ date: 25 }).format('YYYY-MM-DD'),
      displayPicture: row?.displayPicture || '',
      name: row?.entityName,
      price: calculateTotalPrice(row?.productStartDate, row.productEndDate, productPrice.payload.price),
    }

    addDataUnlock(newItem)
    navigate(`/unlock?entityId=${newItem.entityId}&startDate=${newItem.startDate}&endDate=${newItem.endDate}`)
  }

  return (
    <Container maxWidth="lg">
      <ModalChangeAlertStatus
        open={modalStatus}
        handleConfirm={handleConfirmStatus}
        handleClose={() => setModalStatus(false)}
        isActivate={isActivate}
        isConfirmLoading={changeStatusLoading}
      />
      <ModalChangeAlertFrequent
        open={modalFrequent}
        dataEdit={dataEdit}
        setDataEdit={setDataEdit}
        handleClose={() => setModalFrequent(false)}
        isConfirmLoading={changeFrequentLoading}
        handleConfirm={handleConfirmFrequent}
      />
      <Box sx={{ p: { xs: 0, sm: 4 } }}>
        <Typography sx={{ fontFamily: 'Inter', fontSize: { xs: 16, sm: 28 }, pt: { xs: 2, sm: 0 }, fontWeight: 600 }}>
          Subscription Alert
        </Typography>
        <Typography sx={{ fontFamily: 'Inter', fontSize: 14, fontWeight: 400, py: 2 }}>
          Subscription Alert lets users turn on or off notifications for purchased entities and view the last alert
          sent. Notifications will be sent via WhatsApp. If your WhatsApp number hasn't been set up, please configure it
          in your profile first.
        </Typography>
        <Divider />
        <Card sx={{ width: { xs: '280px', sm: '300px' }, padding: 2, marginTop: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography sx={{ fontFamily: 'Inter', fontSize: 14, fontWeight: 600 }}>WhatsApp Number</Typography>
            <WhatsAppIcon />
          </Stack>
          <Typography>{userData?.payload.phoneNumber || '-'}</Typography>
          <Stack direction="row" justifyContent="end" marginTop="12px">
            <Button
              onClick={() => changeProfileSelected(1)}
              size="small"
              variant="contained"
              sx={{ borderRadius: '12px' }}
            >
              Change Number
            </Button>
          </Stack>
        </Card>
        <Card sx={{ padding: 2, mt: 2 }}>
          <Box display={'flex'} alignItems={'center'}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: 2,
                p: 1,
              }}
            >
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    width: '100%',
                    // height: 40,
                    height: { xs: 35, md: 40 },
                    backgroundColor: 'white',
                    fontSize: { xs: 12, md: 16 },
                  },
                }}
                onChange={handleChangeSearch}
                variant="outlined"
                value={params.search}
                placeholder="Search "
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ height: { xs: 15, md: 25 }, width: { xs: 15, md: 25 } }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <TableContainer
            component={Paper}
            sx={{ width: { xs: '100%' } }}
            style={{ maxHeight: '400px', overflow: 'auto', borderRadius: 8 }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
              <TableHead sx={{ backgroundColor: 'rgba(223, 202, 114, 1)' }}>
                <TableRow
                  sx={{
                    backgroundColor: 'rgba(223, 202, 114, 1)',
                    // '& .MuiTableCell-head': {
                    //   backgroundColor: 'rgba(223, 202, 114, 1)',
                    // },
                    '& .MuiTableCell-head': {
                      // padding: '6px 16px',
                      paddingY: { xs: 1, sm: 2 },
                      backgroundColor: 'rgba(223, 202, 114, 1)',
                      fontSize: { xs: 10, sm: 16 },
                      lineHeight: 1.5,
                      // backgroundColor: 'blue',
                    },
                  }}
                >
                  <TableCell>No</TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={params.sortBy === 'entityName'}
                      direction={
                        params.sortBy === 'entityName'
                          ? params.sortDirection.toLowerCase() === 'desc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      }
                      onClick={() => handleRequestSort('entityName')}
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={params.sortBy === 'categoryName'}
                      direction={
                        params.sortBy === 'categoryName'
                          ? params.sortDirection.toLowerCase() === 'desc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      }
                      onClick={() => handleRequestSort('categoryName')}
                    >
                      Category
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={params.sortBy === 'payment'}
                      direction={
                        params.sortBy === 'payment'
                          ? params.sortDirection.toLowerCase() === 'desc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      }
                      onClick={() => handleRequestSort('payment')}
                    >
                      Payment Period
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={params.sortBy === 'expireDate'}
                      direction={
                        params.sortBy === 'expireDate'
                          ? params.sortDirection.toLowerCase() === 'desc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      }
                      onClick={() => handleRequestSort('expireDate')}
                    >
                      Time Left
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Frequent</TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={params.sortBy === 'lastAlert'}
                      direction={
                        params.sortBy === 'lastAlert'
                          ? params.sortDirection.toLowerCase() === 'desc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      }
                      onClick={() => handleRequestSort('lastAlert')}
                    >
                      Last Alert
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={params.sortBy === 'status'}
                      direction={
                        params.sortBy === 'status'
                          ? params.sortDirection.toLowerCase() === 'desc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      }
                      onClick={() => handleRequestSort('status')}
                    >
                      Alert
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listLoading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : !_.isEmpty(dataMyAlert?.payload?.rows) ? (
                  dataMyAlert.payload.rows.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        // '&:last-child td, &:last-child th': { border: 0 }
                        '&:last-child td, &:last-child th': { border: 0 },
                        '& .MuiTableCell-body': {
                          fontSize: { xs: 10, sm: 16 },
                          paddingY: { xs: 1, sm: 2 },
                        },
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell
                        align="left"
                        sx={{ ':hover': { cursor: 'pointer' }, textDecoration: 'underline' }}
                        onClick={() => handleNavigate(row)}
                      >
                        {row.entityName}
                      </TableCell>
                      <TableCell align="center">{row.categoryName}</TableCell>
                      <TableCell align="center">
                        {moment(row.createdAt).format('DD MMM YYYY')} - {moment(row.expireDate).format('DD MMM YYYY')}
                      </TableCell>
                      <TableCell align="center">
                        {row.status === 'EXPIRED'
                          ? 'Expired'
                          : moment(row.expireDate).diff(Date.now(), 'days') > 1
                            ? `${moment(row.expireDate).diff(Date.now(), 'days')} days`
                            : `${moment(row.expireDate).diff(Date.now(), 'days')} day`}
                      </TableCell>
                      <TableCell align="center">
                        {row.threshold
                          ? `${row.threshold}, ${row.alertTime === 25 ? 'Dynamic Time' : moment(moment.utc().set({ hour: row.alertTime, minute: 0 }).toISOString()).format('hh:mm A')}`
                          : '-'}
                      </TableCell>
                      <TableCell align="center">
                        {row.lastAlert ? moment(row.lastAlert).format('DD MMM YYYY, hh:mm A') : '-'}
                      </TableCell>
                      <TableCell align="center">
                        <Switch
                          disabled={row.status === 'EXPIRED'}
                          checked={row.subStatus}
                          onChange={(e) => handleChangeStatus(e, row)}
                        />
                      </TableCell>
                      <TableCell align="left">
                        {row.status === 'ACTIVE' ? (
                          <Button
                            disabled={row.subStatus === 0 || row.status === 'EXPIRED'}
                            variant="contained"
                            size="small"
                            onClick={() => handleChangeFrequent(row)}
                          >
                            EDIT
                          </Button>
                        ) : (
                          <Button onClick={() => handleExtend(row)} variant="text" size="small">
                            EXTEND
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                        <NoDataIcon />
                        <Typography>No data available</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={dataMyAlert?.payload?.count}
            rowsPerPage={params.size}
            page={params.page - 1}
            showFirstButton
            showLastButton
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePageActions}
          />
        </Card>
      </Box>
    </Container>
  )
}
