import moment from 'moment'
import { ApexOptions } from 'apexcharts'
import { formatCurrency } from './storage'
// export const getMonthDetails = (monthsToGet: number) => {
//   const results = []
//   const now = new Date()
//   const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()) // Today's date without time

//   const monthNames = [
//     'January',
//     'February',
//     'March',
//     'April',
//     'May',
//     'June',
//     'July',
//     'August',
//     'September',
//     'October',
//     'November',
//     'December',
//   ]

//   for (let i = 0; i < monthsToGet; i++) {
//     const targetMonth = new Date(now.getFullYear(), now.getMonth() - i, 1) // Start of target month
//     let endOfMonth

//     if (i === 0) {
//       // If it's the current month, set end date to today
//       endOfMonth = today
//     } else {
//       // For past months, set end date to the last day of the month
//       endOfMonth = new Date(targetMonth.getFullYear(), targetMonth.getMonth() + 1, 0)
//     }

//     const display = monthNames[targetMonth.getMonth()] + ' ' + targetMonth.getFullYear()
//     const startValue = `${targetMonth.getFullYear()}-${('0' + (targetMonth.getMonth() + 1)).slice(-2)}-01`
//     const endValue = `${endOfMonth.getFullYear()}-${('0' + (endOfMonth.getMonth() + 1)).slice(-2)}-${('0' + endOfMonth.getDate()).slice(-2)}`

//     results.push({
//       display,
//       startDate: startValue,
//       endDate: endValue,
//     })
//   }
//   return results.reverse()
// }
export const getMonthDetails = (monthsToGet: number) => {
  const results = []
  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()) // Today's date without time

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  // for (let i = 0; i < monthsToGet; i++) {
  //   const targetMonth = new Date(now.getFullYear(), now.getMonth() - i, 1) // Start of target month
  //   const endOfMonth = i === 0 ? today : new Date(targetMonth.getFullYear(), targetMonth.getMonth() + 1, 0)

  //   const display = `${monthNames[targetMonth.getMonth()]} ${targetMonth.getFullYear()}`
  //   const startValue = formatDate(targetMonth)
  //   const endValue = formatDate(endOfMonth)

  //   results.push({ display, startDate: startValue, endDate: endValue })
  // }
  for (let i = 0; i < monthsToGet; i++) {
    const targetMonth = new Date(now.getFullYear(), now.getMonth() - i, 1) // Start of target month
    let endOfMonth

    if (i === 0) {
      // Case 3: Existing month (today's date is reduced by 1 day and calculated 30 days back)
      const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
      const startOfMonth = new Date(yesterday.getFullYear(), yesterday.getMonth(), today.getDate() - 30)
      endOfMonth = yesterday
      const display = `${monthNames[yesterday.getMonth()]} ${yesterday.getFullYear()}`
      const startValue = formatDate(startOfMonth)
      const endValue = formatDate(endOfMonth)
      results.push({ display, startDate: startValue, endDate: endValue })
    } else {
      // Case 1 and 2: Full months
      endOfMonth = new Date(targetMonth.getFullYear(), targetMonth.getMonth() + 1, 0)
      const display = `${monthNames[targetMonth.getMonth()]} ${targetMonth.getFullYear()}`
      const startValue = formatDate(targetMonth)
      const endValue = formatDate(endOfMonth)
      results.push({ display, startDate: startValue, endDate: endValue })
    }
  }

  return results.reverse()
}
function formatDate(date) {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  return `${year}-${month}-${day}`
}
export const calculateTotalPrice = (startDate, endDate, monthlyPrice) => {
  const start = moment(startDate).startOf('month')
  const end = moment(endDate).endOf('month')

  const monthsBetween = Math.ceil(end.diff(start, 'months', true)) // 'true' for a fractional result
  const totalPrice = monthlyPrice * monthsBetween
  return totalPrice
}

export const separateNumberFormat = (num: number) => {
  if (Number.isInteger(num)) {
    return parseFloat(num.toFixed(2))?.toLocaleString('id-Id')
  } else {
    return num?.toLocaleString('id-Id')
  }
}

export const buildDataLineGroup = function (dataDayToDayPerformance) {
  return (dataDayToDayPerformance?.data || []).map((chronological) => ({
    name: chronological?.contentType,
    data: (chronological?.values || []).map(({ timestamp, value }) => {
      return [timestamp, value]
    }),
  }))
}
export const buildDataNewsPortal = function (dataDayToDayPerformance) {
  return (dataDayToDayPerformance || []).map((chronological) => ({
    name: chronological?.contentType,
    data: (chronological?.values || []).map(({ timestamp, value }) => {
      return [timestamp, formatCurrency(value)]
    }),
  }))
}
export const buildDataLineGroups = function (dataDayToDayPerformance) {
  return {
    name: dataDayToDayPerformance?.payload?.data?.contentType,
    data: (dataDayToDayPerformance?.payload?.data?.values || []).map(({ timestamp, value }) => {
      return [timestamp, value]
    }),
  }
}
export const buildDataLineGroupsDay = function (dayToDayTalk) {
  return {
    name: dayToDayTalk?.data?.contentType || '',
    data: (dayToDayTalk?.data?.values || []).map(({ timestamp, value }) => {
      return [timestamp, value]
    }),
  }
}
interface Payload {
  isLock: boolean
  data: {
    [key: string]: number
  }
}
interface PayloadSentiment {
  isLock: boolean
  data: {
    value: string
    name: string
  }[]
}

export const transformDataToDonutChart = (payload: Payload) => {
  const dataEntries = Object.entries(payload.data || '')
  // Extracting series data (values)
  const series: ApexNonAxisChartSeries = dataEntries && dataEntries.map(([, value]) => value)

  const labels = dataEntries && dataEntries.map(([key]) => key)

  // Constructing options for the donut chart
  const options: ApexOptions = {
    labels: labels,
    // responsive: [
    //   {
    //     breakpoint: 480,
    //     options: {
    //       legend: {
    //         position: 'top',
    //       },
    //     },
    //   },
    // ],
    legend: {
      position: 'bottom',
    },
    colors: ['#FFC107', '#FF7043', '#4CAF50'],
  }

  return { series, options }
}
export const transformDataToDonutChartSentiment = (payload: PayloadSentiment) => {
  // const dataEntries = Object.entries(payload.data || '')
  // Extracting series data (values)
  const series = (payload.data || []).map((item) => item.value)

  // Extracting labels (keys)
  const labels = (payload.data || []).map((key) => key.name)
  // Constructing options for the donut chart
  const options: ApexOptions = {
    chart: {
      type: 'pie',
    },
    labels,
    // responsive: [
    //   {
    //     breakpoint: 480,
    //     options: {
    //       chart: {
    //         maxWidth: 400,
    //       },
    //       legend: {
    //         position: 'bottom',
    //       },
    //     },
    //   },
    // ],
    legend: {
      position: 'bottom',
    },
    colors: ['rgba(0, 173, 38, 1)', 'rgba(0, 159, 227, 1)', 'rgba(227, 82, 0, 1)'],
  }

  return { series, options }
}
interface SocialMediaData {
  socialMedia: string
  talk: number
  talker: number
}

export const transformDataToChartFormat = (socialMediaData: SocialMediaData[]) => {
  const series = [
    {
      name: 'Talk',
      data: socialMediaData?.map((item) => item?.talk),
    },
    {
      name: 'Talker',
      data: socialMediaData?.map((item) => item?.talker),
    },
  ]

  const categories = socialMediaData?.map((item) => item?.socialMedia)
  // Merging with predefined options
  const optionsConver: ApexOptions = {
    series,
    chart: {
      type: 'bar',
      height: 430,
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: '10px',
        colors: ['#fff'],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },

    xaxis: {
      categories, // Dynamically setting categories
    },
  }

  return { series, optionsConver }
}

export const replaceUnderscoresWithSpaces = (input: string): string => {
  return input.replace(/_/g, ' ')
}

export const isDateIsInRange = (startDate: string, endDate: string, checkStart: string, checkEnd: string) => {
  // Convert dates to timestamps for comparison
  const startDateTimestamp = new Date(startDate)
  const endDateTimestamp = new Date(endDate)
  const checkStartTimestamp = new Date(checkStart)
  const checkEndTimestamp = new Date(checkEnd)

  return (
    startDateTimestamp >= checkStartTimestamp &&
    startDateTimestamp <= checkEndTimestamp &&
    // Check if endDate is within the check range (inclusive) or equal to checkEnd
    (endDateTimestamp <= checkEndTimestamp || endDateTimestamp === checkEndTimestamp)
  )
}

export const convertStringToUrlPath = (text: string) => {
  return text.toLowerCase().replace(' ', '-')
}
