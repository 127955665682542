/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { AxiosError } from 'axios'
import { InputPassword, Input } from '@/components/Input/Input'
import { signUpSchema, signInSchema } from '@/schemas'
import { signUp, signIn } from '@/network/api/api'
import { setToken } from '@/utils/storage'
import { ModalOtp } from '@/components/Modal/ModalOtp'
import { ModalOtpSuccess } from '@/components/Modal/ModalOtpSuccess'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

const RegisterUser = ({ setKey }) => {
  const [apiErrMsg, setApiErrMsg] = useState('')
  const { activeStep, setActiveStep, setModalOtpSuccess, setModalOtp } = useUserStore()
  const [isReferralExpand, setIsReferralExpand] = useState(false)

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      password: '',
      confirmPassword: '',
      referralCode: '',
    },
    validationSchema: signUpSchema, // Our Yup schema
    onSubmit: async (values) => {
      try {
        const response = await signUp(values)
        if (response.statusCode === 201) {
          const token = response?.payload.token
          setToken(token)
          setModalOtp(true)
        }
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          setApiErrMsg(error.response?.data.message)
        }
      }
    },
  })

  useEffect(() => {
    if (formik.values.password !== formik.values.confirmPassword) {
      formik.setFieldError('confirmPassword', 'password not same check again')
    }
  }, [formik.values.password, formik.values.confirmPassword])

  return (
    <Paper sx={{ padding: '30px', width: { xs: '100%', sm: '500px' }, margin: 'auto' }}>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            marginBottom: 2,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h3"
            fontWeight={700}
            sx={{ marginBottom: '20px', fontSize: { xs: '20px', sm: '28px' }, fontFamily: 'Inter' }}
          >
            Create Account
          </Typography>
          <Typography
            variant="body1"
            fontWeight={400}
            sx={{
              fontFamily: 'Inter',
              fontSize: { xs: '14px', sm: '16px' },
              fontWeight: '400',
              lineHeight: '19px',
              letterSpacing: '0em',
              textAlign: 'center',
            }}
          >
            Enter the data below correctly!
          </Typography>
        </Box>
        <Box
          //   width={400}
          sx={{
            marginBottom: 2,
            // backgroundColor: 'red',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Input
            label="Full Name*"
            placeholder="Full name"
            value={formik.values.fullname}
            error={formik.touched.fullname && Boolean(formik.errors.fullname)}
            helperText={formik.touched.fullname && formik.errors.fullname}
            onChange={(e) => formik.setFieldValue('fullname', e.target.value)}
          />
          <Input
            label="Email*"
            placeholder="Example : example@example.com"
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            onChange={(e) => formik.setFieldValue('email', e.target.value)}
          />
          <InputPassword
            label="Password*"
            placeholder="Enter Password"
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            onChange={(e) => formik.setFieldValue('password', e.target.value)}
            name="password"
          />
          <InputPassword
            label="Confirm Password*"
            placeholder="Confirm Password"
            value={formik.values.confirmPassword}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            onChange={(e) => formik.setFieldValue('confirmPassword', e.target.value)}
            name="confirmPassword"
          />
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            sx={{ cursor: 'pointer' }}
            onClick={() => setIsReferralExpand((prev) => !prev)}
          >
            <Typography mb={1} textAlign="left">
              Referral Code
            </Typography>
            {isReferralExpand ? <ExpandLess /> : <ExpandMore />}
          </Stack>
          {isReferralExpand ? (
            <Input
              label="Enter Code"
              placeholder="example: NLMT"
              value={formik.values.referralCode}
              onChange={(e) => formik.setFieldValue('referralCode', e.target.value)}
            />
          ) : null}
          {apiErrMsg ? (
            <Typography textAlign="center" fontSize={'14px'} color={'#D32F2F'}>
              {apiErrMsg}
            </Typography>
          ) : null}
        </Box>
        <Button
          type="submit"
          sx={{
            backgroundColor: '#6D5103',
            marginTop: 1,
            width: { xs: '100%', sm: '400px' },
            height: { xs: '40px', sm: '57px' },
            fontWeight: 600,
            borderRadius: '14px',
            '&:hover': { bgcolor: '#6D5103' },
          }}
        >
          <Typography
            sx={{ color: '#FFFFFF', fontWeight: 600, fontFamily: 'Inter', fontSize: { xs: '12px', sm: '16px' } }}
          >
            Create Account
          </Typography>
        </Button>
        <Typography sx={{ marginTop: 2, color: '#262626', fontSize: { xs: '12px', sm: '16px' } }}>
          Already have an account?{' '}
          <Button onClick={() => setKey(2)} sx={{ textTransform: 'none' }}>
            <Typography
              component={'span'}
              sx={{
                fontFamily: 'Inter',
                fontSize: { xs: '12px', sm: '16px' },
                fontWeight: 700,
                lineHeight: '19px',
                letterSpacing: '0em',
                textAlign: 'center',
                color: '#6D5103',
              }}
            >
              Login
            </Typography>
          </Button>
        </Typography>
      </form>
      <ModalOtp />
      <ModalOtpSuccess
        onClick={() => {
          Promise.all([setModalOtpSuccess(false), setActiveStep(activeStep + 1)])
        }}
      />
    </Paper>
  )
}

const SignInUser = ({ setKey }) => {
  const navigate = useNavigate()
  const { email, password } = useUserStore()

  const formik = useFormik({
    initialValues: {
      email: email,
      password: password,
    },
    validationSchema: signInSchema, // Our Yup schema
    onSubmit: async (values, { setFieldError }) => {
      try {
        const response = await signIn({ username: values.email, password: values.password })
        setToken(response?.payload.token)
        navigate('/')
      } catch (error: any) {
        setFieldError('email', error.response.statusText)
        setFieldError('password', error.response.statusText)
      }
    },
    enableReinitialize: true,
  })
  return (
    <Paper sx={{ padding: '30px', width: '500px', margin: 'auto' }}>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            width={400}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                marginBottom: 2,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h3"
                fontWeight={700}
                sx={{ marginBottom: '20px', fontSize: '28px', fontFamily: 'Inter' }}
              >
                Login
              </Typography>
              <Typography
                variant="body1"
                fontWeight={400}
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '19px',
                  letterSpacing: '0em',
                  textAlign: 'center',
                }}
              >
                Please Login to your account
              </Typography>
            </Box>
            <Input
              label="Email"
              placeholder="Example : example@example.com"
              value={email}
              name="email"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
            />
            <InputPassword
              label="Password"
              placeholder="Enter Password"
              value={password}
              name="password"
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              onChange={formik.handleChange}
              // onChange={(e: React.ChangeEvent<HTMLInputElement>) => changePassword(e.target.value)}
            />

            <Box sx={{ textAlign: 'right', width: '100%', marginBottom: 1 }}>
              <Button disableRipple disableFocusRipple disableElevation onClick={() => navigate('/forgot')}>
                <Typography
                  variant="button"
                  sx={{ textTransform: 'none', color: '#6D5103', fontWeight: 500, fontFamily: 'Inter' }}
                >
                  Forgot Password ?
                </Typography>
              </Button>
            </Box>
            <Button
              type="submit"
              sx={{
                backgroundColor: '#6D5103',
                width: '400px',
                height: '57px',
                borderRadius: '14px',
                '&:hover': { bgcolor: '#6D5103' },
              }}
            >
              <Typography sx={{ color: '#FFFFFF', fontWeight: 600, fontFamily: 'Inter' }}>Login</Typography>
            </Button>
            <Typography sx={{ marginTop: 2, color: '#262626' }}>
              Don’t have an account?
              <Button onClick={() => setKey(1)} sx={{ textTransform: 'none' }}>
                <Typography
                  component={'span'}
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '19px',
                    letterSpacing: '0em',
                    textAlign: 'center',
                    color: '#6D5103',
                  }}
                >
                  Create your account
                </Typography>
              </Button>
            </Typography>
          </Box>
        </Box>
      </form>
    </Paper>
  )
}

const FormSecondComponent = () => {
  const [key, setKey] = useState(1)

  const SelectComponent = () => {
    switch (key) {
      case 1:
        return <RegisterUser setKey={setKey} />
      case 2:
        return <SignInUser setKey={setKey} />

      default:
        break
    }
  }

  return <SelectComponent />
}

export default FormSecondComponent
