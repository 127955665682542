import {
  Container,
  Typography,
  Grid,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Box,
  Divider,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@mui/material'
import CoinIcon from '@/assets/coin-icon.svg?react'
import { useQuery as useReactQuery } from 'react-query'
import _ from 'lodash'
import { formatCurrency } from '@/utils/storage'
import { getCoinPackage } from '@/network/api/api'

interface User {
  id: number
  coin: number
  amount: number
  amountWithDiscount: number
}

export const CoinPackage = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const { activeStep, setActiveStep } = useUserStore()
  const { topup, setTopupAmountAndCoin } = useProfileStore()
  // const { data: dataPricePerCoin, isLoading } = useReactQuery(['PricePerCoin'], () => getPricePerCoin(), {
  //   staleTime: 1000 * 60 * 1,
  // })
  const { data: dataCoinPackage, isLoading } = useReactQuery(['CoinPackage'], () => getCoinPackage(), {
    staleTime: 1000 * 60 * 1,
  })
  if (isLoading) {
    return <CircularProgress />
  }
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid container item spacing={{ xs: 1, md: 5 }}>
          {_.sortBy(dataCoinPackage?.payload, 'coin').map((item: User) => (
            <Grid item xs={4} md={3}>
              <Card
                sx={{
                  borderRadius: 3,
                  border: item.coin === topup?.coin ? '2px solid #6D5103' : '',
                }}
              >
                <CardActionArea
                  id={`button-${item.coin}-coin`}
                  onClick={() => {
                    setTopupAmountAndCoin(item.amountWithDiscount, item?.coin)
                  }}
                >
                  <CardContent
                    sx={{
                      boxShadow: 3,
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      px: 1,
                      py: 3,
                    }}
                  >
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" gap={1}>
                      <CoinIcon />
                      <Typography sx={{ fontWeight: 500, fontSize: { xs: 14, md: 22 }, fontFamily: 'Inter' }}>
                        {item.coin} Coin
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontWeight: 200,
                        fontColor: 'gray',
                        fontSize: { xs: 10, md: 12 },
                        textDecorationLine: 'line-through',
                        textDecorationColor: 'gray',
                        fontFamily: 'Inter',
                      }}
                    >
                      IDR {formatCurrency(item.amount)}
                    </Typography>
                    <Typography sx={{ fontWeight: 500, fontSize: { xs: 10, md: 16 }, fontFamily: 'Inter' }}>
                      IDR {formatCurrency(item.amountWithDiscount)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end" sx={{ mt: 5 }}>
        <Card sx={{ minWidth: 250, borderRadius: 3, px: 2 }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="cente">
              <Typography sx={{ fontWeight: 500, fontSize: { xs: 12, md: 22 }, fontFamily: 'Inter', mr: 5 }}>
                Total
              </Typography>
              <Typography sx={{ fontWeight: 500, fontSize: { xs: 12, md: 22 }, fontFamily: 'Inter' }}>
                IDR {formatCurrency(topup.amount)}
              </Typography>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Button
              id="checkout-button"
              variant="contained"
              size={matches ? 'large' : 'small'}
              disabled={topup.amount && topup.coin ? false : true}
              sx={{ width: '100%' }}
              onClick={() => setActiveStep(activeStep + 1)}
            >
              Checkout
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Container>
  )
}
