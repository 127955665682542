import {
  FetchSocialMediaStreamParams,
  FetchSocialMediaStreamResponse,
  getSocialMediaListStream,
} from '@/network/api/stream'
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'

export const useGetSocialMediaStream = (
  initialParams: FetchSocialMediaStreamParams,
  options?: UseInfiniteQueryOptions<FetchSocialMediaStreamResponse, unknown, FetchSocialMediaStreamResponse>,
) => {
  const fetchSocialMediaListStream = async ({ pageParam = 1 }) => {
    const params = {
      ...initialParams,
      page: pageParam,
    }
    const response = await getSocialMediaListStream(params)
    return response
  }

  const { data, isError, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } = useInfiniteQuery(
    ['socialMediaListStream', initialParams],
    fetchSocialMediaListStream,
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 1,
      retry: 3,
      getNextPageParam: (lastPage) => {
        const isNoMoreData = lastPage.payload.data.page >= lastPage.payload.data.totalPages
        return isNoMoreData ? undefined : lastPage.payload.data.page + 1
      },
      ...options,
    },
  )

  return {
    data,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  }
}
