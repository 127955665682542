/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useQuery as useReactQuery } from 'react-query'
import TrendDownIcon from '@/assets/trend-down-red-icon.svg?react'
import TrendUpIcon from '@/assets/trend-up-green-icon.svg?react'
import InfoIcon from '@/assets/info-icon.svg?react'
import EngageIcon from '@/assets/engage-icon.svg?react'
import BarChartIcon from '@/assets/bar-chart-icon.svg?react'
import LockBigIcon from '@/assets/lock-big-icon.svg'
import {
  getAudienceTotalEngageUser,
  getAudienceResponseRate,
  getAudienceTopTalker,
  getAudienceTopInfluencer,
  getAudienceTopTalkerByEngagement,
  getAudienceDayToDayEngageUser,
  getAudienceEngageUserByGender,
  getAudienceEngageUserByLocation,
  getAudienceEngageUserByInterest,
} from '@/network/api/audience'
import Chart from 'react-apexcharts'
import { buildDataLineGroups, separateNumberFormat } from '@/utils/helper'
import { formatCurrencyShortForm, getRandomColor, roundToDecimals } from '@/utils/storage'
import { ApexOptions } from 'apexcharts'
import moment from 'moment'
import CustomTooltip from '@/components/Tooltip'

const colors = ['rgba(0, 173, 38, 1)', 'rgba(0, 159, 227, 1)', 'rgba(227, 82, 0, 1)']
interface AudienceModuleProps {
  handleUnlockEntity?: () => void
}
export const AudienceModule: React.FC<AudienceModuleProps> = ({
  handleUnlockEntity,
}: {
  handleUnlockEntity: () => void
}) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const id = query.get('entityId') as string
  const { startDate, endDate, selectedPlatform, selectedEntity } = useComparisonStore()

  const params = {
    startDate,
    endDate,
    entityId: id || selectedEntity.entityId,
    platforms: selectedPlatform,
  }
  const { data: dataAudienceTotalEngageUser } = useReactQuery(
    ['TotalEngageUser', params],
    () => getAudienceTotalEngageUser(params),
    {
      staleTime: 1000 * 60 * 1,
    },
  )
  const { data: dataAudienceResponseRate } = useReactQuery(
    ['ResponseRate', params],
    () => getAudienceResponseRate(params),
    {
      staleTime: 1000 * 60 * 1,
    },
  )
  const { data: dataAudienceDayToDayEngageUser } = useReactQuery(
    ['DayToDayEngageUser', params],
    () => getAudienceDayToDayEngageUser(params),
    {
      staleTime: 1000 * 60 * 1,
    },
  )
  const { data: dataAudienceEngageUserByGender } = useReactQuery(
    ['EngageUserByGender', params],
    () => getAudienceEngageUserByGender(params),
    {
      staleTime: 1000 * 60 * 1,
    },
  )
  const { data: dataAudienceEngageUserByLocation } = useReactQuery(
    ['EngageUserByLocation', params],
    () => getAudienceEngageUserByLocation(params),
    {
      staleTime: 1000 * 60 * 1,
    },
  )
  const { data: dataAudienceEngageUserByInterest } = useReactQuery(
    ['EngageUserByInterest', params],
    () => getAudienceEngageUserByInterest(params),
    {
      staleTime: 1000 * 60 * 1,
    },
  )

  const { data: dataAudienceTopTalker, isLoading: isLoadAudienceTopTalker } = useReactQuery(
    ['TopTalker', params],
    () => getAudienceTopTalker(params),
    {
      staleTime: 1000 * 60 * 1,
    },
  )
  const { data: dataAudienceTopInfluencer, isLoading: isLoadAudienceTopInfluencer } = useReactQuery(
    ['TopInfluencer', params],
    () => getAudienceTopInfluencer(params),
    {
      staleTime: 1000 * 60 * 1,
    },
  )
  const { data: dataAudienceTopTalkerByEngagement, isLoading: isLoadAudienceTopTalkerByEngagement } = useReactQuery(
    ['TopTalkerByEngagement', params],
    () => getAudienceTopTalkerByEngagement(params),
    {
      staleTime: 1000 * 60 * 1,
    },
  )

  const seriesDonutGender = (dataAudienceEngageUserByGender?.payload?.data || []).map((dt) => dt.value)
  const labelsDonutGender = (dataAudienceEngageUserByGender?.payload?.data || []).map((dt) => dt.name)
  const seriesDonutLocation = (dataAudienceEngageUserByLocation?.payload?.data || []).map((dt) => dt.value)
  const labelsDonutLocation = (dataAudienceEngageUserByLocation?.payload?.data || []).map((dt) => dt.name)
  const seriesDonutInterest = (dataAudienceEngageUserByInterest?.payload?.data || []).map((dt) => dt.value)
  const labelsDonutInterest = (dataAudienceEngageUserByInterest?.payload?.data || []).map((dt) => dt.name)
  const legendDonut = { show: true }

  function generateDonutOptions(labels, colors, legendDonut) {
    return {
      labels: labels,
      tooltip: {
        enabled: true,
        y: {
          formatter: (value) => (value % 1 <= 0 ? separateNumberFormat(value) : value.toFixed(3)),
        },
      },
      chart: {
        animations: { enabled: false },
        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
            },
          },
        },
        events: {},
      },
      plotOptions: { pie: { customScale: 1 } },
      colors: colors,
      legend: { ...legendDonut, position: 'bottom', height: 26 },
    }
  }
  function generateDonutOptionsInterest(labels, legendDonut) {
    return {
      labels: labels,
      tooltip: {
        enabled: true,
        y: {
          formatter: (value) => (value % 1 <= 0 ? separateNumberFormat(value) : value.toFixed(3)),
        },
      },
      chart: {
        animations: { enabled: false },
        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
            },
          },
        },
        events: {},
      },
      plotOptions: { pie: { customScale: 1 } },
      colors: getRandomColor(labels.length),
      legend: { ...legendDonut, position: 'bottom', height: 26 },
    }
  }

  const dataLineCharts = buildDataLineGroups(dataAudienceDayToDayEngageUser)
  const optionsDonuts = generateDonutOptions(labelsDonutGender, colors, legendDonut)
  const optionsDonutsLocation = generateDonutOptions(labelsDonutLocation, colors, legendDonut)
  const optionsDonutsInterest = generateDonutOptionsInterest(labelsDonutInterest, legendDonut)

  const dataLineChart = (() => {
    const categories = dataLineCharts?.data?.map((item) => item[0])
    const seriesData = dataLineCharts?.data?.map((item) => item[1])

    return {
      options: {
        chart: {
          id: 'basic-bar',
          height: 1000,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        xaxis: {
          type: 'datetime',
          categories: categories,
          labels: {
            datetimeUTC: true,
            formatter: (values) => {
              const convert =
                moment(endDate).diff(startDate, 'day') <= 1
                  ? moment(values).format('HH:mm')
                  : moment(values).format('DD MMM')

              return convert
            },
          },
        },
      } as ApexOptions,
      series: [
        {
          name: dataLineCharts.name,
          data: seriesData,
        },
      ],
    }
  })()
  return (
    <Box sx={{ py: 2 }}>
      <Box display={'flex'} sx={{ gap: 2, flex: 1, justifyContent: 'space-between' }}>
        <Paper sx={{ width: '100%', p: 2, bgcolor: 'rgba(223, 202, 114, 0.2)', borderRadius: 4 }}>
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <EngageIcon />
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <EngageIcon style={{ display: matches ? 'block' : 'none' }} />
            <Typography fontSize={{ xs: 12, sm: 16 }} fontWeight={600} variant="h6" sx={{ color: '#382A02' }}>
              Total Engage User
            </Typography>
            <Tooltip placement="top-end" title={'Amount of unique user commenting in the post published'}>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'flex-end'}
            sx={{
              flex: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              width: '100%',
              filter: dataAudienceTotalEngageUser?.payload?.isLock ? 'blur(8px)' : 'none',
              opacity: dataAudienceTotalEngageUser?.payload?.isLock ? 0.5 : 1,
              zIndex: dataAudienceTotalEngageUser?.payload?.isLock ? 1 : 0,
            }}
          >
            <Tooltip title={dataAudienceTotalEngageUser?.payload?.data?.recentValue.toLocaleString('id-ID')}>
              <Typography
                fontSize={{ xs: 20, sm: 28 }}
                fontWeight={600}
                sx={{ paddingLeft: { xs: 1, sm: 0 }, width: { xs: '100%', sm: 'auto' }, textAlign: 'left' }}
              >
                {formatCurrencyShortForm(roundToDecimals(dataAudienceTotalEngageUser?.payload?.data?.recentValue, 3))}
              </Typography>
            </Tooltip>

            <Tooltip
              placement="bottom-end"
              title={formatCurrencyShortForm(dataAudienceTotalEngageUser?.payload?.data?.pastValue)}
            >
              <Paper sx={{ display: 'flex', alignItems: 'center', px: 1, gap: 1, borderRadius: 2 }}>
                {dataAudienceTopTalker && dataAudienceTotalEngageUser?.payload?.data?.growth > 0 ? (
                  <TrendUpIcon />
                ) : (
                  <TrendDownIcon />
                )}
                <Typography
                  sx={{
                    color:
                      dataAudienceTopTalker && dataAudienceTotalEngageUser?.payload?.data?.growth > 0 ? 'green' : 'red',
                    fontSize: { xs: 12, sm: 16 },
                  }}
                >
                  {dataAudienceTotalEngageUser?.payload?.data?.growth}%
                </Typography>
              </Paper>
            </Tooltip>
          </Box>
        </Paper>
        <Paper sx={{ width: '100%', p: 2, bgcolor: 'rgba(223, 202, 114, 0.2)', borderRadius: 4 }}>
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <BarChartIcon />
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <BarChartIcon style={{ display: matches ? 'block' : 'none' }} />
            <Typography fontSize={{ xs: 12, sm: 16 }} fontWeight={600} variant="h6" sx={{ color: '#382A02' }}>
              Response Rate
            </Typography>
            <Tooltip placement="top-end" title={'Total Comment / Total Reply'}>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'flex-end'}
            sx={{
              flex: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              width: '100%',
              filter: dataAudienceResponseRate?.payload?.isLock ? 'blur(8px)' : 'none',
              opacity: dataAudienceResponseRate?.payload?.isLock ? 0.5 : 1,
              zIndex: dataAudienceResponseRate?.payload?.isLock ? 1 : 0,
            }}
          >
            <Tooltip title={dataAudienceResponseRate?.payload?.data?.recentValue.toLocaleString('id-ID') + '%'}>
              <Typography
                fontSize={{ xs: 20, sm: 28 }}
                fontWeight={600}
                sx={{ paddingLeft: { xs: 1, sm: 0 }, width: { xs: '100%', sm: 'auto' }, textAlign: 'left' }}
              >
                {formatCurrencyShortForm(roundToDecimals(dataAudienceResponseRate?.payload?.data?.recentValue, 3))}%
              </Typography>
            </Tooltip>

            {/* <Paper sx={{ display: 'flex', alignItems: 'center', px: 1, gap: 1, borderRadius: 2 }}>
              {dataAudienceResponseRate?.payload?.data?.value || 0 ? <TrendUpIcon /> : <TrendDownIcon />}
              <Typography sx={{ color: dataAudienceResponseRate?.payload?.data?.value || 0 ? 'green' : 'red' }}>{dataAudienceResponseRate?.payload?.data?.countTrend || 200}</Typography>
            </Paper> */}
            <Tooltip
              placement="bottom-end"
              title={roundToDecimals(dataAudienceResponseRate?.payload?.data?.pastValue, 3)}
            >
              <Paper sx={{ display: 'flex', alignItems: 'center', px: 1, gap: 1, borderRadius: 2 }}>
                {dataAudienceResponseRate?.payload?.data?.growth > 0 ? <TrendUpIcon /> : <TrendDownIcon />}
                <Typography
                  sx={{
                    color: dataAudienceResponseRate?.payload?.data?.growth > 0 ? 'green' : 'red',
                    fontSize: { xs: 12, sm: 16 },
                  }}
                >
                  {formatCurrencyShortForm(roundToDecimals(dataAudienceResponseRate?.payload?.data?.growth, 3))}%
                </Typography>
              </Paper>
            </Tooltip>
          </Box>
        </Paper>
      </Box>
      <Paper sx={{ p: 2, mb: 2, mt: 2, display: 'flex', flexDirection: 'column' }}>
        <Stack direction="row">
          <Typography fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
            Day-to-day Engage User
          </Typography>
          <CustomTooltip title="How is your talker performance?" />
        </Stack>
        <div style={{ height: '100%', width: '100%', overflow: 'scroll' }}>
          <Box
            sx={{
              width: { xs: 500, sm: '100%' },
              height: { xs: 300, sm: '200px' },
            }}
          >
            <Chart
              options={dataLineChart.options}
              series={dataLineChart.series}
              type="line"
              width="100%"
              height="100%"
            />
          </Box>
        </div>
        {/* <div style={{ width: '100%', height: '200px' }}>
          <Chart options={dataLineChart.options} series={dataLineChart.series} type="line" width="100%" height="100%" />
        </div> */}
      </Paper>
      <Grid container xs={12} spacing={1}>
        <Grid item xs={12} md={6} lg={4}>
          <Paper
            sx={{ p: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', position: 'relative' }}
          >
            <Stack direction="row">
              <Typography fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
                Engaged User By Gender
              </Typography>
              <CustomTooltip title="What is you your engaged users by gender?" />
            </Stack>
            <div className="donut" style={{ width: '100%', minHeight: '200px' }}>
              <Chart
                options={optionsDonuts}
                // seriesDonutGender
                // optionsDonuts
                series={seriesDonutGender}
                type="pie"
                width="100%"
              />
            </div>
            {dataAudienceEngageUserByGender?.payload?.isLock && (
              <Box
                position={'absolute'}
                sx={{
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  '@media (max-width: 100px)': {
                    top: '50%',
                    left: '40%',
                    transform: 'translate(-40%, -40%)',
                  },
                }}
              >
                <IconButton onClick={handleUnlockEntity}>
                  <img src={LockBigIcon} height={150} />{' '}
                </IconButton>
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper
            sx={{ p: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', position: 'relative' }}
          >
            <Stack direction="row">
              <Typography fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
                Engaged User By Interest
              </Typography>
              <CustomTooltip title="What is you your engaged users top interests?" />
            </Stack>
            <div className="donut" style={{ width: '100%', minHeight: '200px' }}>
              <Chart options={optionsDonutsInterest} series={seriesDonutInterest} type="pie" width="100%" />
            </div>
            {dataAudienceEngageUserByInterest?.payload?.isLock && (
              <Box
                position={'absolute'}
                sx={{
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  '@media (max-width: 100px)': {
                    top: '50%',
                    left: '40%',
                    transform: 'translate(-40%, -40%)',
                  },
                }}
              >
                <IconButton onClick={handleUnlockEntity}>
                  <img src={LockBigIcon} height={150} />{' '}
                </IconButton>
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper
            sx={{ p: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', position: 'relative' }}
          >
            <Stack direction="row">
              <Typography fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
                Engaged User By Locations
              </Typography>
              <CustomTooltip title="What is you your engaged users top locations?" />
            </Stack>
            <div className="donut" style={{ width: '100%', minHeight: '200px' }}>
              <Chart options={optionsDonutsLocation} series={seriesDonutLocation} type="pie" width="100%" />
            </div>
            {dataAudienceEngageUserByLocation?.payload?.isLock && (
              <Box
                position={'absolute'}
                sx={{
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  '@media (max-width: 100px)': {
                    top: '50%',
                    left: '40%',
                    transform: 'translate(-40%, -40%)',
                  },
                }}
              >
                <IconButton onClick={handleUnlockEntity}>
                  <img src={LockBigIcon} height={150} />{' '}
                </IconButton>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Paper
        sx={{ p: 2, mb: 2, mt: 2, display: 'flex', flexDirection: 'column', minHeight: 300, position: 'relative' }}
      >
        <Typography mb={2} fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
          Top Talker
          <Typography component={'span'}>
            <Tooltip placement="top-end" title={'Who talk about you the most?'}>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        </Typography>
        <TableContainer component={Paper} style={{ maxHeight: '400px', overflow: 'auto', borderRadius: 8 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
            <TableHead sx={{ backgroundColor: 'rgba(223, 202, 114, 1)' }}>
              <TableRow
                sx={{
                  backgroundColor: 'rgba(223, 202, 114, 1)',
                  '& .MuiTableCell-head': {
                    paddingY: { xs: 1, sm: 2 },
                    backgroundColor: 'rgba(223, 202, 114, 1)',
                    fontSize: { xs: 10, sm: 16 },
                    lineHeight: 1.5,
                  },
                }}
              >
                <TableCell>No</TableCell>
                <TableCell align="center">Username</TableCell>
                <TableCell align="center">Social Media</TableCell>
                <TableCell align="center">Talk</TableCell>
                <TableCell align="center">Followers</TableCell>
                <TableCell align="center">Following</TableCell>
                <TableCell align="center">Engagement</TableCell>
                <TableCell align="center">Like</TableCell>
                <TableCell align="center">Share</TableCell>
                <TableCell align="center">Comment</TableCell>
                <TableCell align="center">View</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoadAudienceTopTalker &&
                (dataAudienceTopTalker?.payload?.data || []).map((row, index) => (
                  <TableRow
                    key={row.No}
                    sx={{
                      // '&:last-child td, &:last-child th': { border: 0 }
                      '&:last-child td, &:last-child th': { border: 0 },
                      '& .MuiTableCell-body': {
                        fontSize: { xs: 10, sm: 16 },
                        paddingY: { xs: 1, sm: 2 },
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={dataAudienceTopTalker?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                    >
                      {row.fromName}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={dataAudienceTopTalker?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                    >
                      {row.socialMedia}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={dataAudienceTopTalker?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                    >
                      <Tooltip title={Number(row.talk).toLocaleString('id-ID')} arrow placement="bottom">
                        <Typography>{formatCurrencyShortForm(row.talk)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.talk)} */}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={dataAudienceTopTalker?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                    >
                      <Tooltip title={Number(row.followers).toLocaleString('id-ID')} arrow placement="bottom">
                        <Typography>{formatCurrencyShortForm(row.followers)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.followers)} */}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={dataAudienceTopTalker?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                    >
                      <Tooltip title={Number(row.following).toLocaleString('id-ID')} arrow placement="bottom">
                        <Typography>{formatCurrencyShortForm(row.following)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.following)} */}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={dataAudienceTopTalker?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                    >
                      <Tooltip title={Number(row.engagement).toLocaleString('id-ID')} arrow placement="bottom">
                        <Typography>{formatCurrencyShortForm(row.engagement)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.engagement)} */}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={dataAudienceTopTalker?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                    >
                      <Tooltip title={Number(row.like).toLocaleString('id-ID')} arrow placement="bottom">
                        <Typography>{formatCurrencyShortForm(row.like)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.like)} */}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={dataAudienceTopTalker?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                    >
                      <Tooltip title={Number(row.share).toLocaleString('id-ID')} arrow placement="bottom">
                        <Typography>{formatCurrencyShortForm(row.share)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.share)} */}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={dataAudienceTopTalker?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                    >
                      <Tooltip title={Number(row.comment).toLocaleString('id-ID')} arrow placement="bottom">
                        <Typography>{formatCurrencyShortForm(row.comment)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.comment)} */}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={dataAudienceTopTalker?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                    >
                      <Tooltip title={Number(row.view).toLocaleString('id-ID')} arrow placement="bottom">
                        <Typography>{formatCurrencyShortForm(row.view)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.view)} */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {dataAudienceTopTalker?.payload?.isLock && (
          <Box
            position={'absolute'}
            sx={{
              top: '70%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              '@media (max-width: 100px)': {
                top: '70%',
                left: '40%',
                transform: 'translate(-40%, -40%)',
              },
            }}
          >
            <IconButton onClick={handleUnlockEntity}>
              <img src={LockBigIcon} height={150} />{' '}
            </IconButton>
          </Box>
        )}
      </Paper>
      <Paper
        sx={{ p: 2, mb: 2, mt: 2, display: 'flex', flexDirection: 'column', minHeight: 300, position: 'relative' }}
      >
        <Typography mb={2} fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
          Top Influencer
          <Typography component={'span'}>
            <Tooltip placement="top-end" title={'Who is your popular talker?'}>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        </Typography>
        <TableContainer component={Paper} style={{ maxHeight: '400px', overflow: 'auto', borderRadius: 8 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
            <TableHead sx={{ backgroundColor: 'rgba(223, 202, 114, 1)' }}>
              <TableRow
                sx={{
                  backgroundColor: 'rgba(223, 202, 114, 1)',
                  // '& .MuiTableCell-head': {
                  //   padding: '6px 16px',
                  //   backgroundColor: 'rgba(223, 202, 114, 1)',
                  // }
                  '& .MuiTableCell-head': {
                    // padding: '6px 16px',
                    paddingY: { xs: 1, sm: 2 },
                    backgroundColor: 'rgba(223, 202, 114, 1)',
                    fontSize: { xs: 10, sm: 16 },
                    lineHeight: 1.5,
                    // backgroundColor: 'blue',
                  },
                }}
              >
                <TableCell>No</TableCell>
                <TableCell align="left">Username</TableCell>
                <TableCell align="left">Social Media</TableCell>
                <TableCell align="left">Followers</TableCell>
                <TableCell align="left">Talk</TableCell>
                <TableCell align="left">Following</TableCell>
                <TableCell align="left">Engagement</TableCell>
                <TableCell align="left">Like</TableCell>
                <TableCell align="left">Share</TableCell>
                <TableCell align="left">Comment</TableCell>
                <TableCell align="left">View</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoadAudienceTopInfluencer &&
                (dataAudienceTopInfluencer?.payload?.data || []).map((row, index) => (
                  <TableRow
                    key={index + 1}
                    sx={{
                      // '&:last-child td, &:last-child th': { border: 0 }
                      '&:last-child td, &:last-child th': { border: 0 },
                      '& .MuiTableCell-body': {
                        fontSize: { xs: 10, sm: 16 },
                        paddingY: { xs: 1, sm: 2 },
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopInfluencer?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}
                      }
                      align="left"
                    >
                      {row.fromName}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopInfluencer?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}
                      }
                      align="left"
                    >
                      {row.socialMedia}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopInfluencer?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}
                      }
                      align="left"
                    >
                      <Tooltip title={Number(row.followers).toLocaleString('id-ID')} arrow placement="bottom-start">
                        <Typography>{formatCurrencyShortForm(row.followers)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.followers)} */}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopInfluencer?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}
                      }
                      align="left"
                    >
                      <Tooltip title={Number(row.talk).toLocaleString('id-ID')} arrow placement="bottom-start">
                        <Typography>{formatCurrencyShortForm(row.talk)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.talk)} */}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopInfluencer?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}
                      }
                      align="left"
                    >
                      <Tooltip title={Number(row.following).toLocaleString('id-ID')} arrow placement="bottom-start">
                        <Typography>{formatCurrencyShortForm(row.following)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.following)} */}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopInfluencer?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}
                      }
                      align="left"
                    >
                      <Tooltip title={Number(row.engagement).toLocaleString('id-ID')} arrow placement="bottom-start">
                        <Typography>{formatCurrencyShortForm(row.engagement)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.engagement)} */}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopInfluencer?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}
                      }
                      align="left"
                    >
                      <Tooltip title={Number(row.like).toLocaleString('id-ID')} arrow placement="bottom-start">
                        <Typography>{formatCurrencyShortForm(row.like)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.like)} */}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopInfluencer?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}
                      }
                      align="left"
                    >
                      <Tooltip title={Number(row.share).toLocaleString('id-ID')} arrow placement="bottom-start">
                        <Typography>{formatCurrencyShortForm(row.share)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.share)} */}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopInfluencer?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}
                      }
                      align="left"
                    >
                      <Tooltip title={Number(row.comment).toLocaleString('id-ID')} arrow placement="bottom-start">
                        <Typography>{formatCurrencyShortForm(row.comment)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.comment)} */}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopInfluencer?.payload?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}
                      }
                      align="left"
                    >
                      <Tooltip title={Number(row.view).toLocaleString('id-ID')} arrow placement="bottom-start">
                        <Typography>{formatCurrencyShortForm(row.view)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.view)} */}
                    </TableCell>
                    {/* <TableCell align="left">{formatCurrency(row.comment)}</TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {dataAudienceTopInfluencer?.payload?.isLock && (
          <Box
            position={'absolute'}
            sx={{
              top: '70%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              '@media (max-width: 100px)': {
                top: '70%',
                left: '40%',
                transform: 'translate(-40%, -40%)',
              },
            }}
          >
            <IconButton onClick={handleUnlockEntity}>
              <img src={LockBigIcon} height={150} />{' '}
            </IconButton>
          </Box>
        )}
      </Paper>
      <Paper
        sx={{ p: 2, mb: 2, mt: 2, display: 'flex', flexDirection: 'column', minHeight: 300, position: 'relative' }}
      >
        <Typography mb={2} fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
          Top Talker by Engagement{' '}
          <Typography component={'span'}>
            <Tooltip placement="top-end" title={'Who talk about you the most according to engagement?'}>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        </Typography>
        <TableContainer component={Paper} style={{ maxHeight: '400px', overflow: 'auto', borderRadius: 8 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
            <TableHead sx={{ backgroundColor: 'rgba(223, 202, 114, 1)' }}>
              <TableRow
                sx={{
                  backgroundColor: 'rgba(223, 202, 114, 1)',
                  height: 10,
                  // '& .MuiTableCell-head': {
                  //   padding: '6px 16px',
                  //   backgroundColor: 'rgba(223, 202, 114, 1)',
                  // }
                  '& .MuiTableCell-head': {
                    // padding: '6px 16px',
                    paddingY: { xs: 1, sm: 2 },
                    backgroundColor: 'rgba(223, 202, 114, 1)',
                    fontSize: { xs: 10, sm: 16 },
                    lineHeight: 1.5,
                    // backgroundColor: 'blue',
                  },
                }}
              >
                <TableCell>No</TableCell>
                <TableCell align="left">Username</TableCell>
                <TableCell align="left">Social Media</TableCell>
                <TableCell align="left">Engagement</TableCell>
                <TableCell align="left">Talk</TableCell>
                <TableCell align="left">Followers</TableCell>
                <TableCell align="left">Following</TableCell>
                <TableCell align="left">Like</TableCell>
                <TableCell align="left">Share</TableCell>
                <TableCell align="left">Comment</TableCell>
                <TableCell align="left">View</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoadAudienceTopTalkerByEngagement &&
                (dataAudienceTopTalkerByEngagement?.payload?.data || []).map((row, index) => (
                  <TableRow
                    key={index + 1}
                    sx={{
                      // '&:last-child td, &:last-child th': { border: 0 }
                      '&:last-child td, &:last-child th': { border: 0 },
                      '& .MuiTableCell-body': {
                        fontSize: { xs: 10, sm: 16 },
                        paddingY: { xs: 1, sm: 2 },
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopTalkerByEngagement?.payload?.isLock && index + 1 >= 1
                          ? { filter: 'blur(4px)' }
                          : {}
                      }
                      align="left"
                    >
                      {row.fromName}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopTalkerByEngagement?.payload?.isLock && index + 1 >= 1
                          ? { filter: 'blur(4px)' }
                          : {}
                      }
                      align="left"
                    >
                      {row.socialMedia}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopTalkerByEngagement?.payload?.isLock && index + 1 >= 1
                          ? { filter: 'blur(4px)' }
                          : {}
                      }
                      align="left"
                    >
                      <Tooltip title={Number(row.engagement).toLocaleString('id-ID')} arrow placement="bottom-start">
                        <Typography>{formatCurrencyShortForm(row.engagement)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.engagement)} */}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopTalkerByEngagement?.payload?.isLock && index + 1 >= 1
                          ? { filter: 'blur(4px)' }
                          : {}
                      }
                      align="left"
                    >
                      <Tooltip title={Number(row.talk).toLocaleString('id-ID')} arrow placement="bottom-start">
                        <Typography>{formatCurrencyShortForm(row.talk)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.talk)} */}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopTalkerByEngagement?.payload?.isLock && index + 1 >= 1
                          ? { filter: 'blur(4px)' }
                          : {}
                      }
                      align="left"
                    >
                      <Tooltip title={Number(row.followers).toLocaleString('id-ID')} arrow placement="bottom-start">
                        <Typography>{formatCurrencyShortForm(row.followers)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.followers)} */}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopTalkerByEngagement?.payload?.isLock && index + 1 >= 1
                          ? { filter: 'blur(4px)' }
                          : {}
                      }
                      align="left"
                    >
                      <Tooltip title={Number(row.following).toLocaleString('id-ID')} arrow placement="bottom-start">
                        <Typography>{formatCurrencyShortForm(row.following)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.following)} */}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopTalkerByEngagement?.payload?.isLock && index + 1 >= 1
                          ? { filter: 'blur(4px)' }
                          : {}
                      }
                      align="left"
                    >
                      <Tooltip title={Number(row.like).toLocaleString('id-ID')} arrow placement="bottom-start">
                        <Typography>{formatCurrencyShortForm(row.like)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.like)} */}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopTalkerByEngagement?.payload?.isLock && index + 1 >= 1
                          ? { filter: 'blur(4px)' }
                          : {}
                      }
                      align="left"
                    >
                      <Tooltip title={Number(row.share).toLocaleString('id-ID')} arrow placement="bottom-start">
                        <Typography>{formatCurrencyShortForm(row.share)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.share)} */}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopTalkerByEngagement?.payload?.isLock && index + 1 >= 1
                          ? { filter: 'blur(4px)' }
                          : {}
                      }
                      align="left"
                    >
                      <Tooltip title={Number(row.comment).toLocaleString('id-ID')} arrow placement="bottom-start">
                        <Typography>{formatCurrencyShortForm(row.comment)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.comment)} */}
                    </TableCell>
                    <TableCell
                      style={
                        dataAudienceTopTalkerByEngagement?.payload?.isLock && index + 1 >= 1
                          ? { filter: 'blur(4px)' }
                          : {}
                      }
                      align="left"
                    >
                      <Tooltip title={Number(row.view).toLocaleString('id-ID')} arrow placement="bottom-start">
                        <Typography>{formatCurrencyShortForm(row.view)}</Typography>
                      </Tooltip>
                      {/* {formatCurrencyShortForm(row.view)} */}
                    </TableCell>
                    {/* <TableCell align="left">{formatCurrency(row.comment)}</TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {dataAudienceTopTalkerByEngagement?.payload?.isLock && (
          <Box
            position={'absolute'}
            sx={{
              top: '70%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              '@media (max-width: 100px)': {
                top: '70%',
                left: '40%',
                transform: 'translate(-40%, -40%)',
              },
            }}
          >
            <IconButton onClick={handleUnlockEntity}>
              <img src={LockBigIcon} height={150} />{' '}
            </IconButton>
          </Box>
        )}
      </Paper>
    </Box>
  )
}
