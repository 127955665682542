import {
  FetchOnlineMediaStreamParams,
  FetchOnlineMediaStreamResponse,
  getOnlineMediaListStream,
} from '@/network/api/stream'
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'

export const useGetOnlineMediaStream = (
  initialParams: FetchOnlineMediaStreamParams,
  options?: UseInfiniteQueryOptions<FetchOnlineMediaStreamResponse, unknown, FetchOnlineMediaStreamResponse>,
) => {
  const fetchOnlineMediaStream = async ({ pageParam = 1 }) => {
    const params = {
      ...initialParams,
      page: pageParam,
    }
    const response = await getOnlineMediaListStream(params)
    return response
  }

  const { data, isError, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } = useInfiniteQuery(
    ['onlineMediaListStream', initialParams],
    fetchOnlineMediaStream,
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 1,
      retry: 3,
      getNextPageParam: (lastPage) => {
        const isNoMoreData = lastPage.payload.data.page >= lastPage.payload.data.totalPages
        return isNoMoreData ? undefined : lastPage.payload.data.page + 1
      },
      ...options,
    },
  )

  return {
    data,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  }
}
