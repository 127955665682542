/* eslint-disable @typescript-eslint/no-explicit-any */
import { API } from './api'

export interface FetchSocialMediaStreamParams {
  startDate: string
  endDate: string
  entityId: string
  platforms: string
  page: number
  size: number
}

export interface FetchOnlineMediaStreamParams {
  startDate: string
  endDate: string
  entityId: string
  page: number
  limit: number
}

export interface EntityStream {
  originalId: string
  socialMedia: string
  displayPicture: string
  link: string
  content: string
  fromId: string
  fromName: string
  timestamp: string
  share: number
  comment: number
  like: number
  audience: number
  engagementRate: number
  sentiment: string
}

export interface FetchSocialMediaStreamResponse {
  payload: {
    isLock: boolean
    data: {
      result: EntityStream[]
      totalRows: number
      totalPages: number
      page: number
      limit: number
    }
  }
}

export interface ArticleStream {
  originalId: string
  datePublished: string
  displayPicture: string
  title: string
  content: string
  link: string
  sentiment: string
  sourceName: string
}

export interface FetchOnlineMediaStreamResponse {
  payload: {
    isLock: boolean
    data: {
      limit: number
      page: number
      totalPages: number
      totalRows: number
      result: ArticleStream[]
    }
  }
}

export const getSocialMediaListStream = async (
  params: FetchSocialMediaStreamParams,
): Promise<FetchSocialMediaStreamResponse> => {
  try {
    const response = await API(`api/v1/social_media/list_stream`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getOnlineMediaListStream = async (
  params: FetchOnlineMediaStreamParams,
): Promise<FetchOnlineMediaStreamResponse> => {
  try {
    const response = await API(`api/v1/online_media/list_stream`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
