/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { request } from '@/network/axios'
import { getToken } from '@/utils/storage'
import { useMutation, useQuery } from 'react-query'

enum APIS {
  GET_CITY_TOTAL_NUMBER = '/xxxx/xxxx/xxxxx',
  AUTH = '/api/v1/auth',
  WALLET = '/api/v1/wallet',
}

interface UserProfileResponse {
  payload: {
    userId: string
    fullname: string
    email: string
    gender: string
    phoneNumber: string
    lastLogin: string
    profileImage: string
    isAccountLocked: string
    lockedReason: string
    timezone: string
    isVerified: string
    roles: [
      {
        roleId: string
        name: string
      },
    ]
  }
}
interface UserWalletResponse {
  payload: any
}

interface TopUpWalletResponse {
  payload: any
  statusCode: number
}

interface APIError {
  message: {}
}

export const useUserProfile = () => {
  const token = getToken()
  return useQuery<UserProfileResponse, APIError>('user', async () => await request.get(`${APIS.AUTH}/me`), {
    refetchOnWindowFocus: false,
    enabled: !!token,
  })
}
export const useUserWallet = () => {
  const token = getToken()
  return useQuery<UserWalletResponse>('wallet', async () => await request.get(`${APIS.WALLET}`), {
    refetchOnWindowFocus: false,
    enabled: !!token,
  })
}

export const useTopupWallet = () => {
  return useMutation(
    async (body: any) => {
      const response = await request.post<TopUpWalletResponse>(`${APIS.WALLET}/topup`, body)
      return response
    },
    {
      onSuccess: (data: any) => {
        window.open(`${data.payload.invoiceUrl}`)
      },
      onError: (error: any) => {
        if (error.response) {
          return error.response.data?.message
        }
      },
    },
  )
}
