import { Box, Button, Modal, Typography } from '@mui/material'
import WhatsappReminder from '@/assets/whatsapp-reminder.svg?react'

export const ModalWhatsappReminder = ({ isShow, setIsShow }) => {
  const navigate = useNavigate()
  const { changeProfileSelected } = useProfileStore()

  return (
    <Modal
      open={isShow}
      onClose={() => setIsShow(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box
        sx={{
          borderRadius: '10px',
          paddingY: 3,
          paddingX: { xs: 3, sm: 8 },
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          top: '50%',
          left: '50%',
          width: { xs: 380, sm: 550 },
          height: 380,
        }}
      >
        <Box>
          <WhatsappReminder />
        </Box>
        <Typography
          gutterBottom={true}
          sx={{
            fontFamily: 'Inter',
            fontSize: '24px',
            fontWeight: '600',
            lineHeight: '29px',
            letterSpacing: '0em',
            textAlign: 'center',
          }}
        >
          WhatsApp number has not been filled in
        </Typography>
        <Typography
          gutterBottom={true}
          sx={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '19px',
            letterSpacing: '0em',
            textAlign: 'center',
            marginBottom: 2,
          }}
        >
          It is hoped that you fill in your cellphone number before you carry out activities in this application
        </Typography>
        <Button
          onClick={() => {
            changeProfileSelected(1)
            navigate('/profile')
          }}
          disableElevation
          disableRipple
          disableTouchRipple
          size="large"
          variant="contained"
          sx={{
            padding: '20px 30px',
            borderRadius: '10px',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '16px',
            letterSpacing: '0em',
            textAlign: 'center',
          }}
        >
          Go to settings
        </Button>
      </Box>
    </Modal>
  )
}
