import { createContext } from 'react'

export const AuthContext = createContext({
  user: null,
  token: null,
})

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const currentUser = JSON.parse(localStorage.getItem('user') || '{}')

  // const login = async (inputs: any) => {
  //   const res = await axios.post('http://localhost:8001/api/login', inputs)
  //   setCurrentUser(res.data)
  // }

  // const logout = async () => {
  //   await axios.post('http://localhost:8001/api/logout')
  //   setCurrentUser(null)
  // }

  // useEffect(() => {
  //   localStorage.setItem('user', JSON.stringify(currentUser))
  // }, [currentUser])

  return (
    <AuthContext.Provider
      value={{
        user: currentUser,
        token: null,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
