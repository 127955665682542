/* eslint-disable @typescript-eslint/no-explicit-any */

import CustomTooltip from '@/components/Tooltip'
import {
  getOnlineMediaBrandPositioning,
  getOnlineMediaPerformance,
  getOnlineMediaSentimentAnalysis,
} from '@/network/api/comparison'
import { formatCurrency, formatCurrencyShortForm } from '@/utils/storage'
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
} from '@mui/material'
import { ApexOptions } from 'apexcharts'
import Chart from 'react-apexcharts'
import { useQuery as useReactQuery } from 'react-query'
const OnlineMediaComparison = ({ setIsError }) => {
  const { startDate, endDate, selectedPlatform, selectedItemsName } = useComparisonStore()

  const params = {
    startDate,
    endDate,
    entityIds: selectedItemsName.join(','),
    platforms: selectedPlatform,
  }
  const { data: dataOnlineMediaBrandPositioning, isLoading: isLoadingBrandPositioning } = useReactQuery(
    ['OnlineMediaBrandPositioning', params],
    () => getOnlineMediaBrandPositioning(params),
    {
      staleTime: 1000 * 60 * 1,
      retry: 2,
    },
  )
  useEffect(() => {
    if (dataOnlineMediaBrandPositioning?.response?.data?.statusCode === 400) {
      if (
        dataOnlineMediaBrandPositioning?.response?.data?.statusCode === 400 &&
        Number(dataOnlineMediaBrandPositioning?.response?.data?.innerException?.errorCode) == 4101
      ) {
        console.log(dataOnlineMediaBrandPositioning?.response?.data?.innerException?.errorCode, '0eoror')
        setIsError(true)
      }
    }
  }, [dataOnlineMediaBrandPositioning])
  const { data: dataOnlineMediaSentimentAnalysis, isLoading: isLoadOnlineMediaSentimentAnalysis } = useReactQuery(
    ['OnlineMediaSentimentAnalysis', params],
    () => getOnlineMediaSentimentAnalysis(params),
    {
      staleTime: 1000 * 60 * 1,
      retry: 2,
    },
  )
  const {
    data: dataOnlineMediaPerformance,
    isLoading: isLoadOnlineMediaPerformance,
    isError: isErrorOnlineMediaPerformance,
  } = useReactQuery(['OnlineMediaPerformance', params], () => getOnlineMediaPerformance(params), {
    staleTime: 1000 * 60 * 1,
    retry: 2,
  })
  const separateNumberFormat = (num: number) => {
    if (Number.isInteger(num)) {
      return parseFloat(num.toFixed(2))?.toLocaleString('id-Id')
    } else {
      return num?.toLocaleString('id-Id')
    }
  }
  const dataSeries = (dataOnlineMediaBrandPositioning?.payload?.data || []).map((res) => {
    return {
      name: res.entityName,
      data: [[res?.entityData?.positivity, res?.entityData?.popularity]],
    }
  })
  let topTalkNum = dataOnlineMediaBrandPositioning?.payload?.data?.map((values) => values.entityData?.popularity) ?? []
  if (topTalkNum.length > 0) {
    topTalkNum = Math.max(...topTalkNum)
  } else {
    topTalkNum = 0
  }
  if (isNaN(topTalkNum)) {
    topTalkNum = 1
  }

  const lcm = 40

  if (topTalkNum % lcm !== 0) {
    topTalkNum = topTalkNum + (lcm - (topTalkNum % lcm))
  }

  const yAnnotation = topTalkNum / 2
  const yAnnotationHeight = topTalkNum / 1000
  const options = {
    chart: {
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: dataOnlineMediaBrandPositioning?.payload?.data?.entityColors,
    annotations: {
      yaxis: [
        {
          y: yAnnotation - yAnnotationHeight,
          y2: yAnnotation + yAnnotationHeight,
          borderColor: '#000000',
          fillColor: '#000000',
          strokeDashArray: 0,
          opacity: 1,
        },
      ],
      xaxis: [
        {
          x: -0.1,
          x2: 0.1,
          borderColor: '#000000',
          fillColor: '#000000',
          strokeDashArray: 0,
          opacity: 1,
        },
      ],
    },
    xaxis: {
      tickAmount: 10,
      min: -102,
      max: 102,
      labels: {
        show: false,
      },
    },
    yaxis: {
      tickAmount: 8,
      show: true,
      min: 0,
      max: topTalkNum,
      labels: {
        show: false,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: 10,
    },
    legend: {
      show: true,
      position: 'right',
      width: 400,
      formatter: function (seriesName: any, { seriesIndex, w }: any) {
        return `${seriesName} (${w.config.series[seriesIndex]?.data[0][0]?.toFixed(2)}%, ${separateNumberFormat(
          w.config.series[seriesIndex]?.data[0][1],
        )})`
      },
      offsetX: -80,
    },
  }
  function transformData(inputData) {
    const transformedData = [
      { name: 'positive', data: [] },
      { name: 'neutral', data: [] },
      { name: 'negative', data: [] },
    ]
    const labels = []

    ;(inputData?.payload?.data || [])?.forEach((entity) => {
      labels.push(entity.entityName)
      entity.entityData.forEach((dataPoint) => {
        const index = transformedData.findIndex((item) => item.name === dataPoint.name)
        if (index !== -1) {
          transformedData[index].data.push(dataPoint.value)
        }
      })
    })

    return {
      data: transformedData,
      label: labels,
    }
  }
  const { label, data } = transformData(dataOnlineMediaSentimentAnalysis)

  const dataBarChart = {
    series: data,
    options: {
      chart: {
        type: 'bar',
        height: 430,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: '10px',
          colors: ['#fff'],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff'],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: label,
      },
      fill: {
        colors: ['#26C281', '#22A7F0', '#DC3023'],
      },
      colors: ['#26C281', '#22A7F0', '#DC3023'],
    } as ApexOptions,
  }
  return (
    <>
      <Paper sx={{ width: '100%', pb: 10, pl: 2, mb: 2, mt: 2, borderRadius: 2, height: '400px' }}>
        <Typography fontWeight={500} fontSize={{ xs: 16, sm: 18 }} pt={2}>
          Brand Positioning Online Media
        </Typography>
        {isLoadingBrandPositioning ? (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={400}>
            <Skeleton variant="rounded" width={'95%'} height={300} />
          </Box>
        ) : (
          <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
            <Stack
              alignItems="center"
              height="100%"
              // width="100%"
              width={dataSeries.length > 1 ? { xs: 650, md: '100%' } : { xs: '100%' }}
            >
              <Box
                fontSize="12px"
                marginBottom="-28px"
                // marginLeft="-310px"
                marginLeft={dataSeries.length > 1 ? '-310px' : 'none'}
                sx={{ width: '100%', textAlign: 'center' }}
              >
                {formatCurrency(topTalkNum)} Talk
              </Box>
              <Stack direction="row" alignItems="center" height="100%" width="100%">
                <Box
                  fontSize="12px"
                  width="5%"
                  // marginRight="-10px"
                  marginRight={dataSeries.length > 1 ? '-10px' : ''}
                >
                  Negative -100%
                </Box>
                <Box fontSize="12px" width="100%" height="100%">
                  <Chart options={options as ApexOptions} series={dataSeries} height="100%" type="scatter" />
                </Box>
                <Box
                  fontSize="12px"
                  width="5%"
                  // marginLeft="-400px"
                  marginLeft={dataSeries.length > 1 ? '-400px' : ''}
                >
                  Positive +100%
                </Box>
              </Stack>
              <Box
                fontSize="12px"
                // marginLeft="-330px"
                marginLeft={dataSeries.length > 1 ? { xs: '-330px', md: '-300px' } : ''}
              >
                0 Talk
              </Box>
            </Stack>
          </div>
        )}
      </Paper>
      <Paper sx={{ p: 2, mb: 2, mt: 2, display: 'flex', flexDirection: 'column', borderRadius: 2 }}>
        <Typography mb={2} fontWeight={500} fontSize={18}>
          Online Media Performance
        </Typography>
        {isLoadOnlineMediaPerformance ? (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={400}>
            <Skeleton variant="rounded" width={'100%'} height={400} />
          </Box>
        ) : (
          <TableContainer component={Paper} style={{ maxHeight: '200px', overflow: 'auto', borderRadius: 8 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
              <TableHead sx={{ backgroundColor: 'rgba(223, 202, 114, 1)' }}>
                <TableRow
                  sx={{
                    backgroundColor: 'rgba(223, 202, 114, 1)',
                    // '& .MuiTableCell-head': {
                    //   padding: '6px 16px',
                    //   backgroundColor: 'rgba(223, 202, 114, 1)',
                    // }
                    '& .MuiTableCell-head': {
                      // padding: '6px 16px',
                      paddingY: { xs: 1, sm: 2 },
                      backgroundColor: 'rgba(223, 202, 114, 1)',
                      fontSize: { xs: 10, sm: 16 },
                      lineHeight: 1.5,
                      // backgroundColor: 'blue',
                    },
                  }}
                >
                  <TableCell>No</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="center">Total Article</TableCell>
                  <TableCell align="center">Total Media</TableCell>
                  <TableCell align="center">Sentiment Score</TableCell>
                  <TableCell align="center">PR Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoadOnlineMediaPerformance &&
                  !isErrorOnlineMediaPerformance &&
                  (dataOnlineMediaPerformance?.payload?.data || []).map((row, index) => (
                    <TableRow
                      key={row.No}
                      sx={{
                        // '&:last-child td, &:last-child th': { border: 0 }
                        '&:last-child td, &:last-child th': { border: 0 },
                        '& .MuiTableCell-body': {
                          fontSize: { xs: 10, sm: 16 },
                          paddingY: { xs: 1, sm: 2 },
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">{row.entityName}</TableCell>
                      <TableCell align="center">{formatCurrencyShortForm(row.entityData.totalArticle)}</TableCell>
                      <TableCell align="center">{formatCurrencyShortForm(row.entityData.totalMedia)}</TableCell>
                      <TableCell align="center">{formatCurrencyShortForm(row.entityData.sentiment)}</TableCell>
                      <TableCell align="center">{formatCurrencyShortForm(row.entityData.prValue)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <Paper sx={{ p: 2, mb: 2, mt: 2, display: 'flex', flexDirection: 'column', borderRadius: 2 }}>
        <Stack direction="row">
          <Typography fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
            Sentiment Analysis
          </Typography>
          <CustomTooltip title="How is your sentiment analysis?" />
        </Stack>
        {isLoadOnlineMediaSentimentAnalysis ? (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={400}>
            <Skeleton variant="rounded" width={'100%'} height={400} />
          </Box>
        ) : (
          <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
            <Box
              sx={{
                width: { xs: 500, sm: '100%' },
                height: 350,
              }}
            >
              <Chart
                options={dataBarChart.options}
                series={dataBarChart.series}
                type="bar"
                height={'100%'}
                width={'100%'}
              />
            </Box>
          </div>
        )}
      </Paper>
    </>
  )
}

export default OnlineMediaComparison
