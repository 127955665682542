/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tab,
  Grid,
  Stack,
  IconButton,
  Skeleton,
  Tooltip,
  CircularProgress,
} from '@mui/material'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import { useQuery as useReactQuery } from 'react-query'
import { ApexOptions } from 'apexcharts'
import Chart from 'react-apexcharts'
import { TabContext, TabList, TabPanel } from '@mui/lab'
// assets
// mock
import { PostmadeAnalytic } from './PostmadeAnalytic'
import { TotalTalkAnalytic } from './TotalTalk'
import { SentimentScoreAnalytic } from './SentimentScore'
import { EngagementRateAnalytic } from './EngagementRate'
import { TopPostAnalytic } from './TopPost'
import { PostTypeAnalytic } from './PostType'
import { SentimentAnalysis } from './SentimentAnalysis'
import {
  getDayToDayTalk,
  getConversationByMedia,
  getPeakTime,
  getDayToDayPerformance,
  getSocialMediaPerformance,
  getArticleByMedia,
  getSentimentAnalysisOnline,
  getOnlineMediaChronoLogicalMedia,
  getOnlineMediaChronoLogicalArticle,
  getWordCloud,
} from '@/network/api/analytic'
// import { WordCloud } from './WordCloud'

// import ReactWordcloud, { MinMaxPair } from 'react-wordcloud'
import {
  buildDataLineGroup,
  buildDataLineGroupsDay,
  buildDataNewsPortal,
  separateNumberFormat,
  transformDataToChartFormat,
} from '@/utils/helper'
import { formatCurrencyShortForm } from '@/utils/storage'
import { TotalMedia } from './TotalMedia'
import { TotalArticle } from './TotalArticle'
import moment from 'moment'
import LockBigIcon from '@/assets/lock-big-icon.svg'
import CustomTooltip from '@/components/Tooltip'
import ReactWordcloud, { MinMaxPair } from '@cyberblast/react-wordcloud'

enum Spiral {
  Archimedean = 'archimedean',
  Rectangular = 'rectangular',
}
enum Scale {
  Linear = 'linear',
  Log = 'log',
  Sqrt = 'sqrt',
}
interface ResponseSocMedPerform {
  isLock: boolean
  data: any
}
interface AnalyticModuleProps {
  handleUnlockEntity?: () => void
}
const optionsWordCloud = {
  rotations: 2,
  padding: 3,
  fontSizes: [10, 50] as MinMaxPair,
  rotationAngles: [0, 0] as [number, number],
  deterministic: true,
  background: 'blue',
  spiral: Spiral.Archimedean,
  scale: Scale.Linear,
}

const size = [700, 360] as [number, number]
const sizeXs = [250, 100] as [number, number]
const callbacks = {
  getWordColor: (word: { value: number }) => (word?.value < 500 ? '#9F9986' : '#382A02'),
  getWordTooltip: (word: { text: string; value: number }) => `${word.text} (${word.value})`,
  // getWordTooltip: (word) => `The word "${word.text}" appears ${word.value} times.`,
}

export const AnalyticModule: React.FC<AnalyticModuleProps> = ({
  handleUnlockEntity,
}: {
  handleUnlockEntity: () => void
}) => {
  const { startDate, endDate, selectedPlatform, selectedEntity } = useComparisonStore()
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const id = query.get('entityId') as string
  const [isLoading, setLoading] = useState(false)
  const [value, setValue] = useState('1')
  const [dataConversationByMedia, setConversationByMedia] = useState<ResponseSocMedPerform>({} as ResponseSocMedPerform)
  const [dataPeakTime, setPeakTime] = useState<ResponseSocMedPerform>({} as ResponseSocMedPerform)
  const [dataSocialMediaPerformance, setSocialMediaPerformance] = useState<ResponseSocMedPerform>(
    {} as ResponseSocMedPerform,
  )
  const [dataDayToDayTalk, setDayToDayTalk] = useState<ResponseSocMedPerform>({} as ResponseSocMedPerform)
  const [dataDayToDayPerformance, setDayToDayPerformance] = useState<ResponseSocMedPerform>({} as ResponseSocMedPerform)
  const [dataWordCloud, setWordCloud] = useState<ResponseSocMedPerform>({} as ResponseSocMedPerform)
  const params = {
    startDate,
    endDate,
    entityId: id || selectedEntity.entityId,
    platforms: selectedPlatform,
  }
  useEffect(() => {
    const fetchTopPost = async () => {
      setLoading(true)
      try {
        const fetchFunctions = [
          getDayToDayTalk(params),
          getConversationByMedia(params),
          getPeakTime(params),
          getDayToDayPerformance(params),
          getSocialMediaPerformance(params),
          getWordCloud(params),
        ]
        const results = await Promise.all(
          fetchFunctions.map((p) => p.catch((e) => ({ statusCode: 400, payload: null, error: e }))),
        )
        const [dayToDayTalk, conversationByMedia, peakTime, dayToDayPerformance, socialMediaPerformance, wordCloud] =
          results.map((result) => (result?.statusCode === 200 ? result?.payload : ''))

        setDayToDayTalk(dayToDayTalk)
        setConversationByMedia(conversationByMedia)
        setPeakTime(peakTime)
        setDayToDayPerformance(dayToDayPerformance)
        setSocialMediaPerformance(socialMediaPerformance)
        setWordCloud(wordCloud)
        setLoading(false)
      } catch (error) {
        console.error('An error occurred during fetching:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchTopPost()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, selectedPlatform, selectedEntity, id])

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  const convertWords = (words) => {
    // Ensure words is an array; use an empty array if words is null or undefined
    const validWords = words || []
    return validWords.slice(0, 50).map((word) => ({
      text: word?.name,
      value: word?.value,
    }))
  }
  const result = _.map(dataSocialMediaPerformance?.data, (v, UID) => ({ UID, ...v }))

  function transformData(data) {
    const platforms = ['facebook', 'instagram', 'youtube', 'twitter', 'tiktok']
    const transformed = []

    platforms.forEach((platform) => {
      const platformData = {
        media: platform,
      }

      data?.forEach((item) => {
        if (item[platform] !== undefined) {
          platformData[item?.UID] = item[platform]
        }
      })
      transformed.push(platformData)
    })

    return transformed
  }
  const transformedData = transformData(result)

  const seriesPeak = dataPeakTime?.data?.peakTimeValue.sort((a, b) => {
    const daysOrder = ['sunday', 'saturday', 'friday', 'thursday', 'wednesday', 'tuesday', 'monday']
    return daysOrder.indexOf(a.name) - daysOrder.indexOf(b.name)
  })
  const min = dataPeakTime?.data?.min
  const max = dataPeakTime?.data?.max
  const colors = ['#DCDAD3', '#9F9986', '#605535', '#382A02']
  const stepperValue = Math.round((max - min) / colors?.length)
  const colorRange = colors.map((color: string, index: number) => ({
    from: index === 0 ? min : stepperValue * index + 1,
    to: index >= colors?.length - 1 ? max : stepperValue * (index + 1),
    color: color,
  }))
  const optionsPeak: ApexOptions = {
    chart: {
      type: 'heatmap',
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      heatmap: {
        radius: 2,
        enableShades: false,
        shadeIntensity: 0.5,
        reverseNegativeShade: false,
        distributed: false,
        colorScale: {
          ranges: colorRange,
          inverse: false,
        },
      },
    },
  }

  const resultss = buildDataLineGroup(dataDayToDayPerformance)

  const dataLineCharts = buildDataLineGroupsDay(dataDayToDayTalk)
  const categories = dataLineCharts?.data?.map((item) => item[0])
  const seriesData = dataLineCharts?.data?.map((item) => item[1])
  const dataLineChart = (() => {
    return {
      options: {
        chart: {
          id: 'line',
          height: 1000,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        xaxis: {
          // min: new Date(startDate),
          // max: new Date(endDate),
          type: 'datetime',

          categories: categories,
          tickAmount: 'dataPoints',
          labels: {
            datetimeUTC: true,
            formatter: (values) => {
              const convert =
                moment(endDate)?.diff(startDate, 'day') <= 1
                  ? moment(values)?.format('HH:mm')
                  : moment(values)?.format('DD MMM')

              return convert
            },
          },
        },
      } as ApexOptions,
      series: [
        {
          name: dataLineCharts.name,
          data: seriesData,
        },
      ],
    }
  })()
  const { data: dataChronologicalMedia } = useReactQuery(
    ['NewsChronologicalMedia', params],
    () => getOnlineMediaChronoLogicalMedia(params),
    {
      staleTime: 1000 * 60 * 1,
    },
  )
  const { data: dataChronologicalArticle } = useReactQuery(
    ['NewsChronologicalArticle', params],
    () => getOnlineMediaChronoLogicalArticle(params),
    {
      staleTime: 1000 * 60 * 1,
    },
  )
  const {
    data: dataArticleByMedia,
    isFetching: isFetchingArticleByMedia,
    refetch: refetchDataArticleByMedia,
  } = useReactQuery(['ArticleByMedia', params], () => getArticleByMedia(params), {
    staleTime: 1000 * 60 * 1,
  })
  const {
    data: dataSentimentAnalysisOnline,
    isFetching: isFetchingSentimentAnalysisOnline,
    refetch: refetchDataSentimentAnalysisOnline,
  } = useReactQuery(['MediaOnlineSentiment', params], () => getSentimentAnalysisOnline(params), {
    staleTime: 1000 * 60 * 1,
  })
  // const resultNewsPortal = buildDataLineGroup(dataChronologicalMedia)
  const seriesDonut = (
    (!dataSentimentAnalysisOnline?.payload?.isLock && dataSentimentAnalysisOnline?.payload?.data) ||
    []
  ).map((dt) => dt.value)
  const labelsDonut = (dataSentimentAnalysisOnline?.payload?.data || []).map((dt) => dt.name)
  const legendDonut = { show: true }
  const optionsDonuts: ApexCharts.ApexOptions = {
    labels: labelsDonut,
    tooltip: {
      enabled: true,
      y: {
        formatter: (value) => (value % 1 <= 0 ? separateNumberFormat(value) : value.toFixed(3)),
      },
    },
    responsive: [
      {
        options: {
          chart: {
            height: 300,
          },
        },
      },
    ],
    chart: {
      animations: {
        enabled: false,
      },
      //height: 300,
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
          },
        },
      },
      events: {},
    },
    plotOptions: {
      pie: {
        customScale: 1,
      },
    },
    // colors: colors,
    colors: ['rgba(0, 173, 38, 1)', 'rgba(0, 159, 227, 1)', 'rgba(227, 82, 0, 1)'],
    legend: { ...legendDonut, position: 'bottom', height: 26 },
  }
  const { series, optionsConver } = transformDataToChartFormat(dataConversationByMedia?.data)
  const mergeData = (data1, data2) => {
    // Define the structure of the merged data
    const mergedData = [
      {
        contentType: 'Portal',
        values: [],
      },
      {
        contentType: 'Media',
        values: [],
      },
    ]

    // Populate the 'Media' content type
    data1.forEach((item) => {
      mergedData[0].values.push({
        timestamp: moment(item.timestamp).utc(),
        value: item.value,
      })
    })

    // Populate the 'Portal' content type
    data2.forEach((item) => {
      mergedData[1].values.push({
        timestamp: moment(item.timestamp).utc(),
        value: item.value,
      })
    })

    return mergedData
  }
  const dataM = mergeData(dataChronologicalArticle?.payload?.data || [], dataChronologicalMedia?.payload?.data || [])

  const resultNewsPortalArticle = buildDataNewsPortal(dataM)

  const dataLineMultiChart: ApexOptions = {
    chart: {
      type: 'line',
      height: 'auto',
      animations: {
        enabled: false,
      },
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    xaxis: {
      type: 'datetime',
      tickAmount: 'dataPoints',
      labels: {
        datetimeUTC: true,
        formatter: (values) => {
          const convert =
            moment(endDate)?.diff(startDate, 'day') <= 1
              ? moment(values).format('HH:mm')
              : moment(values).format('DD MMM')

          return convert
        },
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        labels: {
          formatter: (value) => {
            if (Number.isInteger(value)) {
              return value.toLocaleString('en-US')
            } else {
              return value.toFixed(2)
            }
          },
        },
      },
    ],
    tooltip: {
      x: {
        format: 'dd mm yyyy',
      },
    },
    stroke: {
      width: 5,
      curve: 'straight',
    },
    markers: {
      size: 4,
    },
    legend: {
      show: true,
    },
    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', 'rgb(203, 188, 15)'],
  }

  useEffect(() => {
    refetchDataArticleByMedia()
    refetchDataSentimentAnalysisOnline()
  }, [startDate, endDate, selectedPlatform, selectedEntity, id])

  // Example usage:
  return (
    <Box sx={{ py: 2 }}>
      {value == '1' ? (
        <Box display={'flex'} sx={{ gap: 2, flex: 1, justifyContent: 'space-between', height: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <PostmadeAnalytic id={id} />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <TotalTalkAnalytic id={id} />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <SentimentScoreAnalytic id={id} handleUnlockEntity={handleUnlockEntity} />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <EngagementRateAnalytic id={id} handleUnlockEntity={handleUnlockEntity} />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box display={'flex'} sx={{ gap: 2, flex: 1, justifyContent: 'space-between', height: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <TotalArticle id={id} />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <TotalMedia id={id} />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <SentimentScoreAnalytic id={id} handleUnlockEntity={handleUnlockEntity} />
            </Grid>
          </Grid>
        </Box>
      )}
      <TabContext value={value || '1'}>
        <Box sx={{ width: '100%', boxShadow: 3, mt: 2, mb: 1, borderRadius: 2 }}>
          <TabList
            TabIndicatorProps={{ style: { display: 'none' } }}
            variant="fullWidth"
            onChange={handleChange}
            centered
          >
            <Tab
              value="1"
              label="Social Media"
              sx={{
                borderRadius: 2,
                color: value === '1' ? '#FFFFFF' : 'black',
                backgroundColor: value === '1' ? 'rgba(56, 42, 2, 1)' : 'white',
                '&.Mui-selected': {
                  color: 'white',
                },
              }}
            />
            <Tab
              value="2"
              label="Online Media"
              sx={{
                borderRadius: 2,
                color: value === '2' ? '#FFFFFF' : 'black',
                backgroundColor: value === '2' ? 'rgba(56, 42, 2, 1)' : 'white',
                '&.Mui-selected': {
                  color: 'white',
                },
              }}
            />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: 0 }}>
          <TopPostAnalytic id={id} handleUnlockEntity={handleUnlockEntity} />
          {/* <Paper sx={{ p: 2, mb: 2, mt: 2, display: 'flex', flexDirection: 'column' }}> */}
          <Grid
            container
            // rowGap={4}
            spacing={{ xs: 2, lg: 6 }}
            columns={{ xs: 6, lg: 12 }}
            justifyContent="center"
            alignItems="center"
            // sx={{ width: '100%' }}
          >
            <Grid item xs={12} sm={6}>
              <PostTypeAnalytic id={id} handleUnlockEntity={handleUnlockEntity} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SentimentAnalysis id={id} handleUnlockEntity={handleUnlockEntity} />
            </Grid>
          </Grid>
          <Paper sx={{ p: 2, mb: 2, mt: 2, display: 'flex', flexDirection: 'column', borderRadius: 4 }}>
            <Stack direction="row">
              <Typography fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
                Day-to-day Talk
              </Typography>
              <CustomTooltip title="How is your talk performance?" />
            </Stack>
            <div style={{ height: '100%', width: '100%', overflow: 'scroll' }}>
              <Box
                sx={{
                  width: { xs: 700, sm: '100%' },
                  height: { xs: 300, sm: '200px' },
                  filter: dataDayToDayTalk?.isLock ? 'blur(8px)' : 'none',
                  opacity: dataDayToDayTalk?.isLock ? 0.5 : 1,
                  zIndex: dataDayToDayTalk?.isLock ? 1 : 0,
                }}
              >
                <Chart
                  options={dataLineChart.options}
                  series={dataLineChart.series}
                  type="line"
                  width="100%"
                  height="100%"
                />
              </Box>
            </div>
          </Paper>
          <Paper
            sx={{
              p: 2,
              mb: 2,
              mt: 2,
              borderRadius: 4,
              position: 'relative',
            }}
          >
            <Stack direction="row">
              <Typography fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
                Conversation by Media
              </Typography>
              <CustomTooltip title="Which media talks about you the most?" />
            </Stack>
            <Box
              sx={{
                height: '100%',
                width: '100%',
                filter: dataConversationByMedia?.isLock ? 'blur(8px)' : 'none',
                opacity: dataConversationByMedia?.isLock ? 0.5 : 1,
                zIndex: dataConversationByMedia?.isLock ? 1 : 0,
              }}
            >
              <Chart options={optionsConver} series={series} type="bar" height={350} />
            </Box>
            {dataConversationByMedia?.isLock && (
              <Box
                position={'absolute'}
                sx={{
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  '@media (max-width: 100px)': {
                    top: '50%',
                    left: '40%',
                    transform: 'translate(-40%, -40%)',
                  },
                }}
              >
                <IconButton onClick={handleUnlockEntity}>
                  <img src={LockBigIcon} height={250} />
                </IconButton>
              </Box>
            )}
          </Paper>
          <Paper
            sx={{ p: 2, mb: 2, mt: 2, display: 'flex', flexDirection: 'column', borderRadius: 4, position: 'relative' }}
          >
            <Stack direction="row">
              <Typography fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
                Day-to-day Performance
              </Typography>
              <CustomTooltip title="How is your engagement performance?" />
            </Stack>
            <div style={{ height: '100%', width: '100%', overflow: 'scroll' }}>
              <Box
                sx={{
                  width: { xs: 700, sm: '100%' },
                  height: 400,
                  filter: dataDayToDayPerformance?.isLock ? 'blur(8px)' : 'none',
                  opacity: dataDayToDayPerformance?.isLock ? 0.5 : 1,
                  zIndex: dataDayToDayPerformance?.isLock ? 1 : 0,
                }}
              >
                <Chart options={dataLineMultiChart} series={resultss} type="line" width="100%" height="100%" />
              </Box>
            </div>
            {dataDayToDayPerformance?.isLock && (
              <Box
                position={'absolute'}
                sx={{
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  '@media (max-width: 100px)': {
                    top: '50%',
                    left: '40%',
                    transform: 'translate(-40%, -40%)',
                  },
                }}
              >
                <IconButton onClick={handleUnlockEntity}>
                  <img src={LockBigIcon} height={250} />{' '}
                </IconButton>
              </Box>
            )}
          </Paper>
          <Paper
            sx={{ p: 2, mb: 2, mt: 2, display: 'flex', flexDirection: 'column', borderRadius: 4, position: 'relative' }}
          >
            <Stack direction="row" mb={2}>
              <Typography fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
                Social Media Performance
              </Typography>
              <CustomTooltip title="How is your engagement performance?" />
            </Stack>
            <TableContainer component={Paper} style={{ maxHeight: '400px', overflow: 'auto', borderRadius: 8 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                <TableHead
                  sx={{
                    backgroundColor: 'rgba(223, 202, 114, 1)',

                    height: 10,
                  }}
                >
                  <TableRow
                    sx={{
                      height: 10,
                      backgroundColor: 'rgba(223, 202, 114, 1)',

                      '& .MuiTableCell-head': {
                        // padding: '6px 16px',
                        paddingY: { xs: 1, sm: 2 },
                        backgroundColor: 'rgba(223, 202, 114, 1)',
                        fontSize: { xs: 10, sm: 16 },
                        lineHeight: 1.5,
                        // backgroundColor: 'blue',
                      },
                    }}
                  >
                    <TableCell>No</TableCell>
                    <TableCell align="left">Social Media</TableCell>
                    <TableCell align="center">Engagement</TableCell>
                    <TableCell align="left">Like</TableCell>
                    <TableCell align="left">Comment</TableCell>
                    <TableCell align="left">Share</TableCell>
                    <TableCell align="left">View</TableCell>
                    {/* <TableCell align="left">Talk</TableCell> */}
                    {/* <TableCell align="left">Talker</TableCell> */}
                    <TableCell align="left">Reach</TableCell>
                    <TableCell align="center">Impression</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transformedData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '& .MuiTableCell-body': {
                          fontSize: { xs: 10, sm: 16 },
                          paddingY: { xs: 1, sm: 2 },
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={dataSocialMediaPerformance.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                      >
                        {row.media}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={dataSocialMediaPerformance?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                      >
                        <Tooltip title={Number(row.engagement).toLocaleString('id-ID')} arrow placement="bottom">
                          <Typography>{formatCurrencyShortForm(row.engagement)}</Typography>
                        </Tooltip>
                        {/* {formatCurrencyShortForm(row.engagement)} */}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={dataSocialMediaPerformance?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                      >
                        <Tooltip title={Number(row.like).toLocaleString('id-ID')} arrow placement="bottom-start">
                          <Typography>{formatCurrencyShortForm(row.like)}</Typography>
                        </Tooltip>
                        {/* {formatCurrencyShortForm(row.like)} */}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={dataSocialMediaPerformance?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                      >
                        <Tooltip title={Number(row.comment).toLocaleString('id-ID')} arrow placement="bottom-start">
                          <Typography>{formatCurrencyShortForm(row.comment)}</Typography>
                        </Tooltip>
                        {/* {formatCurrencyShortForm(row.comment)} */}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={dataSocialMediaPerformance?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                      >
                        <Tooltip title={Number(row.share).toLocaleString('id-ID')} arrow placement="bottom-start">
                          <Typography>{formatCurrencyShortForm(row.share)}</Typography>
                        </Tooltip>
                        {/* {formatCurrencyShortForm(row.share)} */}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={dataSocialMediaPerformance?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                      >
                        <Tooltip title={Number(row.view).toLocaleString('id-ID')} arrow placement="bottom-start">
                          <Typography>{formatCurrencyShortForm(row.view)}</Typography>
                        </Tooltip>
                        {/* {formatCurrencyShortForm(row.view)} */}
                      </TableCell>
                      {/* <TableCell
                        align="left"
                        style={dataSocialMediaPerformance?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                      >
                        {formatCurrencyShortForm(row.talk)}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={dataSocialMediaPerformance?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                      >
                        {formatCurrencyShortForm(row.talker)}
                      </TableCell> */}
                      <TableCell
                        align="left"
                        style={dataSocialMediaPerformance?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                      >
                        <Tooltip title={Number(row.reach).toLocaleString('id-ID')} arrow placement="bottom-start">
                          <Typography>{formatCurrencyShortForm(row.reach)}</Typography>
                        </Tooltip>
                        {/* {formatCurrencyShortForm(row.reach)} */}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={dataSocialMediaPerformance?.isLock && index + 1 >= 1 ? { filter: 'blur(4px)' } : {}}
                      >
                        <Tooltip title={Number(row.impression).toLocaleString('id-ID')} arrow placement="bottom">
                          <Typography>{formatCurrencyShortForm(row.impression)}</Typography>
                        </Tooltip>
                        {/* {formatCurrencyShortForm(row.impression)} */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {dataSocialMediaPerformance.isLock && (
              <Box
                position={'absolute'}
                sx={{
                  top: '60%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  '@media (max-width: 100px)': {
                    top: '60%',
                    left: '40%',
                    transform: 'translate(-40%, -40%)',
                  },
                }}
              >
                <IconButton onClick={handleUnlockEntity}>
                  <img src={LockBigIcon} height={150} />{' '}
                </IconButton>
              </Box>
            )}
          </Paper>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Paper sx={{ p: 2, mt: 2, mb: 2, height: '400px', borderRadius: 4 }}>
                <Stack direction="row">
                  <Typography fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
                    Peaktime
                  </Typography>
                  <CustomTooltip title="When do your followers engage?" />
                </Stack>
                <div style={{ height: '100%', width: '100%', overflow: 'scroll' }}>
                  <Box
                    sx={{
                      width: { xs: 600, sm: '100%' },
                      height: '100%',
                      filter: dataPeakTime?.isLock ? 'blur(8px)' : 'none',
                      opacity: dataPeakTime?.isLock ? 0.5 : 1,
                      zIndex: dataPeakTime?.isLock ? 1 : 0,
                    }}
                  >
                    {isLoading || !seriesPeak ? (
                      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={100}>
                        <Skeleton variant="rounded" width={570} height={500} />
                      </Box>
                    ) : (
                      <Chart options={optionsPeak} series={seriesPeak} type="heatmap" width="100%" height="350px" />
                    )}
                  </Box>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={6}>
              <Paper
                sx={{
                  p: 2,
                  mt: 2,
                  position: 'relative',
                  height: { xs: '100%', sm: '400px' },
                  borderRadius: 4,
                  // flexDirection: 'column',
                }}
              >
                <>
                  <Box display={'flex'}>
                    <Typography fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
                      Word Cloud
                    </Typography>
                    <CustomTooltip title="What do your followers talk about?" />
                  </Box>
                  {isLoading && !dataWordCloud?.data ? (
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={350}>
                      <Skeleton variant="rounded" width={570} height={300} />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'auto',
                        // pt: 2,
                      }}
                    >
                      <Box
                        className="word-cloud"
                        sx={{
                          display: { xs: 'none', sm: 'block' },
                          height: '100%',
                          width: '100%',
                        }}
                        style={{
                          // minWidth: 200,
                          // minHeight: 250,
                          filter: dataWordCloud.isLock ? 'blur(8px)' : 'none',
                          opacity: dataWordCloud.isLock ? 0.5 : 1,
                          zIndex: dataWordCloud.isLock ? 1 : 0,
                        }}
                      >
                        <div id="word-cloud" style={{ height: 300, width: 700 }}>
                          <ReactWordcloud
                            words={convertWords(dataWordCloud?.data)}
                            options={optionsWordCloud}
                            size={size}
                            callbacks={callbacks}
                          />
                        </div>
                      </Box>
                      <Box
                        className="word-cloud"
                        sx={{
                          display: { xs: 'block', sm: 'none' },
                          height: '100%',
                          width: '100%',
                        }}
                        style={{
                          // minWidth: 200,
                          // minHeight: 250,
                          filter: dataWordCloud.isLock ? 'blur(8px)' : 'none',
                          opacity: dataWordCloud.isLock ? 0.5 : 1,
                          zIndex: dataWordCloud.isLock ? 1 : 0,
                        }}
                      >
                        <ReactWordcloud
                          words={convertWords(dataWordCloud?.data)}
                          options={optionsWordCloud}
                          size={sizeXs}
                          callbacks={callbacks}
                        />
                      </Box>
                    </Box>
                  )}
                </>
                {dataWordCloud.isLock && (
                  <Box
                    position={'absolute'}
                    sx={{
                      top: '60%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      '@media (max-width: 100px)': {
                        top: '60%',
                        left: '40%',
                        transform: 'translate(-40%, -40%)',
                      },
                    }}
                  >
                    <img src={LockBigIcon} height={150} />{' '}
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          <Paper sx={{ p: 2, mb: 2, mt: 2, display: 'flex', flexDirection: 'column', borderRadius: 4 }}>
            <Typography fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
              News Portal Article
            </Typography>
            <div style={{ height: '100%', width: '100%', overflow: 'auto' }}>
              <Box
                sx={{
                  width: { xs: 700, sm: '100%' },
                  height: '400px',
                }}
                style={{
                  // width: '100%',
                  // height: '400px',
                  filter: dataChronologicalArticle?.payload?.isLock ? 'blur(8px)' : 'none',
                  opacity: dataChronologicalArticle?.payload?.isLock ? 0.5 : 1,
                  zIndex: dataChronologicalArticle?.payload?.isLock ? 1 : 0,
                }}
              >
                <Chart
                  options={dataLineMultiChart}
                  series={resultNewsPortalArticle}
                  type="line"
                  width="100%"
                  height="100%"
                />
              </Box>
            </div>
          </Paper>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <Paper
                sx={{
                  p: 2,
                  mb: 2,
                  mt: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: 4,
                  minHeight: 400,
                  position: 'relative',
                }}
              >
                <Typography mb={2} fontWeight={500} fontSize={18}>
                  Article by Media
                </Typography>
                <TableContainer component={Paper} style={{ maxHeight: '350px', overflow: 'auto', borderRadius: 8 }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                    <TableHead sx={{ backgroundColor: 'rgba(223, 202, 114, 1)' }}>
                      <TableRow
                        sx={{
                          backgroundColor: 'rgba(223, 202, 114, 1)',

                          '& .MuiTableCell-head': {
                            // padding: '6px 16px',
                            paddingY: { xs: 1, sm: 2 },
                            backgroundColor: 'rgba(223, 202, 114, 1)',
                            fontSize: { xs: 10, sm: 16 },
                            lineHeight: 1.5,
                            // backgroundColor: 'blue',
                          },
                        }}
                      >
                        <TableCell>No</TableCell>
                        {/* <TableCell align="right">Username</TableCell> */}
                        <TableCell align="left"> Media</TableCell>
                        <TableCell align="left">Total</TableCell>
                        <TableCell align="left">Positive</TableCell>
                        <TableCell align="left">Negative</TableCell>
                        <TableCell align="left">Neutral</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!isFetchingArticleByMedia &&
                        (dataArticleByMedia?.payload?.data || []).map((row, index) => (
                          <TableRow
                            key={row.No}
                            sx={{
                              // '&:last-child td, &:last-child th': { border: 0 }
                              '&:last-child td, &:last-child th': { border: 0 },
                              '& .MuiTableCell-body': {
                                fontSize: { xs: 10, sm: 16 },
                                paddingY: { xs: 1, sm: 2 },
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            {/* <TableCell align="left">{row.Media}</TableCell> */}
                            <TableCell align="left">{row.media}</TableCell>
                            <TableCell align="left">{row.total}</TableCell>
                            <TableCell align="left">{row.sentimentPositive}</TableCell>
                            <TableCell align="left">{row.sentimentNegative}</TableCell>
                            <TableCell align="left">{row.sentimentNeutral}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {isFetchingArticleByMedia && (
                  <Box
                    position={'absolute'}
                    sx={{
                      top: '60%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      '@media (max-width: 100px)': {
                        top: '60%',
                        left: '40%',
                        transform: 'translate(-40%, -40%)',
                      },
                    }}
                  >
                    <CircularProgress size={100} />
                  </Box>
                )}
                {!isFetchingArticleByMedia && dataArticleByMedia?.payload?.isLock && (
                  <Box
                    position={'absolute'}
                    sx={{
                      top: '60%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      '@media (max-width: 100px)': {
                        top: '60%',
                        left: '40%',
                        transform: 'translate(-40%, -40%)',
                      },
                    }}
                  >
                    <IconButton onClick={handleUnlockEntity}>
                      <img src={LockBigIcon} height={150} />{' '}
                    </IconButton>
                  </Box>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box
                position={'relative'}
                sx={{
                  minHeight: 300,
                  minWidth: { xs: '100%', sx: 300 },
                }}
              >
                <Paper
                  sx={{
                    p: 2,
                    flexGrow: 1,
                    mt: 2,
                    minHeight: 300,
                    minWidth: { xs: '100%', sm: 400 },
                    borderRadius: 4,
                    justifyContent: 'center',
                    flexDirection: 'column',
                    bgcolor: dataSentimentAnalysisOnline?.payload?.isLock ? 'white' : '',
                  }}
                >
                  <Typography fontWeight={500} fontSize={18}>
                    Talk by Sentiment
                  </Typography>
                  <Box className="donut" style={{ width: '100%' }}>
                    <Chart options={optionsDonuts} series={seriesDonut} type="pie" width="100%" />
                  </Box>
                </Paper>
                {isFetchingSentimentAnalysisOnline && (
                  <Box
                    position={'absolute'}
                    sx={{
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',

                      '@media (max-width: 100px)': {
                        top: '60%',
                        left: '40%',
                        transform: 'translate(-40%, -40%)',
                      },
                    }}
                  >
                    <CircularProgress size={100} />
                  </Box>
                )}
                {!isFetchingSentimentAnalysisOnline && dataSentimentAnalysisOnline?.payload?.isLock && (
                  <Box
                    position={'absolute'}
                    sx={{
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',

                      '@media (max-width: 100px)': {
                        top: '60%',
                        left: '40%',
                        transform: 'translate(-40%, -40%)',
                      },
                    }}
                  >
                    <IconButton onClick={handleUnlockEntity}>
                      <img src={LockBigIcon} height={150} />{' '}
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
      </TabContext>
    </Box>
  )
}
