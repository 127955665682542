/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, IconButton, Paper, Stack, Typography } from '@mui/material'
import Chart from 'react-apexcharts'
import LockIcon from '@/assets/lock-white-icon.svg'
import LockBigIcon from '@/assets/lock-big-icon.svg'
import { getPostType } from '@/network/api/analytic'

import { transformDataToDonutChart } from '@/utils/helper'
import CustomTooltip from '@/components/Tooltip'
import { ApexOptions } from 'apexcharts'

interface Payload {
  isLock: boolean
  data: {
    [key: string]: number
  }
}
export const PostTypeAnalytic = ({ id, handleUnlockEntity }: { id: string; handleUnlockEntity: () => void }) => {
  const navigate = useNavigate()
  const { startDate, endDate, selectedPlatform, selectedEntity } = useComparisonStore()
  const [isLoading, setLoading] = useState(false)
  const [dataPostType, setDataPostType] = useState<Payload>({} as Payload)
  const [blur] = useState(0)

  const series = useMemo(() => {
    return transformDataToDonutChart(dataPostType).series
  }, [dataPostType])

  const defaultOptions: ApexOptions = {
    // responsive: [
    //   {
    //     breakpoint: 480,
    //     options: {
    //       legend: {
    //         position: 'top',
    //       },
    //     },
    //   },
    // ],
    chart: {
      type: 'pie',
    },
    legend: {
      position: 'bottom',
    },
    labels: ['text', 'video', 'image'],
    colors: ['#FFC107', '#FF7043', '#4CAF50'],
  }

  useEffect(() => {
    fetchPostType()
  }, [startDate, endDate, selectedPlatform])
  const fetchPostType = async () => {
    const params = {
      startDate,
      endDate,
      entityId: selectedEntity?.entityId || id,
      platforms: selectedPlatform,
    }
    try {
      setLoading(true)
      const response = await getPostType(params)
      if (response.statusCode === 200) {
        setDataPostType(response?.payload)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (isLoading) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={100}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box position={'relative'} sx={{ minHeight: 300, width: '100%' }}>
      <Paper
        sx={{
          p: 2,
          flexGrow: 1,
          flexBasis: '100%',
          minWidth: 200,
          height: { xs: 350, sm: 400 },
          borderRadius: 4,
        }}
      >
        <Stack direction="row">
          <Typography fontWeight={500} fontSize={{ xs: 16, sm: 18 }}>
            Post Type
          </Typography>
          <CustomTooltip title="Percentage of post types (text, image, and video)" />
        </Stack>
        <Box position={'relative'} display={'flex'} justifyContent={'center'}>
          <Box
            className="donut"
            sx={{
              position: 'absolute',
              width: { xs: '100%', sm: 400 },
              height: '100%',
              filter: dataPostType?.isLock ? 'blur(8px)' : 'none',
              opacity: dataPostType?.isLock ? 0.4 : 1,
              zIndex: dataPostType?.isLock ? 1 : 0,
            }}
          >
            <Chart
              type="pie"
              series={series}
              // series={[13, 30, 6]}
              width="100%"
              options={defaultOptions}
            />
          </Box>
          <Box
            position={'absolute'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-between',
              flex: 1,
              top: 150,
              left: 0,
              right: 0,
              bottom: '50%',
              opacity: blur ? 1 : 0,
              zIndex: blur ? 2 : 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 2,
                }}
              >
                <IconButton onClick={handleUnlockEntity}>
                  <img src={LockBigIcon} width={150} />
                </IconButton>
              </Box>
              <IconButton onClick={() => navigate('/unlock', { state: { id, type: 'postmade' } })}>
                <Box
                  sx={{
                    backgroundColor: 'rgba(56, 42, 2, 1)',
                    px: 2,
                    borderRadius: 5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                  }}
                >
                  <img src={LockIcon} />
                  <Typography fontSize={18} fontWeight={600} color={'white'}>
                    Unlock
                  </Typography>
                </Box>
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}
