import { Box, Button, IconButton, Modal, Typography } from '@mui/material'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { getAuthMe, postResendOtp, postVerifyOtp } from '@/network/api/api'

export const ModalOtp = () => {
  const [otp, setOtp] = useState('')
  const [showOtpSentMessage, setShowOtpSentMessage] = useState(false)
  const [timerSeconds, setTimerSeconds] = useState(60) // 1 minute timer
  const [isTimerRunning, setIsTimerRunning] = useState(false)
  const [isResendOtpDisabled, setIsResendOtpDisabled] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const {
    // otp,
    email,
    modalOtp,
    setModalOtp,
    setErrorOtp,
    setModalOtpSuccess,
  } = useUserStore()

  const handleChange = (newValue: string) => {
    setOtp(newValue)
  }
  const triggerResendOtp = async () => {
    try {
      const response = await postResendOtp()
      if (response.statusCode === 201) {
        Promise.all([setOtp(''), setErrorOtp(false)])
        setShowOtpSentMessage(true)
        setIsTimerRunning(true)
        setIsResendOtpDisabled(true)
        setTimeout(() => {
          setShowOtpSentMessage(false)
          setIsTimerRunning(false) // Stop the timer
          setTimerSeconds(60) // Reset the timer
          setIsResendOtpDisabled(false)
        }, 60000)
      }
    } catch (error) {
      setErrorOtp(true)
    }
  }
  const triggerOtp = async () => {
    try {
      const response = await postVerifyOtp(otp)

      if (response.statusCode === 201) {
        const responseAuth = await getAuthMe()
        if (responseAuth.statusCode === 200) {
          if (responseAuth.payload.isVerified === true) {
            Promise.all([setModalOtp(false), setModalOtpSuccess(true), setOtp(''), setErrorOtp(false)])
          }
        }
      }
    } catch (error) {
      setErrorOtp(true)
      setShowErrorMessage(true) // Show the error message
      setTimeout(() => {
        setShowErrorMessage(false) // Hide the error message after 5 seconds
      }, 5000)
    }
  }
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    if (isTimerRunning && timerSeconds > 0) {
      interval = setInterval(() => {
        setTimerSeconds((prevSeconds) => prevSeconds - 1)
      }, 1000)
    } else if (timerSeconds === 0) {
      setIsTimerRunning(false)
      setIsResendOtpDisabled(false)
      setTimerSeconds(60) // Reset the timer
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isTimerRunning, timerSeconds, setIsResendOtpDisabled])

  return (
    <Modal open={modalOtp} aria-labelledby="keep-mounted-modal-title" aria-describedby="keep-mounted-modal-description">
      <Box
        sx={{
          borderRadius: '10px',
          padding: 2,
          my: 5,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          top: '50%',
          left: '50%',
          width: { xs: 360, sm: 550 },
          height: 400,
        }}
      >
        <Typography
          // variant="div"
          gutterBottom={true}
          sx={{
            fontFamily: 'Inter',
            fontSize: '24px',
            fontWeight: '600',
            lineHeight: '29px',
            letterSpacing: '0em',
            textAlign: 'center',
          }}
        >
          Please enter OTP to verify your account
        </Typography>
        <Typography
          gutterBottom={true}
          sx={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '29px',
            letterSpacing: '0em',
            textAlign: 'center',
            mb: 2,
          }}
        >
          Your OTP has been sent to {email}
        </Typography>
        {showErrorMessage && (
          <Typography
            gutterBottom={true}
            sx={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '29px',
              letterSpacing: '0em',
              textAlign: 'center',
              color: 'red',
            }}
          >
            Your OTP is wrong check again
          </Typography>
        )}
        <MuiOtpInput
          // onComplete={handleComplete}
          TextFieldsProps={{ placeholder: '-' }}
          sx={{
            marginBottom: '20px',
            '& .MuiTextField-root': { borderRadius: '6px', width: 56 },
            gap: 3,
            borderRadius: '10px',
          }}
          value={otp}
          onChange={handleChange}
        />

        <Button
          disabled={isResendOtpDisabled}
          disableElevation
          disableRipple
          disableTouchRipple
          onClick={triggerOtp}
          size="large"
          variant="contained"
          // fullWidth
          sx={{
            padding: '20px 60px',
            '&:hover': {
              backgroundColor: '#6D5103',
              boxShadow: 'none',
            },
            borderRadius: '10px',
            marginBottom: '20px',
            backgroundColor: '#6D5103',
            color: 'white',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '16px',
            letterSpacing: '0em',
            textAlign: 'center',
          }}
        >
          Validate
        </Button>
        <IconButton onClick={triggerResendOtp} disabled={isResendOtpDisabled}>
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '16px',
              letterSpacing: '0em',
              textAlign: 'center',
              color: '#6D5103',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            {isTimerRunning ? `Resend OTP in ${timerSeconds} seconds` : 'Resend OTP'}{' '}
          </Typography>
        </IconButton>
        {showOtpSentMessage && <Typography>We have sent you an OTP check you email.</Typography>}
      </Box>
    </Modal>
  )
}
