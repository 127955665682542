import * as React from 'react'
import { Body } from '@/layout'
import { Box, Typography, Stepper, Step, Container } from '@mui/material'

import StepLabel from '@mui/material/StepLabel'
import { CoinPackage } from '@/components/CoinPackage'
import FormSecondComponent from '@/components/CoinPackage/RegisterUser'
import { getToken } from '@/utils/storage'
import { PaymentSummary } from '@/components/CoinPackage/PaymentSummary'

const steps = [
  { title: 'Select Coin Package', id: 0, subtitle: 'Select' },
  { title: 'Sign Up', id: 1, subtitle: 'Create Account' },
  { title: 'Payment', id: 2, subtitle: 'Proceed To Payment' },
]
const stepsHasLogin = [
  { title: 'Select Coin Package', id: 0, subtitle: 'Select' },
  { title: 'Payment', id: 1, subtitle: 'Proceed To Payment' },
]

const TopUpPage = () => {
  const token = getToken()
  const navigate = useNavigate()
  const { activeStep, setActiveStep } = useUserStore()
  const { topup } = useProfileStore()

  const StepComponent = () => {
    if (token) {
      switch (activeStep) {
        case 0:
          return <CoinPackage />
        case 1:
          return <PaymentSummary />
        default:
          break
      }
    } else {
      switch (activeStep) {
        case 0:
          return <CoinPackage />
        case 1:
          return <FormSecondComponent />
        case 2:
          return <PaymentSummary />
        default:
          break
      }
    }
  }
  const handleBack = () => {
    setActiveStep(0)
  }

  useEffect(() => {
    const isLoggedIn = !!token
    if (!isLoggedIn) navigate('/signin', { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <Body sticky={false}>
      <Container sx={{ mt: { xs: 2, md: 10 } }}>
        <Box sx={{ width: '100%', p: { xs: 1, md: 5 } }}>
          <Container sx={{ mb: 4 }}>
            <Stepper activeStep={activeStep}>
              {token
                ? stepsHasLogin.map((item) => {
                    const stepProps: { completed?: boolean } = {}
                    const labelProps: {
                      optional?: React.ReactNode
                    } = {}
                    if (item?.subtitle) {
                      if (item?.id === 0) {
                        labelProps.optional = (
                          <Typography variant="caption" fontSize={{ xs: 8, md: 12 }}>
                            {topup?.coin} Coin.{' '}
                            <Typography
                              onClick={handleBack}
                              variant="caption"
                              component={'span'}
                              fontWeight={600}
                              fontSize={{ xs: 8, md: 12 }}
                            >
                              Change
                            </Typography>
                          </Typography>
                        )
                      } else {
                        labelProps.optional = (
                          <Typography variant="caption" fontSize={{ xs: 8, md: 12 }}>
                            {item.subtitle}
                          </Typography>
                        )
                      }
                    }
                    // if (isStepSkipped(index)) {
                    //   stepProps.completed = false
                    // }
                    return (
                      <Step key={item.id} {...stepProps}>
                        <StepLabel {...labelProps}>
                          <Typography sx={{ fontSize: { xs: 8, md: 14 } }}>{item.title}</Typography>
                        </StepLabel>
                      </Step>
                    )
                  })
                : steps.map((item) => {
                    const stepProps: { completed?: boolean } = {}
                    const labelProps: {
                      optional?: React.ReactNode
                    } = {}
                    if (item?.subtitle) {
                      if (item?.id === 0) {
                        labelProps.optional = (
                          <Typography variant="caption" fontSize={{ xs: 8, md: 12 }}>
                            {topup?.coin} Coin.{' '}
                            <Typography
                              onClick={handleBack}
                              variant="caption"
                              component={'span'}
                              fontWeight={600}
                              fontSize={{ xs: 8, md: 12 }}
                            >
                              Change
                            </Typography>
                          </Typography>
                        )
                      } else {
                        labelProps.optional = (
                          <Typography variant="caption" fontSize={{ xs: 8, md: 12 }}>
                            {item?.subtitle}
                          </Typography>
                        )
                      }
                    }
                    // if (isStepSkipped(index)) {
                    //   stepProps.completed = false
                    // }
                    return (
                      <Step key={item.id} {...stepProps}>
                        <StepLabel {...labelProps}>
                          <Typography sx={{ fontSize: { xs: 8, md: 14 } }}>{item.title}</Typography>
                        </StepLabel>
                      </Step>
                    )
                  })}
            </Stepper>
          </Container>
          <Box>
            <StepComponent />
          </Box>
        </Box>
      </Container>
    </Body>
  )
}

export default TopUpPage
