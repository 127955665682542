/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-catch */
import { request } from '@/network/axios'
import { API as fetchAPI } from './api'

import Axios from 'axios'
import { getToken } from '@/utils/storage'
import { getCanvasData } from './api-params-moudle'
import { GetCityTotal } from './api-res-model'

enum APIS {
  GET_CITY_TOTAL_NUMBER = '/xxxx/xxxx/xxxxx',
  AUTH = '/api/v1/auth',
  USER = '/api/v1/user',
}
interface SignInResponse {
  payload: {
    token: string
  }
}
interface SignUpResponse {
  payload: {
    token: string
  }
  statusCode: number
}
interface VerifyOtpResponse {
  payload: {
    token: string
  }
  statusCode: number
}

export interface signUpLaunchParams {
  fullname: string
  password: string
  email: string
}

const API_HOST = import.meta.env.VITE_API_SERVER_URL
const getBaseUrl = (url: any) => {
  return API_HOST + url
}
export const API = async (url: string, method: string, params: any, body: any) => {
  try {
    const baseUrl = getBaseUrl(url)
    const token = getToken()
    const headers: Record<string, string> = {}
    // const token: string | null = JSON.parse(localStorage.getItem('token'))
    if (token) {
      headers['authorization'] = `Bearer ${token}`
    }
    const option = {
      url: baseUrl,
      headers,
      method,
      timeout: 30000,
    }

    if (params) {
      Object.assign(option, { params })
    }

    if (body) {
      Object.assign(option, { data: body })
    }

    const { data } = await Axios(option)
    return data
  } catch (error) {
    throw error
  }
}
export const getCityTotalNumber = (params: getCanvasData) =>
  request.get<GetCityTotal>(APIS.GET_CITY_TOTAL_NUMBER, params)

export const signIn = (body: { username: string; password: string }) =>
  request.post<SignInResponse>(`${APIS.AUTH}/signin`, body)

export const signUp = (body: { fullname: string; password: string; email: string }) =>
  request.post<SignUpResponse>(`${APIS.AUTH}/register`, body)

export const signUpLaunch = (params: signUpLaunchParams) => request.post<any>(`${APIS.AUTH}/register-v2`, params)

export const postVerifyOtp = (otp: string) => request.post<VerifyOtpResponse>(`${APIS.AUTH}/otp/verify`, { otp })

export const postResendOtp = () => request.post<VerifyOtpResponse>(`${APIS.AUTH}/otp/resend`, null)

export const getCoinHistory = async (params: any) => {
  try {
    const response = await fetchAPI(`api/v1/wallet/history`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getWallet = async () => {
  try {
    const response = await fetchAPI(`api/v1/wallet`, 'GET', null, null)
    return response
  } catch (error) {
    return error
  }
}
export const getSpentHistory = async (params: any) => {
  try {
    const response = await fetchAPI(`api/v1/order/spent_history`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const postInactiveMe = async () => {
  try {
    const response = await fetchAPI(`api/v1/user/inactive/me`, 'post', null, null)
    return response
  } catch (error) {
    return error
  }
}
export const getPricePerCoin = async () => {
  try {
    const response = await fetchAPI(`api/v1/general/coin_price`, 'GET', null, null)
    return response
  } catch (error) {
    return error
  }
}

export const getSearchEntity = async (params: any) => {
  try {
    const response = await fetchAPI(`api/v1/general/category`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getListEntity = async (params: any) => {
  try {
    const response = await fetchAPI(`api/v1/order/my_entity`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const getListSubsAlert = async (params: any) => {
  try {
    const response = await fetchAPI(`api/v1/subs-alert/list-subs-alert`, 'GET', params, null)
    return response
  } catch (error) {
    return error
  }
}
export const postChangeSubsAlertStatus = async (body) => {
  try {
    const response = await fetchAPI(`api/v1/subs-alert/change-status`, 'post', null, body)
    return response
  } catch (error) {
    return error
  }
}
export const postChangeSubsAlertFrequent = async (body) => {
  try {
    const response = await fetchAPI(`api/v1/subs-alert/change-frequent`, 'post', null, body)
    return response
  } catch (error) {
    return error
  }
}
export const putProfile = async (body) => {
  try {
    const response = await fetchAPI(`api/v1/user/profile`, 'put', null, body)
    return response
  } catch (error) {
    return error
  }
}
export const changePassword = async (body) => {
  try {
    const response = await fetchAPI(`api/v1/auth/change-password`, 'post', null, body)
    return response
  } catch (error) {
    return error
  }
}
export const forgotPassword = async (body) => {
  try {
    const response = await fetchAPI(`api/v1/auth/forgot-password`, 'post', null, body)
    return response
  } catch (error) {
    return error.response.data
  }
}
export const verifyForgotPassword = async (body) => {
  try {
    const response = await fetchAPI(`api/v1/auth/forgot-password/verify`, 'post', null, body)
    return response
  } catch (error) {
    return error.response.data
  }
}

export const getPaymentList = async () => {
  try {
    const response = await fetchAPI(`api/v1/payment/channels`, 'get', null, null)
    return response
  } catch (error) {
    return error.response.data
  }
}

export const getCoinPackage = async () => {
  try {
    const response = await fetchAPI(`api/v1/package-coin`, 'get', null, null)
    return response
  } catch (error) {
    return error.response.data
  }
}

export const getAuthMe = async () => {
  try {
    const response = await API(`api/v1/auth/me`, 'GET', null, null)
    return response
  } catch (error) {
    return error
  }
}
